import { useQuery } from "@tanstack/react-query";
import { getAssetsBlocked } from "../infra/api/BankInfra";

export function useGetAssetsBlocked() {
  const { data, ...rest } = useQuery(["getAssetsBlocked"], () =>
    getAssetsBlocked()
  );

  return { data, ...rest };
}
