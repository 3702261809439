import { balcaoApiInstance } from "../../../../utils/axios";

export interface RegisterDirectBuyAndSellBidProps {
  submarket: string;
  type: "COMPRA" | "VENDA";
  productId: string;
  valueFrom: number;
  valueTo: number;
  tolerance: number;
}
export async function registerDirectBuyAndSellBid(
  options: RegisterDirectBuyAndSellBidProps
) {
  const { data } = await balcaoApiInstance.post(
    "/trading/trade-request",
    options
  );
  return data;
}
