import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  pt: {
    translation: {
      "Disable Dark Theme": "Desativar Tema Escuro",
      "Enable Dark Theme": "Ativar Tema Escuro",
      NOT_WORKDAY: "Esta ação está disponível apenas em dias úteis",

      Home: "Home",
      Loading: "Carregando",
      "Sign In": "Entrar",
      "Sign in": "Entrar",
      Welcome: "Bem-vindo(a)",
      "Sign in to your account to continue":
        "Entre na sua conta para continuar",
      "Remember me": "Lembrar-me",
      "Forgot password": "Esqueci a senha",
      "User does not exist or has been disabled.":
        "O usuário não existe ou foi desativado.",
      "The password is invalid.": "A senha está incorreta.",
      "Something went wrong.": "Algo deu errado.",
      "Email already registered.": "E-mail já registrado.",
      "User does not have permission to access this system.":
        "O usuário não tem permissão para acessar este sistema.",

      "Reset Password": "Redefinir Senha",
      "Enter your email to reset your password":
        "Digite seu e-mail para redefinir sua senha",
      "Check your email and follow the instructions to reset your password":
        "Verifique seu e-mail e siga as instruções para redefinir sua senha",
      "404 Error": "Erro 404",
      "Page not found": "Página não encontrada",
      "The page you are looking for might have been removed":
        "A página que você está procurando pode ter sido removida",
      "Return to website": "Retornar ao site",
      "500 Error": "Erro 500",
      "Internal server error": "Erro interno do servidor",
      "The server encountered something unexpected that didn't allow it to complete the request":
        "O servidor encontrou algo inesperado que não permitiu concluir a solicitação",

      Product: "Produto",
      Products: "Produtos",
      Settings: "Configurações",
      "Tunnel Limits": "Limites do Túnel",
      "Product Maintenance": "Manutenção de Produtos",
      "Product List": "Lista de Produtos",
      "Tunnel limit Update": "Atualizar Limite do Túnel",
      "Last Version Date and Time": "Data e Hora da Última Versão",
      "Measurement Unit": "Unidade de Medida",
      "Field Type": "Tipo de Campo",
      "Contract Parameter": "Parâmetro do Contrato",
      Required: "Obrigatório",
      "Field Max Length": "Tamanho Máximo do Campo",
      "Add Product": "Adicionar Produto",
      "Edit Product": "Editar Produto",
      "Product Data": "Dados do Produto",
      "Product Name": "Nome do Produto",
      "ZapSign Contract Identifier": "Identificador do Contrato ZapSign",
      Attributes: "Atributos",
      "Product Attributes": "Atributos do Produto",
      "Attribute List": "Lista de Atributos",
      Description: "Descrição",
      string: "Texto",
      number: "Numérico",
      boolean: "Lógico",
      Participant: "Participante",
      "Choose a Participant": "Escolha um Participante",
      "Associate Products": "Associar Produtos",
      "Select All": "Selecionar Tudo",

      "Associate Participants": "Associar Participantes",

      Participants: "Participantes",
      Draft: "Rascunho",
      Add: "Adicionar",
      Edit: "Editar",
      Enable: "Ativar",
      Disable: "Desativar",
      "Add Participant": "Adicionar Participante",
      "Edit Participant": "Editar Participante",
      "n Selected_one": "{{count}} selecionado",
      "n Selected_other": "{{count}} selecionados",
      "Trade Name": "Nome Fantasia ou Reduzido",
      "CCEE Name": "Nome CCEE",
      CNPJ: "CNPJ",
      Class: "Classe",
      Email: "E-mail",
      Password: "Senha",
      Actions: "Ações",

      Offers: "Ofertas",
      "Offer Type": "Tipo da Oferta",
      "Offers Panel": "Painel de Ofertas",
      "Negotiated Offers": "Ofertas Negociadas",
      "Last Price": "Último Preço",
      Change: "Variação",
      "Highest Price": "Maior Preço",
      "Lowest Price": "Menor Preço",
      "Average Price": "Preço Médio",
      History: "Histórico",
      "Offer History": "Histórico de Ofertas",
      "Register Offer": "Registrar Oferta",
      "Register Offers": "Registrar Ofertas",
      "Offer Details": "Detalhes da Oferta",
      "Make Participant Data Public": "Tornar Público Dados do Participante",
      Type: "Tipo",
      "End of Offer": "Término da Oferta",
      Month: "Mês",
      "Delivery Period": "Período de Fornecimento",
      "Deliv. Period": "Período Forn.",
      Start: "Início",
      End: "Fim",
      to: "a",
      Volume: "Volume",
      "Remaining Order Volume": "Volume Restante da Ordem",
      "Offered Volume": "Volume Ofertado",
      Submarket: "Submercado",
      "Re-TUSD": "Re-TUSD",
      "Source Type": "Tipo de Fonte",
      "Source Type Short": "Tp. de Fonte",
      Price: "Preço",
      Price2: "Cotação",
      "Reverse Price2": "Cotação Inversa",
      "Payment Method": "Método de Pagamento",
      "Bank Transaction": "Transação Bancária",
      "Transaction Fees": "Taxas de Transação",
      "Orders Reached": "Ordens Alcançadas",
      "You receive": "Você receberá",
      "Slippage Tolerance": "Tolerância a Discrepâncias",
      "Price Type": "Tipo de Preço",
      "Atrelado ao PLD": "Atrelado ao PLD",
      "PLD+SPREAD": "Atrelado ao PLD",
      "Atrelado PLD + Spread": "Atrelado ao PLD + Spread",
      Fixo: "Fixo",
      FIXED: "Fixo",
      Spread: "Spread",
      value: "Valor",
      "Lance livre": "Lance Livre",
      "L Livre": "L. Livre",
      Value: "Valor",
      "Updated at": "Atualizado em",
      "Free Bid": "Lance Livre",
      "Free Bid Type": "Tipo do Lance Livre",
      REGISTER_OFFER_CONFIRMATION_MESSAGE1:
        "Confira as informações do seu produto.",
      REGISTER_OFFERS_CONFIRMATION_MESSAGE1:
        "Confira as informações dos seus produtos.",
      REGISTER_OFFER_CONFIRMATION_MESSAGE2:
        'Caso esteja de acordo com os dados, basta clicar em "Confirmar" para inserir o produto na plataforma de negociação.',
      REGISTER_OFFERS_CONFIRMATION_MESSAGE2:
        'Caso esteja de acordo com os dados, basta clicar em "Confirmar" para inserir os produtos na plataforma de negociação.',

      "Select Participant": "Selecionar Participante",
      "Choose a participant to continue":
        "Escolha um participante para continuar",
      "Register Buy Offer": "Registrar Oferta de Compra",
      "Register Sell Offer": "Registrar Oferta de Venda",
      "Sell Offers": "Ofertas de Venda",
      "Buy Offers": "Ofertas de Compra",
      "Operation Code": "Código da Operação",
      "Delivery Point": "Ponto de Entrega",
      "Delivery Point_short": "Pt. Entreg.",
      Period: "Período",
      "Buy Volume": "Vol. de Compra",
      "Buy Offer": "Oferta de Compra",
      "Sell Volume": "Vol. de Venda",
      "Sell Offer": "Oferta de Venda",
      "Best Bid Value": "Melhor Valor do Lance",
      "Best Bid": "Melhor Lance",
      "Remaining Time": "Tempo Restante",
      "Average Remaining Time": "Tempo Médio Restante",
      "Bid Value": "Valor do Lance",
      "Bid Volume": "Vol. do Lance",
      NET: "NET",
      Status: "Status",
      Time: "Hora",
      Time2: "Horário",
      Bid: "Lance",
      "Register Bid": "Registrar Lance",
      "Your bid is currently the best value":
        "Seu lance é o de melhor valor até o momento",
      "Your bid is not the best value": "Seu lance não é o de melhor valor",

      "Fill Order": "Preencher Ordem",
      "Operation Summary": "Resumo da Operação",
      "Please verify that the information below is correct before registering the offer.":
        "Verifique se as informações abaixo estão corretas antes de registrar a oferta.",
      "Please verify that the information provided is correct and try again.":
        "Verifique se as informações fornecidas estão corretas e tente novamente.",
      "Please verify that the information below is correct before registering the bid.":
        "Verifique se as informações abaixo estão corretas antes de registrar o lance.",
      "New Offer": "Nova Oferta",
      "Edit Offer": "Editar Oferta",
      "Register offer footer message":
        'Preencha todos os dados do seu produto e clique em "Salvar Produto". Após, clique em "Avançar para Resumo" para seguir.',
      "Save Product": "Salvar Produto",
      "Go to Summary": "Avançar para Resumo",
      New: "Novo",
      Operation: "Operação",
      "Operation Type": "Tipo da Operação",
      Applies: "Se aplica",
      "Does not apply": "Não se aplica",
      Max: "Max",
      Min: "Min",

      More: "Mais",
      Bids: "Lances",
      "Bids Made": "Lances Efetuados",
      "Offer has not been bid yet": "A oferta ainda não recebeu lances",
      "Date and Time": "Data e Hora",
      Order: "Ordem",
      CRIADO: "Criado",
      CANCELADO: "Cancelado",
      ACEITO: "Aceito",

      ABERTA: "Aberta",
      "PARCIALMENTE ENCERRADA": "Parc. Encerr.",
      "CONSOLIDANDO NEGOCIAÇÃO": "Consolidando",
      ENCERRADA: "Encerrada",
      EXPIRADA: "Expirada",
      CANCELADA: "Cancelada",

      Current: "Atual",
      All: "Todos",
      "Show Finished Offers": "Mostrar Ofertas Encerradas",
      "All Offers": "Todas as Ofertas",
      "My Offers": "Minhas Ofertas",
      "My Bids": "Meus Lances",
      "My Bid": "Meu Lance",

      "Do you really want to bid in the value of {{value}}?":
        "Deseja realmente efetuar um lance no valor de {{value}}?",
      "Do you really want to bid in the spread value of {{value}}?":
        "Deseja realmente efetuar um lance no valor de spread de {{value}}?",
      "Do you really want to cancel offer {{code}}?":
        "Deseja realmente cancelar a oferta {{code}}?",
      "Do you really want to cancel the sell offer from operation {{code}}?":
        "Deseja realmente cancelar a oferta de venda da operação {{code}}?",
      "Do you really want to cancel the buy offer from operation {{code}}?":
        "Deseja realmente cancelar a oferta de compra da operação {{code}}?",
      "Do you really want to change offer {{code}} price to {{price}}?":
        "Deseja realmente mudar o valor da oferta {{code}} para {{price}}?",
      "Do you really want to accept the bid?":
        "Deseja realmente aceitar o lance?",
      "Do you really want to cancel the bid?":
        "Deseja realmente cancelar o lance?",
      "Do you really want to delete this offer?":
        "Deseja realmente apagar esta oferta?",

      "Registration Data": "Dados Cadastrais",
      Documents: "Documentos",
      "Operating Limits": "Limites Operacionais",
      "Operating Limit": "Limite Operacional",
      "Legal Representatives": "Representantes Legais",
      "User Maintenance": "Manutenção de Usuários",

      "Company Data": "Dados da Empresa",
      Address: "Endereço",
      "Participant Class": "Classe",
      "Corporate Name": "Razão Social",
      "State Registration": "Inscrição Estadual",
      "Municipal Registration": "Inscrição Municipal",
      Website: "Site",
      "Telephone Number": "Telefone",
      "Cellphone Number": "Celular",
      "Postal Code": "CEP",
      "Address Number": "Número",
      Complement: "Complemento",
      District: "Bairro",
      City: "Cidade",
      State: "Estado",
      Save: "Salvar",
      Accept: "Aceitar",
      Cancel: "Cancelar",
      "Go Back": "Voltar",
      "Add More Products": "Adicionar Mais Produtos",
      Confirm: "Confirmar",
      Delete: "Apagar",
      Clear: "Limpar",
      "This field is required": "Este campo é obrigatório",
      "The data entered is too large": "O valor informado é muito grande",
      "The data entered is invalid": "O {{data}} informado é inválido",
      "The data entered is invalid_female": "A {{data}} informada é inválida",
      "Max date": "Data máxima {{data}}",
      "Min date": "Data mínima {{data}}",
      "You must fill in all required fields":
        "Você deve preencher todos os campos obrigatórios",
      "The operation completed successfully": "Operação efetuada com sucesso",

      "Bank info": "Dados bancários",
      BankCode: "Código do banco",
      "Invalid bank code": "Código do banco inválido",
      Agency: "Agência",
      "Invalid agency": "Agência informada inválida",
      "Invalid account": "Conta inválida",

      Comercializadora: "Comercializadora",
      "Comercializador varejista": "Comercializador varejista",
      "Consumidor livre": "Consumidor livre",
      "Consumidor especial": "Consumidor especial",
      "Produtor independente": "Produtor independente",
      Autoprodutor: "Autoprodutor",

      merchant: "Comercializadora",
      retailer: "Comercializador varejista",
      freeConsumer: "Consumidor livre",
      specialConsumer: "Consumidor especial",
      independentProducer: "Produtor independente",
      selfProducer: "Autoprodutor",
      generator: "Gerador",

      "Additional Information": "Informações Adicionais",
      "Document Name": "Nome do Documento",
      File: "Arquivo",
      "Upload a File": "Enviar um Arquivo",
      "Drop the file here...": "Solte o arquivo aqui...",
      "File is larger than x": "O arquivo é maior que {{x}}",
      "File type must be x": "O arquivo precisa ser do tipo {{x}}",
      "Files type must be x and less than y":
        "Os arquivos precisam ser do tipo {{x}} e menores que {{y}}",
      "The file is not accepted": "O arquivo não é aceito",

      buy: "Compra",
      Buy: "Comprar",
      COMPRA: "Compra",
      sell: "Venda",
      Sell: "Vender",
      VENDA: "Venda",
      "COMPRA&VENDA": "Compra & Venda",
      "Total Granted": "Total Concedido",
      Consumed: "Consumido",

      Details: "Detalhes",
      "See Details": "Ver detalhes",
      "See Buy Offer Details": "Ver detalhes de oferta de compra",
      "See Sell Offer Details": "Ver detalhes de oferta de venda",
      "Chat with bidder": "Conversar com ofertante",
      "Cancel Offer": "Cancelar Oferta",
      "Cancel Buy Offer": "Cancelar oferta de compra",
      "Cancel Sell Offer": "Cancelar oferta de venda",
      "Change Offer Price": "Alterar valor da oferta",
      "Change Buy Offer Price": "Alterar valor da oferta de compra",
      "Change Sell Offer Price": "Alterar valor da oferta de venda",
      "Offer Chat": "Chat",
      "Sell Offer Chat": "Chat da oferta de venda",
      "Buy Offer Chat": "Chat da oferta de compra",

      "Legal Representative Data": "Dados do Representante Legal",
      "List of Legal Representatives": "Lista de Representantes Legais",
      Name: "Nome",
      "Full Name": "Nome Completo",
      "Always Sign": "Sempre assinar",
      Position: "Cargo",
      CPF: "CPF",
      Nationality: "Nacionalidade",
      "Birth Date": "Data de Nascimento",
      "Document Type": "Tipo de Documento",
      "Document Number": "Número do Documento",
      "Date of Issue": "Data de Emissão",
      "Issuing Agency": "Órgão Expeditor",
      "Federated Unit": "UF",

      "User Data": "Dados do Usuário",
      "User List": "Lista de Usuários",
      "User Permissions": "Permissões do Usuário",
      System: "Sistema",
      "Finish and Activate Participant": "Finalizar e Ativar Participante",
      "The participant is already active": "O participante já está ativo",
      "The participant is not active": "O participante não está ativo",

      Administrators: "Administradores",
      "Administrator Data": "Dados do Administrador",

      Search: "Pesquisar…",
      "Search here": "Pesquise aqui",
      "No chat found": "Nenhuma conversa encontrada",
      "Chat finished": "Esta oferta se encerrou",
      "Chat message blocked":
        "Sua mensagem não pode ser enviada, por favor evite enviar dados pessoais como endereço, número de telefone e e-mail",
      "Chat generic error":
        "Não foi possível enviar a mensagem, por favor verifique sua conexão",
      "No chat selected": "Nenhuma conversa selecionada",
      "Unable to load data": "Não foi possível carregar os dados",
      "Type a message here": "Escreva aqui",
      "Unable to send new messages because the offer has ended":
        "Não é possível enviar novas mensagens porque a oferta terminou",
      "Do you really want to delete this conversation?":
        "Você realmente deseja apagar esta conversa?",
      "New messages": "Novas mensagens",
      "Unread messages": "Mensagens não lidas",
      Seen: "Visualizado",
      Send: "Enviar",
      "My Order": "Minha Ordem",
      "Avg. PLD": "PLD Médio",
      Difference: "Diferença",
      "I'm buying": "Estou comprando",
      "I'm selling": "Estou vendendo",
      "They're buying": "Estão comprando",
      "They're selling": "Estão vendendo",
      "Your counterpart hasn't bid yet": "Sua contraparte ainda não deu lances",
      "You haven't bid yet": "Você ainda não deu lances",
      "New Bid": "Novo Lance",
      Notifications: "Notificações",
      Messages: "Mensagens",
      Languages: "Idiomas",
      Account: "Conta",
      Role: "Perfil",
      "Sign out": "Sair",
      "Welcome back": "Bem vindo(a) de volta",
      "We've missed you": "Sentimos sua falta",
      Administrator: "Administrador",
      Administrator_female: "Administradora",
      User: "Usuário",
      User_female: "Usuária",

      "History Offer Date": "Data",
      "History Product Name": "Produto",
      "History Code Operation": "Código da Operação",
      "History Delivery Location": "Ponto de entrega",
      "History Operation": "Operação",
      Situation: "Situação",
      "Session History": "Histórico da Sessão",
      "There is no data to show at this time":
        "Não há dados para exibir no momento",

      "Parte Vendedora": "Parte Vendedora",
      "Parte Compradora": "Parte Compradora",
      "Participant Data": "Dados do Participante",
      "Payment Data": "Dados de Pagamento",
      Contacts: "Contatos",
      "Contact Name": " Nome do Contato",
      "Offer Information": "Informações da Oferta",
      "Informações Complementares": "Informações Complementares",
      "As partes declaram que possuem poderes de representação para realizar a operação de compra e venda de energia":
        "As partes declaram que possuem poderes de representação para realizar a operação de compra e venda de energia",

      Flexibility: "Flexibilidade",
      Seasonality: "Sazonalidade",
      Modulation: "Modulação",
      "Sector Charges": "Encargos Setoriais",
      "PLD Value": "Valor PLD",
      "Operation Sum Basis": "Base Soma Operação",
      "Operation Total Value": "Valor Total da Operação",
      Taxes: "Impostos",
      Payment: "Pagamento",
      "Payment Date": "Data de Pagamento",
      "Due Date": "Data de Vencimento",
      "Price Base Date": "Data Base do Preço",
      Readjustment: "Reajuste",
      "Financial Guarantee": "Garantia Financeira",
      "Energy Registration": "Registro da Energia",
      "Republication of the PLD": "Republicação do PLD",
      Responsibilities: "Responsabilidades",
      "Marketing Rules": "Regras de Comercialização",
      "Act of God and Force Majeure": "Caso Fortuito e Força Maior",
      "Subscription Commitment CCVE": "Compromisso de Assinatura CCVE",
      Confidentiality: "Confidencialidade",
      "I agree with the Acceptance Term": "Concordo com o Termo de Aceite",
      "You must agree with the Acceptance Term":
        "Você deve concordar com o Termo de Aceite",
      "Do you really want to accept the terms?":
        "Deseja realmente aceitar os termos?",
      "You have already accepted this term": "Você já aceitou este termo",
      Validation: "Validação",

      "Your couterparty has already accepted this term":
        "Sua contraparte já aceitou este termo",
      "Waiting for your counterparty to accept the terms":
        "Aguardando sua contraparte aceitar este termo",

      Limits: "Limites",
      "Adjust Limits": "Ajustar Limites",

      Clone: "Clonar",
      View: "Visualizar",

      Contract: "Contrato",
      Contracts: "Contratos",
      ContractList: "Lista de Contratos",
      "Contract Details": "Detalhes do Contrato",
      "View Contract": "Visualizar Contrato",
      "Contract Status": "Situação do contrato",
      Origin: "Origem",
      Pendente: "Pendente",
      Pending: "Pendente",
      Assinado: "Assinado",
      Signed: "Assinado",
      "Pendente de Assinatura": "Pendente de Assinatura",
      "Pending Signature": "Pendente de Assinatura",

      IssueDate: "Data de Emissão",
      Buyer: "Comprador",
      Seller: "Vendedor",
      TotalValue: "Valor Total",

      "Acceptance Term": "Termo de Aceite",
      "Termo de Aceite": "Termo de Aceite",
      "Closing Statement": "Fechamento da Boleta",
      "Fechamento da Boleta": "Fechamento da Boleta",

      SellerSigners: "Assinantes do vendedor",
      BuyerSigners: "Assinantes do comprador",
      Signer: "Assinante",
      SignerSituation: "Situação",

      "Tipo de Energia": "Tipo de Energia",
      "Profile Key": "Perfil CliqCCEE",
      "Profile Code": "Código CliqCCEE",

      PLD: "PLD",
      "Weekly Average": "Média Semanal",
      "Monthly Average": "Média Mensal",
      "Reference month": "Mês de referência",
      "Unavailable data": "Dados indisponíveis",
      Reference: "Referência",

      "You have unsaved data on this page. Do you really want to leave this page?":
        "Você possui dados não salvos nesta página. Deseja realmente sair desta página?",

      NoMessages: "Nenhuma mensagem ainda aqui",
      NoMessagesReceivedForThisOffer:
        "Nenhuma mensagem recebida para esta oferta",

      "Direct Buy and Sell": "Compra e Venda Direta",
      From: "De",
      To: "Para",
      "Preview conversion": "Pré-visualizar conversão",
      "Volume unavailable": "Volume indisponível",
      Sum: "Soma",
      Created2: "Criadas",
      Orders2: "Ordens de",
      "There are no orders of this type available at the moment":
        "Não há ordens deste tipo disponíveis no momento",
      FAQ: "FAQ",
      Confirmation: "Confirmação",

      No: "Não",
      Yes: "Sim",

      Bank: "Banco",
      "Available Balance": "Saldo Disponível",
      "Financial Balance": "Saldo Financeiro",
      "Energy Balance": "Saldo Energia",
      "Available Financial Balance": "Saldo Financeiro Disponível",
      "Available Energy Balance": "Saldo Energia Disponível",
      Deposit: "Depositar",
      Update: "Atualizar",
      Withdraw: "Sacar",
      "Financial Deposit": "Depósito Financeiro",
      "Energy Deposit": "Depósito Energia",
      "Financial Withdrawal": "Saque Financeiro",
      "Energy Withdrawal": "Saque Energia",
      "Manage Deposits and Withdrawals": "Gerenciar Depósitos e Saques",
      "Deposit Value": "Valor do Depósito",
      "Max Spread Value On First Offer":
        "Valor Máximo do Spread na Primeira Oferta",
      "Min Spread Value On First Offer":
        "Valor Mínimo do Spread na Primeira Oferta",
      "Maximum Positive Deviation when Creating the Offer":
        "Máximo de Desvio Positivo na Criação da Oferta",
      "Maximum Negative Deviation when Creating the Offer":
        "Máximo de Desvio Negativo na Criação da Oferta",
      "Account Name": "Nome da Conta",
      "Bank Name": "Nome do Banco",
      "Account Number": "Número da Conta",
      "bank data": "dados do banco",
      "client data": "dados do cliente",
      "Energy Type": "Tipo de Energia",
      "Bank Country": "País do Banco",
      "Account Holder Name": "Nome do Titular da Conta",
      "Account Type": "Tipo de Conta",
      "Agency Number": "Número da Agência",
      "Withdrawal Value": "Valor do Saque",
      "Current Account": "Conta Corrente",
      "Savings Account": "Conta Poupança",
      "Payment Account": "Conta de Pagamento",
      "Bank Information": "Informações do Banco",
      "CCEE Information": "Informações da CCEE",
      "Withdrawal Information": "Informações do Saque",
      CREATED: "Em Andamento",
      FINISHED: "Finalizado",
      CANCELED: "Falhou",

      "Company Name": "Nome da Empresa",
      "Operation Status": "Status da Operação",
      Quantity: "Quantidade",
      "Request Date": "Data da Solicitação",
      Operator: "Operador",
      deposit: "Depósito",
      withdraw: "Saque",
      withdrawal: "Saque",
      Reason: "Motivo",
      "The minimum allowed value is {{value}}":
        "O valor mínimo permitido é {{value}}",
      "The maximum allowed value is {{value}}":
        "O valor máximo permitido é {{value}}",
      "Spot Trading": "Mercado Spot",
      "My Wallet": "Minha Carteira",

      "Energetic balance": "Balanço Energético",
      "Current Quantity": "Quantidade Atual",
      North: "Norte",
      South: "Sul",
      Northeast: "Nordeste",

      "Bank & CCEE Information": "Informações do Banco & CCEE",
      Support: "Suporte",
      Refuse: "Recusar",
      Justification: "Justificativa",
      Filters: "Filtros",
      Information: "Informações",
      "Bank & CCEE": "Banco & CCEE",
      "My Bank": "Meu Banco",
      "My CCEE": "Minha CCEE",
      "BTG Bank": "Banco BTG",
      "CCEE Bank": "CCEE do Banco",

      "Check that your details are correct":
        "Confira se seus dados estão corretos",

      "Check pending information": "Confira as informações pendentes.",
      "In accordance with the data, just click on “CONFIRM”":
        "Em conformidade com os dados, basta clicar em “CONFIRMAR”.",
      "After confirmation, the amount entered will be automatically credited or debited from the user's account":
        "Após a confirmação será creditado ou debitado automaticamente o valor inserido na conta do usuário.",

      "Estimated Total Balance (Fiat + Spot)":
        "Saldo Total Estimado (Fiat + Spot)",
      "Spot Balance (Energy Balance)": "Saldo Spot (Saldo em Energia)",
      "Fiat Balance (Cash balance)": "Saldo Fiat (Saldo em dinheiro)",

      "See Less": "Ver Menos",
      "See More": "Ver Mais",

      "Spot Market": "Mercado Spot",
      Options: "Opções",
      "Trading Time": "Tempo de Negociação",
      Total: "Total",
      Transactions: "Transações",
      "My Orders": "Minhas Orderns",
      "My History": "Meu Histórico",

      "Term Time of Order": "Prazo Tempo de Ordem",
      "CREATE ORDER": "CRIAR ORDEM",
      "Fixed Delivery Period": "Período de Fornecimento Fixo",

      "User password is incorrect": "Senha Incorreta",
      "Insufficient funds": "Saldo Insuficiente",
      "Withdrawal request completed successfully":
        "Solicitação de saque realizada com sucesso",

      "Tunnel Limit updated successfully":
        "Limite de Túnel atualizado com sucesso",

      INSUFICIENT_BALANCE: "Saldo Insuficiente",
      "Order created successfully": "Ordem criada com sucesso",
      "Successfully canceled order": "Ordem cancelada com sucesso",
      "Successfully canceled orders": "Ordens canceladas com sucesso",
      "Operational limit reached for period, remaining available negotiation value: {{ remainingValue }}":
        "Limite operacional atingido para o período, valor restante disponível para negociação: R$ {{ remainingValue }}",
      "Deposit limit reached, maximum deposit value is {{ maxDepositValue }}":
        "Limite de depósitos atingido, valor restante permitido: R$ {{ maxDepositValue }}",
      "Deposit limit reached, maximum deposit value with this type of energy is R$ {{ maxDepositValue }}":
        "Limite de depósitos atingido, valor restante para este tipo de energia é de R$ {{ maxDepositValue }}",
      "User does not have a limit set": "Usuário não possui limite configurado",

      "Operation outside the price tunnel, the current minimum value for order creation is R$ {{ minimumValue }} or PLD + R$ {{ minimumSpreadValue }}":
        "Operação fora do túnel de preço, o valor atual mínimo para criação de ordem é de R$ {{ minimumValue }} ou PLD + R$ {{ minimumSpreadValue }}",
      "Operation outside the price tunnel, the maximum current value for order creation is R$ {{ maximumValue }} or PLD + R$ {{ maximumSpreadValue }}":
        "Operação fora do túnel de preço, o valor atual máximo para criação de ordem é de R$ {{ maximumValue }} ou PLD + R$ {{ maximumSpreadValue }}",
      "Operation outside the price tunnel, the current minimum value for order creation is R$ {{ minimumValue }} or PLD - R$ {{ minimumSpreadValue }}":
        "Operação fora do túnel de preço, o valor atual mínimo para criação de ordem é de R$ {{ minimumValue }} ou PLD - R$ {{ minimumSpreadValue }}",
      "Operation outside the price tunnel, the maximum current value for order creation is R$ {{ maximumValue }} or PLD - R$ {{ maximumSpreadValue }}":
        "Operação fora do túnel de preço, o valor atual máximo para criação de ordem é de R$ {{ maximumValue }} ou PLD - R$ {{ maximumSpreadValue }}",

      'To cancel this order click "CONFIRM"':
        'Para cancelar essa ordem clique em "CONFIRMAR"',
      "After confirmation, your spot market order will be removed.":
        "Após a confirmação será retirada sua ordem do mercado spot.",
      'To cancel all your orders click "CONFIRM"':
        'Para cancelar todas suas ordens clique em "CONFIRMAR"',
      "After confirmation, all your open orders will be removed from the spot market.":
        "Após a confirmação será removida todas as suas ordens em aberto do mercado spot.",

      Interval: "Intervalo",

      "You must fill in all required fields correctly":
        "Você deve preencher corretamente todos os campos obrigatórios",

      "The Maximum Value must be higher than the minimum":
        "O valor máximo deve ser maior do que o valor mínimo",

      /* ------------------------------------ NOTIFICATION -------------------------------------*/

      "{{notificationsCounter}} New Notifications":
        "{{notificationsCounter}} novas notificações",
      singleNotification: "1 nova notificação",
      noNotifications: "Sem notificações",

      "Lost Limit Reached": "Limite de perdas atingido",
      "Alert: Participant {{ participantName }} reached the lost limit for spot negotiations":
        "Atenção: O participante {{ participantName }} atingiu o valor de {{ lostReached }} em perdas no mercado spot, o limite de perdas configurado é de {{ lostLimit }}",
    },
  },
  en: {
    translation: {
      "Disable Dark Theme": "Disable Dark Theme",
      "Enable Dark Theme": "Enable Dark Theme",
      NOT_WORKDAY: "This action is only available on workdays",

      Home: "Home",
      Loading: "Loading",
      "Sign In": "Sign In",
      "Sign in": "Sign in",
      Welcome: "Welcome",
      "Sign in to your account to continue":
        "Sign in to your account to continue",
      "Remember me": "Remember me",
      "Forgot password": "Forgot password",
      "User does not exist or has been disabled.":
        "User does not exist or has been disabled.",
      "The password is invalid.": "The password is invalid.",
      "Something went wrong.": "Something went wrong.",
      "Email already registered.": "Email already registered.",
      "User does not have permission to access this system.":
        "User does not have permission to access this system.",

      "Reset Password": "Reset Password",
      "Enter your email to reset your password":
        "Enter your email to reset your password",
      "Check your email and follow the instructions to reset your password":
        "Check your email and follow the instructions to reset your password",
      "404 Error": "404 Error",
      "Page not found": "Page not found",
      "The page you are looking for might have been removed":
        "The page you are looking for might have been removed",
      "Return to website": "Return to website",
      "500 Error": "500 Error",
      "Internal server error": "Internal server error",
      "The server encountered something unexpected that didn't allow it to complete the request":
        "The server encountered something unexpected that didn't allow it to complete the request",

      Product: "Product",
      Products: "Products",
      "Product List": "Product List",
      "Product Maintenance": "Product Maintenance",
      "Last Version Date and Time": "Last Version Date and Time",
      "Measurement Unit": "Measurement Unit",
      "Field Type": "Field Type",
      "Contract Parameter": "Contract Parameter",
      Required: "Required",
      "Field Max Length": "Field Max Length",
      "Add Product": "Add Product",
      "Edit Product": "Edit Product",
      "Product Data": "Product Data",
      "Product Name": "Product Name",
      "ZapSign Contract Identifier": "ZapSign Contract Identifier",
      Attributes: "Attributes",
      "Product Attributes": "Product Attributes",
      "Attribute List": "Attribute List",
      Description: "Description",
      string: "Text",
      number: "Number",
      boolean: "Logical",
      Participant: "Participant",
      "Choose a Participant": "Choose a Participant",
      "Associate Products": "Associate Products",
      "Select All": "Select All",

      "Associate Participants": "Associate Participants",

      Participants: "Participants",
      Draft: "Draft",
      Add: "Add",
      Edit: "Edit",
      Enable: "Enable",
      Disable: "Disable",
      "Add Participant": "Add Participant",
      "Edit Participant": "Edit Participant",
      "n Selected": "{{count}} selected",
      "Trade Name": "Trade Name",
      "CCEE Name": "Trade Name",
      CNPJ: "CNPJ",
      Class: "Class",
      Email: "Email",
      Password: "Password",
      Actions: "Actions",

      Offers: "Offers",
      "Offer Type": "Offer Type",
      "Offers Panel": "Offers Panel",
      "Negotiated Offers": "Negotiated Offers",
      "Last Price": "Last Price",
      Change: "Change",
      "Highest Price": "Highest Price",
      "Lowest Price": "Lowest Price",
      "Average Price": "Average Price",
      History: "History",
      "Offer History": "Offer History",
      "Register Offer": "Register Offer",
      "Register Offers": "Register Offers",
      "Offer Details": "Offer Details",
      "Make Participant Data Public": "Make Participant Data Public",
      Type: "Type",
      "End of Offer": "End of Offer",
      Month: "Month",
      "Delivery Period": "Delivery Period",
      "Deliv. Period": "Deliv. Period",
      Start: "Start",
      End: "End",
      to: "to",
      Volume: "Volume",
      "Remaining Order Volume": "Remaining Order Volume",
      "Offered Volume": "Offered Volume",
      Submarket: "Submarket",
      "Re-TUSD": "Re-TUSD",
      "Source Type": "Source Type",
      "Source Type Short": "Source Type",
      Price: "Price",
      Price2: "Price",
      "Reverse Price2": "Reverse Price",
      "Payment Method": "Payment Method",
      "Bank Transaction": "Bank Transaction",
      "Transaction Fees": "Transaction Fees",
      "Orders Reached": "Orders Reached",
      "You receive": "You receive",
      "Slippage Tolerance": "Slippage Tolerance",
      "Price Type": "Price Type",
      "Atrelado ao PLD": "Linked to the PLD",
      "PLD+SPREAD": "Linked to the PLD",
      "Atrelado PLD + Spread": "Linked to the PLD + Spread",
      Fixo: "Fixed",
      FIXED: "Fixed",
      Spread: "Spread",
      value: "Value",
      Value: "Value",
      "Lance livre": "Free Bid",
      "L Livre": "Free Bid",
      "Updated at": "Updated at",
      "Free Bid": "Free Bid",
      "Free Bid Type": "Free Bid Type",
      REGISTER_OFFER_CONFIRMATION_MESSAGE1: "Check your product information.",
      REGISTER_OFFERS_CONFIRMATION_MESSAGE1:
        "Check yours products information.",
      REGISTER_OFFER_CONFIRMATION_MESSAGE2:
        'If you agree with the data, just click on "Confirm" to enter the product on the trading platform.',
      REGISTER_OFFERS_CONFIRMATION_MESSAGE2:
        'If you agree with the data, just click on "Confirm" to enter the products on the trading platform.',

      "Select Participant": "Select Participant",
      "Choose a participant to continue": "Choose a participant to continue",
      "Register Buy Offer": "Register Buy Offer",
      "Register Sell Offer": "Register Sell Offer",
      "Sell Offers": "Sell Offers",
      "Buy Offers": "Buy Offers",
      "Operation Code": "Operation Code",
      "Delivery Point": "Delivery Point",
      "Delivery Point_short": "Deliv. Pt.",
      Period: "Period",
      "Buy Volume": "Buy Volume",
      "Buy Offer": "Buy Offer",
      "Sell Volume": "Sell Volume",
      "Sell Offer": "Sell Offer",
      "Best Bid Value": "Best Bid Value",
      "Best Bid": "Best Bid",
      "Remaining Time": "Remaining Time",
      "Average Remaining Time": "Average Remaining Time",
      "Bid Value": "Bid Value",
      "Bid Volume": "Bid Volume",
      NET: "NET",
      Status: "Status",
      Time: "Time",
      Time2: "Time",
      Bid: "Bid",
      "Register Bid": "Register Bid",
      "Your bid is currently the best value":
        "Your bid is currently the best value",
      "Your bid is not the best value": "Your bid is not the best value",

      "Fill Order": "Fill Order",
      "Operation Summary": "Operation Summary",
      "Please verify that the information below is correct before registering the offer.":
        "Please verify that the information below is correct before registering the offer.",
      "Please verify that the information provided is correct and try again.":
        "Please verify that the information provided is correct and try again.",
      "Please verify that the information below is correct before registering the bid.":
        "Please verify that the information below is correct before registering the bid.",
      "New Offer": "New Offer",
      "Edit Offer": "Edit Offer",
      "Register offer footer message":
        'Fill in all the details of your product and click on "Save Product". Afterwards, click on "Go to Summary" to proceed.',
      "Save Product": "Save Product",
      "Go to Summary": "Go to Summary",
      New: "New",
      Operation: "Operation",
      "Operation Type": "Operation Type",
      Applies: "Applies",
      "Does not apply": "Does not apply",
      Max: "Max",
      Min: "Min",

      More: "More",
      Bids: "Bids",
      "Bids Made": "Bids Made",
      "Offer has not been bid yet": "Offer has not been bid yet",
      "Date and Time": "Date and Time",
      Order: "Order",
      CRIADO: "Created",
      CANCELADO: "Canceled",
      ACEITO: "Accepted",

      ABERTA: "Open",
      "PARCIALMENTE ENCERRADA": "Part. Closed",
      "CONSOLIDANDO NEGOCIAÇÃO": "Consolidating",
      ENCERRADA: "Finished",
      EXPIRADA: "Expired",
      CANCELADA: "Canceled",

      Current: "Current",
      All: "All",
      "Show Finished Offers": "Show Finished Offers",
      "All Offers": "All Offers",
      "My Offers": "My Offers",
      "My Bids": "My Bids",
      "My Bid": "My Bid",

      "Do you really want to bid in the value of {{value}}?":
        "Do you really want to bid in the value of {{value}}?",
      "Do you really want to bid in the spread value of {{value}}?":
        "Do you really want to bid in the spread value of {{value}}?",
      "Do you really want to cancel offer {{code}}?":
        "Do you really want to cancel offer {{code}}?",
      "Do you really want to cancel the sell offer from operation {{code}}?":
        "Do you really want to cancel sell offer from operation {{code}}?",
      "Do you really want to cancel the buy offer from operation {{code}}?":
        "Do you really want to cancel buy offer from operation {{code}}?",
      "Do you really want to change offer {{code}} price to {{price}}?":
        "Do you really want to change offer {{code}} price to {{price}}?",
      "Do you really want to accept the bid?":
        "Do you really want to accept the bid?",
      "Do you really want to cancel the bid?":
        "Do you really want to cancel the bid?",
      "Do you really want to delete this offer?":
        "Do you really want to delete this offer?",

      "Registration Data": "Registration Data",
      Documents: "Documents",
      "Operating Limits": "Operating Limits",
      "Operating Limit": "Operating Limit",
      "Legal Representatives": "Legal Representatives",
      "User Maintenance": "User Maintenance",

      "Company Data": "Company Data",
      Address: "Address",
      "Participant Class": "Class",
      "Corporate Name": "Corporate Name",
      "State Registration": "State Registration",
      "Municipal Registration": "Municipal Registration",
      Website: "Website",
      "Telephone Number": "Telephone Number",
      "Cellphone Number": "Cellphone Number",
      "Postal Code": "Postal Code",
      "Address Number": "Number",
      Complement: "Complement",
      District: "District",
      City: "City",
      State: "State",
      Save: "Save",
      Accept: "Accept",
      Cancel: "Cancel",
      "Go Back": "Go Back",
      "Add More Products": "Add More Products",
      Confirm: "Confirm",
      Delete: "Delete",
      Clear: "Clear",
      "This field is required": "This field is required",
      "The data entered is too large": "The data entered is too large",
      "The data entered is invalid": "The {{data}} entered is invalid",
      "The data entered is invalid_female": "The {{data}} entered is invalid",
      "Max date": "Max date {{data}}",
      "Min date": "Min date {{data}}",
      "You must fill in all required fields":
        "You must fill in all required fields",
      "The operation completed successfully":
        "The operation completed successfully",

      "Bank info": "Bank info",
      BankCode: "Bank code",
      "Invalid bank code": "Invalid bank code",
      Agency: "Agency",
      "Invalid agency": "Invalid agency",
      "Invalid account": "Invalid account",

      Comercializadora: "Trader",
      "Comercializador varejista": "Retailer",
      "Consumidor livre": "Free consumer",
      "Consumidor especial": "Special consumer",
      "Produtor independente": "Independent producer",
      Autoprodutor: "Self-producer",

      merchant: "Trader",
      retailer: "Retailer",
      freeConsumer: "Free consumer",
      specialConsumer: "Special consumer",
      independentProducer: "Independent producer",
      selfProducer: "Self-producer",
      generator: "Generator",

      "Additional Information": "Additional Information",
      "Document Name": "Document Name",
      File: "File",
      "Upload a File": "Upload a File",
      "Drop the file here...": "Drop the file here...",
      "File is larger than x": "File is larger than {{x}}",
      "File type must be x": "File type must be {{x}}",
      "Files type must be x and less than y":
        "Files type must be {{x}} and less than {{y}}",
      "The file is not accepted": "The file is not accepted",

      buy: "Buy",
      Buy: "Buy",
      COMPRA: "Buy",
      sell: "Sell",
      Sell: "Sell",
      VENDA: "Sell",
      "COMPRA&VENDA": "Buy & Sell",
      "Total Granted": "Total Granted",
      Consumed: "Consumed",

      Details: "Details",
      "See Details": "See details",
      "See Buy Offer Details": "See buy offer details",
      "See Sell Offer Details": "See sell offer details",
      "Chat with bidder": "chat with bidder",
      "Cancel Offer": "Cancel Offer",
      "Cancel Buy Offer": "Cancel buy offer",
      "Cancel Sell Offer": "Cancelar sell offer",
      "Change Offer Price": "Change Offer Price",
      "Change Buy Offer Price": "Change Buy Offer Price",
      "Change Sell Offer Price": "Change Sell Offer Price",
      "Offer Chat": "Chat",
      "Sell Offer Chat": "Sell offer chat",
      "Buy Offer Chat": "Buy offer chat",

      "Legal Representative Data": "Legal Representative Data",
      "List of Legal Representatives": "List of Legal Representatives",
      Name: "Name",
      "Full Name": "Full Name",
      "Always Sign": "Always Sign",
      Position: "Position",
      CPF: "CPF",
      Nationality: "Nationality",
      "Birth Date": "Birth Date",
      "Document Type": "Document Type",
      "Document Number": "Document Number",
      "Date of Issue": "Date of Issue",
      "Issuing Agency": "Issuing Agency",
      "Federated Unit": "Federated Unit",

      "User Data": "User Data",
      "User List": "User List",
      "User Permissions": "User Permissions",
      System: "System",
      "Finish and Activate Participant": "Finish and Activate Participant",
      "The participant is already active": "The participant is already active",
      "The participant is not active": "The participant is not active",

      Administrators: "Administrators",
      "Administrator Data": "Administrator Data",

      Search: "Search topics…",
      "Search here": "Search here",
      "No chat found": "No chat found",
      "Chat finished": "This offer has finished",
      "Chat message blocked":
        "Message has invalid content, please avoid sending personal data such as address, phone and email",
      "Chat generic error":
        "Somethind wrong happened, please verify your device connection",
      "No chat selected": "No chat selected",
      "Unable to load data": "Unable to load data",
      "Type a message here": "Type a message here",
      "Unable to send new messages because the offer has ended":
        "Unable to send new messages because the offer has ended",
      "Do you really want to delete this conversation?":
        "Do you really want to delete this conversation?",
      "New messages": "New messages",
      "Unread messages": "Unread messages",
      Seen: "Seen",
      Send: "Send",
      "My Order": "My Order",
      "Avg. PLD": "Avg. PLD",
      Difference: "Difference",
      "I'm buying": "I'm buying",
      "I'm selling": "I'm selling",
      "They're buying": "They're buying",
      "They're selling": "They're selling",
      "Your counterpart hasn't bid yet": "Your counterpart hasn't bid yet",
      "You haven't bid yet": "You haven't bid yet",
      "New Bid": "New Bid",
      Notifications: "Notifications",
      Messages: "Messages",
      Languages: "Languages",
      Account: "Account",
      Role: "Role",
      "Sign out": "Sign out",
      "Welcome back": "Welcome back",
      "We've missed you": "We've missed you",
      Administrator: "Administrator",
      User: "User",

      "History Offer Date": "Date",
      "History Product Name": "Product",
      "History Code Operation": "Operation Code",
      "History Delivery Location": "Delivery",
      "History Operation": "Operation",
      Situation: "Situation",
      "Session History": "Session History",
      "There is no data to show at this time":
        "There is no data to show at this time",

      "Parte Vendedora": "Selling Party",
      "Parte Compradora": "Buying Party",
      "Participant Data": "Participant Data",
      "Payment Data": "Payment Data",
      Contacts: "Contacts",
      "Contact Name": "Contact Name",
      "Offer Information": "Offer Information",
      "Informações Complementares": "Additional Information",
      "As partes declaram que possuem poderes de representação para realizar a operação de compra e venda de energia":
        "The parties declare that they have representation rights to carry out the purchase and sale of energy",

      Flexibility: "Flexibility",
      Seasonality: "Seasonality",
      Modulation: "Modulation",
      "Sector Charges": "Sector Charges",
      "PLD Value": "PLD Value",
      "Operation Sum Basis": "Operation Sum Basis",
      "Operation Total Value": "Operation Total Value",
      Taxes: "Taxes",
      Payment: "Payment",
      "Payment Date": "Payment Date",
      "Due Date": "Due Date",
      "Price Base Date": "Price Base Date",
      Readjustment: "Readjustment",
      "Financial Guarantee": "Financial Guarantee",
      "Energy Registration": "Energy Registration",
      "Republication of the PLD": "Republication of the PLD",
      Responsibilities: "Responsibilities",
      "Marketing Rules": "Marketing Rules",
      "Act of God and Force Majeure": "Act of God and Force Majeure",
      "Subscription Commitment CCVE": "Subscription Commitment CCVE",
      Confidentiality: "Confidentiality",
      "I agree with the Acceptance Term": "I agree with the Acceptance Term",
      "You must agree with the Acceptance Term":
        "You must agree with the Acceptance Term",
      "Do you really want to accept the terms?":
        "Do you really want to accept the terms?",
      "You have already accepted this term":
        "You have already accepted this term",
      Validation: "Validation",
      "Your couterparty has already accepted this term":
        "Your couterparty has already accepted this term",
      "Waiting for your counterparty to accept the terms":
        "Waiting for your counterparty to accept the terms",

      Limits: "Limits",
      "Adjust Limits": "Adjust Limits",

      Clone: "Clone",
      View: "View",

      Contract: "Contract",
      Contracts: "Contracts",
      ContractList: "Contract List",
      "Contract Details": "Contract Details",
      "View Contract": "View Contract",
      "Contract Status": "Contract status",
      Origin: "Origin",
      Pendente: "Pending",
      Pending: "Pending",
      Assinado: "Signed",
      Signed: "Signed",
      "Pendente de Assinatura": "Pending Signature",
      "Pending Signature": "Pending Signature",

      IssueDate: "Issue Date",
      Buyer: "Buyer",
      Seller: "Seller",
      TotalValue: "Total Value",

      "Acceptance Term": "Acceptance Term",
      "Termo de Aceite": "Acceptance Term",
      "Closing Statement": "Closing Statement",
      "Fechamento da Boleta": "Closing Statement",

      SellerSigners: "Seller Signers",
      BuyerSigners: "Buyer Signers",
      Signer: "Signer",
      SignerSituation: "Situation",

      "Tipo de Energia": "Energy Type",
      "Profile Key": "CliqCCEE Profile",
      "Profile Code": "CliqCCEE Code",

      PLD: "PLD",
      "Weekly Average": "Weekly Average",
      "Monthly Average": "Monthly Average",
      "Reference month": "Reference month",
      "Unavailable data": "Unavailable data",
      Reference: "Reference",

      "You have unsaved data on this page. Do you really want to leave this page?":
        "You have unsaved data on this page. Do you really want to leave this page?",

      NoMessages: "No messages",
      NoMessagesReceivedForThisOffer: "No messages received for this offer",

      "Direct Buy and Sell": "Direct Buy and Sell",
      From: "From",
      To: "To",
      "Preview conversion": "Preview conversion",
      "Volume unavailable": "Volume unavailable",
      Sum: "Sum",
      Created2: "Created",
      Orders2: "Orders",
      "There are no orders of this type available at the moment":
        "There are no orders of this type available at the moment",
      FAQ: "FAQ",
      Confirmation: "Confirmation",

      No: "No",
      Yes: "Yes",

      Bank: "Bank",
      "Available Balance": "Available Balance",
      "Financial Balance": "Financial Balance",
      "Energy Balance": "Energy Balance",
      "Available Financial Balance": "Available Financial Balance",
      "Available Energy Balance": "Available Energy Balance",
      Deposit: "Deposit",
      Withdraw: "Withdraw",
      "Financial Deposit": "Financial Deposit",
      "Energy Deposit": "Energy Deposit",
      "Financial Withdrawal": "Financial Withdrawal",
      "Energy Withdrawal": "Energy Withdrawal",
      "Manage Deposits and Withdrawals": "Manage Deposits and Withdrawals",
      "Deposit Value": "Deposit Value",
      "Account Name": "Account Name",
      "Bank Name": "Bank Name",
      "Account Number": "Account Number",
      "bank data": "bank data",
      "client data": "client data",
      "Energy Type": "Energy Type",
      "Bank Country": "Bank Country",
      "Account Holder Name": "Account Holder Name",
      "Account Type": "Account Type",
      "Agency Number": "Agency Number",
      "Withdrawal Value": "Withdrawal Value",
      "Current Account": "Current Account",
      "Savings Account": "Savings Account",
      "Payment Account": "Payment Account",
      "Bank Information": "Bank Information",
      "CCEE Information": "CCEE Information",
      "Withdrawal Information": "Withdrawal Information",
      "Company Name": "Company Name",
      "Operation Status": "Operation Status",
      Quantity: "Quantity",
      "Request Date": "Request Date",
      Operator: "Operator",
      CREATED: "In Progress",
      FINISHED: "Finished",
      CANCELED: "Failure",
      deposit: "Deposit",
      withdraw: "withdraw",
      withdrawal: "Withdrawal",
      Reason: "Reason",
      "The minimum allowed value is {{value}}":
        "The minimum allowed value is {{value}}",
      "The maximum allowed value is {{value}}":
        "The maximum allowed value is {{value}}",
      "Spot Trading": "Spot Trading",
      "My Wallet": "My Wallet",

      "Energetic balance": "Energetic balance",
      "Current Quantity": "Current Quantity",
      North: "North",
      South: "South",
      Northeast: "Northeast",

      "Bank & CCEE Information": "Bank & CCEE Information",
      Support: "Support",
      Refuse: "Refuse",
      Justification: "Justification",
      Filters: "Filters",
      Information: "Information",
      "Bank & CCEE": "Bank & CCEE",
      "My Bank": "My Bank",
      "My CCEE": "My CCEE",
      "BTG Bank": "BTG Bank",
      "CCEE Bank": "CCEE Bank",

      "Check that your details are correct":
        "Check that your details are correct",

      "Check pending information": "Check pending information.",
      "In accordance with the data, just click on “CONFIRM”":
        "In accordance with the data, just click on “CONFIRM”.",
      "After confirmation, the amount entered will be automatically credited or debited from the user's account":
        "After confirmation, the amount entered will be automatically credited or debited from the user's account.",

      "Estimated Total Balance (Fiat + Spot)":
        "Estimated Total Balance (Fiat + Spot)",
      "Spot Balance (Energy Balance)": "Spot Balance (Energy Balance)",
      "Fiat Balance (Cash balance)": "Fiat Balance (Cash balance)",

      "See Less": "See Less",
      "See More": "See More",

      "Spot Market": "Spot Market",
      Options: "Options",
      "Trading Time": "Trading Time",
      Total: "Total",
      Transactions: "Transactions",
      "My Orders": "My Orders",
      "My History": "My History",

      "Term Time of Order": "Term Time of Order",
      "CREATE ORDER": "CRIAR ORDEM",
      "Fixed Delivery Period": "Fixed Delivery Period",

      "User password is incorrect": "User password is incorrect",
      "Insufficient funds": "Insufficient funds",
      "Withdrawal request completed successfully":
        "Withdrawal request completed successfully",

      INSUFICIENT_BALANCE: "Insufficient funds",
      "Order created successfully": "Order created successfully",
      "Successfully canceled order": "Successfully canceled order",
      "Successfully canceled orders": "Successfully canceled orders",
      "Operational limit reached for period, remaining available negotiation value: {{ remainingValue }}":
        "Operational limit reached for period, remaining available negotiation value: R$ {{ remainingValue }}",
      "Deposit limit reached, maximum deposit value is {{ maxDepositValue }}":
        "Deposit limit reached, maximum deposit value is {{ maxDepositValue }}",
      "Deposit limit reached, maximum deposit value with this type of energy is R$ {{ maxDepositValue }}":
        "Deposit limit reached, maximum deposit value with this type of energy is R$ {{ maxDepositValue }}",
      "User does not have a limit set": "User does not have a limit set",

      'To cancel this order click "CONFIRM"':
        'To cancel this order click "CONFIRM"',
      "After confirmation, your spot market order will be removed.":
        "After confirmation, your spot market order will be removed.",
      'To cancel all your orders click "CONFIRM"':
        'To cancel all your orders click "CONFIRM"',
      "After confirmation, all your open orders will be removed from the spot market.":
        "After confirmation, all your open orders will be removed from the spot market.",

      Interval: "Interval",

      "You must fill in all required fields correctly":
        "You must fill in all required fields correctly",

      /* ------------------------------------ NOTIFICATION -------------------------------------*/

      "{{notificationsCounter}} New Notifications":
        "{{notificationsCounter}} novas notificações",
      singleNotification: "1 new notification",
      noNotifications: "No notifications",

      "Lost Limit Reached": "Lost limit reached",
      "Alert: Participant {{ participantName }} reached the lost limit for spot negotiations":
        "Alert: Participant {{ participantName }} reached the lost value of {{ lostReached }} for spot negotiations, current lost limit is set to {{ lostLimit }}",
    },
  },
};

declare module "i18next" {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

i18n.use(initReactI18next).init({
  resources,
  lng: "pt",
  fallbackLng: "pt",
  interpolation: {
    escapeValue: false,
  },
  returnNull: false,
});
