import React, { useCallback, useEffect } from "react";
import styled from "@emotion/styled";
import { DeleteForever, Done, MoreVert, Send } from "@mui/icons-material";
import {
  Badge,
  Box,
  BoxProps,
  Button,
  Divider as MuiDivider,
  IconButton,
  InputBase,
  InputBaseProps,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { User, Users } from "react-feather";
import { useTranslation } from "react-i18next";
import { Search as SearchIcon } from "react-feather";
import { StyledModal } from "../../StyledModal";
import {
  getISOYearMonthDay,
  getSplitedTimeDifference,
  parseDate,
} from "../../../utils/date";
import { useGetChatRooms } from "../hooks/useGetChatrooms";
import { useGetCounterpartStatus } from "../hooks/useGetCounterpartStatus";
import { useGetChatroomMessages } from "../hooks/useGetChatroomMessages";
import { SendChatMessageService } from "../services/SendChatMessageService";
import { useSnackbar } from "notistack";
import { useCurrentTime } from "../../../hooks/useCurrentTime";
import { useGetOperation } from "../../bidModal/hooks/useGetOperation";
import { ChatModalBidAreaContent } from "./ChatModalBidAreaContent";
import { DeleteChatroomService } from "../services/DeleteChatroomService";
import useChooseParticipant from "../../../hooks/useChooseParticipant";
import useAuth from "../../../hooks/useAuth";
import useConfirmationModal from "../../../hooks/useConfirmationModal";
import { useGetBid } from "../../bidModal/hooks/useGetBid";
import useChat from "../../../hooks/useChat";
import { TimeBox } from "../../TimeBox";

const sendChatRoomMessage = new SendChatMessageService();
const deleteChatroomService = new DeleteChatroomService();

const Divider = styled(MuiDivider)(spacing);

const UserAvatar = styled(Box)<{
  type?: "COMPRA" | "VENDA";
  size?: "large" | "medium" | "small";
}>(({ theme, type, size }) => {
  const boxSize = size === "large" ? 45 : size === "small" ? 25 : 35;
  const backgroundColor =
    type === "COMPRA"
      ? theme.palette.success.main
      : type === "VENDA"
      ? theme.palette.error.main
      : theme.palette.common.white;
  const color =
    type === "COMPRA" || type === "VENDA"
      ? theme.palette.common.white
      : "#192634";

  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexShrink: 0,
    width: boxSize,
    height: boxSize,
    borderRadius: boxSize,
    backgroundColor,
    color,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    // fontSize: 8,
  };
});

const TypographyEllipsis = styled(Typography)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
`;

const PaperInput = React.forwardRef<HTMLInputElement, InputBaseProps>(
  (props, ref) => {
    return (
      <Paper
        component={Paper}
        sx={(theme) => ({
          backgroundColor: theme.palette.common.white,
          color: theme.palette.common.black,
          borderRadius: 1000,
          display: "flex",
          alignItems: "center",
          border: "1px solid #dbdbdb",
          width: props.fullWidth ? "100%" : undefined,
        })}
      >
        <InputBase
          inputRef={ref}
          sx={{ mx: 4, flex: 1, color: "inherit", height: 40 }}
          inputProps={{ "aria-label": props.placeholder }}
          {...props}
        />
      </Paper>
    );
  }
);

const MessageDivider = (props: BoxProps) => {
  return (
    <Box sx={{ position: "relative" }}>
      <Divider color="#dbdbdb" my={7} />
      <Box
        sx={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "#E7E7E7",
          color: "common.black",
          px: 2,
          py: 0,
        }}
        {...props}
      />
    </Box>
  );
};

export interface ChatModalProps {
  open: boolean;
  onClose: () => void;
  defaultChatroomId?: string;
}

export function ChatModal({
  open,
  onClose,
  defaultChatroomId,
}: ChatModalProps) {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { showConfirmationModal } = useConfirmationModal();

  const { currentParticipant } = useChooseParticipant();
  const { user } = useAuth();

  const { currentTime } = useCurrentTime();

  const conversationBoxRef = React.useRef<HTMLDivElement | null>(null);
  const newMessageRef = React.useRef<HTMLInputElement | null>(null);

  const [newMessageValue, setNewMessageValue] = React.useState("");
  const [searchValue, setSearchValue] = React.useState("");

  const [selectedChatroomId, setSelectedChatroomId] = React.useState<
    string | null
  >(null);

  useEffect(() => {
    if (currentParticipant === null) {
      setSelectedChatroomId(null);
    }
  }, [currentParticipant]);

  useEffect(() => {
    setSelectedChatroomId(defaultChatroomId || null);
  }, [defaultChatroomId]);

  const { enqueueSnackbar } = useSnackbar();

  const { chatrooms } = useGetChatRooms();
  const { counterpartStatus, forceUpdateLastReadingDate } =
    useGetCounterpartStatus(selectedChatroomId);
  const { messages } = useGetChatroomMessages(selectedChatroomId);

  const { updateChatroomsCount } = useChat();

  React.useEffect(() => {
    const count = chatrooms.reduce(
      (acc, chatroom) => acc + (chatroom.unreadMessagesCount > 0 ? 1 : 0),
      0
    );
    updateChatroomsCount(count);
  }, [chatrooms, updateChatroomsCount]);

  const filteredChatList = React.useMemo(() => {
    return searchValue
      ? chatrooms.filter((item) =>
          item.counterpartyName
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        )
      : chatrooms;
  }, [chatrooms, searchValue]);

  const selectedChatroom = React.useMemo(() => {
    if (selectedChatroomId === null) return null;
    return (
      chatrooms.find((chatroom) => chatroom.id === selectedChatroomId) ?? null
    );
  }, [chatrooms, selectedChatroomId]);

  React.useEffect(() => {
    setTimeout(forceUpdateLastReadingDate, 2 * 1000);
  }, [forceUpdateLastReadingDate, selectedChatroom?.unreadMessagesCount]);

  const { operation } = useGetOperation(selectedChatroom?.operationId);

  const deleteChatroom = useCallback(async () => {
    if (selectedChatroomId === null) {
      return;
    }
    await deleteChatroomService.deleteChatroom(selectedChatroomId);
    setSelectedChatroomId(null);
  }, [selectedChatroomId]);

  const handleDeleteChatroom = useCallback(() => {
    showConfirmationModal({
      onSubmit: deleteChatroom,
      message: t("Do you really want to delete this conversation?"),
      submitButtonColor: "primary",
    });
  }, [deleteChatroom, showConfirmationModal, t]);

  const { bid } = useGetBid(
    selectedChatroom?.operationId,
    selectedChatroom?.offerType,
    selectedChatroom?.prospectId
  );

  const offer = React.useMemo(() => {
    if (!operation || !selectedChatroom) {
      return null;
    }
    return operation[
      selectedChatroom.offerType === "COMPRA" ? "buyOffer" : "sellOffer"
    ];
  }, [operation, selectedChatroom]);

  const firstUnreadMessageIndex = React.useMemo(() => {
    if (!selectedChatroom) return -1;
    return messages.findIndex(
      (message) =>
        !message.sentByMe &&
        (parseDate(message.createdAt)?.valueOf() ?? 0) >
          (parseDate(selectedChatroom.lastReadingDate)?.valueOf() ?? 0)
    );
  }, [messages, selectedChatroom]);

  const lastCounterpartReadMessageIndex = React.useMemo(() => {
    if (!selectedChatroom) return -1;
    const reverseArray = [...messages].reverse();
    const idx = reverseArray.findIndex(
      (message) =>
        message.sentByMe &&
        (parseDate(message.createdAt)?.valueOf() ?? 0) <=
          (parseDate(selectedChatroom.counterpartLastReadingDate)?.valueOf() ??
            0)
    );

    return idx < 0 ? -1 : messages.length - 1 - idx;
  }, [messages, selectedChatroom]);

  const canSendMessages = React.useMemo(() => {
    if (
      offer &&
      operation &&
      (offer.status === "ABERTA" || offer.status === "PARCIALMENTE ENCERRADA")
    ) {
      const offerCloseDateTimeValue = parseDate(operation.offerCloseDateTime);
      if (
        offerCloseDateTimeValue &&
        new Date().valueOf() < offerCloseDateTimeValue.valueOf()
      ) {
        return true;
      }
    }

    return false;
  }, [offer, operation]);

  React.useEffect(() => {
    setNewMessageValue("");
    newMessageRef.current?.focus();
  }, [selectedChatroomId]);

  React.useEffect(() => {
    setSelectedChatroomId(
      (oldValue) => oldValue ?? filteredChatList[0]?.id ?? null
    );
  }, [filteredChatList]);

  const handleSubmitMessage = React.useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (!newMessageValue || !selectedChatroomId) return;

      const sendResult = await sendChatRoomMessage.sendChatRoomMessage(
        newMessageValue,
        selectedChatroomId
      );

      if (sendResult.isRight()) {
        setNewMessageValue("");
      }

      if (sendResult.isLeft()) {
        if (sendResult.left() === "CONTENT_BLOCKED") {
          enqueueSnackbar(t("Chat message blocked"), {
            variant: "error",
          });
        }

        if (sendResult.left() === "CHAT_FINISHED") {
          enqueueSnackbar(t("Chat finished"), {
            variant: "error",
          });
        }

        if (sendResult.left() === "GENERIC_ERROR") {
          enqueueSnackbar(t("Chat generic error"), {
            variant: "error",
          });
        }
      }

      newMessageRef.current?.focus();
    },
    [enqueueSnackbar, newMessageValue, selectedChatroomId, t]
  );

  React.useEffect(() => {
    if (!conversationBoxRef.current) return;

    // const currentScrollTop = conversationBoxRef.current.scrollTop;
    const nextScrollTop =
      conversationBoxRef.current.scrollHeight -
      conversationBoxRef.current.offsetHeight;

    // if (nextScrollTop - currentScrollTop > 500) return;

    conversationBoxRef.current.scrollTo({
      top: nextScrollTop,
    });
  }, [messages.length, selectedChatroomId]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleCloseMoreMenu = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleOpenMoreMenu = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );

  return (
    <>
      <Menu
        id="chat-more-menu"
        MenuListProps={{
          "aria-labelledby": "chat-more-button-1",
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMoreMenu}
      >
        <MenuItem
          id="chat-more-button-1"
          onClick={() => {
            handleCloseMoreMenu();
            handleDeleteChatroom();
          }}
        >
          <ListItemIcon>
            <DeleteForever />
          </ListItemIcon>
          <ListItemText primary={t("Delete")} />
        </MenuItem>
      </Menu>

      <StyledModal
        open={open}
        onClose={onClose}
        leftBox={t("Chat")}
        disablePadding
        contentBoxProps={{ sx: { display: "flex" } }}
      >
        <Box width="100%" display="flex" minHeight="75vh">
          <Box minWidth={320} display="flex">
            <Box
              sx={{
                flex: 1,
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box px={7} pt={7}>
                <Box display="flex" gap={2}>
                  <UserAvatar size="large">
                    <User />
                  </UserAvatar>

                  <Box py={1} width="100%" overflow="hidden">
                    <TypographyEllipsis variant="h6" color="primary.main">
                      {currentParticipant?.companyTradeName}
                    </TypographyEllipsis>
                    <TypographyEllipsis variant="caption" fontWeight={500}>
                      {user?.displayName}
                    </TypographyEllipsis>
                  </Box>

                  {/* <Box sx={{ flexShrink: 0 }}>
                    <Tooltip title={t("Edit")}>
                      <IconButton size="small">
                        <EditOutlined fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                  </Box> */}
                </Box>

                <Box mt={3}>
                  <PaperInput
                    type="search"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    placeholder={`${t("Search here")}...`}
                    startAdornment={
                      <Box sx={{ display: "flex", mr: 2, color: "grey.400" }}>
                        <SearchIcon />
                      </Box>
                    }
                  />
                </Box>

                <Divider color="#dbdbdb" mt={3} />
              </Box>

              <Box sx={{ overflowY: "auto", scrollBehavior: "smooth" }}>
                {filteredChatList.length === 0 ? (
                  <Typography
                    variant="caption"
                    px={7}
                    mt={6}
                    display="block"
                    textAlign="center"
                  >
                    {t("No chat found")}
                  </Typography>
                ) : (
                  <></>
                )}
                {filteredChatList.map((row, idx) => {
                  const remainingTime = getSplitedTimeDifference(
                    currentTime,
                    row.offerCloseDateTime
                  );

                  return (
                    <Button
                      key={row.id}
                      type="button"
                      onClick={() => setSelectedChatroomId(row.id)}
                      fullWidth
                      sx={{
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        color: "unset",
                        textAlign: "unset",
                        py: 0,
                        px: 7,
                        mx: 0,
                        my: "1px",
                        display: "flex",
                        gap: 2,
                        height: 60,
                        ...(selectedChatroomId === row.id
                          ? {
                              backgroundColor: "background.default",
                              pointerEvents: "none",
                            }
                          : {}),
                      }}
                    >
                      <Box sx={{ py: 3, px: 1 }}>
                        <UserAvatar type={row.offerType}>
                          <Users size="18px" />
                        </UserAvatar>
                      </Box>

                      <Box
                        sx={{
                          py: 2,
                          pl: 1,
                          flex: 1,
                          width: "100%",
                          height: "100%",
                          overflow: "hidden",
                          borderBottom:
                            idx < filteredChatList.length - 1
                              ? "1px solid #dbdbdb"
                              : "none",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            gap: 2,
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <TypographyEllipsis
                            variant="subtitle2"
                            color="primary.main"
                            lineHeight={1.2}
                          >
                            {row.counterpartyName}
                          </TypographyEllipsis>

                          <Tooltip title={t("Remaining Time")}>
                            <Typography
                              variant="caption"
                              sx={{ whiteSpace: "nowrap" }}
                            >
                              {remainingTime.totalSeconds <= 0 ? (
                                "-"
                              ) : (
                                <TimeBox time={remainingTime} />
                              )}
                            </Typography>
                          </Tooltip>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            gap: 2,
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <TypographyEllipsis
                            variant="caption"
                            lineHeight={1.2}
                          >
                            {row.lastMessage || " "}
                          </TypographyEllipsis>

                          {row.unreadMessagesCount ? (
                            <Tooltip title={t("Unread messages")}>
                              <Badge
                                badgeContent={row.unreadMessagesCount}
                                color="primary"
                                sx={(theme) => ({
                                  ".MuiBadge-badge": {
                                    position: "relative",
                                    transform: "none",
                                  },
                                })}
                              />
                            </Tooltip>
                          ) : (
                            <></>
                          )}
                        </Box>
                      </Box>
                    </Button>
                  );
                })}
              </Box>
            </Box>
          </Box>

          <Box
            width="100%"
            minWidth={320}
            sx={{ backgroundColor: "background.default" }}
          >
            <Box
              sx={{ display: "flex", flexDirection: "column", height: "100%" }}
            >
              {selectedChatroom === null ? (
                <Typography textAlign="center" mt={16}>
                  {t("Selecione uma conversa para começar")}
                </Typography>
              ) : (
                <>
                  <Box
                    sx={{
                      px: 8,
                      pt: 3,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 2 }}
                      >
                        <UserAvatar
                          size="large"
                          type={selectedChatroom.offerType}
                        >
                          <Users />
                        </UserAvatar>
                        <Box>
                          <Typography variant="h6" color="primary.main">
                            {selectedChatroom.counterpartyName}
                          </Typography>
                          {/* <Typography variant="caption">
                          {selectedChatData.online ? "Online" : "Offline"}
                        </Typography> */}
                        </Box>
                        <Tooltip
                          title={counterpartStatus ? "Online" : "Offline"}
                        >
                          <Box
                            sx={{
                              width: 12,
                              height: 12,
                              borderRadius: 12,
                              backgroundColor: counterpartStatus
                                ? "success.main"
                                : "error.main",
                              // ml: 1,
                            }}
                          />
                        </Tooltip>
                      </Box>

                      <Tooltip title={t("More")}>
                        <IconButton size="small" onClick={handleOpenMoreMenu}>
                          <MoreVert />
                        </IconButton>
                      </Tooltip>
                    </Box>

                    <Divider color="#dbdbdb" mt={2} />
                  </Box>

                  <Box
                    ref={conversationBoxRef}
                    sx={{
                      flex: 1,
                      px: 8,
                      overflowY: "auto",
                      scrollBehavior: "smooth",
                    }}
                  >
                    {messages.map((message, idx, arr) => {
                      // console.log(selectedChatData?.lastReadingDate);
                      // console.log(firstUnreadMessageIndex);

                      const showDate =
                        idx === 0 ||
                        getISOYearMonthDay(message.createdAt) !==
                          getISOYearMonthDay(arr[idx - 1].createdAt);

                      return (
                        <Box
                          key={message.createdAt}
                          sx={{
                            mt:
                              idx > 0 &&
                              !showDate &&
                              arr[idx - 1].sentByMe === message.sentByMe
                                ? 2
                                : 4,
                            mb: idx === arr.length - 1 ? 4 : 0,
                          }}
                        >
                          {showDate && (
                            <MessageDivider>
                              {parseDate(message.createdAt)?.toLocaleDateString(
                                language,
                                {
                                  timeZone: "UTC",
                                }
                              )}
                            </MessageDivider>
                          )}

                          {idx === firstUnreadMessageIndex && (
                            <MessageDivider>{t("New messages")}</MessageDivider>
                          )}

                          <Box
                            sx={{
                              display: "flex",
                              gap: 2,
                              flexDirection: message.sentByMe
                                ? "row-reverse"
                                : "row",
                              alignItems: "flex-end",
                            }}
                          >
                            <UserAvatar
                              size="small"
                              type={
                                !message.sentByMe
                                  ? selectedChatroom.offerType
                                  : undefined
                              }
                            >
                              {message.sentByMe ? (
                                <User size="12px" />
                              ) : (
                                <Users size="12px" />
                              )}
                            </UserAvatar>

                            <Box
                              sx={{
                                maxWidth: "80%",
                              }}
                            >
                              <Box
                                sx={{
                                  fontSize: "0.875rem",
                                  fontWeight: 500,
                                  p: 2,
                                  backgroundColor: message.sentByMe
                                    ? "primary.main"
                                    : "#DCE8FF",
                                  color: message.sentByMe
                                    ? "common.white"
                                    : "common.black",
                                  borderRadius: 2,
                                  borderBottomLeftRadius: !message.sentByMe
                                    ? 0
                                    : undefined,
                                  borderBottomRightRadius: message.sentByMe
                                    ? 0
                                    : undefined,
                                }}
                              >
                                {message.text || " "}
                              </Box>
                            </Box>
                          </Box>

                          {lastCounterpartReadMessageIndex === idx && (
                            <Box
                              fontSize={10}
                              display="flex"
                              alignItems="center"
                              justifyContent="flex-end"
                              flexWrap="nowrap"
                              mr={8}
                              mt={1}
                              gap={1}
                            >
                              <div>{t("Seen")}</div>
                              <Done color="primary" fontSize="inherit" />
                            </Box>
                          )}
                        </Box>
                      );
                    })}
                  </Box>

                  <Box
                    sx={(theme) => ({
                      backgroundColor:
                        theme.palette.mode === "dark" ? "#4C6882" : "#deeaf5",
                      display: "flex",
                      p: 6,
                      minHeight: "5.625rem",
                      alignItems: "center",
                      justifyContent: "center",
                    })}
                  >
                    {!canSendMessages ? (
                      <Typography
                        variant="caption"
                        display="block"
                        textAlign="center"
                      >
                        {t(
                          "Unable to send new messages because the offer has ended"
                        )}
                      </Typography>
                    ) : (
                      <Box
                        component="form"
                        onSubmit={handleSubmitMessage}
                        sx={{
                          display: "flex",
                          gap: 4,
                          flex: 1,
                          width: "100%",
                        }}
                      >
                        <PaperInput
                          ref={newMessageRef}
                          value={newMessageValue}
                          onChange={(e) => setNewMessageValue(e.target.value)}
                          placeholder={`${t("Type a message here")}...`}
                          fullWidth
                        />

                        <Tooltip title={t("Send")}>
                          <Button
                            type="submit"
                            variant="contained"
                            sx={{
                              borderRadius: 1000,
                              height: 40,
                              width: 40,
                              minWidth: "unset",
                            }}
                          >
                            <Send fontSize="small" />
                          </Button>
                        </Tooltip>
                      </Box>
                    )}
                  </Box>
                </>
              )}
            </Box>
          </Box>

          <Box minWidth={320} display="flex" px={3}>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                overflow: "auto",
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              <ChatModalBidAreaContent
                hasSelectedChatroom={selectedChatroom !== null}
                operation={operation}
                offer={offer}
                bid={bid}
                isBidButtonDisabled={!canSendMessages}
              />
            </Box>
          </Box>
        </Box>
      </StyledModal>
    </>
  );
}
