import { Observable, Subscription } from "rxjs";
import { OperationOrderBookResume } from "../entities/data-objects/OfferOrderBookResume";
import { OffersByOrderBookObservableImpl } from "../infra/firestore/OffersByOrderBookObservableImpl";

export abstract class OffersPanelBus {
  abstract listenLastNOperations(): void;
}

export type OffersData = {
  sellOffers: OperationOrderBookResume[];
  buyOffers: OperationOrderBookResume[];
  amount: number | null;
  value: number | null;
  offersReached: number;
};

export type options = {
  value: number | null;
  amount: number | null;
  productId: string | null;
  submarket: string | null;
  limit: number;
};

export abstract class GetOffersByOrderBookObservable extends Observable<OffersData> {
  abstract subscribeWithOptions(
    options: options,
    next?: ((value: OffersData) => void) | null | undefined,
    error?: ((error: any) => void) | null | undefined,
    complete?: (() => void) | null | undefined
  ): Subscription;

  abstract setOptions(options: options): void;
}

export class GetOffersByOrderBookObservableService {
  private observable: GetOffersByOrderBookObservable | null = null;

  public getOffersByOrderBookObservable() {
    if (this.observable === null) {
      this.observable = new OffersByOrderBookObservableImpl();
    }

    return this.observable;
  }
}
