import { useMutation } from "@tanstack/react-query";
import { createEnergyDeposit } from "../infra/api/BankInfra";

export function useSubmitEnergyDeposit(options?: { onSuccess?: () => void }) {
  const { mutate: submitEnergyDepositMutate, ...rest } = useMutation(
    createEnergyDeposit,
    options
  );

  return { submitEnergyDepositMutate, ...rest };
}
