import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { Paper, Typography } from "@mui/material";

import SignInComponent from "../../components/auth/SignIn";
import { useTranslation } from "react-i18next";
import useAuth from "../../hooks/useAuth";
import { Navigate } from "react-router-dom";
import { StyledLogo } from "./StyledLogo";

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function SignIn() {
  const { t } = useTranslation();

  const { isAuthenticated, isInitialized } = useAuth();

  if (isInitialized && isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <React.Fragment>
      <StyledLogo />

      <Wrapper>
        <Helmet title={t("Sign In")} />

        <Typography component="h1" variant="h4" align="center" gutterBottom>
          {t("Welcome")}
        </Typography>
        <Typography component="h2" variant="body1" align="center">
          {t("Sign in to your account to continue")}
        </Typography>

        <SignInComponent />
      </Wrapper>
    </React.Fragment>
  );
}

export default SignIn;
