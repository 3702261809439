import { format, isValid, parseISO, sub } from "date-fns";
import { ptBR, enUS } from "date-fns/locale";
import i18next from "i18next";

export type ParseableDate =
  | Date
  | string
  | number
  | { seconds: number; nanoseconds: number }
  | null
  | undefined;

export function parseDate(date: ParseableDate) {
  if (!date) return null;

  try {
    if (
      typeof date === "object" &&
      !(date instanceof Date) &&
      date?.seconds !== undefined &&
      date?.nanoseconds !== undefined
    ) {
      return new Date(date.seconds * 1000 + date.nanoseconds / 1000000);
    }

    const date2 = date as Date | string | number;

    const newDate = typeof date === "string" ? parseISO(date) : new Date(date2);
    if (!isValid(newDate)) return null;
    return newDate;
  } catch {
    return null;
  }
}

export function getISOYearMonthDay(date: ParseableDate) {
  const newDate = parseDate(date);
  if (newDate === null) return null;
  return format(newDate, "yyyy-MM-dd");
}

export function getISOYearAndMonth(date: ParseableDate) {
  const newDate = parseDate(date);
  if (newDate === null) return null;
  return format(newDate, "yyyy-MM");
}

export function getLocaleMonthAndYearString(
  date: ParseableDate,
  locale?: string
) {
  const newDate = parseDate(date);
  if (newDate === null) return null;
  return locale === "pt"
    ? format(newDate, "LLLL/yyyy", { locale: ptBR })
    : format(newDate, "LLLL yyyy", { locale: enUS });
}

export function getLocaleMonthAndYearShortString(
  date: ParseableDate,
  locale?: string
) {
  const newDate = parseDate(date);
  if (newDate === null) return null;
  return locale === "pt"
    ? format(newDate, "LLL/yy", { locale: ptBR })
    : format(newDate, "LLL yy", { locale: enUS });
}

export function getLocaleMonthAndYearPeriodShortString(
  dateA: ParseableDate,
  dateB: ParseableDate,
  locale = "pt"
) {
  const a = parseDate(dateA);
  const b = parseDate(dateB);
  if (!a || !b) return "-";

  const periodStart = getLocaleMonthAndYearShortString(a, locale);
  const periodEnd = getLocaleMonthAndYearShortString(b, locale);
  const period =
    periodStart && periodEnd
      ? periodStart === periodEnd
        ? periodStart
        : `${periodStart} ${i18next.getFixedT(locale)("to")} ${periodEnd}`
      : "-";

  return period;
}

export function getSplitedTimeDifference(
  startTime: ParseableDate,
  endTime: ParseableDate,
  allowNegative = false
) {
  const start = parseDate(startTime);
  const end = parseDate(endTime);

  let differenceInSeconds =
    start && end && isValid(start) && isValid(end)
      ? Math.floor((end.valueOf() - start.valueOf()) / 1000)
      : 0;

  if (!allowNegative) {
    differenceInSeconds = Math.max(0, differenceInSeconds);
  }

  const hours = Math.floor(differenceInSeconds / (60 * 60)).toString();
  const minutes = (Math.floor(differenceInSeconds / 60) % 60)
    .toString()
    .padStart(2, "0");
  const seconds = (differenceInSeconds % 60).toString().padStart(2, "0");

  return {
    hours,
    minutes,
    seconds,
    totalSeconds: differenceInSeconds,
  };
}

export function getPastDateBasedOnPeriod(
  date: Date,
  groupPeriod: "hour" | "day" | "week"
) {
  const periodMap = {
    hour: { hours: 1 },
    day: { days: 1 },
    week: { weeks: 1 },
  };

  const subtractConfig = periodMap[groupPeriod] || {};

  return sub(date, subtractConfig);
}
