import { Box, FormControl, Select, SelectProps } from "@mui/material";

export function RoundedSelect(props: any) {
  return (
    <FormControl>
      {/* <InputLabel id="demo-select-label">Label</InputLabel> */}
      <Select
        // labelId="demo-select-label"
        {...props}
        size={props.size ?? "small"}
        sx={[
          {
            backgroundColor: "background.default",
            borderRadius: 6,
            paddingLeft: 3,
            minWidth: 120,
            "& .MuiInputBase-input": { textAlign: "center" },
          },
          ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
        ]}
        startAdornment={
          props.startAdornment && <Box mr={4}>{props.startAdornment}</Box>
        }
      />
    </FormControl>
  );
}
