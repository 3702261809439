import { Product } from "../../../types/product";
import {
  balcaoApiInstance,
  registrationApiInstance,
} from "../../../utils/axios";
import { compareStrings } from "../../../utils/string";

export async function getProducts() {
  const { data } = await balcaoApiInstance.get<Array<Product>>("/product");

  return data;
}

export async function getProduct(
  productId: number | string | undefined | null
) {
  if (!productId) {
    return null;
  }

  const { data } = await balcaoApiInstance.get<Product>(
    `/product/${productId}`
  );

  return data;
}

export async function createProduct({ productData }: { productData: Product }) {
  const { data } = await balcaoApiInstance.post("/product", productData);

  return data;
}

export async function editProduct({
  productData,
  productId,
}: {
  productData: Product;
  productId: number | string;
}) {
  const { data } = await balcaoApiInstance.patch(
    `/product/${productId}`,
    productData
  );

  return data;
}

export async function deactivateProduct({
  productId,
}: {
  productId: string | number;
}) {
  const { data } = await balcaoApiInstance.patch(
    `/product/${productId}/deactivate`
  );

  return data;
}

export async function activateProduct({
  productId,
}: {
  productId: string | number;
}) {
  const { data } = await balcaoApiInstance.patch(
    `/product/${productId}/activate`
  );

  return data;
}

export async function getActiveParticipants() {
  const { data } = await registrationApiInstance.get<
    Array<Record<string, any>>
  >("/participants/active");

  const activeParticipants = data.map((item) => ({
    id: item.id,
    tradeName: item.companyTradeName,
    cnpj: item.companyCnpj,
  }));
  return activeParticipants;
}

export async function getActiveProducts() {
  const products = await getProducts();

  const activeProducts = products
    // .filter((item) => item.active)
    .map((item) => ({
      id: item.id,
      name: item.name,
      assetsAvailableCode: item.assetsAvailableCode,
    }));

  return activeProducts;
}

export async function getParticipantProductIds(
  participantId: number | string | null
) {
  if (!participantId) return null;

  const { data } = await balcaoApiInstance.get<{
    participantProducts: Array<{
      id: string;
      profileCode: string;
      profileKey: string;
    }>;
  }>(`/product/participantvsproduct/${participantId}`);

  return data.participantProducts;
}

export async function getParticipantProducts(
  participantId: number | string | null
) {
  if (!participantId) return [];

  const { data } = await balcaoApiInstance.get<{
    participantProducts: Array<Product>;
  }>(`/product/participantvsproduct/${participantId}`);

  return data.participantProducts.sort((a, b) =>
    compareStrings(a.name, b.name)
  );
}

export async function updateAssociateParticipantProducts({
  productList,
  participantId,
  profileKeyByProductId,
  profileCodeByProductId,
}: {
  productList: Array<string>;
  participantId: number | string;
  profileKeyByProductId: any;
  profileCodeByProductId: any;
}) {
  const { data } = await balcaoApiInstance.post(
    `/product/participantvsproduct/${participantId}`,
    {
      products: productList.map((productId) => ({
        productId,
        profileKey: profileKeyByProductId[productId],
        profileCode: profileCodeByProductId[productId],
      })),
    }
  );

  return data;
}

export interface Asset {
  code: string;
  name: string;
  unit: string;
  type: string;
  precision: number;
  avatar: string;
}
export interface AssetBalance {
  asset: Asset;
  balance: number;
  blockedBalance: number;
  submarket: string;
}
export interface BalanceData {
  assetBalances: Array<AssetBalance>;
}

export async function getBalanceBySubmarket(
  participantId: number | string | null,
  referenceMonth: string = ""
) {
  if (!participantId) return null;

  const { data } = await balcaoApiInstance.get<BalanceData>(
    `/wallets/participant/${participantId}?referenceMonth=${referenceMonth}`
  );

  return data;
}
