import React from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import { Line } from "react-chartjs-2";

import { Box, CardContent, Card as MuiCard, Typography } from "@mui/material";
import { spacing } from "@mui/system";
import { alpha } from "@mui/material/styles";

import { ThemeProps } from "../../types/theme";
import { ChartData, ChartOptions } from "chart.js";

const Card = styled(MuiCard)(spacing);

const Spacer = styled.div(spacing);

interface LineChartProps {
  data: Array<number>;
  labels: Array<string>;
  title?: JSX.Element | string;
  subtitle?: string;
  label?: string;
  height?: string | number;
  color?: string;
}

function LineChart({
  theme,
  data,
  labels,
  title,
  subtitle,
  label,
  height,
  color,
}: LineChartProps & ThemeProps) {
  const chartData: ChartData<"line"> = {
    labels,
    datasets: [
      {
        label,
        fill: true,
        // backgroundColor: "transparent",
        backgroundColor: function (context: any) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            return null;
          }

          const gradient = ctx.createLinearGradient(0, 0, 0, 300);
          gradient.addColorStop(
            0,
            alpha(color ?? theme.palette.secondary.main, 0.0875)
          );
          gradient.addColorStop(1, "rgba(0, 0, 0, 0)");

          return gradient;
        },
        borderColor: color ?? theme.palette.secondary.main,
        data,
      },
    ],
  };

  const chartOptions: ChartOptions<"line"> = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          color: "rgba(0,0,0,0.05)",
        },
      },
      y: {
        display: true,
        grid: {
          color: "rgba(0,0,0,0)",
        },
        ticks: {
          precision: 0,
        },
      },
    },
  };

  return (
    <Card>
      <CardContent>
        {title && (
          <Typography variant="h6" gutterBottom>
            {title}
          </Typography>
        )}
        {subtitle && (
          <Typography variant="body2" gutterBottom>
            {subtitle}
          </Typography>
        )}

        <Spacer mb={6} />

        <Box height={height}>
          <Line data={chartData} options={chartOptions} />
        </Box>
      </CardContent>
    </Card>
  );
}

export default withTheme(LineChart);
