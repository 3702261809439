import React from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import {
  Alert as MuiAlert,
  Autocomplete,
  Box,
  Button,
  InputAdornment,
  Paper,
  useMediaQuery,
  useTheme,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { FileDownload } from "@mui/icons-material";
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
  useWatch,
} from "react-hook-form";
import { useSnackbar } from "notistack";
import { FormTextField } from "../formFields/FormTextField";
import { StyledTextField } from "../StyledTextField";
import { getCustomNumberInputProps } from "../directBuyAndSell/BigInput";
import useConfirmationModal from "../../hooks/useConfirmationModal";
import { toFixedWithoutRounding } from "../../utils/number";
import { getCurrencySymbol, toLocaleCurrencyString } from "../../utils/string";
import { useNavigate } from "react-router-dom";
import { useGetBanks } from "./hooks/useGetBanks";
import { useSubmitFinancialWithdrawal } from "./hooks/useSubmitFinancialWithdrawal";
import { useGetParticipantBankData } from "./hooks/useGetParticipantBankData";

const dataListCountry: Array<{ name: string; id: string }> = [
  { name: "Brasil", id: "Brasil" },
];

const accountTypeOptions = [
  {
    name: "Conta Corrente",
    id: "Conta Corrente",
  },
];

const Alert = styled(MuiAlert)(spacing);

const InfoBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24;
`;

interface Inputs {
  countryBankName: string | null;
  bankId: string | null;
  accountOwnerName: string;
  accountType: string | null;
  accountNumber: string;
  agencyNumber: string;
  value: string;
  password: string;
}

const inputsDefaultValues: Inputs = {
  countryBankName: "Brasil",
  bankId: null,
  accountOwnerName: "",
  accountType: null,
  accountNumber: "",
  agencyNumber: "",
  value: "",
  password: "",
};

export function FinancialWithdrawalPageContent() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  const { showConfirmationModal } = useConfirmationModal();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: inputsDefaultValues,
  });

  const selectedBankCountry = useWatch({ control, name: "countryBankName" });

  const {
    banks,
    isLoading: isLoadingBanks,
    isError: isErrorBanks,
  } = useGetBanks();

  const {
    participantBankData,
    isLoading: isLoadingParticipantBankData,
    isError: isErrorParticipantBankData,
  } = useGetParticipantBankData();

  React.useEffect(() => {
    if (participantBankData) {
      setValue("bankId", participantBankData?.bank?.bankId);
      setValue("accountNumber", participantBankData?.bank?.accountNumber);
      setValue("agencyNumber", participantBankData?.bank?.agencyNumber);
    }
  }, [participantBankData, setValue]);

  const {
    submitFinancialWithdrawalMutate,
    isLoading: isLoadingSubmitFinancialWithdrawal,
  } = useSubmitFinancialWithdrawal({
    onSuccess: () => {
      navigate("/bank/manage-deposits-and-withdrawals");
      enqueueSnackbar(t("Withdrawal request completed successfully"), {
        variant: "success",
      });
    },
    onError: (error) => {
      enqueueSnackbar(t(error.message), {
        variant: "error",
      });
    },
  });

  const handleSubmitFinancialWithdrawal = React.useCallback(
    ({
      countryBankName,
      bankId,
      accountOwnerName,
      accountType,
      accountNumber,
      agencyNumber,
      value,
      password,
    }: Inputs) => {
      if (countryBankName === null || bankId === null || accountType === null) {
        enqueueSnackbar(t("Something went wrong."), {
          variant: "error",
        });
        return;
      }

      showConfirmationModal({
        onSubmit: () => {
          submitFinancialWithdrawalMutate({
            countryBankName,
            bankId,
            accountOwnerName,
            accountType,
            accountNumber,
            agencyNumber,
            value: Number(value),
            password,
          });
        },
        message: (
          <>
            {t("Confira as informações de sua solicitação de saque.")}
            <br />
            <br />
            {t('Em conformidade com os dados, basta clicar em "Confirmar".')}
            <br />
            <br />
            {t(
              "Em seguida, será gerado um contrato para assinatura dos responsáveis legais da empresa na aba “Contrato”."
            )}
          </>
        ),
        submitButtonColor: "primary",
      });
    },
    [enqueueSnackbar, showConfirmationModal, submitFinancialWithdrawalMutate, t]
  );

  const onSubmit = React.useCallback<SubmitHandler<Inputs>>(
    (data) => {
      handleSubmitFinancialWithdrawal(data);
    },
    [handleSubmitFinancialWithdrawal]
  );

  const onInvalidSubmit = React.useCallback<SubmitErrorHandler<Inputs>>(() => {
    enqueueSnackbar(t("You must fill in all required fields"), {
      variant: "error",
    });
  }, [enqueueSnackbar, t]);

  if (isErrorBanks || isErrorParticipantBankData) {
    return (
      <Alert mt={2} mb={3} severity="error">
        {t("Something went wrong.")}
      </Alert>
    );
  }

  if (isLoadingBanks || banks === undefined || isLoadingParticipantBankData) {
    return (
      <InfoBox>
        <CircularProgress />
      </InfoBox>
    );
  }

  return (
    <Paper sx={{ maxWidth: 1200, mx: "auto", p: isMdUp ? 12 : 8 }}>
      <Box component="form" onSubmit={handleSubmit(onSubmit, onInvalidSubmit)}>
        <Grid
          container
          sx={{
            maxWidth: 1000,
            mx: "auto",
          }}
        >
          <Grid item xs={12} md={6} pr={isMdUp ? 4 : 0}>
            <Box
              sx={{
                maxWidth: 460,
                mx: "auto",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography variant="h6" mb={8}>
                {t("Bank Information")}
              </Typography>

              <Controller
                control={control}
                name={"countryBankName"}
                rules={{
                  required: {
                    value: true,
                    message: t("This field is required"),
                  },
                  onChange: () => {
                    setValue("bankId", null);
                  },
                }}
                render={({ field: { ref, onChange, ...field } }) => (
                  <Autocomplete
                    {...field}
                    onChange={(e, newValue) => {
                      onChange(newValue);
                    }}
                    readOnly
                    openOnFocus
                    handleHomeEndKeys
                    disablePortal
                    options={dataListCountry?.map((item) => item.id) ?? []}
                    fullWidth
                    renderInput={(params) => (
                      <StyledTextField
                        {...params}
                        inputRef={ref}
                        label={t("Bank Country")}
                        error={!!errors.countryBankName}
                        helperText={errors.countryBankName?.message ?? " "}
                        // size="small"
                      />
                    )}
                  />
                )}
              />

              <Controller
                control={control}
                name={"bankId"}
                rules={{
                  required: {
                    value: true,
                    message: t("This field is required"),
                  },
                }}
                render={({ field: { ref, onChange, ...field } }) => (
                  <Autocomplete
                    {...field}
                    onChange={(e, newValue) => {
                      onChange(newValue);
                    }}
                    readOnly
                    openOnFocus
                    handleHomeEndKeys
                    disablePortal
                    options={
                      selectedBankCountry === null
                        ? []
                        : banks?.map((item) => item.id) ?? []
                    }
                    getOptionLabel={(option) =>
                      banks.find((item) => item.id === option)?.description ??
                      option
                    }
                    fullWidth
                    renderInput={(params) => (
                      <StyledTextField
                        {...params}
                        inputRef={ref}
                        label={t("Bank Name")}
                        error={!!errors.bankId}
                        helperText={errors.bankId?.message ?? " "}
                        // size="small"
                      />
                    )}
                  />
                )}
              />

              <FormTextField
                control={control}
                name={"accountOwnerName"}
                rules={{
                  required: {
                    value: true,
                    message: t("This field is required"),
                  },
                }}
                label={t("Account Holder Name")}
                fullWidth
                // size="small"
              />

              <Controller
                control={control}
                name={"accountType"}
                rules={{
                  required: {
                    value: true,
                    message: t("This field is required"),
                  },
                }}
                render={({ field: { ref, onChange, ...field } }) => (
                  <Autocomplete
                    {...field}
                    onChange={(e, newValue) => {
                      onChange(newValue);
                    }}
                    openOnFocus
                    handleHomeEndKeys
                    disablePortal
                    options={accountTypeOptions?.map((item) => item.id) ?? []}
                    getOptionLabel={(option) =>
                      accountTypeOptions.find((item) => item.id === option)
                        ?.name ?? option
                    }
                    fullWidth
                    renderInput={(params) => (
                      <StyledTextField
                        {...params}
                        inputRef={ref}
                        label={t("Account Type")}
                        error={!!errors.accountType}
                        helperText={errors.accountType?.message ?? " "}
                        // size="small"
                      />
                    )}
                  />
                )}
              />

              <FormTextField
                control={control}
                name={"accountNumber"}
                InputProps={{ readOnly: true }}
                rules={{
                  required: {
                    value: true,
                    message: t("This field is required"),
                  },
                }}
                label={t("Account Number")}
                fullWidth
                // size="small"
              />

              <FormTextField
                control={control}
                name={"agencyNumber"}
                InputProps={{ readOnly: true }}
                rules={{
                  required: {
                    value: true,
                    message: t("This field is required"),
                  },
                }}
                label={t("Agency Number")}
                fullWidth
                // size="small"
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6} pl={isMdUp ? 4 : 0} pt={isMdUp ? 0 : 6}>
            <Box
              sx={{
                maxWidth: 460,
                mx: "auto",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography variant="h6" mb={8}>
                {t("Withdrawal Information")}
              </Typography>

              <FormTextField
                control={control}
                name={"value"}
                rules={{
                  required: {
                    value: true,
                    message: t("This field is required"),
                  },
                  min: {
                    value: 100,
                    message: t("The minimum allowed value is {{value}}", {
                      value: toLocaleCurrencyString(100, "BRL", language),
                    }),
                  },
                  max: {
                    value: 999999999,
                    message: t("The maximum allowed value is {{value}}", {
                      value: toLocaleCurrencyString(999999999, "BRL", language),
                    }),
                  },
                  onChange: (e) => {
                    if (e.target.value.split(".")[1]?.length > 2)
                      setValue(
                        "value",
                        toFixedWithoutRounding(e.target.value, 2)
                      );
                  },
                }}
                label={`${t("Withdrawal Value")} (${getCurrencySymbol(
                  "BRL",
                  language
                )})`}
                fullWidth
                type="number"
                inputProps={{ step: "0.01", min: 0 }}
                InputProps={getCustomNumberInputProps({
                  disableArrows: true,
                  disableWheel: true,
                  disableE: true,
                  disableUpDownKeys: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      {getCurrencySymbol("BRL", language)}
                    </InputAdornment>
                  ),
                })}
                autoComplete="off"
                helperText={`${t("Min")} ${toLocaleCurrencyString(
                  100,
                  "BRL",
                  language
                )}`}
                // size="small"
              />

              <FormTextField
                control={control}
                name={"password"}
                rules={{
                  required: {
                    value: true,
                    message: t("This field is required"),
                  },
                }}
                label={t("Password")}
                fullWidth
                type="password"
                autoComplete="new-password"
                // size="small"
              />

              <Button
                type="submit"
                variant="contained"
                sx={{ minWidth: "16ch", mt: 8 }}
                disabled={isLoadingSubmitFinancialWithdrawal}
              >
                <FileDownload sx={{ marginRight: 2 }} /> {t("Withdraw")}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}
