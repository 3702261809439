import { useQuery } from "@tanstack/react-query";
import useChooseParticipant from "../../../hooks/useChooseParticipant";
import { getParticipantProducts } from "../infra/ProductsInfra";

export function useGetParticipantProducts() {
  const { currentParticipant } = useChooseParticipant();

  const { data: participantProducts, ...rest } = useQuery(
    ["getParticipantProducts", currentParticipant?.id],
    () => getParticipantProducts(currentParticipant?.id ?? null),
    {
      refetchOnWindowFocus: false,
    }
  );

  return { participantProducts, ...rest };
}
