import { Observable, Subscription } from "rxjs";
import * as firestore from "firebase/firestore";
import type { options } from "../../services/GetMyHistoricObservableService";
import {
  MyOrderResume,
  OfferResume,
} from "../../entities/data-objects/OrderResume";
import {
  MyHistoricObservable,
  panelData,
} from "../../services/GetMyHistoricObservableService";

export class MyHistoricObservableImpl
  extends Observable<panelData>
  implements MyHistoricObservable
{
  private firestoreListener: () => void = () => {};
  private options: options = {
    page: 1,
    itemsPerPage: 20,
    participant: -1,
    product: null,
    submarket: null,
    // onlyMyOffers: false,
    // showFinishedOffers: false,
    // onlyOffersWithMyBids: false,
  };
  private subscribers: {
    subscription: Subscription;
    next: ((value: panelData) => void) | null | undefined;
  }[] = [];

  private pageStartBaseElements: {
    [key: number]:
      | firestore.QueryDocumentSnapshot<firestore.DocumentData>
      | number;
  } = {
    1: 0,
  };

  subscribeWithOptions(
    options: options,
    next?: ((value: panelData) => void) | null | undefined,
    error?: ((error: any) => void) | null | undefined,
    complete?: (() => void) | null | undefined
  ): Subscription {
    this.setOptions(options);
    this.options = options;
    const subscription = this.subscribe(next, error, complete);
    this.subscribers.push({
      subscription,
      next,
    });

    subscription.add(() => {
      this.subscribers = this.subscribers.filter(
        (s) => s.subscription !== subscription
      );
      if (this.subscribers.length === 0) {
        this.firestoreListener();
      }
    });

    return subscription;
  }

  setOptions(options: options) {
    this.options = options;
    this.startFirestoreListener();
  }

  private startFirestoreListener() {
    this.firestoreListener();

    const queryFilters: firestore.QueryConstraint[] = [
      // firestore.where("market", "==", "SPOT"),
      // firestore.where("participantId", "==", this.options.participant),
    ];

    // const offerStatusToShow = ["ABERTA", "PARCIALMENTE ENCERRADA"];

    if (this.options.participant !== null) {
      queryFilters.push(
        firestore.where("participantId", "==", this.options.participant)
      );
    }

    if (this.options.product !== null) {
      queryFilters.push(
        firestore.where("product.id", "==", this.options.product)
      );
    }

    if (this.options.submarket !== null) {
      queryFilters.push(
        firestore.where("submarket", "==", this.options.submarket)
      );
    }

    // queryFilters.push(firestore.where("status", "in", offerStatusToShow));

    const query = firestore.query(
      firestore.collection(firestore.getFirestore(), "spotTransactions"),
      firestore.orderBy("offerCreationDate", "desc"),
      ...queryFilters
    );

    const queryWithLimits = firestore.query(
      firestore.collection(firestore.getFirestore(), "spotTransactions"),
      firestore.orderBy("offerCreationDate", "desc"),
      ...(this.pageStartBaseElements[this.options.page]
        ? [firestore.startAfter(this.pageStartBaseElements[this.options.page])]
        : []),
      firestore.limit(this.options.itemsPerPage * 3), // heurística, pega sempre 3x mais que o necessário p/ filtrar as expiradas, uma melhoria pro futuro seria adicionar uma flag expired: true nas operações e de tempos em tempos atualizar essa flag para dar mais precisão na contagem das páginas seguintes
      ...queryFilters
    );

    this.firestoreListener = firestore.onSnapshot(
      queryWithLimits,
      async (snapshot) => {
        const snapshotToCount = await firestore.getCountFromServer(query);
        const totalItems = snapshotToCount.data().count;

        this.subscribers.forEach((subscriber) => {
          if (!subscriber.next) {
            return;
          }

          const docsToConsider = snapshot.docs.slice(
            0,
            this.options.itemsPerPage
          );

          const resume = docsToConsider.map((doc) => {
            return this.firestoreDocToOperationResume(
              doc,
              this.options.participant ? this.options.participant : null
            );
          });

          const expiredCounter =
            docsToConsider.length > 0 // considera apenas a primeira página
              ? snapshot.docs.findIndex(
                  (doc) =>
                    doc.id === docsToConsider[docsToConsider.length - 1].id
                ) -
                this.options.itemsPerPage +
                1
              : 0;

          this.pageStartBaseElements[this.options.page + 1] =
            snapshot.docs[docsToConsider.length - 1 + expiredCounter];

          subscriber.next({
            page: 1,
            orders: resume,
            totalItems: totalItems,
          });
        });
      }
    );
  }

  private firestoreDocToOperationResume(
    doc: firestore.QueryDocumentSnapshot<firestore.DocumentData>,
    participantInterested: number | null
  ): MyOrderResume {
    const docData = doc.data();

    const orderResume = new MyOrderResume(
      docData.operationId,
      docData.operationCreatorId,
      docData.participantId,
      docData.product.name,
      docData.product.measurementUnit,
      docData.submarket,
      docData.operationType === "BUY"
        ? new OfferResume(
            `${docData.operationId}b`,
            docData.operationId,
            docData.transactionVolume / 1000,
            0,
            docData.value / 100,
            null,
            null,
            0, // toDo -> realizar calculo baseado no melhor lance do participante (quando trazer esses dados de lance p/ operação)
            (docData.value - docData.PLD) / 100,
            true,
            null,
            "FINALIZADA",
            docData.operationType === "BUY" ? "COMPRA" : "VENDA"
          )
        : null,
      docData.operationType === "SELL"
        ? new OfferResume(
            `${docData.operationId}s`,
            docData.operationId,
            docData.transactionVolume / 1000,
            0,
            docData.value / 100,
            null,
            null,
            0, // toDo -> realizar calculo baseado no melhor lance do participante (quando trazer esses dados de lance p/ operação)
            (docData.value - docData.PLD) / 100,
            true,
            null,
            "FINALIZADA",
            docData.operationType === "BUY" ? "COMPRA" : "VENDA"
          )
        : null,
      docData.offerCreationDate.toDate(),
      docData.date.toDate()
    );

    return orderResume;
  }

  private getOfferBestBid(
    participantsBidsBuyOffer: {
      amount: number;
      value: 11;
    }[],
    type: "BUY" | "SELL"
  ) {
    if (participantsBidsBuyOffer.length === 0) {
      return null;
    }
    return participantsBidsBuyOffer.sort((a, b) =>
      type === "BUY" ? a.value - b.value : b.value - a.value
    )[0];
  }
}
