import { balcaoApiInstance } from "../../../../utils/axios";
import { ChatroomRepository } from "../../services/GetChatroomIdService";
import { ChatroomRepository as ChatroomRepositoryB } from "../../services/DeleteChatroomService";

export class ChatroomRepositoryImpl
  implements ChatroomRepository, ChatroomRepositoryB
{
  async deleteChatroom(chatroomId: string): Promise<void> {
    await balcaoApiInstance.delete(`/chat-room/${chatroomId}`);
  }

  async getChatroomId(operationId: string, offerType: string): Promise<string> {
    const { data } = await balcaoApiInstance.get(
      `/chat-room?operationId=${operationId}&offerType=${offerType}`
    );
    return data.id;
  }
}
