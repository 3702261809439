import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { SummaryTable } from "../../components/SummaryTable";
import { toLocaleCurrencyString } from "../../utils/string";
import { Contract } from "../../types/contracts";
import { StyledModal } from "../../components/StyledModal";
import {
  StatusChip,
  StyledFooterChip,
} from "../../components/StyledFooterChip";

export const getBuyerAndSellerData = (row: Contract) => {
  if (
    !row.acceptanceTerms?.acceptedByWinner ||
    !row.acceptanceTerms?.acceptedByOwner
  ) {
    return { buyerName: "-", sellerName: "-" };
  }

  if ((row.offer as any).offerType === "COMPRA") {
    return {
      buyerName: row.ownerParticipant?.participant?.companyTradeName ?? "-",
      sellerName: row.winnerParticipant?.participant?.companyTradeName ?? "-",
    };
  } else {
    return {
      buyerName: row.winnerParticipant?.participant?.companyTradeName ?? "-",
      sellerName: row.ownerParticipant?.participant?.companyTradeName ?? "-",
    };
  }
};

interface ContractModalContentProps {
  contract: Contract;
  onClose?: () => void;
}
export function ContractModalContent({
  contract,
  onClose,
}: ContractModalContentProps) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { buyerName, sellerName } = getBuyerAndSellerData(contract);

  const value = React.useMemo(
    () =>
      contract.winnerBid.value +
      ((contract.offer as any).priceType === "Atrelado ao PLD"
        ? contract.pldToConsider ?? 0
        : 0),
    [
      (contract.offer as any).priceType,
      contract.pldToConsider,
      contract.winnerBid.value,
    ]
  );

  const contractSummaryData1 = React.useMemo(
    () =>
      contract
        ? [
            [
              {
                label: t("IssueDate"),
                value: contract.contract?.issueDate
                  ? new Date(contract.contract?.issueDate).toLocaleDateString(
                      language,
                      { timeZone: "UTC" }
                    )
                  : "-",
              },
              {
                label: t("Buyer"),
                value: buyerName,
              },
              {
                label: t("Seller"),
                value: sellerName,
              },
              {
                label: t("Submarket"),
                value: contract.operation.submarket,
              },
              {
                label: t("Source Type"),
                value: contract.operation.product?.name ?? "-",
              },
              {
                label: t("Volume"),
                value: `${Number(contract.winnerBid.amount).toLocaleString(
                  language
                )} ${contract.operation.product?.measurementUnit ?? "-"}`,
              },
              {
                label: t("Value"),
                value: toLocaleCurrencyString(value, "BRL", language),
              },
            ],
          ]
        : [],
    [buyerName, contract, language, sellerName, t, value]
  );

  const contractSummaryData2 = React.useMemo(
    () =>
      contract
        ? [
            [
              {
                label: `${t("Deliv. Period")} (${t("Start")})`,
                value: contract.operation.deliveryPeriodStart
                  ? new Date(
                      contract.operation.deliveryPeriodStart
                    ).toLocaleDateString(language, { timeZone: "UTC" })
                  : "-",
              },
              {
                label: `${t("Deliv. Period")} (${t("End")})`,
                value: contract.operation.deliveryPeriodEnd
                  ? new Date(
                      contract.operation.deliveryPeriodEnd
                    ).toLocaleDateString(language, { timeZone: "UTC" })
                  : "-",
              },
              {
                label: t("TotalValue"),
                value: toLocaleCurrencyString(
                  contract.winnerBid.amount * value,
                  "BRL",
                  language
                ),
              },
              {
                label: t("Seasonality"),
                value: t("No"),
              },
              {
                label: t("Flexibility"),
                value: t("No"),
              },
              {
                label: t("Modulation"),
                value: t("No"),
              },
              {
                label: t("Readjustment"),
                value: t("No"),
              },
            ],
          ]
        : [],
    [contract, t, language, value]
  );

  if (!contract) return <></>;

  return (
    <>
      <Box px={2}>
        <Typography variant="h6" gutterBottom>
          {t("Contract Details")}
        </Typography>
      </Box>

      <Box sx={{ p: 2, display: "flex", gap: 2 }}>
        <strong>{t("Status")}: </strong>
        <StatusChip
          color={contract.contract?.status === "signed" ? "success" : "warning"}
        >
          {contract.contract?.status === "signed"
            ? t("Signed")
            : t("Pending Signature")}
        </StatusChip>
      </Box>

      <Button
        component="a"
        href={contract.contract?.contractUrl}
        target="_blank"
        rel="noreferrer"
      >
        {t("View Contract")}
      </Button>

      <Box px={2} mt={6}>
        <Grid container columnSpacing={4}>
          <Grid item xs={12} lg={6}>
            <SummaryTable data={contractSummaryData1} />
          </Grid>

          <Grid item xs={12} lg={6}>
            <SummaryTable data={contractSummaryData2} />
          </Grid>
        </Grid>
      </Box>

      <Box px={2} mt={8}>
        <Typography variant="h6" gutterBottom>
          {t("BuyerSigners")}
        </Typography>

        {(contract.contract?.buyerSigners || []).map((row) => {
          return (
            <Box key={row.id} my={6}>
              <StyledFooterChip
                label={row.name}
                value={row.hasSigned ? t("Signed") : t("Pending")}
                color={row.hasSigned ? "success" : "warning"}
              />
            </Box>
          );
        })}
      </Box>

      <Box px={2} mt={8}>
        <Typography variant="h6" gutterBottom>
          {t("SellerSigners")}
        </Typography>

        {(contract.contract?.sellerSigners || []).map((row) => {
          return (
            <Box key={row.id} my={6}>
              <StyledFooterChip
                label={row.name}
                value={row.hasSigned ? t("Signed") : t("Pending")}
                color={row.hasSigned ? "success" : "warning"}
              />
            </Box>
          );
        })}
      </Box>

      {/* {onClose && (
        <Box
          display="inline-flex"
          justifyContent="center"
          width="100%"
          textAlign="center"
          gap={4}
          mt={8}
        >
          <Button
            type="button"
            variant="outlined"
            color="primary"
            onClick={onClose}
          >
            {t("Go Back")}
          </Button>
        </Box>
      )} */}
    </>
  );
}

export interface ContractModalProps {
  open: boolean;
  onClose?: () => void;
  contract: Contract;
}
export function ContractModal({ open, onClose, contract }: ContractModalProps) {
  const { t } = useTranslation();

  return (
    <StyledModal
      leftBox={t("Contract")}
      title={t("Contract Details")}
      open={open}
      onClose={onClose}
    >
      <ContractModalContent contract={contract} onClose={onClose} />
    </StyledModal>
  );
}
