export class OfferOrderBookResume {
  constructor(
    private id: string,
    private operationCode: string,
    private volume: number,
    private remainingVolume: number,
    private value: number | null,
    private bestBidValue: number | null,
    private bestBidVolume: number | null,
    private net: number,
    private spread: number | null,
    private isPldFixed: boolean,
    private currentParticipantBidVolume: number | null, // toDo verificar se é o melhor lance do participante, o último, ou se o participante só pode ter um bid por oferta
    private status: string,
    private type: "COMPRA" | "VENDA"
  ) {}

  public getId() {
    return this.id;
  }

  public getOperationCode() {
    return this.operationCode;
  }

  public getVolume() {
    return this.volume;
  }

  public getRemainingVolume() {
    return this.remainingVolume;
  }

  public getValue() {
    return this.value;
  }

  public getBestBidValue() {
    return this.bestBidValue;
  }

  public getBestBidVolume() {
    return this.bestBidVolume;
  }

  public getNet() {
    return this.net;
  }

  public getSpread() {
    return this.spread;
  }

  public getIsPldFixed() {
    return this.isPldFixed;
  }

  public getCurrentParticipantBidVolume() {
    return this.currentParticipantBidVolume === null
      ? 0
      : this.getCurrentParticipantBidVolume;
  }

  public getStatus() {
    return this.status;
  }

  public getType() {
    return this.type;
  }

  public isFreeBid() {
    return (
      (this.value === null || this.value === undefined) &&
      (this.spread === null || this.spread === undefined)
    );
  }

  public getSpreadOrValue() {
    return this.value || this.spread;
  }
}

export class ProductResume {
  constructor(
    private id: string,
    private assetsAvailableCode: string,
    private name: string
  ) {}

  public getId() {
    return this.id;
  }

  public getAssetsAvailableCode() {
    return this.assetsAvailableCode;
  }

  public getName() {
    return this.name;
  }
}

export class OperationOrderBookResume {
  constructor(
    private operationId: string,
    private isOperationOwner: boolean,
    private product: ProductResume,
    private productMeasurementUnit: string,
    private submarketCode: string,
    private buyOffer: OfferOrderBookResume | null,
    private sellOffer: OfferOrderBookResume | null,
    private closeAt: Date,
    private status: string,
    private deliveryPeriodStart: Date,
    private deliveryPeriodEnd: Date,
    private reTusd: boolean,
    private participantClassName: string
  ) {}

  public getOperationId() {
    return this.operationId;
  }

  public getIsOperationOwner() {
    return this.isOperationOwner;
  }

  public getProduct() {
    return this.product;
  }

  public getProductMeasurementUnit() {
    return this.productMeasurementUnit;
  }

  public getSubmarketCode() {
    return this.submarketCode;
  }

  public getBuyOffer() {
    return this.buyOffer;
  }

  public getSellOffer() {
    return this.sellOffer;
  }

  public getCloseAt() {
    return this.closeAt;
  }

  public getStatus() {
    return this.status;
  }

  public getDeliveryPeriodStart() {
    return this.deliveryPeriodStart;
  }

  public getDeliveryPeriodEnd() {
    return this.deliveryPeriodEnd;
  }

  public getReTusd() {
    return this.reTusd;
  }

  public getParticipantClassName() {
    return this.participantClassName;
  }
}
