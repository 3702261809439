import React from "react";
import { Subscription } from "rxjs";
import useChooseParticipant from "../../../hooks/useChooseParticipant";
import { PublicBid } from "../entities/Bid";
import {
  BidsFromOfferObservable,
  GetBidsFromOfferObservableService,
  options,
} from "../services/GetBidsFromOfferObservableService";

const getBidsFromOfferObservableService =
  new GetBidsFromOfferObservableService();

export function useGetBidsFromOffer(
  offerId: string | null | undefined,
  bidType: "BUY" | "SELL"
) {
  const { participantId } = useChooseParticipant();

  const [bids, setBids] = React.useState<PublicBid[]>([]);

  React.useEffect(() => {
    let subscription: Subscription | null = null;
    if (offerId) {
      const options: options = {
        bidType,
        operationId: Number(offerId),
        page: 1,
        participant: Number(participantId || -1),
      };

      subscription = getBidsFromOfferObservableService
        .getBidsFromOfferObservable()
        .subscribeWithOptions(options, (bidsData) => {
          setBids(bidsData.bids);
        });
    }

    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [offerId, participantId]);

  return { bids };
}
