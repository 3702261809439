import { ExpandMore } from "@mui/icons-material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert as MuiAlert,
  Box,
  CircularProgress,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useGetFaqData } from "./hooks/useGetFaqData";

const Alert = styled(MuiAlert)(spacing);

const InfoBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24;
`;

export function DirectBuyAndSellOfferFaqArea() {
  const { t } = useTranslation();

  const { faqData, isLoading, isError } = useGetFaqData();

  const [expandedIdx, setExpandedIdx] = React.useState<number | null>(null);

  return (
    <Box>
      <Typography variant="h3" textAlign="center" my={8}>
        {t("FAQ")}
      </Typography>

      <Box sx={{ p: 6 }}>
        {isError ? (
          <Alert mt={2} mb={3} severity="error">
            {t("Something went wrong.")}
          </Alert>
        ) : isLoading || !faqData ? (
          <InfoBox>
            <CircularProgress />
          </InfoBox>
        ) : (
          faqData.map((item, idx) => {
            return (
              <Accordion
                // disableGutters
                expanded={expandedIdx === idx}
                onChange={(e, isExpanded) => {
                  setExpandedIdx(isExpanded ? idx : null);
                }}
                key={item.question}
                // sx={{
                //   "&.Mui-expanded": {
                //     backgroundColor: "rgba(217, 217, 217, 0.15)",
                //     borderRadius: 1,
                //   },
                // }}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography variant="subtitle2" fontWeight={600}>
                    {`${idx + 1}. ${item.question}`}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    backgroundColor: "rgba(217, 217, 217, 0.15)",
                    borderRadius: 1,
                  }}
                >
                  <Typography
                    // variant="body1"
                    dangerouslySetInnerHTML={{ __html: item.answer }}
                  />
                </AccordionDetails>
              </Accordion>
            );
          })
        )}
      </Box>
    </Box>
  );
}
