import React from "react";
import useChat from "../../../hooks/useChat";
import {
  GetChatCounterpartStatusObservableService,
  StatusObservable,
} from "../services/GetChatCounterpartStatusObservableService";

const getChatCounterpartStatusObservableService =
  new GetChatCounterpartStatusObservableService();

export function useGetCounterpartStatus(chatroomId: string | null) {
  const counterpartStatusObservable = React.useRef<StatusObservable | null>(
    null
  );
  const [counterpartStatus, setCounterpartStatus] = React.useState<
    boolean | null
  >(null);

  const { isChatModalOpen } = useChat();

  React.useEffect(() => {
    const options = {
      chatroomId: isChatModalOpen ? chatroomId : null,
      updateLastReadingDate: isChatModalOpen,
    };

    const subscription = getChatCounterpartStatusObservableService
      .getStatusObservable()
      .subscribeWithOptions(options, (status) => {
        setCounterpartStatus(status);
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [chatroomId, isChatModalOpen]);

  const forceUpdateLastReadingDate = React.useCallback(() => {
    counterpartStatusObservable.current?.forceUpdateLastReadingDate();
  }, []);

  return { counterpartStatus, forceUpdateLastReadingDate };
}
