import React from "react";
import { useRoutes } from "react-router-dom";
import { Provider } from "react-redux";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { CacheProvider } from "@emotion/react";

import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ptBR, enUS } from "date-fns/locale";

import "./i18n";
import createTheme from "./theme";
import routes from "./routes";

import useTheme from "./hooks/useTheme";
import { store } from "./redux/store";
import createEmotionCache from "./utils/createEmotionCache";

import { useTranslation } from "react-i18next";
import { SnackbarProvider } from "notistack";
import { AuthProvider } from "./contexts/FirebaseAuthContext";
import { ChooseParticipantProvider } from "./contexts/ChooseParticipantContext";
import { ConfirmationModalProvider } from "./contexts/ConfirmationModalContext";
import { ChatProvider } from "./contexts/ChatContext";
// import { AuthProvider } from "./contexts/JWTContext";
// import { AuthProvider } from "./contexts/Auth0Context";
// import { AuthProvider } from "./contexts/CognitoContext";

const clientSideEmotionCache = createEmotionCache();

function App({ emotionCache = clientSideEmotionCache }) {
  const {
    i18n: { language },
  } = useTranslation();

  const content = useRoutes(routes);

  const { theme } = useTheme();

  return (
    <CacheProvider value={emotionCache}>
      <HelmetProvider>
        <Helmet
          titleTemplate="%s | Plug1"
          defaultTitle="Plug1 - Balcão de Negociação de Energia Elétrica"
        />
        <Provider store={store}>
          <MuiThemeProvider theme={createTheme(theme, language)}>
            <LocalizationProvider
              // @ts-ignore
              dateAdapter={AdapterDateFns}
              adapterLocale={language === "en" ? enUS : ptBR}
            >
              <SnackbarProvider
                preventDuplicate
                maxSnack={3}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
              >
                <AuthProvider>
                  <ChooseParticipantProvider>
                    <ConfirmationModalProvider>
                      <ChatProvider>{content}</ChatProvider>
                    </ConfirmationModalProvider>
                  </ChooseParticipantProvider>
                </AuthProvider>
              </SnackbarProvider>
            </LocalizationProvider>
          </MuiThemeProvider>
        </Provider>
      </HelmetProvider>
    </CacheProvider>
  );
}

export default App;
