import { Box } from "@mui/material";

export interface DirectBuyAndSellInputBoxProps {
  TitleComponent?: string | JSX.Element;
  LeftComponent?: string | JSX.Element;
  RightComponent?: string | JSX.Element;
}

export function DirectBuyAndSellInputBox({
  TitleComponent,
  LeftComponent,
  RightComponent,
}: DirectBuyAndSellInputBoxProps) {
  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.mode === "dark" ? "#4C6882" : "#deeaf5",
        px: 4,
        py: 3,
        borderRadius: 1,
        width: "100%",
      })}
    >
      <Box fontSize="1rem">{TitleComponent}</Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 2,
          mt: 2,
        }}
      >
        <Box>
          {LeftComponent}

          {RightComponent}
        </Box>
      </Box>
    </Box>
  );
}
