import { Observable, Subscription } from "rxjs";
import * as firestore from "firebase/firestore";
import {
  ChatroomsData,
  ChatroomsObservable,
  options,
} from "../../services/GetChatroomsObservableService";
import { ChatRoom } from "../../entities/ChatRoom";

export class ChatroomsObservableImpl
  extends Observable<ChatroomsData>
  implements ChatroomsObservable
{
  private firestoreListener: () => void = () => {};
  private options: options = {
    participant: -1,
  };
  private subscribers: {
    subscription: Subscription;
    next: ((value: ChatroomsData) => void) | null | undefined;
  }[] = [];

  subscribeWithOptions(
    options: options,
    next?: ((value: ChatroomsData) => void) | null | undefined,
    error?: ((error: any) => void) | null | undefined,
    complete?: (() => void) | null | undefined
  ): Subscription {
    this.setOptions(options);
    this.options = options;
    const subscription = this.subscribe(next, error, complete);
    this.subscribers.push({
      subscription,
      next,
    });

    subscription.add(() => {
      this.subscribers = this.subscribers.filter(
        (s) => s.subscription !== subscription
      );
      if (this.subscribers.length === 0) {
        this.firestoreListener();
      }
    });

    return subscription;
  }

  setOptions(options: options) {
    this.options = options;
    this.startFirestoreListener();
  }

  private startFirestoreListener() {
    this.firestoreListener();

    const queryFilters: firestore.QueryConstraint[] = [
      firestore.where(
        "participants",
        "array-contains",
        this.options.participant
      ),
    ];

    const query = firestore.query(
      firestore.collection(firestore.getFirestore(), `chatRooms`),
      firestore.orderBy("lastMessageCreatedAt", "desc"),
      ...queryFilters
    );

    this.firestoreListener = firestore.onSnapshot(query, async (snapshot) => {
      this.subscribers.forEach((subscriber) => {
        if (!subscriber.next) {
          return;
        }

        const chatrooms = snapshot.docs.map((doc) => {
          return this.firestoreDocToChatRoom(doc, this.options.participant);
        });

        subscriber.next({
          chatrooms,
          totalItems: chatrooms.length,
        });
      });
    });
  }

  private firestoreDocToChatRoom(
    doc: firestore.QueryDocumentSnapshot<firestore.DocumentData>,
    participantInterested: number
  ): ChatRoom {
    const docData = doc.data();

    const chatroom = new ChatRoom(
      doc.id,
      docData.operationId,
      docData.offerType === "COMPRA"
        ? `${docData.operationId}b`
        : `${docData.operationId}s`,
      docData.lastMessage,
      docData.lastMessageCreatedAt.toDate(),
      participantInterested === docData.owner.id ? participantInterested : null,
      docData.owner.name,
      docData.owner.lastReadingDate.toDate(),
      participantInterested === docData.owner.id
        ? docData.owner.unreadMessagesCount
        : null,
      docData.prospect.id,
      docData.prospect.name,
      docData.prospect.lastReadingDate.toDate(),
      participantInterested === docData.prospect.id
        ? docData.prospect.unreadMessagesCount
        : null,
      docData.offerCloseDateTime.toDate(),
      docData.offerType,
      docData.offerType
    );

    return chatroom;
  }
}
