import { PriorityHigh } from "@mui/icons-material";
import { Box, Button, ButtonProps, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { StyledModal } from "./StyledModal";

export interface ConfirmationModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  message?: string | JSX.Element;
  subtitle?: string | JSX.Element;
  submitButtonColor?: ButtonProps["color"];
}

export default function ConfirmationModal({
  open,
  onClose,
  onSubmit,
  message,
  subtitle,
  submitButtonColor,
}: ConfirmationModalProps) {
  const { t } = useTranslation();

  return (
    <StyledModal
      open={open}
      onClose={onClose}
      maxWidth={600}
      titleStartAdornment={
        <Box
          sx={{
            width: 27,
            height: 27,
            borderRadius: 27,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#EEBE44",
            mr: 2,
          }}
        >
          <PriorityHigh />
        </Box>
      }
      title={t("Atenção")}
    >
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Typography
            variant="h6"
            // align="center"
            gutterBottom
          >
            {message}
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          // textAlign="center"
        >
          <Typography variant="caption" align="center" gutterBottom>
            {subtitle}
          </Typography>
        </Grid>

        <Box
          display="inline-flex"
          justifyContent="center"
          width="100%"
          textAlign="center"
          gap={4}
          mt={8}
        >
          <Button
            type="button"
            variant="outlined"
            color="primary"
            onClick={onClose}
          >
            {t("Go Back")}
          </Button>
          <Button
            variant="contained"
            color={submitButtonColor || "primary"}
            type="submit"
            onClick={onSubmit}
          >
            {t("Confirm")}
          </Button>
        </Box>
      </Grid>
    </StyledModal>
  );
}
