export class ChatMessage {
  constructor(
    private id: string,
    private text: string,
    private createdAt: Date,
    private senderId: number | null
  ) {}

  public getId() {
    return this.id;
  }

  public getText() {
    return this.text;
  }

  public getCreatedAt() {
    return this.createdAt;
  }

  public getSenderId() {
    return this.senderId;
  }
}
