import { Either } from "monet";
import { balcaoApiInstance } from "../../../../utils/axios";
import { MessagesRepository } from "../../services/SendChatMessageService";

export class MessagesRepositoryImpl implements MessagesRepository {
  async saveChatRoomMessage(
    message: string,
    chatRoomId: string
  ): Promise<
    Either<"CONTENT_BLOCKED" | "CHAT_FINISHED" | "GENERIC_ERROR", undefined>
  > {
    try {
      await balcaoApiInstance.post(`chat-room/message/${chatRoomId}`, {
        text: message,
      });
      return Either.Right(undefined);
    } catch (e: any) {
      if (
        e.error ===
        "o status da oferta precisa ser aberta ou parcialmente encerrada"
      ) {
        return Either.Left("CHAT_FINISHED");
      }

      if (e.error === "Encontrado palavra proibida") {
        return Either.Left("CONTENT_BLOCKED");
      }

      return Either.Left("GENERIC_ERROR");
    }
  }
}
