import { Observable, Subscription } from "rxjs";
import { MyOrderResume } from "../entities/data-objects/OrderResume";
import { MyHistoricObservableImpl } from "../infra/firestore/MyHistoricObservableImpl";

export abstract class OffersPanelBus {
  abstract listenLastNOperations(): void;
}

export type panelData = {
  page: number;
  totalItems: number;
  orders: MyOrderResume[];
};

export type options = {
  page: number;
  itemsPerPage: number;
  participant: number | null;
  product: string | null;
  submarket: string | null;
};

export abstract class MyHistoricObservable extends Observable<panelData> {
  abstract subscribeWithOptions(
    options: options,
    next?: ((value: panelData) => void) | null | undefined,
    error?: ((error: any) => void) | null | undefined,
    complete?: (() => void) | null | undefined
  ): Subscription;

  abstract setOptions(options: options): void;
}

export class GetMyHistoricObservableService {
  private observable: MyHistoricObservable | null = null;

  public getMyHistoricObservable() {
    if (this.observable === null) {
      this.observable = new MyHistoricObservableImpl();
    }

    return this.observable;
  }
}
