import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  MenuItem,
  Paper,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import { EnhancedTable, HeadCell } from "../../components/EnhancedTable";
import { compareStrings, toLocaleCurrencyString } from "../../utils/string";
import { submarketColors } from "../../constants";
import { StyledTextField } from "../../components/StyledTextField";
import {
  ArrowDropDownRounded,
  ArrowDropUpRounded,
  ExpandMore,
  Remove,
} from "@mui/icons-material";

import { useFirestoreQuery } from "../../hooks/useFirestoreQuery";
import * as firestore from "firebase/firestore";
import { NegotiatedOfferStats } from "../../types/offer";
import { parseDate } from "../../utils/date";

export function NegotiatedOffersStatistics() {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const {
    data: [stats],
  } = useFirestoreQuery<NegotiatedOfferStats>(() => {
    return [
      firestore.query(
        firestore.collection(firestore.getFirestore(), "offersStatsPeriods"),
        firestore.orderBy("startDate", "desc"),
        firestore.limit(1)
      ),
    ];
  }, []);

  const statsData = React.useMemo(
    () => (stats ? stats[0] : undefined),
    [stats]
  );

  const [selectedProduct, setSelectedProduct] = React.useState<string>(
    statsData ? statsData.products[0].productName ?? "" : ""
  );
  const selectedProductData = React.useMemo(() => {
    if (!statsData || !selectedProduct) return [];

    const data = statsData.products.find(
      (item) => item.productName === selectedProduct
    );

    if (!data) return [];

    const submarkets = Object.keys(data?.submarkets ?? {}).sort((a, b) =>
      compareStrings(b, a)
    );

    const sortedSubmarketsData = submarkets.map((submarket) => ({
      ...data.submarkets[submarket],
      submarket,
    }));

    return sortedSubmarketsData;
  }, [statsData, selectedProduct]);

  React.useEffect(() => {
    if (statsData) {
      setSelectedProduct((oldValue) =>
        !oldValue ? statsData.products[0].productName : oldValue
      );
    }
  }, [statsData]);

  const headCells = React.useMemo<Array<HeadCell>>(
    () => [
      {
        id: "submarket",
        alignment: "left",
        label: t("Submarket"),
        disableSort: true,
      },
      {
        id: "lastSpreadValue",
        alignment: "right",
        label: t("Last Price"),
        disableSort: true,
      },
      {
        id: "spreadAverageLastPeriod",
        alignment: "right",
        label: t("Change"),
        disableSort: true,
      },
      {
        id: "maxSpreadValue",
        alignment: "right",
        label: t("Highest Price"),
        disableSort: true,
      },
      {
        id: "minSpreadValue",
        alignment: "right",
        label: t("Lowest Price"),
        disableSort: true,
      },
      {
        id: "averagePrice",
        alignment: "right",
        label: t("Average Price"),
        disableSort: true,
      },
      {
        id: "amountNegotiated",
        alignment: "right",
        label: t("Volume"),
        disableSort: true,
      },
    ],
    [t]
  );

  const period = React.useMemo(() => {
    if (!statsData) return "-";

    const startDate = parseDate(statsData.startDate);
    if (!startDate) return "-";
    const endDate = parseDate(statsData.endDate) as Date;

    const startDateStr = startDate.toLocaleDateString(language);
    const endDateStr = endDate.toLocaleDateString(language);

    return `(${startDateStr}${
      startDateStr === endDateStr ? "" : ` - ${endDateStr}`
    })`;
  }, [language, statsData]);

  return (
    <Paper sx={{ overflow: "auto" }}>
      <Accordion disableGutters defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="h6">
            {t("Negotiated Offers")}{" "}
            <Typography component="span" color="#B6B9C0">
              {period}
            </Typography>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <EnhancedTable
            // title={t("Negotiated Offers")}
            // subtitle={`(${new Date(
            //   latestNegotiatedOffers.periodStart
            // ).toLocaleDateString(language, { timeZone: "UTC" })} - ${new Date(
            //   latestNegotiatedOffers.periodEnd
            // ).toLocaleDateString(language, { timeZone: "UTC" })})`}
            data={selectedProductData}
            headCells={statsData ? headCells : []}
            avoidEmptyRows
            disablePagination
            TableProps={{
              size: "small",
            }}
            toolbarFiltersArea={
              <>
                {statsData !== undefined && (
                  <StyledTextField
                    id="product-statistics-select"
                    aria-label={t("Product")}
                    select
                    value={selectedProduct}
                    onChange={(e) => setSelectedProduct(e.target.value)}
                    variant="standard"
                    sx={{ minWidth: "14ch" }}
                  >
                    {statsData.products.map((product) => (
                      <MenuItem
                        key={product.productId}
                        value={product.productName}
                      >
                        {product.productName}
                      </MenuItem>
                    ))}
                  </StyledTextField>
                )}
                {statsData === undefined && (
                  <Typography component="span" color="#B6B9C0">
                    {t("There is no data to show at this time")}
                  </Typography>
                )}
              </>
            }
            keyExtractor={(row) => row.submarket}
            renderRow={(row) => {
              return (
                <TableRow hover tabIndex={-1}>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      fontWeight: 600,
                      whiteSpace: "nowrap",
                      color: submarketColors[row.submarket],
                    }}
                  >
                    {row.submarket}
                  </TableCell>

                  <TableCell align="right">
                    {!row.lastSpreadValue ? (
                      "-"
                    ) : (
                      <>
                        {`PLD ${row.lastSpreadValue < 0 ? "-" : "+"} `}
                        <span style={{ color: submarketColors[row.submarket] }}>
                          {toLocaleCurrencyString(
                            Math.abs(row.lastSpreadValue),
                            "BRL",
                            language
                          )}
                        </span>
                      </>
                    )}
                  </TableCell>

                  <TableCell align="right">
                    <Box display="inline-flex" flexWrap="nowrap">
                      {!row.spreadAverageLastPeriod || !row.spreadAverage ? (
                        "-"
                      ) : (
                        <>
                          <span>
                            {toLocaleCurrencyString(
                              row.spreadAverage - row.spreadAverageLastPeriod,
                              "BRL",
                              language
                            )}
                          </span>
                          <Box
                            width={24}
                            height={24}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                          >
                            {row.spreadAverage - row.spreadAverageLastPeriod <
                            0 ? (
                              <ArrowDropDownRounded color="error" />
                            ) : row.spreadAverage -
                                row.spreadAverageLastPeriod >
                              0 ? (
                              <ArrowDropUpRounded color="success" />
                            ) : (
                              <Remove color="primary" sx={{ fontSize: 12 }} />
                            )}
                          </Box>
                        </>
                      )}
                    </Box>
                  </TableCell>

                  <TableCell align="right">
                    {!row.maxSpreadValue ? (
                      "-"
                    ) : (
                      <>
                        {`PLD ${row.maxSpreadValue < 0 ? "-" : "+"} `}
                        <span style={{ color: submarketColors[row.submarket] }}>
                          {toLocaleCurrencyString(
                            Math.abs(row.maxSpreadValue),
                            "BRL",
                            language
                          )}
                        </span>
                      </>
                    )}
                  </TableCell>

                  <TableCell align="right">
                    {!row.minSpreadValue ? (
                      "-"
                    ) : (
                      <>
                        {`PLD ${row.minSpreadValue < 0 ? "-" : "+"} `}
                        <span style={{ color: submarketColors[row.submarket] }}>
                          {toLocaleCurrencyString(
                            Math.abs(row.minSpreadValue),
                            "BRL",
                            language
                          )}
                        </span>
                      </>
                    )}
                  </TableCell>

                  <TableCell align="right">
                    {!row.spreadAverage ? (
                      "-"
                    ) : (
                      <>
                        {`PLD ${row.spreadAverage < 0 ? "-" : "+"} `}
                        <span style={{ color: submarketColors[row.submarket] }}>
                          {toLocaleCurrencyString(
                            Math.abs(row.spreadAverage),
                            "BRL",
                            language
                          )}
                        </span>
                      </>
                    )}
                  </TableCell>

                  <TableCell align="right">{`${row.amountNegotiated.toLocaleString(
                    language
                  )} MWh`}</TableCell>
                </TableRow>
              );
            }}
          />
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
}
