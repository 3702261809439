import {
  AssetsPriceObservable,
  options,
} from "../../services/GetAssetsPricesObservableService";

import { Observable, Subscription } from "rxjs";
import * as firestore from "firebase/firestore";
import { AssetsPriceTable } from "../../entities/AssetsPriceTable";

export class AssetsPriceObservableImpl
  extends Observable<AssetsPriceTable>
  implements AssetsPriceObservable
{
  private firestoreListener: () => void = () => {};
  private options: options = {
    participant: -1,
    chatroomId: null,
  };
  private subscribers: {
    subscription: Subscription;
    next: ((value: AssetsPriceTable) => void) | null | undefined;
  }[] = [];

  subscribeWithOptions(
    options: options,
    next?: ((value: AssetsPriceTable) => void) | null | undefined,
    error?: ((error: any) => void) | null | undefined,
    complete?: (() => void) | null | undefined
  ): Subscription {
    this.setOptions(options);
    this.options = options;
    const subscription = this.subscribe(next, error, complete);
    this.subscribers.push({
      subscription,
      next,
    });

    subscription.add(() => {
      this.subscribers = this.subscribers.filter(
        (s) => s.subscription !== subscription
      );
      if (this.subscribers.length === 0) {
        this.firestoreListener();
      }
    });

    return subscription;
  }

  setOptions(options: options) {
    this.startFirestoreListener();
  }

  private startFirestoreListener() {
    this.firestoreListener();

    const query = firestore.query(
      firestore.collection(firestore.getFirestore(), `quotes`)
    );

    this.firestoreListener = firestore.onSnapshot(query, async (snapshot) => {
      this.subscribers.forEach((subscriber) => {
        if (!subscriber.next) {
          return;
        }

        const priceTable = this.firestoreDocsToPriceTable(
          snapshot.docs.map((doc) => doc.data())
        );

        subscriber.next(priceTable);
      });
    });
  }

  private firestoreDocsToPriceTable(docs: any[]) {
    return new AssetsPriceTable(
      docs.map((doc) => ({
        assetCode: doc.assetCode,
        submarket: doc.submarket,
        value: doc.quote,
      }))
    );
  }
}
