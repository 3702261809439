import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CurrencyIcon } from "./CurrencyIcon";
import { StyledModal } from "../StyledModal";
import {
  getCurrencySymbol,
  toLocaleCurrencyString,
  toLocaleCurrencyStringWithoutSymbol,
} from "../../utils/string";
import { InfoOutlined, South } from "@mui/icons-material";
import { EnergyProductIcon } from "./EnergyProductIcon";
import React from "react";
import { useMutation } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { ApiError } from "../../types/error";
import {
  registerDirectBuyAndSellBid,
  RegisterDirectBuyAndSellBidProps,
} from "./infra/api/DirectBuyAndSellBidInfra";

export interface BidConfirmationData {
  submarket: string;
  currencyFrom: string;
  currencyTo: string;
  productId: string;
  valueFrom: number;
  valueTo: number;
  tolerance: number;
  offersReached: number;
}

interface ValueLineProps {
  adornment: JSX.Element;
  leftText?: JSX.Element | string;
  rightText?: JSX.Element | string;
}
function ValueLine({ adornment, leftText, rightText }: ValueLineProps) {
  return (
    <Box
      sx={{
        display: "flex",
        gap: 2,
        alignItems: "center",
        justifyContent: "space-between",
        fontSize: "1.5rem",
        fontWeight: 600,
      }}
    >
      <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
        {adornment}
        <Box>{leftText}</Box>
      </Box>
      <Box>{rightText}</Box>
    </Box>
  );
}

interface DirectBuyAndSellBidConfirmationModalContentProps {
  data: BidConfirmationData;
  onClose?: () => void;
  onSuccess?: () => void;
}

function DirectBuyAndSellBidConfirmationModalContent({
  data,
  onClose = () => {},
  onSuccess = () => {},
}: DirectBuyAndSellBidConfirmationModalContentProps) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const summary = React.useMemo(() => {
    return [
      {
        label: (
          <>
            {t("Price2")}
            <Tooltip
              title={t(
                "A cotação exibida é apenas uma estimativa e não é possível garantir que será o valor final da negociação"
              )}
            >
              <IconButton type="button" size="small" sx={{ my: -5 }}>
                <InfoOutlined fontSize="small" />
              </IconButton>
            </Tooltip>
          </>
        ),
        value:
          (data.currencyFrom === "CURRENCY"
            ? toLocaleCurrencyString(1, "BRL", language)
            : `${(1).toLocaleString(language)} MWh ${data.currencyFrom}`) +
          " = " +
          (data.currencyFrom === "CURRENCY"
            ? `${(data.valueTo / data.valueFrom).toLocaleString(language, {
                minimumFractionDigits: 3,
                maximumFractionDigits: 3,
              })} MWh ${data.currencyTo}`
            : toLocaleCurrencyString(
                data.valueTo / data.valueFrom,
                "BRL",
                language
              )),
      },
      {
        label: t("Reverse Price2"),
        value:
          (data.currencyTo === "CURRENCY"
            ? toLocaleCurrencyString(1, "BRL", language)
            : `${(1).toLocaleString(language)} MWh ${data.currencyTo}`) +
          " = " +
          (data.currencyTo === "CURRENCY"
            ? `${(data.valueFrom / data.valueTo).toLocaleString(language, {
                minimumFractionDigits: 3,
                maximumFractionDigits: 3,
              })} MWh ${data.currencyFrom}`
            : toLocaleCurrencyString(
                data.valueFrom / data.valueTo,
                "BRL",
                language
              )),
      },
      {
        label: t("Payment Method"),
        value: t("Bank Transaction"),
      },
      {
        label: (
          <>
            {t("Transaction Fees")}
            <Tooltip
              title={`${t(
                "A taxa de transação é um valor fixo de"
              )} ${toLocaleCurrencyString(0.15, "BRL", language)}/MWh`}
            >
              <IconButton type="button" size="small" sx={{ my: -5 }}>
                <InfoOutlined fontSize="small" />
              </IconButton>
            </Tooltip>
          </>
        ),
        value: (
          <Box
            sx={{
              color: "success.main",
              backgroundColor: "#E3FFF1",
              borderRadius: 1,
              px: 1,
              lineHeight: 1.2,
              mb: 1.2,
            }}
          >{`${toLocaleCurrencyString(0.15, "BRL", language)}/MWh`}</Box>
        ),
      },
      {
        label: t("Submarket"),
        value: data.submarket,
      },
      {
        label: t("Orders Reached"),
        value: data.offersReached.toLocaleString(language),
      },
      {
        label: t("Average Price"),
        value: `${toLocaleCurrencyString(
          data.currencyFrom === "CURRENCY"
            ? data.valueFrom / data.valueTo
            : data.valueTo / data.valueFrom,
          "BRL",
          language
        )}/MWh`,
      },
      {
        label: t("Slippage Tolerance"),
        value: (
          <Typography
            component="span"
            fontSize="inherit"
            color={data.tolerance >= 0.05 ? "warning.main" : undefined}
          >
            {data.tolerance.toLocaleString(language, {
              style: "percent",
              minimumFractionDigits: 2,
            })}
          </Typography>
        ),
      },
      {
        label: (
          <Box sx={{ color: "success.main", fontWeight: 600, mt: 6 }}>
            {t("You receive")}
          </Box>
        ),
        value: (
          <Box
            sx={{
              color: "success.main",
              fontWeight: 600,
              fontSize: "1.5rem",
              lineHeight: 1.5,
            }}
          >
            {data.currencyTo === "CURRENCY"
              ? toLocaleCurrencyString(data.valueTo, "BRL", language)
              : `${data.valueTo.toLocaleString(language, {
                  minimumFractionDigits: 3,
                  maximumFractionDigits: 3,
                })} MWh`}
          </Box>
        ),
      },
    ];
  }, [data, language, t]);

  const registerDirectBuyAndSellBidMutation = useMutation(
    registerDirectBuyAndSellBid,
    {
      onSuccess: () => {
        enqueueSnackbar(t("The operation completed successfully"), {
          variant: "success",
        });

        onSuccess();
        onClose();
      },
      onError: (e) => {
        enqueueSnackbar(
          t((e as ApiError | null)?.error ?? "Something went wrong."),
          {
            variant: "error",
          }
        );

        onClose();
      },
    }
  );

  const handleSubmit = React.useCallback(() => {
    const dataToSubmit: RegisterDirectBuyAndSellBidProps = {
      submarket: data.submarket,
      type: data.currencyFrom === "CURRENCY" ? "COMPRA" : "VENDA",
      productId: data.productId,
      valueFrom: data.valueFrom,
      valueTo: data.valueTo,
      tolerance: data.tolerance,
    };

    registerDirectBuyAndSellBidMutation.mutate(dataToSubmit);
  }, [data, registerDirectBuyAndSellBidMutation]);

  return (
    <Box
      component="form"
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        py: 2,
      }}
    >
      <Box sx={{ width: "90%" }}>
        <ValueLine
          adornment={
            data.currencyFrom === "CURRENCY" ? (
              <CurrencyIcon size={28} />
            ) : (
              <EnergyProductIcon size={28} />
            )
          }
          leftText={
            data.currencyFrom === "CURRENCY" ? (
              toLocaleCurrencyStringWithoutSymbol(
                data.valueFrom,
                "BRL",
                language
              )
            ) : (
              <span>
                {data.valueFrom.toLocaleString(language, {
                  minimumFractionDigits: 3,
                  maximumFractionDigits: 3,
                })}{" "}
                <Box component="span" sx={{ fontSize: "1.25rem" }}>
                  MWh
                </Box>
              </span>
            )
          }
          rightText={
            data.currencyFrom === "CURRENCY"
              ? getCurrencySymbol("BRL", language)
              : data.currencyFrom
          }
        />
        <Box sx={{ my: 4 }}>
          <South />
        </Box>
        <ValueLine
          adornment={
            data.currencyTo === "CURRENCY" ? (
              <CurrencyIcon size={28} />
            ) : (
              <EnergyProductIcon size={28} />
            )
          }
          leftText={
            data.currencyTo === "CURRENCY" ? (
              toLocaleCurrencyStringWithoutSymbol(data.valueTo, "BRL", language)
            ) : (
              <span>
                {data.valueTo.toLocaleString(language, {
                  minimumFractionDigits: 3,
                  maximumFractionDigits: 3,
                })}{" "}
                <Box component="span" sx={{ fontSize: "1.25rem" }}>
                  MWh
                </Box>
              </span>
            )
          }
          rightText={
            data.currencyTo === "CURRENCY"
              ? getCurrencySymbol("BRL", language)
              : data.currencyTo
          }
        />
      </Box>

      <Box
        sx={(theme) => ({
          mt: 8,
          backgroundColor:
            theme.palette.mode === "dark" ? "#4C6882" : "#deeaf5",
          px: 8,
          py: 4,
          borderRadius: 1,
          width: "100%",
        })}
      >
        {summary.map((row, idx) => {
          return (
            <Box
              key={String(idx)}
              sx={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "space-between",
                fontSize: "0.9375rem",
                lineHeight: 2,
              }}
            >
              <Box>{row.label}</Box>
              <Box>{row.value}</Box>
            </Box>
          );
        })}
      </Box>

      <Button
        type="submit"
        variant="contained"
        color="success"
        sx={{ mt: 6, minWidth: "40%" }}
        disabled={registerDirectBuyAndSellBidMutation.isLoading}
      >
        {t("Confirm")}
      </Button>
    </Box>
  );
}

export interface DirectBuyAndSellBidConfirmationModalProps {
  open: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
  data: BidConfirmationData;
}
export function DirectBuyAndSellBidConfirmationModal({
  open,
  onClose,
  onSuccess,
  data,
}: DirectBuyAndSellBidConfirmationModalProps) {
  const { t } = useTranslation();

  return (
    <StyledModal
      // leftBox={t("Direct Buy and Sell")}
      // leftBox={t("Confirmation")}
      title={t("Confirmation")}
      open={open}
      onClose={onClose}
      maxWidth={540}
    >
      <DirectBuyAndSellBidConfirmationModalContent
        data={data}
        onClose={onClose}
        onSuccess={onSuccess}
      />
    </StyledModal>
  );
}
