import React from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  MenuItem,
  Paper,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/system";
import { useTranslation } from "react-i18next";

import { CurrencyIcon } from "./CurrencyIcon";
import { EnergyProductIcon } from "./EnergyProductIcon";
import { submarketColors } from "../../constants";
import {
  StyledToggleButton,
  StyledToggleButtonGroup,
} from "../StyledToggleButton";
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
  useWatch,
} from "react-hook-form";
import { getCurrencySymbol } from "../../utils/string";
import { useSnackbar } from "notistack";
import { DirectBuyAndSellInputBox } from "./DirectBuyAndSellInputBox";
import { BigInput, getCustomNumberInputProps } from "./BigInput";
import { RoundedSelect } from "./RoundedSelect";
import { SwapButton } from "./SwapButton";
import {
  BidConfirmationData,
  DirectBuyAndSellBidConfirmationModal,
} from "./DirectBuyAndSellBidConfirmationModal";
import { StyledTextField } from "../StyledTextField";
import { OfferBookItem } from "./entities/OfferBookItem";
import { Product } from "../../types/product";
import { roundToDecimalPlaces } from "../../utils/number";

interface Inputs {
  tolerance: string;
  submarket: string;
  valueFrom: string;
  currencyFrom: string;
  valueTo: string;
  currencyTo: string;
}

const savedDefaultValues: Partial<Inputs> | null = JSON.parse(
  localStorage.getItem("directBuyAndSellDefaultValues") ?? "null"
);

const inputsDefaultValues: Inputs = {
  tolerance: savedDefaultValues?.tolerance ?? "0.50",
  submarket: savedDefaultValues?.submarket ?? Object.keys(submarketColors)[0],
  currencyFrom: "CURRENCY", // savedDefaultValues?.currencyFrom ?? "CURRENCY",
  currencyTo: "", // savedDefaultValues?.currencyTo ?? "",
  valueFrom: "",
  valueTo: "",
};

interface DirectBuyAndSellBidAreaProps {
  products: Array<Product>;
  buyOfferBook: Array<OfferBookItem>;
  sellOfferBook: Array<OfferBookItem>;
  onChangeSubmarket: (submarket: string) => void;
  onChangeProductId: (product: string | null) => void;
  onChangeValue: (product: number | null) => void;
  onChangeVolume: (product: number | null) => void;
  calculatedValue: number | null;
  calculatedVolume: number | null;
  offersReached: number;
}

export function DirectBuyAndSellBidArea({
  products,
  buyOfferBook,
  sellOfferBook,
  onChangeSubmarket,
  onChangeProductId,
  onChangeValue,
  onChangeVolume,
  calculatedValue,
  calculatedVolume,
  offersReached,
}: DirectBuyAndSellBidAreaProps) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));

  const defaultProduct = React.useMemo(() => {
    // if (
    //   inputsDefaultValues.currencyFrom &&
    //   inputsDefaultValues.currencyFrom !== "CURRENCY"
    // )
    //   return inputsDefaultValues.currencyFrom;
    // if (
    //   inputsDefaultValues.currencyTo &&
    //   inputsDefaultValues.currencyTo !== "CURRENCY"
    // )
    //   return inputsDefaultValues.currencyTo;
    return products[0]?.name;
  }, [products]);

  const { control, handleSubmit, watch, setValue, reset } = useForm<Inputs>({
    defaultValues: inputsDefaultValues,
  });

  const tolerance = useWatch({ control, name: "tolerance" });
  const submarket = useWatch({ control, name: "submarket" });
  const currencyFrom = useWatch({ control, name: "currencyFrom" });
  const currencyTo = useWatch({ control, name: "currencyTo" });
  const valueFrom = useWatch({ control, name: "valueFrom" });

  React.useEffect(() => {
    localStorage.setItem(
      "directBuyAndSellDefaultValues",
      JSON.stringify({
        tolerance,
        submarket,
        currencyFrom,
        currencyTo,
      } as Partial<Inputs>)
    );
  }, [currencyFrom, currencyTo, reset, submarket, tolerance]);

  const productName = React.useMemo(() => {
    return currencyFrom === "CURRENCY" ? currencyTo : currencyFrom;
  }, [currencyFrom, currencyTo]);

  const productId = React.useMemo(() => {
    return products.find((item) => item.name === productName)?.id ?? null;
  }, [productName, products]);

  React.useEffect(() => {
    onChangeSubmarket(submarket);
    onChangeProductId(
      products.find((item) => item.name === productName)?.id ?? null
    );
    onChangeValue(currencyFrom === "CURRENCY" ? Number(valueFrom) : null);
    onChangeVolume(currencyFrom === "CURRENCY" ? null : Number(valueFrom));
  }, [
    currencyFrom,
    onChangeProductId,
    onChangeSubmarket,
    onChangeValue,
    onChangeVolume,
    productName,
    products,
    submarket,
    valueFrom,
  ]);

  // Initial reset
  React.useEffect(() => {
    if (defaultProduct) {
      reset({ ...inputsDefaultValues, currencyTo: defaultProduct });
    }
  }, [defaultProduct, reset]);

  const handleReset = React.useCallback(() => {
    reset({
      ...watch(),
      valueFrom: inputsDefaultValues.valueFrom,
      valueTo: inputsDefaultValues.valueTo,
    });
  }, [reset, watch]);

  const handleSwapCurrency = React.useCallback(() => {
    const currencyFrom = watch("currencyFrom");
    const currencyTo = watch("currencyTo");
    const valueFrom = watch("valueFrom");
    const valueTo = watch("valueTo");
    setValue("currencyFrom", currencyTo);
    setValue("currencyTo", currencyFrom);
    setValue("valueFrom", Number(valueTo) === 0 ? "" : valueTo);
    setValue("valueTo", Number(valueFrom) === 0 ? "" : valueFrom);
  }, [setValue, watch]);

  // Force one and only one of the currencies to be equals "CURRENCY"
  React.useEffect(() => {
    if (currencyFrom !== "CURRENCY" && watch("currencyTo") !== "CURRENCY") {
      setValue("currencyTo", "CURRENCY");
    } else if (
      currencyFrom === "CURRENCY" &&
      watch("currencyTo") === "CURRENCY"
    ) {
      setValue("currencyTo", defaultProduct);
    }
  }, [currencyFrom, defaultProduct, products, setValue, watch]);
  React.useEffect(() => {
    if (currencyTo !== "CURRENCY" && watch("currencyFrom") !== "CURRENCY") {
      setValue("currencyFrom", "CURRENCY");
    } else if (
      currencyTo === "CURRENCY" &&
      watch("currencyFrom") === "CURRENCY"
    ) {
      setValue("currencyFrom", defaultProduct);
    }
  }, [currencyTo, defaultProduct, products, setValue, watch]);

  //Calculation
  React.useEffect(() => {
    const newValueTo =
      (currencyTo === "CURRENCY"
        ? roundToDecimalPlaces(calculatedValue || 0, 2)?.toString()
        : roundToDecimalPlaces(calculatedVolume || 0, 3)?.toString()) ?? null;
    setValue("valueTo", newValueTo ?? "0");
  }, [calculatedValue, calculatedVolume, currencyTo, setValue]);

  const [bidConfirmationModalData, setBidConfirmationModalData] =
    React.useState<BidConfirmationData | null>(null);
  const handleOpenBidConfirmationModal = React.useCallback(
    (data: BidConfirmationData) => {
      setBidConfirmationModalData(data);
    },
    []
  );
  const handleCloseBidConfirmationModal = React.useCallback(() => {
    setBidConfirmationModalData(null);
  }, []);

  const onSubmit = React.useCallback<SubmitHandler<Inputs>>(
    (data) => {
      handleOpenBidConfirmationModal({
        submarket: data.submarket,
        currencyFrom: data.currencyFrom,
        currencyTo: data.currencyTo,
        productId: productId ?? "",
        valueFrom: Number(data.valueFrom),
        valueTo: roundToDecimalPlaces(data.valueTo, 3),
        tolerance: Number(data.tolerance) / 100,
        offersReached,
      });
    },
    [handleOpenBidConfirmationModal, offersReached, productId]
  );

  const onInvalidSubmit = React.useCallback<SubmitErrorHandler<Inputs>>(
    (errors) => {
      enqueueSnackbar(t("You must fill in all required fields"), {
        variant: "error",
      });
    },
    [enqueueSnackbar, t]
  );

  return (
    <>
      {bidConfirmationModalData && (
        <DirectBuyAndSellBidConfirmationModal
          open={bidConfirmationModalData !== null}
          onClose={handleCloseBidConfirmationModal}
          onSuccess={handleReset}
          data={bidConfirmationModalData}
        />
      )}

      <Paper
        component="form"
        onSubmit={handleSubmit(onSubmit, onInvalidSubmit)}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          borderRadius: 5,
          px: 10,
          py: 6,
          gap: 6,
        }}
      >
        <Controller
          control={control}
          name={"submarket"}
          rules={{
            required: {
              value: true,
              message: t("This field is required"),
            },
          }}
          render={({ field: { ref, ...field } }) => (
            <FormControl>
              <FormLabel
                id="submarket-toggle-button-group-label"
                sx={{ mb: 2, color: "text.primary" }}
              >
                {t("Submarket")}
              </FormLabel>
              <StyledToggleButtonGroup
                {...field}
                aria-labelledby="submarket-toggle-button-group-label"
                size="small"
                toggleButtonMinWidth={isSmUp ? 80 : 48}
                exclusive
              >
                {Object.keys(submarketColors).map((item, idx) => {
                  return (
                    <StyledToggleButton
                      key={item}
                      ref={idx === 0 ? ref : undefined}
                      value={item}
                    >
                      {item}
                    </StyledToggleButton>
                  );
                })}
              </StyledToggleButtonGroup>
            </FormControl>
          )}
        />

        <Controller
          control={control}
          name={"tolerance"}
          rules={{
            required: {
              value: true,
              message: t("This field is required"),
            },
            onBlur: (e) => {
              let n = Number(e.target.value);
              n = Math.min(n, 100);
              n = Math.max(n, 0);
              setValue("tolerance", n.toFixed(2));
            },
            min: 0,
            max: 100,
          }}
          render={({ field: { ref, ...field } }) => (
            <FormControl>
              <FormLabel
                id="tolerance-toggle-button-group-label"
                sx={{ mb: 2, color: "text.primary" }}
              >
                {t("Slippage Tolerance")}
              </FormLabel>

              <Box sx={{ display: "flex", gap: 4 }}>
                <StyledToggleButtonGroup
                  {...field}
                  aria-labelledby="tolerance-toggle-button-group-label"
                  size="small"
                  toggleButtonMinWidth={isSmUp ? 60 : 48}
                  exclusive
                >
                  {["0.10", "0.50", "1.00"].map((item, idx) => {
                    return (
                      <StyledToggleButton
                        key={item}
                        ref={idx === 0 ? ref : undefined}
                        value={String(item)}
                      >
                        {Number(item).toLocaleString(language, {
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 1,
                        })}
                        %
                      </StyledToggleButton>
                    );
                  })}
                </StyledToggleButtonGroup>
                <StyledTextField
                  {...field}
                  // type="number"
                  inputProps={{ min: 0, max: 100, step: "0.01" }}
                  size="small"
                  sx={{ maxWidth: 80 }}
                  color={
                    Number(watch("tolerance")) / 100 >= 0.05
                      ? "warning"
                      : "primary"
                  }
                  InputProps={getCustomNumberInputProps({
                    disableArrows: true,
                    disableWheel: true,
                    disableE: true,
                    disableUpDownKeys: true,
                    endAdornment: "%",
                  })}
                />
              </Box>
            </FormControl>
          )}
        />

        <DirectBuyAndSellInputBox
          TitleComponent={t("From")}
          LeftComponent={
            <Controller
              control={control}
              name={"valueFrom"}
              rules={{
                required: {
                  value: true,
                  message: t("This field is required"),
                },
              }}
              render={({ field: { ref, ...field } }) => (
                <BigInput
                  {...field}
                  inputRef={ref}
                  aria-label={t("From")}
                  placeholder="0"
                  disableArrows
                  disableWheel
                  disableE
                />
              )}
            />
          }
          RightComponent={
            <Controller
              control={control}
              name={"currencyFrom"}
              rules={{
                required: {
                  value: true,
                  message: t("This field is required"),
                },
              }}
              render={({ field: { ref, ...field } }) => (
                <RoundedSelect
                  inputRef={ref}
                  startAdornment={
                    watch("currencyFrom") === "CURRENCY" ? (
                      <CurrencyIcon size={28} />
                    ) : (
                      <EnergyProductIcon size={28} />
                    )
                  }
                  {...field}
                >
                  <MenuItem value={"CURRENCY"}>
                    {getCurrencySymbol("BRL", language)}
                  </MenuItem>
                  {products.map((product) => (
                    <MenuItem key={product.name} value={product.name}>
                      {product.name}
                    </MenuItem>
                  ))}
                </RoundedSelect>
              )}
            />
          }
        />

        <SwapButton onClick={handleSwapCurrency} />

        <DirectBuyAndSellInputBox
          TitleComponent={t("To")}
          LeftComponent={
            <Controller
              control={control}
              name={"valueTo"}
              rules={{
                required: {
                  value: true,
                  message: t("This field is required"),
                },
              }}
              render={({ field: { ref, ...field } }) => (
                <BigInput
                  {...field}
                  inputRef={ref}
                  aria-label={t("To")}
                  placeholder="0"
                  disabled
                  sx={(theme) => ({
                    ".Mui-disabled": {
                      WebkitTextFillColor: theme.palette.text.primary,
                    },
                  })}
                  disableArrows
                  disableWheel
                  disableE
                />
              )}
            />
          }
          RightComponent={
            <Controller
              control={control}
              name={"currencyTo"}
              rules={{
                required: {
                  value: true,
                  message: t("This field is required"),
                },
              }}
              render={({ field: { ref, ...field } }) => (
                <RoundedSelect
                  inputRef={ref}
                  startAdornment={
                    watch("currencyTo") === "CURRENCY" ? (
                      <CurrencyIcon size={28} />
                    ) : (
                      <EnergyProductIcon size={28} />
                    )
                  }
                  {...field}
                >
                  <MenuItem value={"CURRENCY"}>
                    {getCurrencySymbol("BRL", language)}
                  </MenuItem>
                  {products.map((product) => (
                    <MenuItem key={product.name} value={product.name}>
                      {product.name}
                    </MenuItem>
                  ))}
                </RoundedSelect>
              )}
            />
          }
        />

        <Button
          variant="contained"
          color="success"
          type="submit"
          disabled={offersReached === 0}
        >
          {offersReached === 0 && Number(valueFrom) !== 0
            ? t("Volume unavailable")
            : t("Preview conversion")}
        </Button>
      </Paper>
    </>
  );
}
