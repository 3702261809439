import React, { useMemo } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Chip,
  CircularProgress,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  ListItemText,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  TextField,
  Autocomplete,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { deepPurple, green, orange } from "@mui/material/colors";
import { MoreHoriz as MoreHorizIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { submarketColors } from "../../constants";
import {
  EnhancedTable,
  EnhancedTableManualPagination,
  HeadCell,
} from "../../components/EnhancedTable";
import useChooseParticipant from "../../hooks/useChooseParticipant";
import { toLocaleCurrencyStringWithoutSymbol } from "../../utils/string";
import { getChildrenOfferAndContracts } from "../../services/contracts";
import { Contract, ContractData } from "../../types/contracts";
import { ClosingStatementModal } from "./ClosingStatementModal";
import { AcceptanceTermModal } from "./AcceptanceTermModal";
import { ContractModal, getBuyerAndSellerData } from "./ContractModalContent";
import useAuth from "../../hooks/useAuth";
import { useFirestoreQuery } from "../../hooks/useFirestoreQuery";
import * as firestore from "firebase/firestore";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { StyledTextField } from "../../components/StyledTextField";
import { DateTime } from "luxon";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Alert = styled(MuiAlert)(spacing);

const InfoBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24;
`;

const getContractStatus = (childrenOffer: Contract) => {
  if (
    !childrenOffer.acceptanceTerms?.acceptedByOwner ||
    !childrenOffer.acceptanceTerms?.acceptedByWinner
  ) {
    return "Termo de Aceite";
  }
  if (!childrenOffer.contract || childrenOffer.contract?.status === "pending") {
    return "Pendente de Assinatura";
  }
  return "Assinado";
};

function ContractsTable() {
  const { currentParticipant } = useChooseParticipant();
  const { user } = useAuth();

  const [currentPage, setCurrentPage] = React.useState(1);
  const [itemsPerPage, setItemsPerPage] = React.useState(10);

  const [productFilterValue, setProductFilterValue] = React.useState<
    string | null
  >(null);
  const [submarketFilterValue, setSubmarketFilterValue] = React.useState<
    string | null
  >(null);
  const [contractStatusFilterValue, setContractStatusFilterValue] =
    React.useState<string | null>(null);
  const [contractOriginFilter, setContractOriginFilter] = React.useState<
    string | null
  >(null);
  const [periodFilterEnabled, setPeriodFilterEnabled] =
    React.useState<boolean>(false);

  const [periodFilterStartDate, setPeriodFilterStartDate] =
    React.useState<Date>(DateTime.now().startOf("month").toJSDate());

  const [periodFilterEndDate, setPeriodFilterEndDate] = React.useState<Date>(
    DateTime.now().endOf("month").toJSDate()
  );

  const [periodFilterHasError, setPeriodFilterHasError] = React.useState(false);

  const {
    data: [products],
  } = useFirestoreQuery<{ name: string; assetsAvailableCode: string }>(() => {
    return [
      firestore.query(
        firestore.collection(firestore.getFirestore(), "products")
      ),
    ];
  }, []);

  const productsSorted = useMemo(() => {
    if (!products) return [];

    return products.sort((a, b) => {
      const assetA = a.name.toString().toUpperCase();
      const assetB = b.name.toString().toUpperCase();
      return assetA.localeCompare(assetB, undefined, { numeric: true });
    });
  }, [products]);

  const {
    data: contracts,
    isLoading,
    isError,
  } = useQuery(
    [
      "/contracts",
      currentParticipant?.id,
      itemsPerPage,
      currentPage,
      productFilterValue,
      submarketFilterValue,
      contractStatusFilterValue,
      contractOriginFilter,
      periodFilterEnabled,
      periodFilterStartDate,
      periodFilterEndDate,
      periodFilterHasError,
    ],
    (() => {
      if (periodFilterHasError && periodFilterEnabled) {
        return Promise.resolve({
          childrenOffersAndOffers: contracts
            ? contracts.childrenOffersAndOffers
            : [],
          total: 0,
        } as any);
      }
      return getChildrenOfferAndContracts(
        currentParticipant?.id,
        {
          origin:
            contractOriginFilter === t("Offers Panel")
              ? "PANEL"
              : contractOriginFilter === t("Spot Trading")
              ? "SPOT"
              : null,
          dateOfIssueBegin: periodFilterEnabled ? periodFilterStartDate : null,
          dateOfIssueEnd: periodFilterEnabled ? periodFilterEndDate : null,
          product:
            productFilterValue && products
              ? products?.find((p) => p.name === productFilterValue)!.id
              : null,
          situation:
            contractStatusFilterValue === t("Signed")
              ? "SIGNED"
              : contractStatusFilterValue === t("Pending")
              ? "PENDING"
              : contractStatusFilterValue === t("Acceptance Term")
              ? "ACCEPTANCE_TERMS"
              : null,
          submarket: submarketFilterValue,
        },
        {
          page: currentPage,
          limit: itemsPerPage,
        }
      );
    }) as () => Promise<{
      childrenOffersAndOffers: Contract[];
      total: number;
    }>
  );
  const totalItems = React.useMemo(
    () => contracts?.total ?? 0,
    [contracts?.total]
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [isContractModalOpen, setIsContractModalOpen] = React.useState(false);
  const [isAcceptanceTermModalOpen, setIsAcceptanceTermModalOpen] =
    React.useState(false);
  const [isClosingStatementModalOpen, setIsClosingStatementModalOpen] =
    React.useState(false);

  const [selectedMoreMenuId, setSelectedMoreMenuId] = React.useState<
    string | null
  >(null);

  const selectedMoreMenu = React.useMemo(() => {
    return contracts?.childrenOffersAndOffers.find(
      (contract: any) => contract.id === selectedMoreMenuId
    );
  }, [contracts, selectedMoreMenuId]);

  const handleOpenMoreMenu = React.useCallback(
    (event: React.MouseEvent<HTMLElement>, id: string) => {
      setAnchorEl(event.currentTarget);
      setSelectedMoreMenuId(id);
    },
    []
  );
  const handleCloseMoreMenu = React.useCallback(() => {
    setAnchorEl(null);
    setSelectedMoreMenuId(null);
  }, []);

  const [selectedContractId, setSelectedContractId] = React.useState<
    string | null
  >(null);
  const selectedContract = React.useMemo(
    () =>
      selectedContractId
        ? contracts?.childrenOffersAndOffers?.find(
            (contract: any) => contract.id === selectedContractId
          ) ?? null
        : null,
    [contracts, selectedContractId]
  );

  const handleOpenContractDetailsModal = React.useCallback(() => {
    setSelectedContractId(String(selectedMoreMenuId) ?? null);
    setIsContractModalOpen(true);
  }, [selectedMoreMenuId]);
  const handleCloseContractDetailsModal = React.useCallback(() => {
    setSelectedContractId(null);
    setIsContractModalOpen(false);
  }, []);

  const handleOpenAcceptanceTermModal = React.useCallback(() => {
    setSelectedContractId(String(selectedMoreMenuId) ?? null);
    setIsAcceptanceTermModalOpen(true);
  }, [selectedMoreMenuId]);
  const handleCloseAcceptanceTermModal = React.useCallback(() => {
    setSelectedContractId(null);
    setIsAcceptanceTermModalOpen(false);
  }, []);

  const handleOpenClosingStatementModal = React.useCallback(() => {
    setSelectedContractId(String(selectedMoreMenuId) ?? null);
    setIsClosingStatementModalOpen(true);
  }, [selectedMoreMenuId]);
  const handleCloseClosingStatementModal = React.useCallback(() => {
    setSelectedContractId(null);
    setIsClosingStatementModalOpen(false);
  }, []);

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const headCells = React.useMemo<Array<HeadCell>>(
    () => [
      {
        id: "issueDate",
        alignment: "left",
        label: t("IssueDate"),
        disableSort: true,
      },
      {
        id: "submarket",
        alignment: "left",
        label: t("Submarket"),
        disableSort: true,
      },
      {
        id: "buyer",
        alignment: "left",
        label: t("Buyer"),
        disableSort: true,
      },
      {
        id: "seller",
        alignment: "left",
        label: t("Seller"),
        disableSort: true,
      },
      {
        id: "product",
        alignment: "left",
        label: t("Source Type"),
        disableSort: true,
      },
      {
        id: "amount",
        alignment: "left",
        label: t("Volume"),
        disableSort: true,
      },
      {
        id: "value",
        alignment: "left",
        label: `${t("Value")} (R$)`,
        disableSort: true,
      },
      {
        id: "totalValue",
        alignment: "left",
        label: `${t("TotalValue")} (R$)`,
        disableSort: true,
      },
      {
        id: "situation",
        alignment: "left",
        label: t("Situation"),
        disableSort: true,
      },
      {
        id: "actions",
        alignment: "right",
        label: t("Actions"),
        disableSort: true,
      },
    ],
    [t]
  );

  if (isError) {
    return (
      <Alert mt={2} mb={3} severity="error">
        {t("Something went wrong.")}
      </Alert>
    );
  }

  if (isLoading || contracts === undefined) {
    return (
      <InfoBox>
        <CircularProgress />
      </InfoBox>
    );
  }

  return (
    <>
      {selectedContract && (
        <ContractModal
          open={isContractModalOpen}
          onClose={handleCloseContractDetailsModal}
          contract={selectedContract}
        />
      )}

      {selectedContract && currentParticipant?.id && (
        <AcceptanceTermModal
          open={isAcceptanceTermModalOpen}
          onClose={handleCloseAcceptanceTermModal}
          participantId={currentParticipant.id}
          contract={selectedContract}
        />
      )}

      {selectedContract && currentParticipant?.id && (
        <ClosingStatementModal
          open={isClosingStatementModalOpen}
          onClose={handleCloseClosingStatementModal}
          participantId={currentParticipant.id}
          contract={selectedContract}
        />
      )}

      <Menu
        id="more-menu"
        MenuListProps={{
          "aria-labelledby": `more-button-${selectedMoreMenuId}`,
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMoreMenu}
      >
        <MenuItem
          onClick={() => {
            handleCloseMoreMenu();
            handleOpenAcceptanceTermModal();
          }}
        >
          <ListItemText primary={t("Acceptance Term")} />
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleCloseMoreMenu();
            handleOpenClosingStatementModal();
          }}
          disabled={
            !(
              selectedMoreMenu?.acceptanceTerms?.acceptedByOwner === true &&
              selectedMoreMenu?.acceptanceTerms?.acceptedByWinner === true
            )
          }
        >
          <ListItemText primary={t("Closing Statement")} />
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleCloseMoreMenu();
            handleOpenContractDetailsModal();
          }}
          disabled={!selectedMoreMenu?.contract}
        >
          <ListItemText primary={t("Contract")} />
        </MenuItem>
      </Menu>

      <EnhancedTableManualPagination
        title={t("Contracts")}
        data={contracts.childrenOffersAndOffers.filter(
          (item) => item.operation
        )}
        headCells={headCells}
        avoidEmptyRows
        keyExtractor={(row) => String(row.id)}
        paginationOptions={{
          onPageChange: (page) => {
            setCurrentPage(page);
          },
          onRowsPerPageChange: (rows) => {
            setItemsPerPage(rows);
            setCurrentPage(1);
          },
          currentPage: currentPage,
          itemsPerPage: itemsPerPage,
          totalItems: totalItems,
        }}
        toolbarFiltersArea={
          <Box width="fit-content" display="flex" gap={3} flexWrap="wrap">
            <Box minWidth={150}>
              <Autocomplete
                value={contractOriginFilter}
                onChange={(e, data) => {
                  setCurrentPage(1);
                  setContractOriginFilter(data);
                }}
                openOnFocus
                handleHomeEndKeys
                disablePortal
                options={[t("Offers Panel"), t("Spot Trading")]}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("Origin")}
                    placeholder={t("All")}
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                  />
                )}
              />
            </Box>

            <Box minWidth={150}>
              <Autocomplete
                value={productFilterValue}
                onChange={(e, data) => {
                  setCurrentPage(1);
                  setProductFilterValue(data);
                }}
                openOnFocus
                handleHomeEndKeys
                disablePortal
                options={productsSorted?.map((item) => item.name) ?? []}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("Source Type")}
                    placeholder={t("All")}
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                  />
                )}
              />
            </Box>

            <Box minWidth={150}>
              <Autocomplete
                value={submarketFilterValue}
                onChange={(e, data) => {
                  setCurrentPage(1);
                  setSubmarketFilterValue(data);
                }}
                openOnFocus
                handleHomeEndKeys
                disablePortal
                options={Object.keys(submarketColors)}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("Submarket")}
                    placeholder={t("All")}
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                  />
                )}
              />
            </Box>

            <Box minWidth={170}>
              <Autocomplete
                value={contractStatusFilterValue}
                onChange={(e, data) => {
                  setCurrentPage(1);
                  setContractStatusFilterValue(data);
                }}
                openOnFocus
                handleHomeEndKeys
                disablePortal
                options={[t("Pending"), t("Signed"), t("Acceptance Term")]}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("Contract Status")}
                    placeholder={t("All")}
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                  />
                )}
              />
            </Box>

            <Box
              minWidth={350}
              style={{ marginTop: periodFilterEnabled ? "-30px" : "0" }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={periodFilterEnabled}
                    onChange={(e) => {
                      setPeriodFilterEnabled(e.target.checked);
                    }}
                  />
                }
                label="Filtrar por período"
              />

              {periodFilterEnabled && (
                <Box minWidth={350} style={{ display: "flex" }}>
                  <Box minWidth={150} maxWidth={150}>
                    <DesktopDatePicker
                      value={periodFilterStartDate}
                      label={`Data inicial`}
                      onChange={(date) => {
                        if (!date) {
                          return;
                        }
                        if (
                          date &&
                          periodFilterEndDate &&
                          date.getTime() > periodFilterEndDate.getTime()
                        ) {
                          setPeriodFilterHasError(true);
                        } else {
                          setPeriodFilterHasError(false);
                        }
                        setPeriodFilterStartDate(date);
                      }}
                      slotProps={{
                        openPickerButton: {
                          size: "small",
                          sx: {
                            "& svg": {
                              transform: "scale(0.75)",
                            },
                          },
                        },
                        textField: {
                          fullWidth: true,
                          size: "small",
                          // error: !!errors.deliveryPeriodEnd,
                          // helperText: errors.deliveryPeriodEnd?.message || " ",
                        },
                      }}
                    />
                  </Box>

                  <Box
                    minWidth={150}
                    maxWidth={150}
                    style={{ marginLeft: "1rem" }}
                  >
                    <DesktopDatePicker
                      value={periodFilterEndDate}
                      label={`Data final`}
                      onChange={(date) => {
                        if (!date) {
                          return;
                        }
                        if (
                          date &&
                          periodFilterStartDate &&
                          periodFilterStartDate.getTime() > date.getTime()
                        ) {
                          setPeriodFilterHasError(true);
                        } else {
                          setPeriodFilterHasError(false);
                        }
                        setPeriodFilterEndDate(date);
                      }}
                      slotProps={{
                        openPickerButton: {
                          size: "small",
                          sx: {
                            "& svg": {
                              transform: "scale(0.75)",
                            },
                          },
                        },
                        textField: {
                          fullWidth: true,
                          size: "small",
                          error: periodFilterHasError ? true : undefined,
                          helperText: periodFilterHasError
                            ? "Data inválida"
                            : " ",
                        },
                      }}
                    />
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        }
        renderRow={(row) => {
          const { buyerName, sellerName } = getBuyerAndSellerData(row);
          const status = getContractStatus(row);
          const isSpread = (row.offer as any).priceType === "Atrelado ao PLD";
          const totalValue =
            row.winnerBid.value + (isSpread ? row.pldToConsider ?? 0 : 0);

          return (
            <TableRow hover tabIndex={-1}>
              <TableCell
                component="th"
                scope="row"
                sx={{ whiteSpace: "nowrap" }}
              >
                {row.contract?.issueDate
                  ? new Date(row.contract?.issueDate).toLocaleDateString()
                  : "-"}
              </TableCell>
              <TableCell align="left">{row.operation.submarket}</TableCell>
              <TableCell align="left">{buyerName}</TableCell>
              <TableCell align="left">{sellerName}</TableCell>
              <TableCell align="left">
                {row.operation.product?.name ?? "-"}
              </TableCell>
              <TableCell align="left">{`${Number(
                row.winnerBid.amount
              ).toLocaleString(language)}${
                row.operation.product?.measurementUnit
                  ? ` ${row.operation.product.measurementUnit}`
                  : ""
              }`}</TableCell>
              <TableCell align="left">
                {toLocaleCurrencyStringWithoutSymbol(
                  totalValue,
                  "BRL",
                  language
                )}
              </TableCell>
              <TableCell align="left">
                {toLocaleCurrencyStringWithoutSymbol(
                  row.winnerBid.amount * totalValue,
                  "BRL",
                  language
                )}
              </TableCell>
              <TableCell align="left">
                <Chip
                  size="small"
                  label={t(status)}
                  sx={(theme) => ({
                    ...(status === "Assinado"
                      ? {
                          backgroundColor: green[500],
                          color: theme.palette.common.white,
                        }
                      : status === "Pendente de Assinatura"
                      ? {
                          backgroundColor: deepPurple[500],
                          color: theme.palette.common.white,
                        }
                      : status === "Termo de Aceite"
                      ? {
                          backgroundColor: orange[600],
                          color: theme.palette.common.white,
                        }
                      : {}),
                  })}
                />
              </TableCell>
              <TableCell padding="none" align="right">
                <Box mr={2} whiteSpace="nowrap">
                  <Tooltip title={t("More")}>
                    <span>
                      <IconButton
                        id={`more-button-${row.id}`}
                        aria-label={t("More")}
                        size="large"
                        onClick={(e) => handleOpenMoreMenu(e, row.id)}
                        disabled={user?.role !== "user"}
                      >
                        <MoreHorizIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </Box>
              </TableCell>
            </TableRow>
          );
        }}
      />
    </>
  );
}

export default function ContractList() {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Helmet title={t("Contracts")} />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            {t("Contracts")}
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              {t("Home")}
            </Link>
            <Typography>{t("Contracts")}</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <ContractsTable />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
