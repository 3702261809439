import { useQuery } from "@tanstack/react-query";
import { getTunnelLimits } from "../infra/api/TunnelLimitsInfra";

export function useGetTunnelLimits() {
  const { data: tunnelLimits, ...rest } = useQuery(
    ["getTunnelLimits"],
    getTunnelLimits,
    {
      refetchOnWindowFocus: false,
    }
  );

  return { tunnelLimits, ...rest };
}
