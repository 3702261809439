/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useMemo } from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import {
  Alert as MuiAlert,
  Box,
  Paper,
  useMediaQuery,
  useTheme,
  CircularProgress,
  TableRow,
  TableCell,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Autocomplete,
  TextField,
  Typography,
} from "@mui/material";
import InputMask from "react-input-mask";
import { useTranslation } from "react-i18next";
import {
  CancelOutlined,
  CheckCircleOutlineOutlined,
  InfoOutlined,
  MoreHoriz as MoreHorizIcon,
} from "@mui/icons-material";
import { formatCNPJ, toLocaleCurrencyString } from "../../utils/string";
import { EnhancedTableManualPagination, HeadCell } from "../EnhancedTable";
import { parseDate } from "../../utils/date";
import { OptionalTooltip } from "../OptionalTooltip";
import { useGetDepositsAndWithdrawalsRequests } from "./hooks/useGetDepositsAndWithdrawalsRequests";
import { BankCceeInformationModal } from "./modals/BankCceeInformationModal";
import { RefuseModal } from "./modals/RefuseModal";
import useConfirmationModal from "../../hooks/useConfirmationModal";
import useAuth from "../../hooks/useAuth";
import { useSubmitAcceptAssetRequest } from "./hooks/useSubmitAcceptAssetRequest";
import { toFixedDecimalPlaces } from "../../utils/number";

const Alert = styled(MuiAlert)(spacing);

const InfoBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24;
`;

const statusOptions = [
  {
    id: "CREATED",
    name: "Em andamento",
  },
  {
    id: "CANCELED",
    name: "Falhou",
  },
  {
    id: "FINISHED",
    name: "Finalizado",
  },
];

export function ManageDepositsAndWithdrawalsPageContent() {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { user } = useAuth();
  const { showConfirmationModal } = useConfirmationModal();

  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  const [cnpjFilter, setCnpjFilter] = React.useState<string | null>(null);
  const [statusFilter, setStatusFilter] = React.useState<string | null>(null);

  const [page, setPage] = React.useState(1);
  const [itemsPerPage, setItemsPerPage] = React.useState(10);

  const {
    depositsAndWithdrawalsRequestsData,
    refetch,
    isFetching,
    isLoading: isLoadingDepositsAndWithdrawalsRequests,
    isError: isErrorDepositsAndWithdrawalsRequests,
  } = useGetDepositsAndWithdrawalsRequests({
    page,
    itemsPerPage,
    cnpj: handleCnpjFilter(cnpjFilter) ?? undefined,
    status: statusFilter ?? undefined,
  });

  const { submitAcceptAssetRequestMutate } = useSubmitAcceptAssetRequest({
    onSuccess: () => refetch(),
  });

  function handleCnpjFilter(value: string | null) {
    return value?.length === 18 ? value : null;
  }

  const totalItems = React.useMemo(
    () => depositsAndWithdrawalsRequestsData?.total ?? 0,
    [depositsAndWithdrawalsRequestsData?.total]
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [selectedMoreMenuId, setSelectedMoreMenuId] = React.useState<
    number | string | null
  >(null);

  const selectedTransaction = useMemo(() => {
    return depositsAndWithdrawalsRequestsData?.data.find(
      (operation) => operation.id === selectedMoreMenuId
    );
  }, [depositsAndWithdrawalsRequestsData, selectedMoreMenuId]);

  const handleOpenMoreMenu = React.useCallback(
    (event: React.MouseEvent<HTMLElement>, id: number | string) => {
      setAnchorEl(event.currentTarget);
      setSelectedMoreMenuId(id);
    },
    []
  );

  const handleCloseMoreMenu = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  const [isOpenBankAndCceeModal, setIsOpenBankAndCceeModal] =
    React.useState(false);
  const [isOpenRefuseModal, setIsOpenRefuseModal] = React.useState(false);

  const handleOpenBankAndCceeModal = React.useCallback(() => {
    setIsOpenBankAndCceeModal(true);
  }, []);

  const handleCloseBankAndCceeModal = React.useCallback(() => {
    setIsOpenBankAndCceeModal(false);
    setSelectedMoreMenuId(null);
  }, []);

  const handleOpenRefuseModal = React.useCallback(() => {
    setIsOpenRefuseModal(true);
  }, []);
  const handleCloseRefuseModal = React.useCallback(() => {
    setIsOpenRefuseModal(false);
    setSelectedMoreMenuId(null);
  }, []);

  const headCells = React.useMemo<Array<HeadCell>>(
    () => [
      {
        id: "companyName",
        alignment: "left",
        label: t("Company Name"),
        disableSort: true,
      },
      {
        id: "status",
        alignment: "center",
        label: t("Operation Status"),
        disableSort: true,
      },
      {
        id: "cnpj",
        alignment: "center",
        label: t("CNPJ"),
        disableSort: true,
      },
      {
        id: "requestType",
        alignment: "center",
        label: t("Operation"),
        disableSort: true,
      },
      {
        id: "amount",
        alignment: "center",
        label: t("Quantity"),
        disableSort: true,
      },
      {
        id: "type",
        alignment: "center",
        label: t("Type"),
        disableSort: true,
      },
      {
        id: "submarket",
        alignment: "center",
        label: t("Submarket"),
        disableSort: true,
      },
      {
        id: "requestedAt",
        alignment: "center",
        label: t("Request Date"),
        disableSort: true,
      },
      {
        id: "operatorName",
        alignment: "center",
        label: t("Operator"),
        disableSort: true,
      },
      {
        id: "actions",
        alignment: "right",
        label: t("Actions"),
        disableSort: true,
      },
    ],
    [t]
  );

  if (isErrorDepositsAndWithdrawalsRequests) {
    return (
      <Alert mt={2} mb={3} severity="error">
        {t("Something went wrong.")}
      </Alert>
    );
  }

  if (
    isLoadingDepositsAndWithdrawalsRequests ||
    depositsAndWithdrawalsRequestsData === undefined ||
    isFetching
  ) {
    return (
      <InfoBox>
        <CircularProgress />
      </InfoBox>
    );
  }

  function handleAcceptAssetRequest() {
    showConfirmationModal({
      onSubmit: () => submitAcceptAssetRequestMutate(selectedTransaction?.id!),
      onClose: () => setSelectedMoreMenuId(null),
      message: (
        <>
          {t("Check pending information")}
          <br />
          <br />
          {t("In accordance with the data, just click on “CONFIRM”")}
          <br />
          <br />
          {t(
            "After confirmation, the amount entered will be automatically credited or debited from the user's account"
          )}
        </>
      ),
    });
  }

  return (
    <>
      {selectedTransaction && isOpenBankAndCceeModal && (
        <BankCceeInformationModal
          open={isOpenBankAndCceeModal}
          onClose={handleCloseBankAndCceeModal}
          transaction={selectedTransaction}
        />
      )}

      {selectedTransaction && (
        <RefuseModal
          open={isOpenRefuseModal}
          onClose={handleCloseRefuseModal}
          transaction={selectedTransaction}
          reloadData={refetch}
        />
      )}

      <Menu
        id="more-menu"
        MenuListProps={{
          "aria-labelledby": `more-button-${selectedMoreMenuId}`,
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMoreMenu}
      >
        <MenuItem
          onClick={() => {
            handleCloseMoreMenu();
            handleOpenBankAndCceeModal();
          }}
        >
          <ListItemIcon>
            <InfoOutlined />
          </ListItemIcon>
          <ListItemText primary={t("Bank & CCEE Information")} />
        </MenuItem>
        {/* <MenuItem
          onClick={() => {
            handleCloseMoreMenu();
            console.log("Suporte: ", selectedTransaction);
          }}
        >
          <ListItemIcon>
            <SupportAgentRounded />
          </ListItemIcon>
          <ListItemText primary={t("Support")} />
        </MenuItem> */}
        <MenuItem
          onClick={() => {
            handleCloseMoreMenu();
            handleAcceptAssetRequest();
          }}
          disabled={
            user?.role !== "admin" || selectedTransaction?.status !== "CREATED"
          }
        >
          <ListItemIcon>
            <CheckCircleOutlineOutlined />
          </ListItemIcon>
          <ListItemText primary={t("Accept")} />
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleCloseMoreMenu();
            handleOpenRefuseModal();
          }}
          disabled={
            user?.role !== "admin" || selectedTransaction?.status !== "CREATED"
          }
        >
          <ListItemIcon>
            <CancelOutlined />
          </ListItemIcon>
          <ListItemText primary={t("Refuse")} />
        </MenuItem>
      </Menu>

      <Paper
        sx={{
          // maxWidth: 1200,
          mx: "auto",
          p: isMdUp ? 12 : 8,
        }}
      >
        <EnhancedTableManualPagination
          // title={t("Manage Deposits and Withdrawals")}
          data={depositsAndWithdrawalsRequestsData.data}
          headCells={headCells}
          avoidEmptyRows
          paginationOptions={{
            onPageChange: (page) => {
              setPage(page);
            },
            onRowsPerPageChange: (rows) => {
              setItemsPerPage(rows);
            },
            currentPage: page,
            itemsPerPage: itemsPerPage,
            totalItems: totalItems,
          }}
          toolbarFiltersArea={
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              flexWrap="wrap"
            >
              <Typography variant="h6" mb={2}>
                {t("Filters")}:
              </Typography>
              <Box width="fit-content" display="flex" gap={3} flexWrap="wrap">
                <Box minWidth={150}>
                  <Autocomplete
                    value={statusFilter}
                    onChange={(e, data) => {
                      setPage(1);
                      setStatusFilter(data);
                    }}
                    openOnFocus
                    handleHomeEndKeys
                    disablePortal
                    options={statusOptions?.map((item) => item.id) ?? []}
                    getOptionLabel={(option) =>
                      statusOptions.find((item) => item.id === option)?.name ??
                      option
                    }
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("Operation Status")}
                        placeholder={t("All")}
                        InputLabelProps={{ shrink: true }}
                        variant="standard"
                      />
                    )}
                  />
                </Box>

                {user?.role === "admin" && (
                  <Box minWidth={150}>
                    <InputMask
                      mask="99.999.999/9999-99"
                      maskPlaceholder={null}
                      alwaysShowMask={false}
                      value={cnpjFilter ?? ""}
                      onChange={(e) => setCnpjFilter(e.target.value)}
                    >
                      <TextField
                        label={t("CNPJ")}
                        placeholder={t("All")}
                        InputLabelProps={{ shrink: true }}
                        variant="standard"
                      />
                    </InputMask>
                  </Box>
                )}
              </Box>
            </Box>
          }
          keyExtractor={(row) => String(row.id)}
          renderRow={(row) => {
            const requestedAt = parseDate(row.requestedAt);
            const requestedAtString = requestedAt
              ? `${requestedAt.toLocaleDateString(language, {
                  timeZone: "UTC",
                })} ${requestedAt.toLocaleTimeString(language, {
                  // timeZone: "UTC",
                  hour: language.includes("pt") ? "2-digit" : "numeric",
                  minute: "2-digit",
                })}`
              : "-";

            const statusColor =
              row.status === "CREATED"
                ? "primary.main"
                : row.status === "FINISHED"
                ? "success.main"
                : row.status === "CANCELED"
                ? "error.main"
                : "action.disabled";

            const amount = toFixedDecimalPlaces(
              row.amount,
              row.asset.precision
            );

            const convertedAmount =
              row.asset.type === "currency"
                ? toLocaleCurrencyString(amount, row.asset.code, language)
                : `${amount.toLocaleString(language, {
                    minimumFractionDigits: 3,
                    maximumFractionDigits: 3,
                  })} MWh`;

            return (
              <TableRow hover tabIndex={-1}>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    whiteSpace: "nowrap",
                    position: "relative",
                    "&:before": {
                      content: '""',
                      borderRadius: 2,
                      width: 4,
                      height: "80%",
                      backgroundColor: statusColor,
                      position: "absolute",
                      top: "50%",
                      left: 0,
                      transform: "translateY(-50%)",
                    },
                  }}
                >
                  {row.participant.name}
                </TableCell>
                <TableCell align="center" sx={{ color: statusColor }}>
                  <OptionalTooltip
                    disableTooltip={row.status !== "CANCELED"}
                    title={
                      <>
                        {row.cancelStatusReason && (
                          <>
                            <strong>{t("Reason")}: </strong>
                            {row.cancelStatusReason.cancelStatus.description}
                            <br />
                            {row.cancelStatusReason.details && (
                              <>
                                <strong>{t("Justification")}: </strong>
                                {row.cancelStatusReason.details}
                              </>
                            )}
                          </>
                        )}
                      </>
                    }
                  >
                    <span>
                      <Box
                        sx={{
                          width: 12,
                          height: 12,
                          borderRadius: 9999,
                          backgroundColor: statusColor,
                          display: "inline-block",
                        }}
                      />{" "}
                      {t(row.status)}
                    </span>
                  </OptionalTooltip>
                </TableCell>
                <TableCell align="center">
                  {formatCNPJ(row.participant.cnpj)}
                </TableCell>
                <TableCell align="center">{t(row.requestType)}</TableCell>
                <TableCell align="center">{convertedAmount}</TableCell>
                <TableCell align="center">{row.asset.name}</TableCell>
                <TableCell align="center">{row.submarket ?? "-"}</TableCell>
                <TableCell align="center">{requestedAtString}</TableCell>
                <TableCell
                  align="center"
                  sx={{
                    maxWidth: "10ch",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <Tooltip title={row.operator.name}>
                    <span>{row.operator.name}</span>
                  </Tooltip>
                </TableCell>

                <TableCell padding="none" align="right">
                  <Box whiteSpace="nowrap">
                    <Tooltip title={t("More")}>
                      <span>
                        <IconButton
                          id={`more-button-${row.id}`}
                          aria-label={t("More")}
                          size="large"
                          onClick={(e) => handleOpenMoreMenu(e, row.id)}
                          // disabled={user?.role !== "user"}
                        >
                          <MoreHorizIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Box>
                </TableCell>
              </TableRow>
            );
          }}
        />
      </Paper>
    </>
  );
}
