import { useMutation } from "@tanstack/react-query";
import { createEnergyWithdrawal } from "../infra/api/BankInfra";

type Error = {
  message: string;
  statusCode: number;
};

export function useSubmitEnergyWithdrawal(options?: {
  onSuccess?: () => void;
  onError?: (error: Error) => void;
}) {
  const { mutate: submitEnergyWithdrawalMutate, ...rest } = useMutation(
    createEnergyWithdrawal,
    options
  );

  return { submitEnergyWithdrawalMutate, ...rest };
}
