import React from "react";
import styled from "@emotion/styled";
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  FileDownload,
  FileUpload,
  InfoRounded,
  WalletRounded,
} from "@mui/icons-material";
import { getCurrencySymbol, toLocaleCurrencyString } from "../../utils/string";
import { Link } from "react-router-dom";
import { submarketColors } from "../../constants";
import { toFixedDecimalPlaces } from "../../utils/number";
import { useFirestoreQuery } from "../../hooks/useFirestoreQuery";
import * as firestore from "firebase/firestore";
import useChooseParticipant from "../../hooks/useChooseParticipant";

const energyNames: any = {
  "E-CON": "Conv",
  "E-CQ1": "CQ1",
  "E-CQ5": "CQ5",
  "E-#I0": "I0",
  "E-#I1": "I1",
  "E-#I5": "I5",
  "E-#I8": "I8",
};

const InfoBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24;
`;

interface ResumeBalanceProps {
  type: "currency" | "energy";
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#233044",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    minWidth: "350px",
  },
}));

export function ResumeBalanceBox({ type }: ResumeBalanceProps) {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { participantId } = useChooseParticipant();

  const {
    data: [balanceData],
  } = useFirestoreQuery<{
    balances: Array<{
      amount: number;
      balanceType: string;
      assetCode: string;
      blockedAmount: number;
      submarket?: string;
    }>;
  }>(() => {
    if (participantId === undefined) {
      return [];
    }
    return [
      firestore.query(
        firestore.collection(firestore.getFirestore(), `assets_wallets`),
        firestore.where("participantId", "==", participantId)
      ),
    ];
  }, [participantId]);

  const totalAvaliableBalance = React.useMemo(() => {
    if (!balanceData?.length) {
      return { currency: 0, energy: 0 };
    }
    return (
      balanceData[0]?.balances.reduce((acc, curr) => {
        return {
          ...acc,
          [curr.balanceType]:
            (acc[curr.balanceType] ?? 0) +
            curr.amount / 10 ** (curr.balanceType === "currency" ? 2 : 3),
        };
      }, {} as Record<string, number>) ?? { currency: 0, energy: 0 }
    );
  }, [balanceData]);

  const tableResumeEnergyBody = React.useMemo(() => {
    const array: Array<{
      name: string;
      submarkets: { S: number; "SE/CO": number; N: number; NE: number };
    }> = [];
    if (!balanceData?.length) return [];
    const objeto =
      balanceData[0]?.balances
        ?.filter((item) => item.balanceType === "energy" && item.amount > 0)
        ?.reduce((acc, curr) => {
          return {
            ...acc,
            [curr.assetCode]: {
              S:
                curr.submarket === "S"
                  ? curr.amount
                  : acc[curr.assetCode]?.S
                  ? acc[curr.assetCode]?.S
                  : 0,
              "SE/CO":
                curr.submarket === "SE/CO"
                  ? curr.amount
                  : acc[curr.assetCode]?.["SE/CO"]
                  ? acc[curr.assetCode]?.["SE/CO"]
                  : 0,
              N:
                curr.submarket === "N"
                  ? curr.amount
                  : acc[curr.assetCode]?.N
                  ? acc[curr.assetCode]?.N
                  : 0,
              NE:
                curr.submarket === "NE"
                  ? curr.amount
                  : acc[curr.assetCode]?.NE
                  ? acc[curr.assetCode]?.NE
                  : 0,
            },
          };
        }, [] as any) ?? [];

    for (const chave in objeto) {
      array.push({
        name: chave,
        submarkets: objeto[chave],
      });
    }
    return array;
  }, [balanceData]);

  function handleBalaceBySubmarket(value: number) {
    if (value === 0) return "-";
    return toFixedDecimalPlaces(value, 3)?.toLocaleString(language, {
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    });
  }

  const tableResumeEnergyHead = [
    {
      name: "S",
      color: submarketColors["S"],
    },
    {
      name: "SE/CO",
      color: submarketColors["SE/CO"],
    },
    {
      name: "NE",
      color: submarketColors["NE"],
    },
    {
      name: "N",
      color: submarketColors["N"],
    },
  ];

  // if (isErrorBalanceBySubmarket) {
  //   return (
  //     <Alert mt={2} mb={3} severity="error">
  //       {t("Something went wrong.")}
  //     </Alert>
  //   );
  // }

  if (balanceData === undefined) {
    return (
      <InfoBox>
        <CircularProgress />
      </InfoBox>
    );
  }

  return (
    <Box
      display="flex"
      gap={5}
      alignItems="center"
      justifyContent="space-between"
    >
      <Box display="flex" gap={3} alignItems="center" color="#8295B2">
        <WalletRounded />
        <Typography variant="subtitle2">
          {`${t("My Wallet")} (${
            type === "currency" ? getCurrencySymbol("BRL", language) : "MWh"
          })`}
        </Typography>
        <Typography
          variant={type === "currency" ? "h4" : "h5"}
          color="#ffffff"
          display="flex"
          alignItems="center"
          gap={2}
        >
          <strong>
            {type === "currency"
              ? `${toLocaleCurrencyString(
                  totalAvaliableBalance["currency"] ?? 0,
                  "BRL",
                  language
                )}`
              : (totalAvaliableBalance["energy"] ?? 0)?.toLocaleString(
                  language,
                  {
                    minimumFractionDigits: 3,
                    maximumFractionDigits: 3,
                  }
                )}
          </strong>
          {type === "energy" && (
            <LightTooltip
              title={
                <Paper>
                  <Typography color="#8295B2">
                    {t("My Wallet")} (MWh)
                  </Typography>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell></TableCell>
                          {tableResumeEnergyHead.map((item, index) => (
                            <TableCell
                              key={index}
                              sx={{
                                fontSize: "12px",
                                color: item.color,
                                fontWeight: "bold",
                              }}
                              align="center"
                            >
                              {item.name}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {tableResumeEnergyBody?.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell
                              sx={{ fontSize: "12px", color: "#8295B2" }}
                            >
                              {energyNames?.[item.name] ?? "-"}
                            </TableCell>
                            <TableCell
                              sx={{ fontSize: "12px", color: "#8295B2" }}
                              align="center"
                            >
                              {handleBalaceBySubmarket(
                                item.submarkets?.["S"] ?? 0
                              )}
                            </TableCell>
                            <TableCell
                              sx={{ fontSize: "12px", color: "#8295B2" }}
                              align="center"
                            >
                              {handleBalaceBySubmarket(
                                item.submarkets?.["SE/CO"] ?? 0
                              )}
                            </TableCell>
                            <TableCell
                              sx={{ fontSize: "12px", color: "#8295B2" }}
                              align="center"
                            >
                              {handleBalaceBySubmarket(
                                item.submarkets?.["NE"] ?? 0
                              )}
                            </TableCell>
                            <TableCell
                              sx={{ fontSize: "12px", color: "#8295B2" }}
                              align="center"
                            >
                              {handleBalaceBySubmarket(
                                item.submarkets?.["N"] ?? 0
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              }
            >
              <InfoRounded sx={{ width: "12px" }} />
            </LightTooltip>
          )}
        </Typography>
      </Box>
      <Box display="flex">
        <Button
          component={Link}
          variant="text"
          sx={{
            color: "#FFffff",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 3,
          }}
          to={
            type === "currency"
              ? "/bank/financial-deposit"
              : "/bank/energy-deposit"
          }
        >
          <FileDownload sx={{ width: "18px" }} /> {t("Deposit")}
        </Button>
        <Button
          component={Link}
          variant="text"
          sx={{
            color: "#FFffff",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 3,
          }}
          to={
            type === "currency"
              ? "/bank/financial-withdrawal"
              : "/bank/energy-withdrawal"
          }
        >
          <FileUpload sx={{ width: "18px" }} /> {t("withdrawal")}
        </Button>
      </Box>
    </Box>
  );
}
