import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";

import {
  Avatar as MuiAvatar,
  Badge,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover as MuiPopover,
  SvgIcon,
  Tooltip,
  Typography,
} from "@mui/material";
import { Bell, Home, UserPlus, Server } from "react-feather";
import { useTranslation } from "react-i18next";
import { useGetNotifications } from "./notification/hooks/useGetNotifications";
import { TradingLostLimitReached } from "./notification/entities/Notification";
import { SetNotificationsAsReadService } from "./notification/services/SetNotificationsAsReadService";
import { toLocaleCurrencyString } from "../../utils/string";

const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 300px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const Indicator = styled(Badge)`
  .MuiBadge-badge {
    background: ${(props) => props.theme.header.indicator.background};
    color: ${(props) => props.theme.palette.common.white};
  }
`;

const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
`;

const NotificationHeader = styled(Box)`
  text-align: center;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
`;

const setNotificationsAsReadService = new SetNotificationsAsReadService();

function Notification({
  title,
  description,
  Icon,
}: {
  title: string;
  description: string;
  Icon: React.ElementType;
}) {
  return (
    <ListItem divider component={Link} to="#">
      <ListItemAvatar>
        <Avatar>
          <SvgIcon fontSize="small">
            <Icon />
          </SvgIcon>
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        primaryTypographyProps={{
          variant: "subtitle2",
          color: "textPrimary",
        }}
        secondary={description}
      />
    </ListItem>
  );
}

function NavbarNotificationsDropdown() {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setNotificationsAsReadService.setNotificationsAsRead();
  };

  const { notifications, unreadedNotifications } = useGetNotifications();

  return (
    <React.Fragment>
      <Tooltip title={t("Notifications")}>
        <IconButton color="inherit" ref={ref} onClick={handleOpen} size="large">
          <Indicator badgeContent={unreadedNotifications}>
            <Bell />
          </Indicator>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <NotificationHeader p={2}>
          <Typography variant="subtitle1" color="textPrimary">
            {(() => {
              if (unreadedNotifications === 1) {
                return t("singleNotification");
              }

              if (unreadedNotifications > 1) {
                return t("{{notificationsCounter}} New Notifications", {
                  notificationsCounter: unreadedNotifications,
                });
              }

              return t("noNotifications");
            })()}
          </Typography>
        </NotificationHeader>
        <React.Fragment>
          <List disablePadding>
            {/* <Notification
              title="Update complete"
              description="Restart server to complete update."
              Icon={Server}
            />
            <Notification
              title="New connection"
              description="Anna accepted your request."
              Icon={UserPlus}
            /> */}
            {notifications.map((notification, i) => {
              if (notification instanceof TradingLostLimitReached) {
                return (
                  <Notification
                    key={i}
                    title={t("Lost Limit Reached")}
                    description={t(
                      "Alert: Participant {{ participantName }} reached the lost limit for spot negotiations",
                      {
                        participantName: notification.getParticipantName(),
                        lostReached: toLocaleCurrencyString(
                          Math.round(
                            notification.getLostReached().getAmount() / 100
                          ),
                          "BRL",
                          language
                        ),
                        lostLimit: toLocaleCurrencyString(
                          Math.round(
                            notification.getParticipantLostLimit().getAmount() /
                              100
                          ),
                          "BRL",
                          language
                        ),
                      }
                    )}
                    Icon={Bell}
                  />
                );
              }
            })}
            {/* <Notification
              title="New login"
              description="Login from 192.186.1.1."
              Icon={Home}
            /> */}
          </List>
          {/* <Box p={1} display="flex" justifyContent="center">
            <Button size="small" component={Link} to="#">
              Show all notifications
            </Button>
          </Box> */}
        </React.Fragment>
      </Popover>
    </React.Fragment>
  );
}

export default NavbarNotificationsDropdown;
