import Decimal from "decimal.js";

export function toFixedWithoutRounding(
  num: number | string,
  fractionDigits: number
) {
  const re = new RegExp("^-?\\d+(?:.\\d{0," + (fractionDigits || -1) + "})?");
  return (num.toString().match(re) ?? [""])[0];
}

export function toFixedDecimalPlaces(
  value: number | string,
  precision: number
) {
  return Number(value) / 10 ** precision;
}

export function roundToDecimalPlaces(value: string | number, decimals: number) {
  return Number(Number(value || 0).toFixed(decimals));
}

export function floatingPointFixedOp(
  firstNumber: number,
  operation: "/" | "*" | "-",
  secondNumber: number
): number {
  if (!firstNumber) return 0;

  const number = new Decimal(firstNumber);

  const operations = {
    "*": number.times(secondNumber),
    "/": number.dividedBy(secondNumber),
    "-": number.minus(secondNumber),
  };

  return operations[operation].toNumber();
}
