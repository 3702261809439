import { Observable, Subscription } from "rxjs";
import * as firestore from "firebase/firestore";
import {
  bidsData,
  BidsFromOfferObservable,
  options,
} from "../../services/GetBidsFromOfferObservableService";
import { Bid } from "../../entities/Bid";

export class PanelOperationsObservableImpl
  extends Observable<bidsData>
  implements BidsFromOfferObservable
{
  private firestoreListener: () => void = () => {};
  private options: options = {
    page: 1,
    operationId: 0,
    bidType: "BUY",
    participant: -1,
  };
  private subscribers: {
    subscription: Subscription;
    next: ((value: bidsData) => void) | null | undefined;
  }[] = [];

  private pageStartBaseElements: {
    [key: number]:
      | firestore.QueryDocumentSnapshot<firestore.DocumentData>
      | number;
  } = {
    1: 0,
  };

  subscribeWithOptions(
    options: options,
    next?: ((value: bidsData) => void) | null | undefined,
    error?: ((error: any) => void) | null | undefined,
    complete?: (() => void) | null | undefined
  ): Subscription {
    this.setOptions(options);
    this.options = options;
    const subscription = this.subscribe(next, error, complete);
    this.subscribers.push({
      subscription,
      next,
    });

    subscription.add(() => {
      this.subscribers = this.subscribers.filter(
        (s) => s.subscription !== subscription
      );
      if (this.subscribers.length === 0) {
        this.firestoreListener();
      }
    });

    return subscription;
  }

  setOptions(options: options) {
    this.options = options;
    this.startFirestoreListener();
  }

  private startFirestoreListener() {
    this.firestoreListener();

    const queryFilters: firestore.QueryConstraint[] = [];

    const queryWithLimits = firestore.query(
      firestore.collection(firestore.getFirestore(), `bids`),
      firestore.where("operationId", "==", this.options.operationId),
      firestore.where("bidType", "==", this.options.bidType),
      firestore.orderBy("createdAt", "desc"),
      ...(this.pageStartBaseElements[this.options.page]
        ? [firestore.startAfter(this.pageStartBaseElements[this.options.page])]
        : []),
      // firestore.limit(this.options.itemsPerPage),
      ...queryFilters
    );

    this.firestoreListener = firestore.onSnapshot(
      queryWithLimits,
      async (snapshot) => {
        this.pageStartBaseElements[this.options.page + 1] =
          snapshot.docs[snapshot.docs.length - 1];

        this.subscribers.forEach((subscriber) => {
          if (!subscriber.next) {
            return;
          }

          const bids = snapshot.docs
            .map((doc) => {
              return this.firestoreDocToBid(doc, this.options.participant);
            })
            .filter((bid) => bid.getStatus() === "CRIADO");

          subscriber.next({
            bids,
          });
        });
      }
    );
  }

  private firestoreDocToBid(
    doc: firestore.QueryDocumentSnapshot<firestore.DocumentData>,
    participantInterested: number
  ): Bid {
    const docData = doc.data();

    const bid = new Bid(
      doc.id,
      docData.status,
      docData.participantId === participantInterested
        ? docData.participantId
        : null,
      this.options.participant,
      docData.createdAt.toDate(),
      docData.pldValue,
      docData.value,
      docData.amount
    );

    return bid;
  }
}
