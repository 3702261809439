import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getCurrencySymbol } from "../../../../../utils/string";

export const SellOfferValueHeader: React.FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  return (
    <Box
      display="inline-flex"
      flexDirection="column"
      alignItems="center"
      lineHeight={1}
    >
      <Button
        variant="contained"
        color="success"
        sx={{
          pointerEvents: "none",
          display: "flex",
          flexDirection: "column",
          gap: 1,
          py: 1,
          backgroundColor: "#E83A3A",
        }}
      >
        <Typography variant="caption" lineHeight={1} fontSize="0.5rem">
          {t("Order")}
        </Typography>
        <Typography lineHeight={1}>{t("VENDA").toUpperCase()}</Typography>
      </Button>

      <Box display="flex" gap={8} mt={2}>
        <Box
          sx={{
            minWidth: "6ch",
            p: 1,
            border: "1px solid #E83A3A",
            fontSize: "0.75rem",
            textAlign: "center",
          }}
        >
          MWh
        </Box>
        <Box
          sx={{
            minWidth: "6ch",
            p: 1,
            border: "1px solid #E83A3A",
            fontSize: "0.75rem",
            textAlign: "center",
          }}
        >
          {getCurrencySymbol("BRL", language)}
        </Box>
      </Box>
    </Box>
  );
};
