// Themes
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
};

// export const submarketColors: Record<string, string | undefined> = {
//   N: "#FF00A8",
//   S: "#0075FF",
//   NE: "#00B307",
//   "SE/CO": "#FF8A00",
// };

// export const submarketColors: Record<string, string | undefined> = {
//   "SE/CO": "rgb(1, 131, 143)",
//   S: "rgb(2, 119, 189)",
//   NE: "rgb(220, 88, 49)",
//   N: "rgb(182, 48, 106)",
// };

export const submarketColors: Record<string, string | undefined> = {
  "SE/CO": "#00B307",
  S: "#0075FF",
  NE: "#FF8A00",
  N: "#FF00A8",
};
