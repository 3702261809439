import React from "react";

import useAuth from "../../hooks/useAuth";
import Page404 from "../../pages/auth/Page404";
import { LoadingPage } from "../LoadingPage";

interface UserGuardType {
  children: React.ReactNode;
}

// For routes that can only be accessed by admin users
function UserGuard({ children }: UserGuardType) {
  const { isAuthenticated, isInitialized, user } = useAuth();

  if (!isInitialized) {
    return <LoadingPage />;
  }

  if (!isAuthenticated || user?.role !== "user") {
    return <Page404 />;
  }

  return <React.Fragment>{children}</React.Fragment>;
}

export default UserGuard;
