import { useContext } from "react";
import { ChooseParticipantContext } from "../contexts/ChooseParticipantContext";

const useChooseParticipant = () => {
  const context = useContext(ChooseParticipantContext);

  if (!context)
    throw new Error(
      "ChooseParticipantContext must be placed within ChooseParticipantProvider"
    );

  return context;
};

export default useChooseParticipant;
