import React from "react";
import { styled } from "@mui/material/styles";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { darken, getContrastRatio } from "@mui/system";

interface StyledToggleButtonGroupProps {
  toggleButtonMinWidth?: string | number;
  onChange?: any;
}
export const StyledToggleButtonGroup = styled(
  React.forwardRef<
    unknown,
    StyledToggleButtonGroupProps &
      React.ComponentProps<typeof ToggleButtonGroup>
  >(({ toggleButtonMinWidth, ...rest }, ref) => (
    <ToggleButtonGroup ref={ref} {...rest} />
  ))
)(({ theme, toggleButtonMinWidth, size }) => ({
  flexWrap: "wrap",
  gap: 16,
  "& .MuiToggleButtonGroup-grouped": {
    margin: 0,
    border: 0,
    ...(size === "small" ? { lineHeight: "normal" } : {}),
    minWidth: toggleButtonMinWidth,
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: theme.shape.borderRadius,
      border: 0,
      margin: 0,
    },
    "&:first-of-type": {
      borderRadius: theme.shape.borderRadius,
      border: 0,
    },
  },
}));

interface StyledToggleButtonProps {
  bgColor?: "default" | "COMPRA" | "VENDA" | "COMPRA&VENDA";
  initialBgColor?: string;
}
export const StyledToggleButton = styled(
  React.forwardRef<
    HTMLButtonElement | null,
    StyledToggleButtonProps & React.ComponentProps<typeof ToggleButton>
  >(({ bgColor, initialBgColor, ...rest }, ref) => (
    <ToggleButton ref={ref} {...rest} />
  ))
)(({ theme, bgColor = "default", initialBgColor }) => {
  const colors = {
    default: "#60ECFF",
    COMPRA: "#00CB85",
    VENDA: "#EB5757",
    "COMPRA&VENDA": "#00CB85",
  };

  const textUnselectedColor = initialBgColor
    ? getContrastRatio(theme.palette.common.white, initialBgColor) > 2
      ? theme.palette.common.white
      : theme.palette.action.active
    : undefined;

  const textSelectedColor =
    getContrastRatio(theme.palette.common.white, colors[bgColor]) > 2
      ? theme.palette.common.white
      : theme.palette.common.black;

  const buttonBackground =
    bgColor === "COMPRA&VENDA"
      ? {
          backgroundImage:
            "linear-gradient(90deg, #00CB85 13.54%, #E05757 64.58%, #FF4760 100%)",
          "&:hover": {
            backgroundImage: `linear-gradient(90deg, ${darken(
              "#00CB85",
              0.2
            )} 13.54%, ${darken("#E05757", 0.2)} 64.58%, ${darken(
              "#FF4760",
              0.2
            )} 100%)`,
          },
        }
      : {
          backgroundColor: colors[bgColor],
          "&:hover": {
            backgroundColor: darken(colors[bgColor], 0.2),
          },
        };

  return {
    ...(textUnselectedColor ? { color: textUnselectedColor } : {}),
    backgroundColor: initialBgColor ?? theme.palette.action.hover,
    "&:hover": {
      backgroundColor: theme.palette.action.selected,
    },
    "&.Mui-selected": {
      color: textSelectedColor,
      ...buttonBackground,
    },
  };
});
