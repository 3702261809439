import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Paper,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { SubmitHandler, useForm } from "react-hook-form";

import { SummaryTable, SummaryTableData } from "../../components/SummaryTable";
import { FormCheckbox } from "../../components/formFields/FormCheckbox";
import useConfirmationModal from "../../hooks/useConfirmationModal";
import { toLocaleCurrencyString } from "../../utils/string";
import {
  AcceptAcceptanceTermProps,
  acceptAcceptanceTerm,
} from "../../services/contracts";
import { ApiError } from "../../types/error";
import { Contract } from "../../types/contracts";
import { StyledModal } from "../../components/StyledModal";

interface AcceptanceTermModalContentProps {
  participantId: number | null;
  contract: Contract;
  onClose?: () => void;
}

function AcceptanceTermModalContent({
  participantId,
  contract,
  onClose,
}: AcceptanceTermModalContentProps) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const isSpread = React.useMemo(() => {
    return (contract.offer as any).priceType === "Atrelado ao PLD";
  }, [(contract.offer as any).priceType]);

  const ownerOrWinner = React.useMemo(() => {
    return contract.operation.participantId === participantId
      ? "owner"
      : contract.winnerParticipant === null ||
        contract.winnerParticipant.participant.id === participantId
      ? "winner"
      : null;
  }, [contract, participantId]);

  const contractSummaryData = React.useMemo<SummaryTableData>(
    () =>
      contract
        ? [
            [
              {
                label: t("Delivery Period"),
                value: `${new Date(
                  contract.operation.deliveryPeriodStart
                ).toLocaleDateString(language, {
                  timeZone: "UTC",
                })} ${t("to")} ${new Date(
                  contract.operation.deliveryPeriodEnd
                ).toLocaleDateString(language, { timeZone: "UTC" })}`,
                // value: contract.contract?.issueDate
                //   ? new Date(contract.contract?.issueDate).toLocaleDateString()
                //   : "-",
              },
              {
                label: t("Volume"),
                value: `${contract.winnerBid.amount} ${
                  contract.operation.product?.measurementUnit
                    ? ` ${contract.operation.product.measurementUnit}`
                    : ""
                }`,
              },
              {
                label: t("Source Type"),
                value: contract.operation.product?.name ?? "-",
              },
              {
                label: t("History Delivery Location"),
                value: "Centro de Gravidade",
              },
              {
                label: t("Submarket"),
                value: contract.operation.submarket,
              },
              {
                label: t("Flexibility"),
                value: "Flat",
              },
              {
                label: t("Seasonality"),
                value: "Flat",
              },
              {
                label: t("Modulation"),
                value: "Flat",
              },
            ],
            [
              {
                label: t("Price"),
                value: t((contract.offer as any).priceType),
              },

              {
                label: t("Value"),
                value: !isSpread
                  ? toLocaleCurrencyString(
                      contract.winnerBid.value,
                      "BRL",
                      language
                    )
                  : null,
              },
              {
                label: t("PLD Value"),
                value: isSpread
                  ? toLocaleCurrencyString(
                      contract.pldToConsider ?? "-",
                      "BRL",
                      language
                    )
                  : null,
              },
              {
                label: t("Spread"),
                value: isSpread
                  ? `${
                      Number(contract.winnerBid.value) < 0 ? "-" : "+"
                    } ${toLocaleCurrencyString(
                      contract.winnerBid.value,
                      "BRL",
                      language
                    )}`
                  : null,
              },
              {
                label: t("Sector Charges"),
                value:
                  "Cada Parte arcará com os encargos setoriais e os tributos de que estiverem sob sua responsabilidade, conforme legislação vigente.",
              },
              {
                label: t("Operation Sum Basis"),
                value: `${toLocaleCurrencyString(
                  isSpread
                    ? (contract.pldToConsider ?? 0) + contract.winnerBid.value
                    : contract.winnerBid.value,
                  "BRL",
                  language
                )}/${contract.operation.product?.measurementUnit ?? "-"}`,
              },
              {
                label: t("Operation Total Value"),
                value: `${toLocaleCurrencyString(
                  (isSpread
                    ? // ? contract.winnerBid.PLD ?? 0 + contract.winnerBid.value
                      (contract.pldToConsider ?? 0) + contract.winnerBid.value
                    : contract.winnerBid.value) * contract.winnerBid.amount,
                  "BRL",
                  language
                )}/${contract.operation.product?.measurementUnit ?? "-"}`,
              },
              {
                label: t("Taxes"),
                value: toLocaleCurrencyString(
                  // contract.winnerBid.taxes ?? 0, //TODO
                  0, //TODO
                  "BRL",
                  language
                ),
              },

              {
                label: t("Payment"),
                value: (
                  <>
                    Até o 6º (d.u) dia útil do mês subsequente ao do suprimento.
                    <br />
                    Caso, por qualquer motivo, a COMPRADORA deixe de pagar
                    qualquer fatura até a sua data de vencimento, a COMPRADORA
                    ficará sujeita ao pagamento de multa equivalente a 2% (dois
                    por cento) sobre o valor total da fatura, acrescido de juros
                    de mora de 1% (um por cento) ao mês, <i>pro rata tempore</i>
                    , devendo este valor ser corrigido pela variação acumulada
                    do IGP-M da data de seu vencimento até a data do efetivo
                    pagamento.
                  </>
                ),
              },
              {
                label: t("Price Base Date"),
                value: "Não há/Não se aplica",
              },
              {
                label: t("Readjustment"),
                value: "Não há/Não se aplica",
              },
              {
                label: t("Financial Guarantee"),
                value: "Modalidade de registro contra pagamento",
              },
            ],
            [
              {
                label: `${t("Energy Registration")} CliqCCEE`,
                value: (
                  <>
                    Registro e ajuste do montante mensal contratado no CliqCCEE,
                    após confirmação do pagamento.
                    <br />
                    (Caso a CCEE cancele integralmente ou parcialmente o
                    registro do contrato referente a esta operação, nos termos
                    das Regras e Procedimentos de Comercialização e conforme
                    estabelecido na Resolução Normativa ANEEL nº. 531, de 21 de
                    dezembro de 2012, a PARTE que deu causa se obriga a
                    ressarcir a outra, através de Nota de Débito a ser paga em
                    até 10 (dez) dias após a sua data de emissão, os prejuízos
                    decorrentes do referido cancelamento, proporcionais ao
                    montante de energia cancelado pela CCEE, incluindo, mas não
                    se limitando a: (I) valores pagos no mercado de curto prazo;
                    (II) penalidades por insuficiência de lastro de energia e de
                    potência; e (III) energia de reposição a ser contratada pela
                    COMPRADORA).
                  </>
                ),
              },
              {
                label: t("Re-TUSD"),
                value: (
                  <>
                    Caso o tipo de fonte de energia seja com desconto e o
                    Vendedor não atenda a obrigação da entrega da energia com o
                    desconto no uso dos sistemas de transmissão e distribuição,
                    a diferença negativa em relação ao referido desconto, será
                    liquidada, no prazo máximo de até 10 (dez) dias, após a
                    divulgação do relatório oficial de desconto da CCEE. O prazo
                    máximo para exigência da cobrança de poluição do desconto,
                    quando existente, será de até 6 (seis) meses a contar da
                    data da divulgação do relatório oficial da CCEE.
                    <br />
                    A perda total do referido desconto corresponde o valor de
                    referência de:
                    <br />
                    a) R$35,00/MWh (trinta e cinco reais) para fonte de energia
                    “com 50% de desconto na TUSD/TUST”;
                    <br />
                    b) R$50,00/MWh (cinquenta reais) para fonte de energia “com
                    80% de desconto na TUSD/TUST”;
                    <br />
                    c) R$70,00/MWh (setenta reais) para fonte de energia “com
                    100% de desconto na TUSD/TUST.
                    <br />A perda parcial do referido desconto correspondente à
                    proporção desse valor, conforme fórmula constate na Minuta
                    Contratual.
                  </>
                ),
              },
              {
                label: t("Republication of the PLD"),
                value: (
                  <>
                    Caso a operação seja referenciada ao PLD, fica acordado que
                    em caso de Recálculo do PLD, do referido mês objeto desta
                    negociação, as partes (COMPRADORA e VENDEDORA),
                    comprometem-se em realizar o acerto financeiro cabível e
                    correção do preço no Contrato de Compra e Venda de Energia
                    de Curto Prazo.
                  </>
                ),
              },
              {
                label: t("Responsibilities"),
                value: (
                  <>
                    Este Termo é reconhecido por ambas as Partes como título
                    executivo, na forma do artigo 784, III, do Código de
                    Processo Civil Brasileiro, bem como nos termos das Regras e
                    Procedimentos de Comercialização.
                    <br />A emissão do presente Termo de Fechamento de Compra e
                    Venda de Energia, considerar-se-á, para todos os fins, que a
                    compra e venda de energia elétrica, estão concretizadas de
                    pleno direito, constituindo compromisso firme, irretratável
                    e irrevogável, restando apenas, a concretização dos atos
                    jurídicos pertinentes, os quais, uma vez formalizados,
                    constituirão atos jurídicos perfeitos e acabados. Caso as
                    PARTES, após a formalização do Termo, se recusar a assinar o
                    respectivo CCVE (Contrato de Compra e Venda de Energia
                    Elétrica) em até 30 (trinta) dias da presente data, arcará
                    com a multa pecuniária no valor de 100% (Cem por cento) do
                    valor da operação.
                  </>
                ),
              },
              {
                label: t("Marketing Rules"),
                value: (
                  <>
                    As Partes declaram que têm conhecimento e respeitarão os
                    prazos previstos nas Regras e Procedimentos de
                    Comercialização da CCEE.
                  </>
                ),
              },
              {
                label: t("Act of God and Force Majeure"),
                value: (
                  <>
                    Nos termos da Lei 13.874, de 20 de setembro de 2019, o
                    Parágrafo Único do artigo 421 e 421-A do Código Civil, as
                    Partes declaram que, mediante o aceite desta Proposta, que
                    estão concluindo esta negociação em meio à uma situação de
                    pandemia do Covid-19 declarada pela Organização Mundial de
                    Saúde, mas que o fazem de livre e espontânea vontade e que,
                    portanto, reconhecem como válidas, exigíveis e executáveis
                    as disposições acordadas nesta Proposta, admitindo que as
                    hipóteses da Cláusula “Caracterização do Evento de Força
                    Maior”, incluindo a atual pandemia, não serão consideradas
                    de Caso Fortuito ou Força Maior, ainda que seus efeitos se
                    agravem e/ou que restrições complementares em nível federal,
                    estadual ou municipal sejam decretadas:
                    <br />
                    a) Problemas e/ou dificuldades de ordem econômico-financeira
                    de qualquer das Partes;
                    <br />
                    b) Qualquer ação de qualquer Autoridade Governamental que a
                    Parte afetada pudesse ter evitado se tivesse cumprido as
                    Normas Legais aplicáveis;
                    <br />
                    c) Insolvência, liquidação, falência, reorganização,
                    encerramento das atividades
                    <br />
                    d) Perda de mercado, paralisação das operações, redução do
                    consumo, ou impossibilidade de consumir e/ou comercializar a
                    Energia Contratada pelas Partes;
                    <br />
                    e) Oportunidade que se apresentar à Vendedora ou à
                    Compradora para, respectivamente, vender ou comprar no
                    mercado energia elétrica, em quantidades equivalentes à
                    Energia Contratada, a preços e/ou condições mais favoráveis
                    do que o preço e as condições deste Contrato;
                    <br />
                    f) Greve e/ou interrupções trabalhistas ou medidas tendo
                    efeito semelhante, apenas de empregados e contratados de
                    qualquer das Partes e/ou de suas eventuais subcontratadas;
                    <br />
                    g) A recusa da CCEE em proceder a contabilização e/ou
                    liquidação deste Contrato, causada por ação ou falha de
                    qualquer das Partes em obter qualquer consentimento
                    necessário de uma Autoridade Governamental
                    <br />
                    h) Qualquer falha nas instalações integrantes do SIN ou
                    realização de paradas das instalações da Compradora, por
                    qualquer motivo;
                    <br />
                    i) Mudança de Norma Legal;
                  </>
                ),
              },
              {
                label: t("Subscription Commitment CCVE"),
                value: (
                  <>
                    O presente Termo faz parte integrante da Minuta de Contrato
                    de Compra e Venda de Energia.
                  </>
                ),
              },
              {
                label: t("Confidentiality"),
                value: (
                  <>
                    Todos os documentos e informações relativos a esse processo
                    serão considerados confidenciais, destinados exclusivamente
                    ao uso das Partes.
                  </>
                ),
              },
            ],
          ]
        : [],
    [contract, t, language, isSpread]
  );

  const { control, handleSubmit, watch } = useForm<{
    iAgreeWithTheAcceptanceTerm: boolean;
  }>({
    defaultValues: { iAgreeWithTheAcceptanceTerm: false },
  });

  const acceptAcceptanceTermMutation = useMutation<
    any,
    unknown,
    AcceptAcceptanceTermProps
  >(acceptAcceptanceTerm, {
    onSuccess: () => {
      enqueueSnackbar(t("The operation completed successfully"), {
        variant: "success",
      });

      queryClient.invalidateQueries(["/contracts"]);

      if (onClose) onClose();
    },
    onError: (e) => {
      enqueueSnackbar(
        t((e as ApiError | null)?.error ?? "Something went wrong."),
        {
          variant: "error",
        }
      );
    },
  });

  const hasAccepted = React.useMemo(
    () =>
      (ownerOrWinner === "owner" &&
        contract.acceptanceTerms?.acceptedByOwner) ||
      (ownerOrWinner === "winner" &&
        contract.acceptanceTerms?.acceptedByWinner),
    [
      contract.acceptanceTerms?.acceptedByOwner,
      contract.acceptanceTerms?.acceptedByWinner,
      ownerOrWinner,
    ]
  );

  const { showConfirmationModal } = useConfirmationModal();

  const onSubmit = React.useCallback<
    SubmitHandler<{ iAgreeWithTheAcceptanceTerm: boolean }>
  >(
    ({ iAgreeWithTheAcceptanceTerm }) => {
      if (hasAccepted) {
        return;
      }

      if (!iAgreeWithTheAcceptanceTerm) {
        enqueueSnackbar(t("You must agree with the Acceptance Term"), {
          variant: "error",
        });
        return;
      }

      showConfirmationModal({
        onSubmit: () => {
          if (ownerOrWinner === null) {
            enqueueSnackbar(t("Something went wrong."), {
              variant: "error",
            });
            return;
          }

          acceptAcceptanceTermMutation.mutate({
            offerId: (contract.offer as any).id,
            childrenOfferId: contract.id,
            ownerOrWinner,
          });
        },
        message: t("Do you really want to accept the terms?"),
      });
    },
    [
      acceptAcceptanceTermMutation,
      contract.id,
      (contract.offer as any).id,
      enqueueSnackbar,
      hasAccepted,
      ownerOrWinner,
      showConfirmationModal,
      t,
    ]
  );

  if (!contract || !participantId) return <></>;

  return (
    <Box
      display="flex"
      flexDirection="column"
      overflow="hidden"
      maxHeight="70vh"
    >
      <Paper sx={{ overflow: "auto" }}>
        <Accordion disableGutters defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="subtitle2">
              {t("Offer Information")}:
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <SummaryTable data={[contractSummaryData[0]]} />
          </AccordionDetails>
        </Accordion>

        <Accordion disableGutters>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="subtitle2">{t("Payment")}:</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <SummaryTable data={[contractSummaryData[1]]} />
          </AccordionDetails>
        </Accordion>

        <Accordion disableGutters>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="subtitle2">
              {t("Informações Complementares")}:
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <SummaryTable data={[contractSummaryData[2]]} />
          </AccordionDetails>
        </Accordion>
      </Paper>

      <Box px={2} mt={8}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography>
            {t(
              "As partes declaram que possuem poderes de representação para realizar a operação de compra e venda de energia"
            )}
            .
          </Typography>

          <Box textAlign="center" p={4}>
            {hasAccepted ? (
              <Box
                sx={(theme) => ({
                  borderBottom: `1px solid ${theme.palette.text.primary}`,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mt: 4,
                })}
              >
                <Typography variant="caption" fontSize={14}>
                  {t("Validation")}
                </Typography>
                <Box
                  sx={{
                    backgroundColor: "#CBFBEA",
                    color: "#006323",
                    borderRadius: 1,
                    px: 2,
                  }}
                >
                  {t("You have already accepted this term")}
                </Box>
              </Box>
            ) : (
              <FormCheckbox
                control={control}
                name={"iAgreeWithTheAcceptanceTerm"}
                label={t("I agree with the Acceptance Term")}
              />
            )}

            <Box
              display="inline-flex"
              justifyContent="center"
              width="100%"
              textAlign="center"
              gap={4}
              mt={4}
            >
              {onClose && (
                <Button
                  type="button"
                  variant="outlined"
                  color="primary"
                  onClick={onClose}
                >
                  {t("Go Back")}
                </Button>
              )}

              {!hasAccepted && (
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={
                    !watch("iAgreeWithTheAcceptanceTerm") ||
                    hasAccepted ||
                    acceptAcceptanceTermMutation.isLoading
                  }
                  size="small"
                >
                  {t("Confirm")}
                </Button>
              )}
            </Box>
          </Box>
        </form>
      </Box>
    </Box>
  );
}

export interface AcceptanceTermModalProps {
  open: boolean;
  onClose?: () => void;
  participantId: number | null;
  contract: Contract;
}
export function AcceptanceTermModal({
  open,
  onClose,
  participantId,
  contract,
}: AcceptanceTermModalProps) {
  const { t } = useTranslation();

  return (
    <StyledModal
      leftBox={t("Contract")}
      title={t("Acceptance Term")}
      open={open}
      onClose={onClose}
      maxWidth={800}
    >
      <AcceptanceTermModalContent
        participantId={participantId}
        contract={contract}
        onClose={onClose}
      />
    </StyledModal>
  );
}
