import { Observable, Subscription } from "rxjs";
import { ChatRoom } from "../entities/ChatRoom";
import { ChatroomsObservableImpl } from "../infra/firestore/ChatObservableImpl";

export type ChatroomsData = {
  totalItems: number;
  chatrooms: ChatRoom[];
};

export type options = {
  participant: number;
};

export abstract class ChatroomsObservable extends Observable<ChatroomsData> {
  abstract subscribeWithOptions(
    options: options,
    next?: ((value: ChatroomsData) => void) | null | undefined,
    error?: ((error: any) => void) | null | undefined,
    complete?: (() => void) | null | undefined
  ): Subscription;

  abstract setOptions(options: options): void;
}

export class GetChatroomsObservableService {
  private observable: ChatroomsObservable | null = null;

  public getChatroomsObservable() {
    if (this.observable === null) {
      this.observable = new ChatroomsObservableImpl();
    }

    return this.observable;
  }
}
