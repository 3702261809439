// interface pública de lances
export interface PublicBid {
  getId(): string;
  getStatus(): string;
  getCreatedAt(): Date;
  getPldValue(): number | null;
  getValue(): number;
  getAmount(): number;
  isMyBid(): boolean;
}

// interface privada de lances (caso o lance pertença ao participante)
export interface MyBid {}

// interface de sistema/administrativa de lances
export class Bid implements PublicBid, MyBid {
  constructor(
    private id: string,
    private status: string,
    private participantId: number | null,
    private participantRequestingBidData: number | null,
    private createdAt: Date,
    private pldValue: number | null,
    private value: number,
    private amount: number
  ) {}

  public getId(): string {
    return this.id;
  }

  public getStatus() {
    return this.status;
  }

  public getCreatedAt() {
    return this.createdAt;
  }

  public getPldValue() {
    return this.pldValue;
  }

  public getValue() {
    return this.value;
  }

  public getAmount() {
    return this.amount;
  }

  // dado que apenas o dono da bid consegue saber o id do participante
  public isMyBid(): boolean {
    return (
      this.participantRequestingBidData !== null &&
      this.participantRequestingBidData === this.participantId
    );
  }
}
