import { Theme } from "@emotion/react";
import {
  TableCell,
  Button,
  IconButton,
  Tooltip,
  Box,
  TableRow,
} from "@mui/material";
import { darken } from "@mui/system";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  PriceTypeShort,
  PeriodShort,
} from "../../../../../components/OfferUtils";
import { getSplitedTimeDifference } from "../../../../../utils/date";
import { toLocaleCurrencyStringWithoutSymbol } from "../../../../../utils/string";
import useAuth from "../../../../../hooks/useAuth";
import { MoreHoriz as MoreHorizIcon } from "@mui/icons-material";
import {
  OfferResume,
  OperationResume,
} from "../../../entities/data-objects/OperationResume";
import { DateTime } from "luxon";
import { useCurrentTime } from "../../../../../hooks/useCurrentTime";
import { TimeBox } from "../../../../../components/TimeBox";

const formatCurrencyValue = (
  value: number | null | undefined,
  isPLD: boolean,
  language: string
) => {
  if (value === null || value === undefined) {
    return "-";
  }

  if (isPLD) {
    return `${value < 0 ? "-" : "+"}${toLocaleCurrencyStringWithoutSymbol(
      Math.abs(value),
      "BRL",
      language
    )}`;
  }

  return `${toLocaleCurrencyStringWithoutSymbol(value, "BRL", language)}`;
};

type ResumeTableRowProps = {
  handleOpenSeeDetailsModal: (offer: OfferResume) => void;
  handleOpenMoreMenu: (
    event: React.MouseEvent<HTMLElement>,
    id: number | string
  ) => void;
  handleOpenBidModal: (offer: OfferResume) => void;
  row: OperationResume;
};

export const ResumeTableRow: React.FC<ResumeTableRowProps> = ({
  row,
  handleOpenSeeDetailsModal,
  handleOpenMoreMenu,
  handleOpenBidModal,
}) => {
  const { user } = useAuth();

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { currentTime } = useCurrentTime();

  const isThisOfferOpenToBids =
    (row.getStatus() === "ABERTA" ||
      row.getStatus() === "PARCIALMENTE ENCERRADA") &&
    row.getCloseAt() > new Date();

  const isThisOfferMine = row.getIsOperationOwner();

  // const myBid = participantId
  //   ? row.bids?.find(
  //       (item: any) =>
  //         item.participantId === participantId && item.status === "CRIADO"
  //     ) ?? null
  //   : null;

  // const isMyBidBestValue =
  //   Boolean(myBid) && row.currentBestBidValue === myBid?.value;

  const getBackgroundColor = (theme: Theme) =>
    !isThisOfferOpenToBids
      ? "#aaaaaa"
      : isThisOfferMine
      ? theme.palette.primary.main
      : // : isMyBidBestValue
        // ? theme.palette.success.main
        // : Boolean(myBid)
        // ? theme.palette.warning.main
        undefined;

  const remainingTime = getSplitedTimeDifference(currentTime, row.getCloseAt());

  return (
    <TableRow
      tabIndex={-1}
      hover
      sx={(theme: any) => {
        const backgroundColor = getBackgroundColor(theme);
        return {
          // "&:nth-of-type(odd)": {
          //   backgroundColor: "rgba(0, 0, 0, 0.02)",
          // },
          ...(!isThisOfferOpenToBids ? { "th, td": { opacity: 0.7 } } : {}),
          ...(backgroundColor
            ? { backgroundColor: `${backgroundColor}16 !important` }
            : {}),
          "&:hover": {
            backgroundColor: `${backgroundColor ?? "#000000"}10 !important`,
          },
        };
      }}
    >
      <Tooltip title={row.getOperationId()}>
        {/* Product */}
        <TableCell
          component="th"
          scope="row"
          sx={(theme) => ({
            // whiteSpace: "nowrap",
            position: "relative",
            "&:before": {
              content: '""',
              borderRadius: 2,
              width: 4,
              height: "80%",
              // backgroundColor:
              //   row.operation === "COMPRA" ? green[600] : red[600],
              backgroundColor: theme.palette.action.disabled,
              position: "absolute",
              top: "50%",
              left: 0,
              transform: "translateY(-50%)",
            },
          })}
        >
          <PriceTypeShort
            submarket={row.getSubmarketCode()}
            product={row.getProduct()}
            priceType={(() => {
              const referenceOffer = row.getBuyOffer() ?? row.getSellOffer();

              if (!referenceOffer) return "-";

              if (
                referenceOffer.getValue() !== null &&
                referenceOffer.getValue() !== undefined
              ) {
                return "Fixo";
              }

              if (
                referenceOffer.getSpread() !== null &&
                referenceOffer.getSpread() !== undefined
              ) {
                return "Atrelado ao PLD";
              }

              return "Lance livre";
            })()}
            freeBid={(() => {
              const referenceOffer = row.getBuyOffer() ?? row.getSellOffer();

              if (!referenceOffer) return "-";

              if (
                referenceOffer.getValue() !== null &&
                referenceOffer.getValue() !== undefined
              ) {
                return undefined;
              }

              if (
                referenceOffer.getSpread() !== null &&
                referenceOffer.getSpread() !== undefined
              ) {
                return undefined;
              }

              return referenceOffer.getIsPldFixed()
                ? "Atrelado PLD + Spread"
                : "Fixo";
            })()}
          />
        </TableCell>
      </Tooltip>

      {/* Period */}
      <Tooltip
        title={`${row
          .getDeliveryPeriodStart()
          .toLocaleDateString(language, { timeZone: "UTC" })} ${t("to")} ${row
          .getDeliveryPeriodEnd()
          .toLocaleDateString(language, { timeZone: "UTC" })}`}
      >
        <TableCell
          align="center"
          sx={{
            // whiteSpace: "nowrap",
            width: "10ch",
            minWidth: "10ch",
          }}
        >
          <PeriodShort
            periodStart={DateTime.fromJSDate(row.getDeliveryPeriodStart())
              .plus({ hours: 3 })
              .toISO()}
            periodEnd={DateTime.fromJSDate(row.getDeliveryPeriodEnd())
              .plus({ hours: 3 })
              .toISO()}
          />
        </TableCell>
      </Tooltip>

      {/* --------------- */}

      {/* Buy Offer Volume */}
      <TableCell align="right" sx={{ minWidth: "9ch" }}>
        {row.getBuyOffer() === null
          ? "-"
          : row.getBuyOffer()!.getRemainingVolume().toLocaleString(language, {
              minimumFractionDigits: 3,
            })}
      </TableCell>

      {/* Buy Offer Value */}
      <TableCell align="right" sx={{ whiteSpace: "nowrap", minWidth: "9ch" }}>
        {row.getBuyOffer() === null
          ? "-"
          : row.getBuyOffer()!.getValue() === null &&
            row.getBuyOffer()!.getSpread() === null
          ? t("L Livre")
          : formatCurrencyValue(
              row.getBuyOffer()!.getValue() || row.getBuyOffer()!.getSpread(),
              row.getBuyOffer()!.getIsPldFixed(),
              language
            )}
      </TableCell>

      {/* Buy Offer Actions */}
      <TableCell
        align="center"
        sx={{
          minWidth: "9ch",
        }}
      >
        {row.getBuyOffer() !== null && (
          <Button
            size="small"
            variant="contained"
            fullWidth
            sx={{
              minWidth: "unset",
              maxWidth: "12ch",
              px: 1,
              py: "1px",
              fontSize: "0.75rem",
              ...(isThisOfferMine
                ? {
                    backgroundColor: "#828B9E",
                    "&:hover": {
                      backgroundColor: darken("#828B9E", 0.2),
                    },
                  }
                : {}),
            }}
            onClick={() => {
              if (isThisOfferMine) {
                handleOpenSeeDetailsModal(row.getBuyOffer()!);
              } else {
                handleOpenBidModal(row.getBuyOffer()!); // toDO corrigir esse modal para buscar pelo id da oferta
              }
            }}
            disabled={
              user?.role !== "user" ||
              !(
                row.getBuyOffer()!.getStatus() === "ABERTA" ||
                row.getBuyOffer()!.getStatus() === "PARCIALMENTE ENCERRADA"
              ) ||
              row.getCloseAt() < new Date()
            }
          >
            {isThisOfferMine ? t("Details") : t("Sell")}
          </Button>
        )}
      </TableCell>

      {/* Buy Offer Best Bid Value */}
      <TableCell align="right" sx={{ whiteSpace: "nowrap", minWidth: "9ch" }}>
        {row.getBuyOffer() === null ||
        row.getBuyOffer()!.getBestBidValue() === null
          ? "-"
          : formatCurrencyValue(
              row.getBuyOffer()!.getBestBidValue(),
              row.getBuyOffer()!.getIsPldFixed(),
              language
            )}
      </TableCell>

      {/* Buy Offer Best Bid Volume */}
      <TableCell align="right" sx={{ minWidth: "9ch" }}>
        {row.getBuyOffer() === null ||
        row.getBuyOffer()!.getBestBidVolume() === null
          ? "-"
          : row.getBuyOffer()!.getBestBidVolume()!.toLocaleString(language, {
              minimumFractionDigits: 3,
            }) ?? "-"}
      </TableCell>

      {/* Buy Offer NET */}
      <TableCell align="right" sx={{ minWidth: "9ch", whiteSpace: "nowrap" }}>
        {row.getBuyOffer() == null ||
        row.getBuyOffer()!.getCurrentParticipantBidVolume() === null
          ? "-"
          : Number(row.getBuyOffer()!.getNet()).toLocaleString(language, {
              minimumFractionDigits: 3,
            })}
      </TableCell>

      {/* Buy Offer Status */}
      <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
        {row.getBuyOffer() === null
          ? "-"
          : row.getCloseAt() < new Date()
          ? "Expirada"
          : t(row.getBuyOffer()!.getStatus())}
      </TableCell>

      {/* <TableCell
        align="center"
        sx={{ ...(isXlUp ? { minWidth: 56 } : {}) }}
      >
        {""}
      </TableCell> */}

      {/* --------------- */}

      {/* Sell Offer Volume */}
      <TableCell align="right" sx={{ minWidth: "9ch" }}>
        {row.getSellOffer() === null
          ? "-"
          : row.getSellOffer()!.getRemainingVolume().toLocaleString(language, {
              minimumFractionDigits: 3,
            })}
      </TableCell>

      {/* Sell Offer Value */}
      <TableCell align="right" sx={{ whiteSpace: "nowrap", minWidth: "9ch" }}>
        {row.getSellOffer() === null
          ? "-"
          : row.getSellOffer()!.getValue() === null &&
            row.getSellOffer()!.getSpread() === null
          ? t("L Livre")
          : formatCurrencyValue(
              row.getSellOffer()!.getValue() || row.getSellOffer()!.getSpread(),
              row.getSellOffer()!.getIsPldFixed(),
              language
            )}
      </TableCell>

      {/* Sell Offer Actions */}
      <TableCell
        align="center"
        sx={{
          minWidth: "9ch",
          // maxWidth: "9ch",
        }}
      >
        {row.getSellOffer() !== null && (
          <Button
            size="small"
            variant="contained"
            fullWidth
            sx={{
              minWidth: "unset",
              maxWidth: "12ch",
              px: 1,
              py: "1px",
              fontSize: "0.75rem",
              ...(isThisOfferMine
                ? {
                    backgroundColor: "#828B9E",
                    "&:hover": {
                      backgroundColor: darken("#828B9E", 0.2),
                    },
                  }
                : {}),
            }}
            onClick={() => {
              if (isThisOfferMine) {
                handleOpenSeeDetailsModal(row.getSellOffer()!);
              } else {
                handleOpenBidModal(row.getSellOffer()!); // toDO corrigir esse modal para buscar pelo id da oferta
              }
            }}
            disabled={
              user?.role !== "user" ||
              !(
                row.getSellOffer()!.getStatus() === "ABERTA" ||
                row.getSellOffer()!.getStatus() === "PARCIALMENTE ENCERRADA"
              ) ||
              row.getCloseAt() < new Date()
            }
          >
            {isThisOfferMine ? t("Details") : t("Buy")}
          </Button>
        )}
      </TableCell>

      {/* Sell Offer Best Bid Value */}
      <TableCell align="right" sx={{ whiteSpace: "nowrap", minWidth: "9ch" }}>
        {row.getSellOffer() === null ||
        row.getSellOffer()!.getBestBidValue() === null
          ? "-"
          : formatCurrencyValue(
              row.getSellOffer()!.getBestBidValue(),
              row.getSellOffer()!.getIsPldFixed(),
              language
            )}
      </TableCell>

      {/* Sell Offer Best Bid Volume */}
      <TableCell align="right" sx={{ minWidth: "9ch" }}>
        {row.getSellOffer() === null ||
        row.getSellOffer()!.getBestBidVolume() === null
          ? "-"
          : row.getSellOffer()!.getBestBidVolume()!.toLocaleString(language, {
              minimumFractionDigits: 3,
            }) ?? "-"}
      </TableCell>

      {/* Sell Offer NET */}
      <TableCell align="right" sx={{ minWidth: "9ch", whiteSpace: "nowrap" }}>
        {row.getSellOffer() == null ||
        row.getSellOffer()!.getCurrentParticipantBidVolume() === null
          ? "-"
          : Number(row.getSellOffer()!.getNet()).toLocaleString(language, {
              minimumFractionDigits: 3,
            })}
      </TableCell>

      {/* Sell Offer Status */}
      <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
        {row.getSellOffer() === null
          ? "-"
          : row.getCloseAt() < new Date()
          ? "Expirada"
          : t(row.getSellOffer()!.getStatus())}
      </TableCell>

      {/* --------------- */}

      <Tooltip
        title={
          row.getCloseAt() ? row.getCloseAt().toLocaleString(language) : "-"
        }
      >
        <TableCell
          align="center"
          sx={{ whiteSpace: "nowrap", minWidth: "10ch" }}
        >
          {remainingTime.totalSeconds <= 0 ||
          row.getStatus() === "ENCERRADA" ? (
            "-"
          ) : (
            <TimeBox time={remainingTime} />
          )}
        </TableCell>
      </Tooltip>
      <TableCell padding="none" align="right">
        <Box whiteSpace="nowrap">
          <Tooltip title={t("More")}>
            <IconButton
              id={`more-button-${row.getOperationId()}`}
              aria-label={t("More")}
              size="small"
              onClick={(e) => handleOpenMoreMenu(e, row.getOperationId())}
            >
              <MoreHorizIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </TableCell>
    </TableRow>
  );
};
