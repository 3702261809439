import React, { useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Outlet, useLocation } from "react-router-dom";

import { Box, CssBaseline, Paper as MuiPaper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { spacing } from "@mui/system";

import GlobalStyle from "../components/GlobalStyle";
import Navbar from "../components/navbar/Navbar";
import getDashboardItems from "../components/sidebar/dashboardItems";
import Sidebar from "../components/sidebar/Sidebar";
import Footer from "../components/Footer";
// import Settings from "../components/Settings";
import { useTranslation } from "react-i18next";
import useAuth from "../hooks/useAuth";

const drawerWidth = 258;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div<{ open: boolean }>`
  ${(props) => props.theme.breakpoints.up("xl")} {
    width: ${(props) => (props.open ? drawerWidth : 0)}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

interface DashboardType {
  children?: React.ReactNode;
}

const Dashboard: React.FC<DashboardType> = ({ children }) => {
  const router = useLocation();
  const { t } = useTranslation();

  const theme = useTheme();
  const isXlUp = useMediaQuery(theme.breakpoints.up("xl"));
  const isXxlUp = useMediaQuery(theme.breakpoints.up(1920));

  const [mobileOpen, setMobileOpen] = useState(false);
  const [desktopOpen, setDesktopOpen] = useState(true);

  const handleMobileDrawerToggle = useCallback(() => {
    setMobileOpen((oldValue) => !oldValue);
  }, []);
  const handleDesktopDrawerToggle = useCallback(() => {
    setDesktopOpen((oldValue) => !oldValue);
  }, []);

  // Close mobile menu when navigation occurs
  useEffect(() => {
    setMobileOpen(false);
  }, [router.pathname]);

  useEffect(() => {
    if (router.pathname === "/bank/spot-trading") {
      setDesktopOpen(false);
    }
  }, [router.pathname]);

  useEffect(() => {
    if (isXlUp) {
      setMobileOpen(false);
    }
  }, [isXlUp]);

  const handleDrawerToggle = useCallback(() => {
    if (isXlUp) {
      handleDesktopDrawerToggle();
    } else {
      handleMobileDrawerToggle();
    }
  }, [handleDesktopDrawerToggle, handleMobileDrawerToggle, isXlUp]);

  const { user } = useAuth();

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Drawer open={desktopOpen}>
        <Box sx={{ display: { xs: "block", xl: "none" } }}>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleMobileDrawerToggle}
            items={getDashboardItems(t, user?.role)}
          />
        </Box>
        <Box sx={{ display: { xs: "none", xl: "block" } }}>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            variant="persistent"
            open={desktopOpen}
            onClose={handleDesktopDrawerToggle}
            items={getDashboardItems(t, user?.role)}
          />
        </Box>
      </Drawer>
      <AppContent>
        <Navbar onDrawerToggle={handleDrawerToggle} />
        <MainContent p={isXxlUp ? 12 : isXlUp ? 5 : 4}>
          {children}
          <Outlet />
        </MainContent>
        <Footer />
      </AppContent>
      {/* <Settings /> */}
    </Root>
  );
};

export default Dashboard;
