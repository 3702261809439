import React from "react";
import {
  GetOffersByOrderBookObservableService,
  OffersData,
} from "../services/GetOffersByOrderBookObservableService";

const getOffersByOrderBookObservableService =
  new GetOffersByOrderBookObservableService();

interface useGetOfferBookProps {
  submarket: string | null;
  value?: number | null;
  volume?: number | null;
  productId?: string | null;
  limit?: number;
}
export function useGetOffersOrderBook({
  productId,
  submarket,
  value,
  volume,
  limit,
}: useGetOfferBookProps) {
  const [offersData, setOffersData] = React.useState<OffersData | null>(null);

  const options = React.useMemo(
    () => ({
      amount: volume ?? null,
      value: value ?? null,
      submarket: submarket,
      productId: productId ?? null,
      limit: limit ?? 3,
    }),
    [productId, submarket, value, volume, limit]
  );

  React.useEffect(() => {
    const subscription = getOffersByOrderBookObservableService
      .getOffersByOrderBookObservable()
      .subscribeWithOptions(options, (offersData) => {
        setOffersData(offersData);
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [options]);

  return { offersData };
}
