export class ChatRoom {
  constructor(
    private id: string,
    private operationId: string,
    private offerId: string,
    private lastMessage: string,
    private lastMessageCreatedAt: Date,
    private offerOwnerId: number | null,
    private offerOwnerName: string,
    private offerOwnerLastReadingDate: Date,
    private offerOwnerUnreadMessagesCount: number | null,
    private prospectId: number,
    private prospectName: string,
    private prospectLastReadingDate: Date,
    private prospectUnreadMessagesCount: number | null,
    private offerCloseAt: Date,
    private offerType: string,
    private operationType: string
  ) {}

  public getId() {
    return this.id;
  }

  public getOperationId() {
    return this.operationId;
  }

  public getOfferId() {
    return this.offerId;
  }

  public getLastMessage() {
    return this.lastMessage;
  }

  public getLastMessageCreatedAt() {
    return this.lastMessageCreatedAt;
  }

  public getOfferOwnerId() {
    return this.offerOwnerId;
  }

  public getOfferOwnerName() {
    return this.offerOwnerName;
  }

  public getOfferOwnerLastReadingDate() {
    return this.offerOwnerLastReadingDate;
  }

  public getOfferOwnerUnreadMessagesCount() {
    return this.offerOwnerUnreadMessagesCount;
  }

  public getProspectId() {
    return this.prospectId;
  }

  public getProspectName() {
    return this.prospectName;
  }

  public getProspectLastReadingDate() {
    return this.prospectLastReadingDate;
  }

  public getProspectUnreadMessagesCount() {
    return this.prospectUnreadMessagesCount;
  }

  public getOfferCloseAt() {
    return this.offerCloseAt;
  }

  public getOfferType() {
    return this.offerType;
  }

  public getOperationType() {
    return this.operationType;
  }
}
