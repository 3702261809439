import React, { useRef } from "react";
import { Badge, IconButton, Tooltip } from "@mui/material";
import { MessageSquare } from "react-feather";
import { useTranslation } from "react-i18next";

import useChat from "../../hooks/useChat";
import useAuth from "../../hooks/useAuth";

export function NavbarMessagesDropdown() {
  const { t } = useTranslation();
  const { openChatModal } = useChat();

  const ref = useRef(null);

  const { unreadChatroomsCount } = useChat();
  const { user } = useAuth();

  return (
    <React.Fragment>
      {user?.role === "user" && (
        <Tooltip title={t("Messages")}>
          <IconButton
            color="inherit"
            ref={ref}
            onClick={() => openChatModal({})}
            size="large"
          >
            <Badge badgeContent={unreadChatroomsCount} color="primary">
              <MessageSquare />
            </Badge>
          </IconButton>
        </Tooltip>
      )}
    </React.Fragment>
  );
}
