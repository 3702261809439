import React from "react";
import { createContext, ReactNode, useCallback, useState } from "react";

import { useSendOnlineStatus } from "../components/chat/hooks/useSendOnlineStatus";
import { ChatModal } from "../components/chat/Chat/ChatModal";
import useAuth from "../hooks/useAuth";

interface ChatOptions {
  onClose?: () => void;
  defaultChatroomId?: string;
}

interface ChatContextProps {
  openChatModal: (options: ChatOptions) => void;
  isChatModalOpen: boolean;
  unreadChatroomsCount: number;
  updateChatroomsCount: (count: number) => void;
}

const ChatContext = createContext<ChatContextProps>({
  openChatModal: () => {},
  isChatModalOpen: false,
  unreadChatroomsCount: 0,
  updateChatroomsCount: () => {},
});

function ChatProvider({ children }: { children: ReactNode }) {
  const { isAuthenticated, isInitialized, user } = useAuth();

  useSendOnlineStatus();

  const [isChatModalOpen, setIsChatModalOpen] = useState(false);
  const [options, setOptions] = useState<ChatOptions>({});

  const [unreadChatroomsCount, setUnreadChatroomsCount] = useState(0);
  const updateChatroomsCount = useCallback((count: number) => {
    setUnreadChatroomsCount(count);
  }, []);

  const handleOpenModal = useCallback(() => {
    setIsChatModalOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsChatModalOpen(false);
  }, []);

  const openChatModal = useCallback(
    (options: ChatOptions) => {
      setOptions(options);
      handleOpenModal();
    },
    [handleOpenModal]
  );

  return (
    <ChatContext.Provider
      value={{
        openChatModal,
        isChatModalOpen,
        unreadChatroomsCount,
        updateChatroomsCount,
      }}
    >
      <>
        {isAuthenticated && isInitialized && user?.role === "user" && (
          <ChatModal
            open={isChatModalOpen}
            onClose={() => {
              handleCloseModal();
              if (options.onClose) options.onClose();
            }}
            defaultChatroomId={options.defaultChatroomId}
          />
        )}

        {children}
      </>
    </ChatContext.Provider>
  );
}

export { ChatContext, ChatProvider };
