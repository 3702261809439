import React from "react";
import { Subscription } from "rxjs";
import useChooseParticipant from "../../../hooks/useChooseParticipant";
import {
  ChatroomsObservable,
  GetChatroomsObservableService,
} from "../services/GetChatroomsObservableService";

export interface Chatroom {
  id: string;
  operationId: string;
  offerId: string;
  prospectId: number;
  counterpartyId: string;
  counterpartyName: string;
  counterpartLastReadingDate: string;
  lastReadingDate: string;
  lastMessage: string;
  offerCloseDateTime: string;
  offerType: "COMPRA" | "VENDA";
  operationType: "COMPRA" | "VENDA" | "COMPRA&VENDA";
  unreadMessagesCount: number;
  lastMessageCreatedAt: string;
}

const getChatroomsObservableService = new GetChatroomsObservableService();

export function useGetChatRooms() {
  const { currentParticipant } = useChooseParticipant();

  const [chatrooms, setChatrooms] = React.useState<Array<Chatroom>>([]);

  React.useEffect(() => {
    const options = {
      participant: currentParticipant ? currentParticipant.id : -1,
    };

    const subscription = getChatroomsObservableService
      .getChatroomsObservable()
      .subscribeWithOptions(options, (chatroomsData) => {
        setChatrooms(
          chatroomsData.chatrooms.map((chatroom) => ({
            id: chatroom.getId(),
            operationId: chatroom.getOperationId(),
            offerId: chatroom.getOfferId(),
            prospectId: chatroom.getProspectId(),
            counterpartyId: "-1",
            counterpartyName:
              chatroom.getOfferOwnerId() === null
                ? chatroom.getOfferOwnerName()
                : chatroom.getProspectName(),
            counterpartLastReadingDate:
              chatroom.getOfferOwnerId() === null
                ? chatroom.getOfferOwnerLastReadingDate().toISOString()
                : chatroom.getProspectLastReadingDate().toISOString(),
            lastReadingDate:
              chatroom.getOfferOwnerId() === null
                ? chatroom.getProspectLastReadingDate().toISOString()
                : chatroom.getOfferOwnerLastReadingDate().toISOString(),
            lastMessage: chatroom.getLastMessage(),
            offerCloseDateTime: chatroom.getOfferCloseAt().toISOString(),
            offerType: chatroom.getOfferType() as "COMPRA" | "VENDA",
            operationType: chatroom.getOperationType() as
              | "COMPRA"
              | "VENDA"
              | "COMPRA&VENDA",
            unreadMessagesCount:
              chatroom.getOfferId() !== null
                ? (chatroom.getOfferOwnerUnreadMessagesCount() as number)
                : (chatroom.getProspectUnreadMessagesCount() as number),
            lastMessageCreatedAt: chatroom
              .getLastMessageCreatedAt()
              .toISOString(),
          }))
        );
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [currentParticipant]);

  return { chatrooms };
}
