import React, { useContext, useState, useMemo } from "react";
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import {
  CancelOutlined,
  EditOutlined,
  InfoOutlined,
  ChatOutlined,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { useSnackbar } from "notistack";

import useChooseParticipant from "../../../../hooks/useChooseParticipant";
import { cancelOffer, CancelOfferProps } from "../../../../services/offers";
import useConfirmationModal from "../../../../hooks/useConfirmationModal";
import { useFirestoreQuery } from "../../../../hooks/useFirestoreQuery";
import * as firestore from "firebase/firestore";
import { ApiError } from "../../../../types/error";

import { ChangeOfferPriceModal } from "../Modals/OffersPanelChangeOfferPriceModal";
import { getHeadCells } from "./HeadCells";
import { ResumeTableRow } from "./ResumeTableRow";
import { OfferResume } from "../../entities/data-objects/OperationResume";
import { EnhancedTableManualPagination } from "../../../../components/EnhancedTable";
import { ChatContext } from "../../../../contexts/ChatContext";
import { GetChatroomIdService } from "../../../../components/chat/services/GetChatroomIdService";
import { BidModal } from "../../../../components/bidModal/BidModal";
import useAuth from "../../../../hooks/useAuth";
import { submarketColors } from "../../../../constants";
import { useGetOperations } from "../../hooks/useGetOperations";

const getChatroomIdService = new GetChatroomIdService();

interface OffersTableProps {}
export const OffersTable: React.FC<OffersTableProps> = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { user } = useAuth();
  const { participantId } = useChooseParticipant();

  const [selectedOffer, setSelectedOffer] = useState<OfferResume | null>(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);

  const [productFilterValue, setProductFilterValue] = React.useState<
    string | null
  >(null);
  const [submarketFilterValue, setSubmarketFilterValue] = React.useState<
    string | null
  >(null);
  const [offersToShow, setOffersToShow] = React.useState("All Offers");
  const [showFinishedOffers, setShowFinishedOffers] = React.useState(false);

  const { operations, totalItems } = useGetOperations({
    page: currentPage,
    itemsPerPage,
    productFilterValue,
    submarketFilterValue,
    offersToShow,
    showFinishedOffers,
  });

  const [selectedMoreMenuId, setSelectedMoreMenuId] = React.useState<
    number | string | null
  >(null);

  const selectedOperation = useMemo(() => {
    return operations.find(
      (operation) => operation.getOperationId() === selectedMoreMenuId
    );
  }, [operations, selectedMoreMenuId]);

  const toggleShowFinishedOffers = React.useCallback(() => {
    setCurrentPage(1);
    setShowFinishedOffers((oldValue) => !oldValue);
  }, []);

  const chatModalContext = useContext(ChatContext);

  const {
    data: [products],
  } = useFirestoreQuery<{ name: string; assetsAvailableCode: string }>(() => {
    return [
      firestore.query(
        firestore.collection(firestore.getFirestore(), "products")
      ),
    ];
  }, []);

  const productsSorted = useMemo(() => {
    if (!products) return [];

    return products.sort((a, b) => {
      const assetA = a.name.toString().toUpperCase();
      const assetB = b.name.toString().toUpperCase();
      return assetA.localeCompare(assetB, undefined, { numeric: true });
    });
  }, [products]);

  const submitCancelOffer = useMutation<any, unknown, CancelOfferProps>(
    cancelOffer,
    {
      onSuccess: () => {
        enqueueSnackbar(t("The operation completed successfully"), {
          variant: "success",
        });
      },
      onError: (e) => {
        enqueueSnackbar(
          t((e as ApiError | null)?.error ?? "Something went wrong."),
          {
            variant: "error",
          }
        );
      },
    }
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleCloseMoreMenu = React.useCallback(() => {
    setAnchorEl(null);
    // setSelectedMoreMenuId(null);
  }, []);

  const [isBidModalOpen, setIsBidModalOpen] = React.useState(false);
  const handleOpenBidModal = React.useCallback((offer: OfferResume) => {
    setSelectedOffer(offer);
    setSelectedMoreMenuId(offer.getOperationCode());
    setIsBidModalOpen(true);
  }, []);
  const handleCloseBidModal = React.useCallback(() => {
    setIsBidModalOpen(false);
  }, []);

  const [isSeeDetailsModalOpen, setIsSeeDetailsModalOpen] =
    React.useState(false);
  const handleOpenSeeDetailsModal = React.useCallback((offer: OfferResume) => {
    setSelectedOffer(offer);
    setSelectedMoreMenuId(offer.getOperationCode());
    setIsSeeDetailsModalOpen(true);
  }, []);
  const handleCloseSeeDetailsModal = React.useCallback(() => {
    setIsSeeDetailsModalOpen(false);
  }, []);

  const handleOpenChatModal = React.useCallback(
    async (operationId: string, offerType: string) => {
      const chatRoomId = await getChatroomIdService.getChatroomId(
        operationId,
        offerType
      );
      chatModalContext.openChatModal({
        onClose: () => {},
        defaultChatroomId: chatRoomId,
      });
    },
    [chatModalContext]
  );

  const handleOpenMoreMenu = React.useCallback(
    (event: React.MouseEvent<HTMLElement>, id: number | string) => {
      setAnchorEl(event.currentTarget);
      setSelectedMoreMenuId(id);
    },
    []
  );

  const [isChangeOfferPriceModalOpen, setIsChangeOfferPriceModalOpen] =
    React.useState(false);
  const handleOpenChangeOfferPriceModal = React.useCallback(() => {
    setIsChangeOfferPriceModalOpen(true);
  }, []);
  const handleCloseChangeOfferPriceModal = React.useCallback(() => {
    setIsChangeOfferPriceModalOpen(false);
  }, []);

  const { showConfirmationModal } = useConfirmationModal();

  const handleCancelOffer = React.useCallback(
    (offerResume: OfferResume, type: "sell" | "buy", operationId: string) => {
      const offerId = offerResume.getId();
      showConfirmationModal({
        onSubmit: () => {
          submitCancelOffer.mutate({
            operationId: Number(offerResume.getOperationCode()),
            type: type === "buy" ? "BUY" : "SELL",
          });
        },
        message: t(
          `Do you really want to cancel the ${type} offer from operation {{code}}?`,
          {
            code: operationId,
          }
        ),
        submitButtonColor: "primary",
      });
    },
    [showConfirmationModal, submitCancelOffer, t]
  );

  return (
    <>
      {selectedOffer && selectedOperation && isBidModalOpen && (
        <BidModal
          open={isBidModalOpen}
          onClose={handleCloseBidModal}
          operationId={selectedOperation.getOperationId()}
          offerType={selectedOffer.getType()}
        />
      )}

      {selectedOffer && selectedOperation && (
        <BidModal
          open={isSeeDetailsModalOpen}
          onClose={handleCloseSeeDetailsModal}
          operationId={selectedOperation.getOperationId()}
          offerType={selectedOffer.getType()}
          showMoreDetails
          disableBidArea
        />
      )}

      {selectedOffer && selectedOperation && (
        <ChangeOfferPriceModal
          open={isChangeOfferPriceModalOpen}
          onClose={handleCloseChangeOfferPriceModal}
          offerData={selectedOffer}
          operationData={selectedOperation}
          participantId={participantId}
        />
      )}

      <Menu
        id="more-menu"
        MenuListProps={{
          "aria-labelledby": `more-button-${selectedMoreMenuId}`,
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMoreMenu}
      >
        {selectedOperation && selectedOperation.getBuyOffer() && (
          <MenuItem
            onClick={() => {
              handleCloseMoreMenu();
              handleOpenSeeDetailsModal(selectedOperation.getBuyOffer()!);
            }}
          >
            <ListItemIcon>
              <InfoOutlined />
            </ListItemIcon>
            <ListItemText
              primary={
                selectedOperation!.getSellOffer() === null
                  ? t("See Details")
                  : t("See Buy Offer Details")
              }
            />
          </MenuItem>
        )}

        {selectedOperation && selectedOperation.getSellOffer() && (
          <MenuItem
            onClick={() => {
              handleCloseMoreMenu();
              handleOpenSeeDetailsModal(selectedOperation.getSellOffer()!);
            }}
          >
            <ListItemIcon>
              <InfoOutlined />
            </ListItemIcon>
            <ListItemText
              primary={
                selectedOperation!.getBuyOffer() === null
                  ? t("See Details")
                  : t("See Sell Offer Details")
              }
            />
          </MenuItem>
        )}

        {selectedOperation && selectedOperation.getBuyOffer() && (
          <MenuItem
            onClick={() => {
              handleCloseMoreMenu();
              handleOpenChatModal(selectedOperation.getOperationId(), "COMPRA");
            }}
            disabled={
              selectedOperation!.getIsOperationOwner() ||
              selectedOperation.getBuyOffer()!.getStatus() !== "ABERTA" ||
              user?.role !== "user"
            }
          >
            <ListItemIcon>
              <ChatOutlined />
            </ListItemIcon>
            <ListItemText
              primary={
                selectedOperation!.getSellOffer() === null
                  ? t("Offer Chat")
                  : t("Buy Offer Chat")
              }
            />
          </MenuItem>
        )}

        {selectedOperation && selectedOperation.getSellOffer() && (
          <MenuItem
            onClick={() => {
              handleCloseMoreMenu();
              handleOpenChatModal(selectedOperation.getOperationId(), "VENDA");
            }}
            disabled={
              selectedOperation!.getIsOperationOwner() ||
              selectedOperation.getSellOffer()!.getStatus() !== "ABERTA" ||
              user?.role !== "user"
            }
          >
            <ListItemIcon>
              <ChatOutlined />
            </ListItemIcon>
            <ListItemText
              primary={
                selectedOperation!.getBuyOffer() === null
                  ? t("Offer Chat")
                  : t("Sell Offer Chat")
              }
            />
          </MenuItem>
        )}

        {/* <MenuItem
          onClick={() => {
            handleCloseMoreMenu();
            handleOpenChatModal();
          }}
          disabled={selectedMoreMenu?.status !== "ABERTA"}
        >
          <ListItemIcon>
            <ChatOutlined />
          </ListItemIcon>
          <ListItemText primary={t("Chat with bidder")} />
        </MenuItem> */}

        {selectedOperation && selectedOperation.getBuyOffer() && (
          <MenuItem
            onClick={() => {
              handleCloseMoreMenu();
              handleCancelOffer(
                selectedOperation.getBuyOffer()!,
                "buy",
                selectedOperation!.getOperationId()
              );
            }}
            disabled={
              !selectedOperation!.getIsOperationOwner() ||
              selectedOperation.getBuyOffer()!.getStatus() !== "ABERTA"
            }
          >
            <ListItemIcon>
              <CancelOutlined />
            </ListItemIcon>
            <ListItemText
              primary={
                selectedOperation!.getSellOffer() === null
                  ? t("Cancel Offer")
                  : t("Cancel Buy Offer")
              }
            />
          </MenuItem>
        )}

        {selectedOperation && selectedOperation.getSellOffer() && (
          <MenuItem
            onClick={() => {
              handleCloseMoreMenu();
              handleCancelOffer(
                selectedOperation.getSellOffer()!,
                "sell",
                selectedOperation!.getOperationId()
              );
            }}
            disabled={
              !selectedOperation!.getIsOperationOwner() ||
              selectedOperation.getSellOffer()!.getStatus() !== "ABERTA"
            }
          >
            <ListItemIcon>
              <CancelOutlined />
            </ListItemIcon>
            <ListItemText
              primary={
                selectedOperation!.getBuyOffer() === null
                  ? t("Cancel Offer")
                  : t("Cancel Sell Offer")
              }
            />
          </MenuItem>
        )}

        {selectedOperation && selectedOperation.getBuyOffer() && (
          <MenuItem
            onClick={() => {
              handleCloseMoreMenu();
              setSelectedOffer(selectedOperation.getBuyOffer());
              handleOpenChangeOfferPriceModal();
            }}
            disabled={
              !selectedOperation!.getIsOperationOwner() ||
              (selectedOperation.getBuyOffer()!.getStatus() !== "ABERTA" &&
                selectedOperation.getBuyOffer()!.getStatus() !==
                  "PARCIALMENTE ENCERRADA") ||
              selectedOperation.getBuyOffer()!.getRemainingVolume() <= 0 ||
              (selectedOperation.getBuyOffer()!.getValue() === null &&
                selectedOperation.getBuyOffer()!.getSpread() === null)
            }
          >
            <ListItemIcon>
              <EditOutlined />
            </ListItemIcon>
            <ListItemText
              primary={
                selectedOperation!.getSellOffer() === null
                  ? t("Change Offer Price")
                  : t("Change Buy Offer Price")
              }
            />
          </MenuItem>
        )}
        {selectedOperation && selectedOperation.getSellOffer() && (
          <MenuItem
            onClick={() => {
              handleCloseMoreMenu();
              setSelectedOffer(selectedOperation.getSellOffer());
              handleOpenChangeOfferPriceModal();
            }}
            disabled={
              !selectedOperation!.getIsOperationOwner() ||
              (selectedOperation.getSellOffer()!.getStatus() !== "ABERTA" &&
                selectedOperation.getSellOffer()!.getStatus() !==
                  "PARCIALMENTE ENCERRADA") ||
              selectedOperation.getSellOffer()!.getRemainingVolume() <= 0 ||
              (selectedOperation.getSellOffer()!.getValue() === null &&
                selectedOperation.getSellOffer()!.getSpread() === null)
            }
          >
            <ListItemIcon>
              <EditOutlined />
            </ListItemIcon>
            <ListItemText
              primary={
                selectedOperation!.getBuyOffer() === null
                  ? t("Change Offer Price")
                  : t("Change Sell Offer Price")
              }
            />
          </MenuItem>
        )}
      </Menu>

      <EnhancedTableManualPagination
        title={t("Offers Panel")}
        data={operations}
        headCells={getHeadCells(language)}
        rowsPerPage={20}
        paginationOptions={{
          currentPage: currentPage,
          itemsPerPage: itemsPerPage,
          onPageChange: (page) => {
            setCurrentPage(page);
          },
          onRowsPerPageChange: (rows) => {
            setItemsPerPage(rows);
            setCurrentPage(
              Math.min(currentPage, Math.floor(totalItems / rows) + 1)
            );
          },
          totalItems: totalItems,
        }}
        avoidEmptyRows
        TableProps={{
          size: "small",
          sx: {
            // transform: "scale(0.8)",
            // transformOrigin: "top left",
            "& .MuiTableCell-root": {
              // fontSize: 10,
              px: 2,
              pr: 1,
            },
          },
        }}
        toolbarFiltersArea={
          <Box display="flex" justifyContent="space-between" flexWrap="wrap">
            <Box width="fit-content" display="flex" gap={3} flexWrap="wrap">
              <Box minWidth={150}>
                <Autocomplete
                  value={productFilterValue}
                  onChange={(e, data) => {
                    setCurrentPage(1);
                    setProductFilterValue(data);
                  }}
                  openOnFocus
                  handleHomeEndKeys
                  disablePortal
                  options={productsSorted?.map((item) => item.name) ?? []}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("Source Type")}
                      placeholder={t("All")}
                      InputLabelProps={{ shrink: true }}
                      variant="standard"
                    />
                  )}
                />
              </Box>

              <Box minWidth={150}>
                <Autocomplete
                  value={submarketFilterValue}
                  onChange={(e, data) => {
                    setCurrentPage(1);
                    setSubmarketFilterValue(data);
                  }}
                  openOnFocus
                  handleHomeEndKeys
                  disablePortal
                  options={Object.keys(submarketColors)}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("Submarket")}
                      placeholder={t("All")}
                      InputLabelProps={{ shrink: true }}
                      variant="standard"
                    />
                  )}
                />
              </Box>

              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showFinishedOffers}
                      onChange={toggleShowFinishedOffers}
                      name="showFinishedOffers"
                    />
                  }
                  label={t("Show Finished Offers")}
                />
              </Box>
            </Box>

            {participantId && (
              <Box textAlign="right">
                <FormControl>
                  <RadioGroup
                    aria-labelledby="priceType-radio-buttons-group"
                    name="priceType-radio-buttons-group"
                    row
                    value={offersToShow}
                    onChange={(e, value) => {
                      setCurrentPage(1);
                      setOffersToShow(value);
                    }}
                  >
                    <FormControlLabel
                      value={"All Offers"}
                      control={<Radio />}
                      label={t("All Offers")}
                    />
                    <FormControlLabel
                      value={"My Offers"}
                      control={<Radio />}
                      label={t("My Offers")}
                    />
                    <FormControlLabel
                      value={"My Bids"}
                      control={<Radio />}
                      label={t("My Bids")}
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            )}
          </Box>
        }
        keyExtractor={(row) => String(row.getOperationId())}
        renderRow={(row) => {
          return (
            <ResumeTableRow
              row={row}
              handleOpenSeeDetailsModal={handleOpenSeeDetailsModal}
              handleOpenMoreMenu={handleOpenMoreMenu}
              handleOpenBidModal={handleOpenBidModal}
            />
          );
        }}
      />
    </>
  );
};
