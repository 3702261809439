import { Tooltip, TooltipProps } from "@mui/material";

interface OptionalTooltipProps extends TooltipProps {
  disableTooltip?: boolean;
}
export function OptionalTooltip({
  disableTooltip,
  ...props
}: OptionalTooltipProps) {
  return disableTooltip ? props.children : <Tooltip {...props} />;
}
