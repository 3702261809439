import React from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import {
  Alert as MuiAlert,
  Box,
  CircularProgress,
  Paper,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { subMonths } from "date-fns";
import {
  getISOYearAndMonth,
  getLocaleMonthAndYearString,
} from "../../utils/date";
import { useGetFixedDeliveryPeriod } from "../../components/spotMarket/hooks/useGetFixedDeliveryPeriod";

const Alert = styled(MuiAlert)(spacing);

const InfoBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24;
`;

export function FixedDeliveryPeriodBox() {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const lastMonth = React.useMemo(
    () => getISOYearAndMonth(subMonths(new Date(), 1)),
    []
  );

  const {
    fixedDeliveryPeriod,
    isLoading: isLoadingFixedDeliveryPeriod,
    isError: isErrorFixedDeliveryPeriod,
  } = useGetFixedDeliveryPeriod();

  if (isErrorFixedDeliveryPeriod) {
    return (
      <Alert mt={2} mb={3} severity="error">
        {t("Something went wrong.")}
      </Alert>
    );
  }

  if (isLoadingFixedDeliveryPeriod || fixedDeliveryPeriod === undefined) {
    return (
      <InfoBox>
        <CircularProgress />
      </InfoBox>
    );
  }

  return (
    <Paper
      sx={{
        textAlign: "left",
        p: 2,
      }}
    >
      <Typography lineHeight={1} fontSize="0.875rem">
        <strong>{t("Fixed Delivery Period")}</strong>
      </Typography>

      <Typography variant="caption">
        {`${t("Reference month")}: ${
          lastMonth ? getLocaleMonthAndYearString(lastMonth, language) : "-"
        }`}
      </Typography>

      <Box display="flex" gap={10} mt={2}>
        <Typography component="span" fontWeight={500}>
          {`${t("Start")}: ${fixedDeliveryPeriod?.firstDay}`}
        </Typography>
        <Typography component="span" fontWeight={500}>
          {`${t("End")}: ${fixedDeliveryPeriod?.lastDay}`}
        </Typography>
      </Box>
    </Paper>
  );
}
