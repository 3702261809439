import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Grid,
  styled,
  Typography,
  Alert as MuiAlert,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { compareStrings } from "../../../utils/string";
import { StyledModal } from "../../StyledModal";
import { SummaryTable } from "../../SummaryTable";
import { useGetBankData } from "../hooks/useGetBankData";
import { useGetParticipantBankDataByAssetId } from "../hooks/useGetParticipantBankData";
import { ParticipantBankData } from "../infra/api/BankInfra";

const Alert = styled(MuiAlert)(spacing);

const InfoBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24;
`;

export interface BankCceeInformationModalProps {
  open: boolean;
  onClose?: () => void;
  transaction: any;
}

export function BankCceeInformationModal({
  open,
  onClose,
  transaction,
}: BankCceeInformationModalProps) {
  const { t } = useTranslation();

  const {
    participantBankData,
    isLoading: isLoadingParticipantBankData,
    isError: isErrorParticipantBankData,
  } = useGetParticipantBankDataByAssetId(transaction?.id);

  const {
    bankData,
    isLoading: isLoadingBankData,
    isError: isErrorBankData,
  } = useGetBankData();

  function handleCceeData(array: ParticipantBankData | undefined) {
    return (
      array?.ccee
        ?.map((item) => {
          return {
            label: item.name,
            value: item.profileKey,
            secondValue: item.profileCode,
          };
        })
        ?.sort(function (a, b) {
          return compareStrings(a.label, b.label);
        }) ?? []
    );
  }

  const participantCcee = useMemo(() => {
    return handleCceeData(participantBankData);
  }, [participantBankData]);

  const bankCcee = useMemo(() => {
    return handleCceeData(bankData);
  }, [bankData]);

  const participantBank = useMemo(
    () => [
      [
        {
          label: t("Account Name"),
          value: participantBankData?.bank?.accountOwnerName,
        },
        {
          label: t("Bank Name"),
          value: participantBankData?.bank?.bankName,
        },
        {
          label: t("Agency"),
          value: participantBankData?.bank?.agencyNumber,
        },
        {
          label: t("Account Number"),
          value: participantBankData?.bank?.accountNumber,
        },
        {
          label: t("CNPJ"),
          value: participantBankData?.bank?.cnpj,
        },
      ],
    ],
    [t, participantBankData]
  );

  const bank = useMemo(
    () => [
      [
        {
          label: t("Account Name"),
          value: bankData?.bank?.accountOwnerName,
        },
        {
          label: t("Bank Name"),
          value: bankData?.bank?.bankName,
        },
        {
          label: t("Agency"),
          value: bankData?.bank?.agencyNumber,
        },
        {
          label: t("Account Number"),
          value: bankData?.bank?.accountNumber,
        },
        {
          label: t("CNPJ"),
          value: bankData?.bank?.cnpj,
        },
      ],
    ],
    [t, bankData]
  );

  const tableHead = [t("Product Name"), t("Profile Key"), t("Profile Code")];

  function confirmDataLabel() {
    return (
      <Box
        sx={(theme) => ({
          borderBottom: `1px solid ${theme.palette.text.primary}`,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mt: 4,
        })}
      >
        <Typography
          variant="body1"
          fontSize={14}
          sx={() => ({
            backgroundColor: "rgba(44, 105, 178, 0.3)",
            borderRadius: "5px",
            padding: "1px 20px",
            marginBottom: "2px",
          })}
        >
          {t("Check that your details are correct")}
        </Typography>
      </Box>
    );
  }

  if (isErrorBankData || isErrorParticipantBankData) {
    return (
      <Alert mt={2} mb={3} severity="error">
        {t("Something went wrong.")}
      </Alert>
    );
  }

  return (
    <StyledModal
      leftBox={t("Information")}
      title={t("Bank & CCEE")}
      open={open}
      onClose={onClose}
    >
      <Grid container spacing={12}>
        {isLoadingParticipantBankData || isLoadingBankData ? (
          <Box
            width="100%"
            height="120px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            ml="48px"
          >
            <InfoBox>
              <CircularProgress />
            </InfoBox>
          </Box>
        ) : (
          <>
            <Grid item md={6} width="100%">
              <Box>
                <Box px={2} mb={4}>
                  <Accordion defaultExpanded disableGutters>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography variant="subtitle2">
                        {t("My CCEE")}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <SummaryTable
                        data={[participantCcee]}
                        tableHead={tableHead}
                      />
                      <br />
                      <Typography>{t("My Bank")}</Typography>
                      <SummaryTable data={[[...participantBank[0]]]} />
                      {confirmDataLabel()}
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Box>
            </Grid>
            <Grid item md={6} width="100%">
              <Box>
                <Box px={2} mb={4}>
                  <Accordion defaultExpanded disableGutters>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography variant="subtitle2">
                        {t("CCEE Bank")}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <SummaryTable data={[bankCcee]} tableHead={tableHead} />
                      <br />
                      <Typography>{t("BTG Bank")}</Typography>
                      <SummaryTable data={[[...bank[0]]]} />
                      {confirmDataLabel()}
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Box>
            </Grid>
          </>
        )}
        <Box
          display="inline-flex"
          justifyContent="center"
          width="100%"
          marginLeft="48px"
        >
          {onClose && (
            <Button
              type="button"
              variant="outlined"
              color="primary"
              onClick={onClose}
            >
              {t("Go Back")}
            </Button>
          )}
        </Box>
      </Grid>
    </StyledModal>
  );
}
