import React from "react";
import styled from "@emotion/styled";
import { spacing, useTheme } from "@mui/system";
import {
  Alert as MuiAlert,
  Box,
  CircularProgress,
  Grid,
  Paper,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { subMonths } from "date-fns";
import { submarketColors } from "../../constants";
import { getMonthlyPld } from "../../services/pld";
import {
  getISOYearAndMonth,
  getLocaleMonthAndYearString,
} from "../../utils/date";
import {
  getCurrencySymbol,
  toLocaleCurrencyStringWithoutSymbol,
} from "../../utils/string";

const Alert = styled(MuiAlert)(spacing);

const InfoBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24;
`;

export function OffersPanelPldBox() {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));

  const lastMonth = React.useMemo(
    () => getISOYearAndMonth(subMonths(new Date(), 1)),
    []
  );

  const {
    data: pldData,
    isLoading,
    isError,
  } = useQuery(["/pld/month", lastMonth, lastMonth], () =>
    getMonthlyPld(lastMonth, lastMonth)
  );

  const lastMonthPld = React.useMemo(
    () =>
      pldData && pldData.length > 0 ? pldData[0].monthlyPldBySubmarket : {},
    [pldData]
  );

  if (isError) {
    return (
      <Alert mt={2} mb={3} severity="error">
        {t("Something went wrong.")}
      </Alert>
    );
  }

  if (isLoading || lastMonthPld === undefined) {
    return (
      <InfoBox>
        <CircularProgress />
      </InfoBox>
    );
  }

  return (
    <Paper sx={{ textAlign: "left", p: 2 }}>
      <Tooltip title="Preço de Liquidação das Diferenças">
        <Typography lineHeight={1} fontSize="0.875rem">
          <strong>PLD</strong> ({getCurrencySymbol("BRL", language)}/MWh)
        </Typography>
      </Tooltip>

      <Typography variant="caption">
        {`${t("Reference month")}: ${
          lastMonth ? getLocaleMonthAndYearString(lastMonth, language) : "-"
        }`}
      </Typography>

      <Box mt={2}>
        {Object.keys(lastMonthPld).length === 0 ? (
          <Typography variant="caption">{t("Unavailable data")}</Typography>
        ) : (
          <Grid container rowSpacing={1} columnSpacing={isSmUp ? 4 : 1}>
            {Object.keys(lastMonthPld)
              .sort()
              .reverse()
              .map((key) => (
                <Grid item justifyContent="space-between" key={key}>
                  <Typography>
                    <Typography
                      component="span"
                      fontWeight={500}
                      display="inline"
                      color={submarketColors[key]}
                    >
                      {key}
                      {": "}
                    </Typography>
                    {toLocaleCurrencyStringWithoutSymbol(
                      Number(lastMonthPld[key]),
                      "BRL",
                      language
                    )}
                  </Typography>
                </Grid>
              ))}
          </Grid>
        )}
      </Box>
    </Paper>
  );
}
