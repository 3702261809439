import { useMutation } from "@tanstack/react-query";
import { deleteOrder } from "../infra/api/SpotMarketInfra";

type Error = {
  message: string;
  statusCode: number;
};

export function useDeleteOrder(operationId?: {
  onSuccess?: () => void;
  onError?: (error: Error) => void;
}) {
  const { mutate: deleteOrderMutate, ...rest } = useMutation(
    deleteOrder,
    operationId
  );

  return { deleteOrderMutate, ...rest };
}
