import { SidebarItemsType } from "../../types/sidebar";

import { BiWallet } from "react-icons/bi";
import { MdOutlineCandlestickChart } from "react-icons/md";
import { ShoppingCart, CheckCircle, BarChart2 } from "react-feather";
import {
  AccountBalanceOutlined,
  SellOutlined,
  Settings,
  Compress,
} from "@mui/icons-material";
import { TFunction } from "i18next";

const getAppSection = (t: TFunction, userRole: string) =>
  [
    {
      href: "/bank/spot-trading",
      icon: MdOutlineCandlestickChart,
      title: t("Spot Trading"),
      disabled: userRole !== "user",
    },
    {
      href: "/bank/my-wallet",
      icon: BiWallet,
      title: t("My Wallet"),
      disabled: userRole !== "user",
    },
    {
      href: "/offers",
      icon: ShoppingCart,
      title: t("Offers"),
      children: [
        {
          href: "/offers/offers-panel",
          title: t("Offers Panel"),
        },
        {
          href: "/offers/register-offer",
          title: t("Register Offer"),
          disabled: userRole !== "user",
        },
        {
          href: "/offers/direct-buy-and-sell",
          title: t("Direct Buy and Sell"),
          disabled: userRole !== "user",
        },
        {
          href: "/offers/offer-history",
          title: t("Offer History"),
        },
      ],
    },
    {
      href: "/contracts",
      icon: CheckCircle,
      title: t("Contracts"),
      children: [
        {
          href: "/contracts/list",
          title: t("ContractList"),
        },
      ],
    },
    {
      href: "/pld",
      icon: BarChart2,
      title: t("PLD"),
      children: [
        {
          href: "/pld/weekly-average",
          title: t("Weekly Average"),
        },
        {
          href: "/pld/monthly-average",
          title: t("Monthly Average"),
        },
      ],
    },
    {
      href: "/bank",
      icon: AccountBalanceOutlined,
      title: t("Bank"),
      children: [
        {
          href: "/bank/available-balance",
          title: t("Available Balance"),
          disabled: userRole !== "user",
        },
        {
          href: "/bank/financial-deposit",
          title: t("Financial Deposit"),
          disabled: userRole !== "user",
        },
        {
          href: "/bank/energy-deposit",
          title: t("Energy Deposit"),
          disabled: userRole !== "user",
        },
        {
          href: "/bank/financial-withdrawal",
          title: t("Financial Withdrawal"),
          disabled: userRole !== "user",
        },
        {
          href: "/bank/energy-withdrawal",
          title: t("Energy Withdrawal"),
          disabled: userRole !== "user",
        },
        {
          href: "/bank/manage-deposits-and-withdrawals",
          title: t("Manage Deposits and Withdrawals"),
        },
      ],
    },
    {
      href: "/settings",
      icon: Settings,
      title: t("Settings"),
      children: [
        {
          href: "/settings/products",
          icon: SellOutlined,
          title: t("Products"),
          children: [
            {
              href: "/settings/products/list",
              title: t("Product List"),
            },
            {
              href: "/settings/products/associate-participants",
              title: t("Associate Participants"),
            },
          ],
        },
        {
          href: "/settings/tunnel-limits",
          icon: Compress,
          title: t("Tunnel Limits"),
          children: [
            {
              href: "/settings/tunnel-limits/update",
              title: t("Tunnel limit Update"),
            },
          ],
        },
      ],
      disabled: userRole !== "admin",
    },
  ] as SidebarItemsType[];

const getNavItems = (t: TFunction, userRole = "") => [
  {
    title: "",
    pages: getAppSection(t, userRole),
  },
];

export default getNavItems;
