import React from "react";
import { TextField, TextFieldProps } from "@mui/material";

export const StyledTextField = React.forwardRef<any, TextFieldProps>(
  (props, ref) => {
    const InputPropsSx = props["InputProps"]?.sx ?? {};
    const InputLabelPropsSx = props["InputLabelProps"]?.sx ?? {};

    return (
      <TextField
        {...props}
        ref={ref}
        size={props.size ?? "small"}
        InputProps={{
          ...props["InputProps"],
          sx: [
            {
              ".MuiOutlinedInput-notchedOutline": {
                border: "1px solid rgba(84, 110, 229, 0.61)",
                background: "rgba(255, 255, 255, 0.08)",
              },
              ".MuiInputBase-input": {
                ...(props.size === "small"
                  ? {
                      paddingTop: "calc((30px - 1.4375em) / 2)",
                      paddingBottom: "calc((30px - 1.4375em) / 2)",
                    }
                  : {}),
              },
            },
            ...(Array.isArray(InputPropsSx) ? InputPropsSx : [InputPropsSx]),
          ],
        }}
        InputLabelProps={{
          ...props["InputLabelProps"],
          shrink: false,
          sx: [
            (theme) => ({
              transform: "unset",
              position: "unset",
              mb: 2,
              color: theme.palette.text.primary,
            }),
            ...(Array.isArray(InputLabelPropsSx)
              ? InputLabelPropsSx
              : [InputLabelPropsSx]),
          ],
        }}
      />
    );
  }
);
