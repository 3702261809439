import { useEffect, useState } from "react";
import * as firestore from "firebase/firestore";
import * as firebaseApp from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

import { firebaseConfig } from "../config";
import useAuth from "../hooks/useAuth";

if (!firebaseApp.getApps().length) {
  firebaseApp.initializeApp(firebaseConfig);
  firestore.initializeFirestore(firebaseApp.getApp(), {});
}

/**
 * A hook that receives a function that returns an array of Firebase Firestore queries and adds onSnapshots on those queries.
 * Also receives an array of dependencies to recriate the onSnapshot.
 * @param queriesFn A function that return an array of Firebase Firestore queries
 * @param deps An array of dependencies
 */
export function useFirestoreQuery<T = Record<string, any>>(
  queriesFn: () => Array<firestore.CollectionReference | firestore.Query>,
  deps: React.DependencyList
) {
  const { isInitialized, isAuthenticated } = useAuth();

  const [docsData, setDocsData] = useState<
    Array<Array<T & { id: string }> | undefined>
  >([]);

  const [isFetching, setIsFetching] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (!isInitialized || !isAuthenticated) {
      return;
    }

    const unsubscribers = queriesFn().map((query, idx) => {
      return firestore.onSnapshot(
        query,
        (querySnapshot) => {
          setIsFetching(true);
          setIsError(false);
          const incomingDocsData = [] as Array<T & { id: string }>;

          querySnapshot.forEach((doc) => {
            const docData = doc.data() as T;
            incomingDocsData.push({
              id: doc.id,
              ...docData,
            });
          });

          setDocsData((oldArray) => {
            const newArray = [...oldArray];
            if (idx >= newArray.length) {
              newArray.push(incomingDocsData);
            } else {
              newArray[idx] = incomingDocsData;
            }
            return newArray;
          });
          setIsFetching(false);
          setIsLoading(false);
        },
        (e) => {
          setIsError(true);

          setDocsData((oldArray) => {
            const newArray = [...oldArray];
            if (idx >= newArray.length) {
              newArray.push([]);
            } else {
              newArray[idx] = [];
            }
            return newArray;
          });

          throw e;
        }
      );
    });

    return () => {
      unsubscribers.forEach((unsubscribe) => {
        unsubscribe();
      });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isInitialized, ...deps]);

  return { data: docsData, isFetching, isLoading, isError };
}

export function useFirestoreDoc<Q = firestore.DocumentData>(
  queriesFn: () => Array<firestore.DocumentReference<Q>>,
  deps: React.DependencyList
) {
  const { isInitialized, isAuthenticated } = useAuth();

  const [docsData, setDocsData] = useState<Array<Q | undefined>>([]);

  const [isFetching, setIsFetching] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (!isInitialized || !isAuthenticated) {
      return;
    }

    const unsubscribers = queriesFn().map((query, idx) => {
      return firestore.onSnapshot(
        query,
        (querySnapshot) => {
          setIsFetching(true);
          setIsError(false);
          const docData = querySnapshot.data();

          setDocsData((oldArray) => {
            const newArray = [...oldArray];
            if (idx >= newArray.length) {
              newArray.push(docData);
            } else {
              newArray[idx] = docData;
            }
            return newArray;
          });

          setIsFetching(false);
          setIsLoading(false);
        },
        (e) => {
          setIsError(true);

          setDocsData((oldArray) => {
            const newArray = [...oldArray];
            if (idx >= newArray.length) {
              newArray.push(undefined);
            } else {
              newArray[idx] = undefined;
            }
            return newArray;
          });

          throw e;
        }
      );
    });

    return () => {
      unsubscribers.forEach((unsubscribe) => {
        unsubscribe();
      });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isInitialized, ...deps]);

  return { data: docsData, isFetching, isLoading, isError };
}
