import React from "react";
import styled from "@emotion/styled";

import {
  Alert as MuiAlert,
  Box,
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { spacing } from "@mui/system";

import { useTranslation } from "react-i18next";
import { Check } from "@mui/icons-material";
import useChooseParticipant from "../../hooks/useChooseParticipant";

const Alert = styled(MuiAlert)(spacing);

const InfoBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24;
`;

function ChooseParticipant() {
  const { t } = useTranslation();

  const {
    participantList,
    currentParticipant,
    changeParticipant,
    isInitialized,
    isError,
  } = useChooseParticipant();

  if (isError) {
    return (
      <Alert mt={2} mb={3} severity="error">
        {t("Something went wrong.")}
      </Alert>
    );
  }

  if (!isInitialized || !participantList) {
    return (
      <InfoBox>
        <CircularProgress />
      </InfoBox>
    );
  }

  return (
    <Box>
      <List disablePadding>
        {participantList.map((item) => {
          const title = item.companyTradeName;
          const description = item.companyCnpj;
          const isSelected =
            currentParticipant && item.id === currentParticipant.id;

          return (
            <ListItem
              key={item.id}
              divider
              component={Button}
              onClick={() => {
                changeParticipant(item.id);
              }}
            >
              <ListItemText
                primary={title}
                primaryTypographyProps={{
                  variant: "subtitle2",
                  color: "textPrimary",
                }}
                secondary={description}
              />
              {isSelected && <Check />}
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
}

export default ChooseParticipant;
