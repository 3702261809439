import React from "react";
import {
  Box,
  BoxProps,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { OfferBookItem } from "../../directBuyAndSell/entities/OfferBookItem";
import {
  getCurrencySymbol,
  toLocaleCurrencyString,
  toLocaleCurrencyStringWithoutSymbol,
} from "../../../utils/string";
import { useTranslation } from "react-i18next";
import { useCurrentTime } from "../../../hooks/useCurrentTime";
import { TimeBox } from "../../TimeBox";
import { getSplitedTimeDifference } from "../../../utils/date";

interface FloatingBoxProps extends BoxProps {
  anchorEl?: null | Element;
  anchorOffsetY?: "top" | "bottom";
}

function FloatingBox({
  anchorEl,
  anchorOffsetY = "top",
  ...rest
}: FloatingBoxProps) {
  return (
    <Box
      {...rest}
      sx={[
        (theme) => ({
          position: "fixed",
          visibility: "hidden",
          zIndex: 1,
          ...(anchorEl
            ? {
                top:
                  anchorEl.getBoundingClientRect().y +
                  (anchorOffsetY === "bottom"
                    ? anchorEl.getBoundingClientRect().height
                    : 0),
                left: anchorEl.getBoundingClientRect().x,
                transform: "translate(calc(-100% - 24px), -50%)",
                visibility: "visible",
              }
            : {}),
          backgroundColor:
            theme.palette.mode === "dark" ? "#78808B" : "#EBEBEB",
          p: 2,
          borderTopLeftRadius: 6,
          borderBottomLeftRadius: 6,
          transformStyle: "preserve-3d",
          "&::after": {
            content: '""',
            position: "absolute",
            right: 0,
            top: 0,
            borderRadius: "6px",
            height: "100%",
            aspectRatio: "1 / 1",
            transform:
              "translateX(25%) scaleX(0.5) translateX(50%) translateY(-0.5px) scale(0.73) rotate(45deg) translateZ(-1px)",
            backgroundColor: "inherit",
          },
        }),
        ...(Array.isArray(rest.sx) ? rest.sx : [rest.sx]),
      ]}
    />
  );
}

interface SetRowsProps {
  offerBook: Array<OfferBookItem>;
  type: "COMPRA" | "VENDA";
  productName: string;
}

export function SetRowsTable({ offerBook, type, productName }: SetRowsProps) {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { currentTime } = useCurrentTime();

  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  const [hoveredRow, setHoveredRow] = React.useState<number | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLTableRowElement | null>(
    null
  );

  const { averageValue, totalVolume, totalValue } = React.useMemo(() => {
    const defaultValue = { averageValue: 0, totalVolume: 0, totalValue: 0 };

    if (hoveredRow === null) {
      return defaultValue;
    }

    const offerBookData =
      type === "COMPRA" ? offerBook : [...offerBook].reverse();

    return offerBookData
      .slice(
        0,
        type === "COMPRA" ? hoveredRow + 1 : offerBookData.length - hoveredRow
      )
      .reduce((acc, curr, idx, arr) => {
        return {
          averageValue:
            (acc.averageValue * acc.totalVolume + curr.value * curr.volume) /
            (acc.totalVolume + curr.volume),
          totalVolume: acc.totalVolume + curr.volume,
          totalValue: acc.totalValue + curr.total,
        };
      }, defaultValue);
  }, [hoveredRow, offerBook, type]);
  return (
    <>
      {isMdUp && (
        <FloatingBox
          anchorEl={anchorEl}
          anchorOffsetY={type === "COMPRA" ? "bottom" : "top"}
        >
          {t("Average Price")}: PLD{" "}
          {`${averageValue < 0 ? "-" : "+"} ${toLocaleCurrencyString(
            Math.abs(averageValue),
            "BRL",
            language
          )}`}
          <br />
          {`${t("Sum")} ${productName}: `}
          {totalVolume.toLocaleString(language, {
            minimumFractionDigits: 3,
            maximumFractionDigits: 3,
          })}{" "}
          MWh
          <br />
          {`${t("Sum")} ${getCurrencySymbol("BRL", language)}: `}
          {toLocaleCurrencyStringWithoutSymbol(totalValue, "BRL", language)}
        </FloatingBox>
      )}
      {offerBook?.map((order, indexOrder) => {
        const largestTotal = offerBook.reduce(
          (acc, curr) => (curr.total > acc ? curr.total : acc),
          0
        );

        const remainingTime = getSplitedTimeDifference(
          currentTime,
          order.offerCloseDateTime
        );

        return (
          <React.Fragment key={indexOrder}>
            {remainingTime.totalSeconds > 0 && (
              <Box
                width="100%"
                display="flex"
                onMouseOver={(event: any) => {
                  setHoveredRow(indexOrder);
                  setAnchorEl(event.currentTarget);
                }}
                onMouseOut={() => {
                  setHoveredRow(null);
                  setAnchorEl(null);
                }}
                sx={[
                  (theme) => {
                    if (
                      hoveredRow !== null &&
                      ((type === "COMPRA" && indexOrder <= hoveredRow) ||
                        (type === "VENDA" && indexOrder >= hoveredRow))
                    ) {
                      return {
                        backgroundColor:
                          theme.palette.mode === "dark"
                            ? "rgba(255, 255, 255, 0.08)"
                            : "rgba(0, 0, 0, 0.04)",
                      };
                    }
                    return {};
                  },
                ]}
              >
                <Box
                  sx={{
                    fontSize: "12px",
                    color: "#a6b4c9",
                    width: "25%",
                    minWidth: "25%",
                    padding: "5px",
                    border: "none",
                  }}
                >
                  <Typography
                    textAlign="center"
                    color={type === "COMPRA" ? "success.main" : "error.main"}
                  >
                    {`PLD ${
                      order.value > 0 ? "+" : "-"
                    } ${toLocaleCurrencyStringWithoutSymbol(
                      Math.abs(order.value),
                      "BRL",
                      language
                    )}`}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    fontSize: "12px",
                    color: "#a6b4c9",
                    width: "25%",
                    minWidth: "25%",
                    padding: "5px",
                    border: "none",
                  }}
                >
                  <Typography textAlign="center">
                    {order.volume.toLocaleString(language, {
                      minimumFractionDigits: 3,
                      maximumFractionDigits: 3,
                    })}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    fontSize: "12px",
                    color: "#a6b4c9",
                    width: "25%",
                    minWidth: "25%",
                    padding: "5px",
                    border: "none",
                  }}
                >
                  <Typography
                    textAlign="center"
                    sx={{
                      position: "relative",
                      transformStyle: "preserve-3d",
                      "&::before": {
                        content: '""',
                        position: "absolute",
                        right: 0,
                        top: "50%",
                        transform: "translateY(-50%) translateZ(-1px)",
                        height: "80%",
                        width: `${(order.total * 100) / largestTotal}%`,
                        backgroundColor:
                          type === "COMPRA" ? "success.main" : "error.main",
                        opacity: 0.2,
                      },
                    }}
                  >
                    {toLocaleCurrencyStringWithoutSymbol(
                      order.total,
                      "BRL",
                      language
                    )}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    fontSize: "12px",
                    color: "#a6b4c9",
                    width: "25%",
                    minWidth: "25%",
                    padding: "5px",
                    border: "none",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {remainingTime.totalSeconds > 0 ? (
                    <TimeBox time={remainingTime} />
                  ) : (
                    "-"
                  )}
                </Box>
              </Box>
            )}
          </React.Fragment>
        );
      })}
    </>
  );
}
