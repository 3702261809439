import React, { useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card,
  CardContent,
  Divider as MuiDivider,
  Grid,
  Link,
  Tab,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useTranslation } from "react-i18next";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { EnhancedTable, HeadCell } from "../../components/EnhancedTable";
import {
  getCurrencySymbol,
  toLocaleCurrencyStringWithoutSymbol,
} from "../../utils/string";
import { submarketColors } from "../../constants";
import LineChart from "./LineChart";
import { balcaoApiInstance } from "../../utils/axios";
import { DateTime } from "luxon";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Divider = styled(MuiDivider)(spacing);

type GraphData = {
  start: string;
  end: string;
  data: {
    [key: string]: string;
  };
}[];

type PLDStatsFromAPI = {
  data_inicio: string;
  data_fim: string;
  values: {
    [key: string]: string;
  };
}[];

export default function PldWeeklyAverage() {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const [graphData, setGraphData] = useState<GraphData>([]);

  const getGraphData = useCallback(async () => {
    const { data: data1 } = await balcaoApiInstance.get<PLDStatsFromAPI>(
      "pld/week/current-month"
    );

    setGraphData(
      data1.map((weekData: any) => {
        return {
          start: DateTime.fromISO(weekData.data_inicio).toFormat("yyyy-MM-dd"),
          end: DateTime.fromISO(weekData.data_fim).toFormat("yyyy-MM-dd"),
          data: weekData.values,
        };
      })
    );
    setValue((data1.length - 1).toString());
  }, []);

  useEffect(() => {
    getGraphData();
  }, [getGraphData]);

  const [value, setValue] = React.useState("0");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const headCells = React.useMemo<Array<HeadCell>>(
    () => [
      {
        id: "submarket",
        alignment: "left",
        label: t("Submarket"),
        disableSort: true,
      },
      {
        id: "weeklyAverage",
        alignment: "right",
        label: `${t("Weekly Average")} (${getCurrencySymbol(
          "BRL",
          language
        )}/MWh)`,
        disableSort: true,
      },
    ],
    [language, t]
  );

  return (
    <React.Fragment>
      <Helmet title={t("Weekly Average")} />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            {t("Weekly Average")}
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              {t("Home")}
            </Link>
            <Typography>{t("Weekly Average")}</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6} justifyContent="center">
        <Grid item xs={12} lg={8}>
          <Grid container spacing={6} justifyContent="center">
            {["N", "NE", "SE/CO", "S"].map((submarket) => {
              const datasetData = graphData.map((item) =>
                Number(item.data[submarket as "N" | "NE" | "SE/CO" | "S"])
              );
              const labels = graphData.map((item) =>
                new Date(item.end).toLocaleDateString(language, {
                  timeZone: "UTC",
                })
              );

              return (
                <Grid item xs={12} sm={6} key={submarket}>
                  <LineChart
                    data={datasetData}
                    labels={labels}
                    title={
                      <span style={{ color: submarketColors[submarket] }}>
                        {submarket}
                      </span>
                    }
                    color={submarketColors[submarket]}
                    label={`PLD (${getCurrencySymbol("BRL", language)}/MWh)`}
                    height={138}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>

        <Grid item xs={12} lg={4}>
          <Card sx={{ height: "100%" }}>
            <CardContent>
              <Box textAlign="center">
                <Typography variant="h6">{t("Weekly Average")}</Typography>
              </Box>

              <Box textAlign="center">
                <Typography variant="body2" mt={1}>
                  {graphData.length > 0 && (
                    <>
                      {" "}
                      {DateTime.fromISO(
                        graphData[parseInt(value)].end
                      ).toLocaleString({ month: "long" }, { locale: language })}
                      {language === "pt" ? "/" : " "}
                      {DateTime.fromISO(
                        graphData[parseInt(value)].end
                      ).toFormat("yyyy")}
                    </>
                  )}
                  {/* {t("Janeiro/2023")} */}
                </Typography>
              </Box>

              <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={value}>
                  {/* <Box textAlign="center">
                    <Typography variant="caption" mt={1}>
                      {t("Selecione uma semana")}
                    </Typography>
                  </Box> */}
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                    variant="scrollable"
                    scrollButtons="auto"
                    // variant="fullWidth"
                    sx={{
                      ".MuiTabs-flexContainer": { justifyContent: "center" },
                    }}
                  >
                    {graphData.map((item, idx) => {
                      return (
                        <Tab
                          label={String(idx + 1)}
                          value={String(idx)}
                          key={String(idx)}
                          sx={{ minWidth: 56 }}
                        />
                      );
                      // return <Tab label={item.week} value={String(idx)} />;
                    })}
                  </TabList>
                  {graphData.map((item, idx) => {
                    return (
                      <TabPanel value={String(idx)} key={String(idx)}>
                        <EnhancedTable
                          data={(
                            Object.keys(item.data) as Array<
                              keyof typeof item.data
                            >
                          ).map((submarket) => ({
                            submarket: submarket,
                            value: item.data[submarket],
                          }))}
                          headCells={headCells}
                          avoidEmptyRows
                          disablePagination
                          keyExtractor={(row) => `${idx}:${row.submarket}`}
                          renderRow={(row) => {
                            return (
                              <TableRow hover tabIndex={-1}>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{
                                    fontWeight: 500,
                                    color: submarketColors[row.submarket],
                                  }}
                                >
                                  {row.submarket}
                                </TableCell>
                                <TableCell align="right">
                                  {toLocaleCurrencyStringWithoutSymbol(
                                    Number(row.value),
                                    "BRL",
                                    language
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          }}
                          footer={
                            <Box px={2} py={4}>
                              <Typography color="text.disabled">
                                {t("Reference")}:{" "}
                                {new Date(item.start).toLocaleDateString(
                                  language,
                                  { timeZone: "UTC" }
                                )}{" "}
                                {t("to")}{" "}
                                {new Date(item.end).toLocaleDateString(
                                  language,
                                  {
                                    timeZone: "UTC",
                                  }
                                )}
                              </Typography>
                            </Box>
                          }
                        />
                      </TabPanel>
                    );
                  })}
                </TabContext>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
