import { useTranslation } from "react-i18next";
import { submarketColors } from "../constants";
import { getLocaleMonthAndYearShortString, ParseableDate } from "../utils/date";

export interface PriceTypeShortProps {
  submarket: string;
  product: string;
  priceType: string;
  freeBid: string | undefined;
}
export function PriceTypeShort({
  submarket,
  product,
  priceType,
  freeBid,
}: PriceTypeShortProps) {
  const { t } = useTranslation();

  const priceTypeShort =
    priceType === "Fixo"
      ? t("Fixo")
      : priceType === "Atrelado ao PLD"
      ? "PLD+Spread"
      : priceType === "Lance livre"
      ? t("L Livre") +
        " " +
        (freeBid === "Fixo"
          ? t("Fixo")
          : freeBid === "Atrelado PLD + Spread"
          ? "PLD+Spread"
          : freeBid)
      : priceType;

  return (
    <span>
      <span style={{ color: submarketColors[submarket] }}>{submarket}</span>{" "}
      {product} {priceTypeShort}
    </span>
  );
}

export interface PeriodShortProps {
  periodStart: ParseableDate;
  periodEnd: ParseableDate;
}
export function PeriodShort({ periodStart, periodEnd }: PeriodShortProps) {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const periodStartShort = getLocaleMonthAndYearShortString(
    periodStart,
    language
  );
  const periodEndShort = getLocaleMonthAndYearShortString(periodEnd, language);

  const period =
    periodStartShort && periodEndShort
      ? periodStartShort === periodEndShort
        ? periodStartShort
        : `${periodStartShort} ${t("to")} ${periodEndShort}`
      : "-";

  return <span>{period}</span>;
}
