import React, { useMemo, useState } from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import {
  Alert as MuiAlert,
  Box,
  CircularProgress,
  Autocomplete,
  Button,
  Paper,
  Divider,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { useSnackbar } from "notistack";
import { StyledTextField } from "../StyledTextField";
import { FormTextField } from "../formFields/FormTextField";
import { getISOYearAndMonth } from "../../utils/date";
import { getCustomNumberInputProps } from "../directBuyAndSell/BigInput";
import { AddRounded, RemoveRounded } from "@mui/icons-material";
import { subMonths } from "date-fns";
import { useQuery } from "@tanstack/react-query";
import { getMonthlyPld } from "../../services/pld";
import {
  toLocaleCurrencyString,
  toLocaleCurrencyStringWithoutSymbol,
} from "../../utils/string";
import { useGetFixedDeliveryPeriod } from "./hooks/useGetFixedDeliveryPeriod";
import { submarketColors } from "../../constants";
import { useFirestoreQuery } from "../../hooks/useFirestoreQuery";
import * as firestore from "firebase/firestore";
import { DateTime } from "luxon";
import { useSubmitNewOrder } from "./hooks/useSubmitNewOrder";
import { toFixedWithoutRounding } from "../../utils/number";
import { useGetParamsWithHash } from "../bank/hooks/useGetParamsWithHash";

const offerCloseDateTimeOptions: Array<{ id: string; name: string }> = [
  { id: "1", name: "1 hora" },
  { id: "2", name: "2 horas" },
  { id: "3", name: "3 horas" },
  { id: "4", name: "4 horas" },
  { id: "5", name: "5 horas" },
  { id: "6", name: "6 horas" },
  { id: "7", name: "7 horas" },
  { id: "8", name: "8 horas" },
  { id: "9", name: "9 horas" },
  { id: "10", name: "10 horas" },
  { id: "11", name: "11 horas" },
  { id: "12", name: "12 horas" },
  { id: "24", name: "24 horas" },
  { id: "36", name: "36 horas" },
  { id: "48", name: "48 horas" },
];

const orderType: any = {
  0: "Buy",
  1: "Sell",
};

interface Inputs {
  orderType: string;
  submarket: string | null;
  product: string | null;
  offerCloseDateTime: string | null;
  priceValue: string;
  volume: string;
  total: string;
}

const inputsDefaultValues: Inputs = {
  orderType: "Buy",
  submarket: null,
  product: null,
  offerCloseDateTime: null,
  priceValue: "0",
  volume: "0",
  total: "0",
};

const Alert = styled(MuiAlert)(spacing);

const InfoBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24;
`;

const ButtonAction = styled(Button)`
  color: #ffffff;
  border-radius: 0;
  font-size: 0.8rem;
  width: 100%;

  &:hover {
    background-color: transparent !important;
  }
`;

const ButtonSubmit = styled(LoadingButton)`
  color: #ffffff;
  background-color: #72bf65;
  border-radius: 10px;

  &:hover {
    background-color: #72bf65;
    opacity: 0.9;
  }
`;

export function BoxBuyOrSell() {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();

  const lastMonth = React.useMemo(
    () => getISOYearAndMonth(subMonths(new Date(), 1)),
    []
  );

  const { energyCode } = useGetParamsWithHash();

  const {
    data: pldData,
    isLoading: isLoadingPld,
    isError: isErrorPld,
  } = useQuery(["/pld/month", lastMonth, lastMonth], () =>
    getMonthlyPld(lastMonth, lastMonth)
  );

  const { fixedDeliveryPeriod } = useGetFixedDeliveryPeriod();

  const {
    data: [products],
  } = useFirestoreQuery<{ name: string; assetsAvailableCode: string }>(() => {
    return [
      firestore.query(
        firestore.collection(firestore.getFirestore(), "products")
      ),
    ];
  }, []);

  const productsSorted = useMemo(() => {
    if (!products) return [];

    return products.sort((a, b) => {
      const assetA = a.name.toString().toUpperCase();
      const assetB = b.name.toString().toUpperCase();
      return assetA.localeCompare(assetB, undefined, { numeric: true });
    });
  }, [products]);

  const { submitNewOrderMutate, isLoading: isLoadingNewOrder } =
    useSubmitNewOrder({
      onSuccess: () => {
        enqueueSnackbar(t("Order created successfully"), {
          variant: "success",
        });
      },
      onError: (error) => {
        if ((error as any).data?.invalidFields) {
          const invalidFields = (error as any).data?.invalidFields;
          for (const key in invalidFields) {
            const value = invalidFields[key];
            enqueueSnackbar(t(value), {
              variant: "error",
            });
          }

          return;
        } else if (error.message.startsWith("Offer limit reached")) {
          const limit = parseInt((error as any).value);

          enqueueSnackbar(
            t(
              "Operational limit reached for period, remaining available negotiation value: {{ remainingValue }}",
              {
                remainingValue: toLocaleCurrencyStringWithoutSymbol(
                  Math.max(0, limit),
                  "BRL",
                  language
                ),
              }
            ),
            {
              variant: "error",
            }
          );

          return;
        } else if (error.message.startsWith("Offer value is too low")) {
          const PLD = pldDataBySubmarket[submarket as string] * 100;
          const minimumValue = parseFloat((error as any).value);
          const minimumSpreadValue = (minimumValue * 100 - PLD) / 100;
          const minimumSpreadValueSign =
            Math.sign(minimumSpreadValue) === -1 ? "-" : "+";
          const absoluteMinimumSpreadValue = Math.abs(minimumSpreadValue);

          enqueueSnackbar(
            t(
              `Operation outside the price tunnel, the current minimum value for order creation is R$ {{ minimumValue }} or PLD ${minimumSpreadValueSign} R$ {{ minimumSpreadValue }}`,
              {
                minimumValue: toLocaleCurrencyStringWithoutSymbol(
                  Math.max(0, minimumValue),
                  "BRL",
                  language
                ),
                minimumSpreadValue: toLocaleCurrencyStringWithoutSymbol(
                  Math.max(0, absoluteMinimumSpreadValue),
                  "BRL",
                  language
                ),
              }
            ),
            {
              variant: "error",
            }
          );

          return;
        } else if (error.message.startsWith("Offer value is too high")) {
          const PLD = pldDataBySubmarket[submarket as string] * 100;
          const maximumValue = parseFloat((error as any).value);
          const maximumSpreadValue = (maximumValue * 100 - PLD) / 100;
          const minimumSpreadValueSign =
            Math.sign(maximumSpreadValue) === -1 ? "-" : "+";
          const absoluteMaximumSpreadValue = Math.abs(maximumSpreadValue);

          enqueueSnackbar(
            t(
              `Operation outside the price tunnel, the maximum current value for order creation is R$ {{ maximumValue }} or PLD ${minimumSpreadValueSign} R$ {{ maximumSpreadValue }}`,
              {
                maximumValue: toLocaleCurrencyStringWithoutSymbol(
                  Math.max(0, maximumValue),
                  "BRL",
                  language
                ),
                maximumSpreadValue: toLocaleCurrencyStringWithoutSymbol(
                  Math.max(0, absoluteMaximumSpreadValue),
                  "BRL",
                  language
                ),
              }
            ),
            {
              variant: "error",
            }
          );

          return;
        } else if (error.message.startsWith("User does not have a limit set")) {
          enqueueSnackbar(t("User does not have a limit set"), {
            variant: "error",
          });

          return;
        }

        if (error.statusCode === 500) {
          enqueueSnackbar(
            t(
              "Não foi possível realizar a operação, por favor tente novamente em alguns minutos"
            ),
            {
              variant: "error",
            }
          );
        } else {
          enqueueSnackbar(t(error.message), {
            variant: "error",
          });
        }
      },
    });

  const buttonsActions = [t("Buy"), t("Sell")];

  const [tabActive, setTabActive] = React.useState<number>(0);

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: inputsDefaultValues,
  });

  const [submarket, product, priceValue, volume, total] = watch([
    "submarket",
    "product",
    "priceValue",
    "volume",
    "total",
  ]);

  const pldDataBySubmarket: any = React.useMemo(() => {
    return pldData ? pldData[0].monthlyPldBySubmarket : [];
  }, [pldData]);

  function fixedNumber(value: string) {
    return value.replace(",", ".");
  }

  const [minValue, setMinValue] = useState<number | null>(null);

  function handleActiveTab(index: number) {
    setValue("orderType", orderType[index]);
    setTabActive(index);
  }

  React.useEffect(() => {
    if (submarket) {
      const pldAndPrice =
        Number(pldDataBySubmarket[submarket]) +
        Number(fixedNumber(priceValue.toString()));
      setMinValue(pldDataBySubmarket[submarket] * -1);
      setValue(
        "total",
        toLocaleCurrencyStringWithoutSymbol(
          pldAndPrice * Number(fixedNumber(volume)),
          "BRL",
          language
        )
      );
    }
  }, [priceValue, volume, setValue, submarket, pldDataBySubmarket, language]);

  React.useEffect(() => {
    if (energyCode && !product) {
      setValue(
        "product",
        products?.find((item) => item.assetsAvailableCode === energyCode)?.id ??
          null
      );
    }
  }, [energyCode, product, products, setValue]);

  const onSubmit = React.useCallback<SubmitHandler<Inputs>>(
    (data) => {
      const {
        priceValue,
        volume,
        submarket,
        orderType,
        product,
        offerCloseDateTime,
      } = data;
      if (submarket) {
        const data = {
          submarket,
          offerCloseDateTime: DateTime.now()
            .plus({ hours: Number(offerCloseDateTime) })
            .toISO(),
          product,
          [orderType === "Buy" ? "buyOffer" : "sellOffer"]: {
            priceValue: Number(priceValue),
            volume: Number(volume),
          },
        };

        submitNewOrderMutate(data);
      }
    },
    [submitNewOrderMutate]
  );

  const onInvalidSubmit = React.useCallback<SubmitErrorHandler<Inputs>>(() => {
    enqueueSnackbar(t("You must fill in all required fields correctly"), {
      variant: "error",
    });
  }, [enqueueSnackbar, t]);

  if (isErrorPld) {
    return (
      <Alert mt={2} mb={3} severity="error">
        {t("Something went wrong.")}
      </Alert>
    );
  }

  if (isLoadingPld || pldData === undefined) {
    return (
      <InfoBox>
        <CircularProgress />
      </InfoBox>
    );
  }

  return (
    <Paper sx={{ p: 2 }}>
      <Box height="100%">
        <Box display="flex" mb={5}>
          {buttonsActions.map((item, index) => {
            const isActive = tabActive === index;
            return (
              <ButtonAction
                key={index}
                sx={{
                  borderBottom: `3px solid`,
                  borderColor: isActive
                    ? tabActive === 0
                      ? "success.main"
                      : "error.main"
                    : "transparent",
                }}
                onClick={() => handleActiveTab(index)}
              >
                {item}
              </ButtonAction>
            );
          })}
        </Box>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit, onInvalidSubmit)}
          display="flex"
          flexDirection="column"
          gap={2}
          justifyContent="space-between"
          // height="calc(100% - 57px)"
        >
          <Box display="flex" gap={2} alignItems="flex-end">
            <Controller
              control={control}
              name={"submarket"}
              rules={{
                required: {
                  value: true,
                  message: t("This field is required"),
                },
              }}
              render={({ field: { ref, onChange, ...field } }) => (
                <Autocomplete
                  {...field}
                  onChange={(e, newValue) => {
                    onChange(newValue);
                  }}
                  openOnFocus
                  handleHomeEndKeys
                  disablePortal
                  options={Object.keys(submarketColors)}
                  fullWidth
                  renderInput={(params) => (
                    <StyledTextField
                      {...params}
                      InputLabelProps={{
                        sx: [
                          {
                            whiteSpace: "initial",
                            fontSize: "12px",
                          },
                        ],
                      }}
                      inputRef={ref}
                      label={t("Delivery Point")}
                      error={!!errors.submarket}
                      helperText={errors.submarket?.message ?? " "}
                      FormHelperTextProps={{
                        sx: [
                          {
                            display: !!errors.submarket ? "initial" : "none",
                          },
                        ],
                      }}
                      // size="small"
                    />
                  )}
                />
              )}
            />
            <StyledTextField
              InputLabelProps={{
                sx: [
                  {
                    whiteSpace: "initial",
                    fontSize: "12px",
                  },
                ],
              }}
              label={`${t("Delivery Period")} (${t("Start")})`}
              fullWidth
              value={fixedDeliveryPeriod?.firstDay}
              InputProps={{ readOnly: true }}
              // helperText=" "
              // size="small"
            />
          </Box>
          <Box display="flex" gap={2} alignItems="flex-end">
            <Controller
              control={control}
              name={"product"}
              rules={{
                required: {
                  value: true,
                  message: t("This field is required"),
                },
              }}
              render={({ field: { ref, onChange, ...field } }) => (
                <Autocomplete
                  {...field}
                  onChange={(e, newValue) => {
                    onChange(newValue);
                  }}
                  openOnFocus
                  handleHomeEndKeys
                  disablePortal
                  options={productsSorted?.map((item) => item.id) ?? []}
                  getOptionLabel={(option) =>
                    products?.find((product) => product.id === option)?.name ??
                    option
                  }
                  fullWidth
                  renderInput={(params) => (
                    <StyledTextField
                      {...params}
                      InputLabelProps={{
                        sx: [
                          {
                            whiteSpace: "initial",
                            fontSize: "12px",
                          },
                        ],
                      }}
                      inputRef={ref}
                      label={t("Source Type")}
                      error={!!errors.submarket}
                      helperText={errors.submarket?.message ?? " "}
                      FormHelperTextProps={{
                        sx: [
                          {
                            display: !!errors.submarket ? "initial" : "none",
                          },
                        ],
                      }}
                      // size="small"
                    />
                  )}
                />
              )}
            />
            <StyledTextField
              InputLabelProps={{
                sx: [
                  {
                    whiteSpace: "initial",
                    fontSize: "12px",
                  },
                ],
              }}
              label={`${t("Delivery Period")} (${t("End")})`}
              fullWidth
              value={fixedDeliveryPeriod?.lastDay}
              InputProps={{ readOnly: true }}
              // helperText=" "
              // size="small"
            />
          </Box>

          <Controller
            control={control}
            name={"offerCloseDateTime"}
            rules={{
              required: {
                value: true,
                message: t("This field is required"),
              },
            }}
            render={({ field: { ref, onChange, ...field } }) => (
              <Autocomplete
                {...field}
                onChange={(e, newValue) => {
                  onChange(newValue);
                }}
                openOnFocus
                handleHomeEndKeys
                disablePortal
                options={offerCloseDateTimeOptions.map((item) => item.id)}
                getOptionLabel={(option) =>
                  offerCloseDateTimeOptions.find(
                    (product) => product.id === option
                  )?.name ?? option
                }
                fullWidth
                sx={{ width: "calc(50% - 4px)" }}
                renderInput={(params) => (
                  <StyledTextField
                    {...params}
                    InputLabelProps={{
                      sx: [
                        {
                          whiteSpace: "initial",
                          fontSize: "12px",
                        },
                      ],
                    }}
                    inputRef={ref}
                    label={t("Term Time of Order")}
                    error={!!errors.submarket}
                    helperText={errors.submarket?.message ?? " "}
                    FormHelperTextProps={{
                      sx: [
                        {
                          display: !!errors.submarket ? "initial" : "none",
                        },
                      ],
                    }}
                    // size="small"
                  />
                )}
              />
            )}
          />
          <Divider />
          <Box
            display="flex"
            flexDirection="column"
            gap={2}
            justifyContent="center"
            py={2}
          >
            <FormTextField
              InputLabelProps={{
                sx: [
                  {
                    whiteSpace: "initial",
                    fontSize: "12px",
                  },
                ],
              }}
              control={control}
              name={"priceValue"}
              rules={{
                required: {
                  value: true,
                  message: t("This field is required"),
                },
                max: {
                  value: 999999999,
                  message: t("The maximum allowed value is {{value}}", {
                    value: toLocaleCurrencyString(999999999, "BRL", language),
                  }),
                },
                onChange: (e) => {
                  if (e.target.value.split(".")[1]?.length > 2)
                    setValue(
                      "priceValue",
                      toFixedWithoutRounding(e.target.value, 2)
                    );
                },
                validate: (value) =>
                  Number(value) > (minValue || 0) ||
                  t("The data entered is invalid", {
                    data: t("Price"),
                  }),
              }}
              label={`${t("Price")} PLD + R$`}
              fullWidth
              type="number"
              size="small"
              inputProps={{ min: minValue, step: "0.001" }}
              InputProps={getCustomNumberInputProps({
                disableArrows: true,
                disableWheel: true,
                disableE: true,
                disableUpDownKeys: true,
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ lineHeight: 0, color: "rgba(255, 255, 255, 0.5)" }}
                  >
                    PLD +{" "}
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      sx={{ p: 0 }}
                      onClick={() => {
                        if (
                          minValue === null ||
                          Number(priceValue) - 0.5 <= minValue
                        ) {
                          setValue("priceValue", (minValue || 0).toString());
                          return;
                        }
                        setValue(
                          "priceValue",
                          (Number(priceValue) - 0.5).toString()
                        );
                      }}
                      disabled={
                        minValue === null || Number(priceValue) <= minValue
                      }
                    >
                      <RemoveRounded />
                    </IconButton>
                    <IconButton
                      sx={{ p: 0, mr: 1 }}
                      onClick={() =>
                        setValue(
                          "priceValue",
                          (Number(priceValue) + 0.5).toString()
                        )
                      }
                    >
                      <AddRounded />
                    </IconButton>
                    R$
                  </InputAdornment>
                ),
                sx: [{ height: "35.69px" }],
              })}
              FormHelperTextProps={{
                sx: [
                  {
                    display: !!errors.submarket ? "initial" : "none",
                  },
                ],
              }}
            />
            <FormTextField
              InputLabelProps={{
                sx: [
                  {
                    whiteSpace: "initial",
                    fontSize: "12px",
                  },
                ],
              }}
              control={control}
              name={"volume"}
              type="number"
              rules={{
                required: {
                  value: true,
                  message: t("This field is required"),
                },
                min: {
                  value: 1,
                  message: t("The minimum allowed value is {{value}}", {
                    value:
                      (1).toLocaleString(language, {
                        minimumFractionDigits: 3,
                        maximumFractionDigits: 3,
                      }) + " MWh",
                  }),
                },
                max: {
                  value: 999999999,
                  message: t("The maximum allowed value is {{value}}", {
                    value:
                      (999999999).toLocaleString(language, {
                        minimumFractionDigits: 3,
                        maximumFractionDigits: 3,
                      }) + " MWh",
                  }),
                },
                onChange: (e) => {
                  if (e.target.value.split(".")[1]?.length > 3)
                    setValue(
                      "volume",
                      toFixedWithoutRounding(e.target.value, 3)
                    );
                },
                validate: (value) =>
                  Number(value) > 0 ||
                  t("The data entered is invalid_female", {
                    data: t("Quantity"),
                  }),
              }}
              label={t("Quantity")}
              fullWidth
              size="small"
              inputProps={{ min: 0, step: "0.001" }}
              InputProps={getCustomNumberInputProps({
                disableArrows: true,
                disableWheel: true,
                disableE: true,
                disableUpDownKeys: true,
                endAdornment: (
                  <InputAdornment position="end">MWh</InputAdornment>
                ),
                sx: [{ height: "35.69px" }],
              })}
              FormHelperTextProps={{
                sx: [
                  {
                    display: !!errors.submarket ? "initial" : "none",
                  },
                ],
              }}
            />
            <StyledTextField
              InputLabelProps={{
                sx: [
                  {
                    whiteSpace: "initial",
                    fontSize: "12px",
                  },
                ],
              }}
              label={t("Total")}
              fullWidth
              value={total}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">R$</InputAdornment>
                ),
              }}
              // helperText=" "
              // size="small"
            />
          </Box>
          <Divider />
          <ButtonSubmit type="submit" loading={isLoadingNewOrder}>
            {t("CREATE ORDER")}
          </ButtonSubmit>
        </Box>
      </Box>
    </Paper>
  );
}
