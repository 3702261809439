import { ChevronLeft } from "@mui/icons-material";
import {
  Box,
  BoxProps,
  Button,
  Modal,
  ModalProps,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import logoImg from "../vendor/logo-plug1-dark.svg";

export interface StyledModalProps extends ModalProps {
  maxWidth?: React.CSSProperties["maxWidth"];
  titleStartAdornment?: string | number | JSX.Element;
  leftBox?: string | number | JSX.Element;
  disablePadding?: boolean;
  disableTitlebar?: boolean;
  contentBoxProps?: BoxProps;
}

export function StyledModal({
  maxWidth = "1200px",
  title,
  titleStartAdornment,
  leftBox,
  disablePadding,
  contentBoxProps,
  disableTitlebar,
  children,
  ...rest
}: StyledModalProps) {
  const { t } = useTranslation();

  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Modal {...rest}>
      <Paper
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          maxWidth,
          maxHeight: "85%",
          overflowY: "auto",
          borderRadius: 4,
          // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          // height: "100%",
        }}
      >
        {!disableTitlebar && (
          <Box
            sx={{
              width: "100%",
              minHeight: 52,
              backgroundColor: "#767676",
              color: "#fff",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 4,
              p: 3,
            }}
          >
            {leftBox !== undefined && (
              <Box
                sx={{
                  border: "1px solid #fff",
                  minWidth: 64,
                  minHeight: 24,
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  px: 4,
                }}
              >
                <Typography variant="h6">{leftBox}</Typography>
              </Box>
            )}

            <Box
              sx={{
                minHeight: 24,
                display: "flex",
                alignItems: "center",
                flex: 1,
              }}
            >
              {titleStartAdornment !== undefined && titleStartAdornment}
              {title !== undefined && (
                <Typography variant="h6">{title}</Typography>
              )}
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                height: 28,
              }}
            >
              {isSmUp && (
                <Box
                  sx={{
                    minHeight: 24,
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 1,
                  }}
                >
                  {/* <Typography variant="h6">Plug1</Typography> */}
                  <img src={logoImg} alt="Plug1" width={52} height={24} />
                </Box>
              )}

              {rest.onClose && (
                <Button
                  type="button"
                  onClick={(e) => {
                    if (rest.onClose) rest.onClose(e, "backdropClick");
                  }}
                  sx={{
                    color: "inherit",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.08)" },
                  }}
                >
                  <ChevronLeft />
                  {t("Go Back")}
                </Button>
              )}
            </Box>
          </Box>
        )}

        <Box
          {...contentBoxProps}
          sx={{
            p: disablePadding ? 0 : 6,
            flex: 1,
            overflow: "auto",
            ...(contentBoxProps?.sx ?? {}),
          }}
        >
          {children}
        </Box>
      </Paper>
    </Modal>
  );
}
