import React from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  ArrowCircleDownRounded,
  ArrowCircleUpRounded,
} from "@mui/icons-material";
import { OfferBookItem } from "../../directBuyAndSell/entities/OfferBookItem";
import { SetRowsTable } from "./SetRowsTable";

import { toLocaleCurrencyString } from "../../../utils/string";

export interface OrderBookProps {
  name: string;
  orders: Array<OfferBookItem>;
}

interface TableOrderBookProps {
  dataBuy: Array<OrderBookProps>;
  dataSell: Array<OrderBookProps>;
  currentValue: number;
  status: "valued" | "devalued" | "remained";
}

export function TableOrderBook({
  dataBuy,
  dataSell,
  currentValue,
  status,
}: TableOrderBookProps) {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const ValueStatus = (
    currentValue: number,
    status: "valued" | "devalued" | "remained"
  ) => {
    function color() {
      if (status === "valued") return "success.main";
      if (status === "devalued") return "error.main";
      return "#ffffff";
    }

    return (
      <Box
        display="flex"
        alignItems="center"
        width="100%"
        py={2}
        gap={3}
        pl={2}
      >
        {
          <>
            <Typography variant="h5" color={color()} fontWeight="bold">
              {toLocaleCurrencyString(currentValue, "BRL", language)}
            </Typography>
            {status === "valued" && (
              <ArrowCircleUpRounded
                fontSize="small"
                sx={{ color: "success.main" }}
              />
            )}
            {status === "devalued" && (
              <ArrowCircleDownRounded
                fontSize="small"
                sx={{ color: "error.main" }}
              />
            )}
          </>
        }
      </Box>
    );
  };

  return (
    <>
      <Box sx={{ width: "100%", overflow: "auto" }}>
        <Box>
          <Box gap={3} mb={2}>
            <Box display="flex">
              <Box
                sx={{
                  fontSize: "12px",
                  color: "#a6b4c9",
                  width: "25%",
                  minWidth: "25%",
                  paddingX: "5px",
                  pb: 0,
                  border: "none",
                }}
              >
                <Typography textAlign="center">{`${t(
                  "Price"
                )} (R$)`}</Typography>
              </Box>
              <Box
                sx={{
                  fontSize: "12px",
                  color: "#a6b4c9",
                  width: "25%",
                  minWidth: "25%",
                  paddingX: "5px",
                  pb: 0,
                  border: "none",
                }}
              >
                <Typography textAlign="center">{`${t(
                  "Quantity"
                )} (MWh)`}</Typography>
              </Box>
              <Box
                sx={{
                  fontSize: "12px",
                  color: "#a6b4c9",
                  width: "25%",
                  minWidth: "25%",
                  paddingX: "5px",
                  pb: 0,
                  border: "none",
                }}
              >
                <Typography textAlign="center">{`${t(
                  "Total"
                )} (R$)`}</Typography>
              </Box>
              <Box
                sx={{
                  fontSize: "12px",
                  color: "#a6b4c9",
                  width: "25%",
                  minWidth: "25%",
                  paddingX: "5px",
                  pb: 0,
                  border: "none",
                }}
              >
                <Typography textAlign="center">{`${t(
                  "Average Remaining Time"
                )}`}</Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              borderBottom: dataSell.length
                ? "1px solid rgba(255, 255, 255, 0.12)"
                : "none",
              width: "100%",
              maxHeight: "240px",
              overflow: "auto",
            }}
          >
            {dataSell?.map((item, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    borderTop: "1px solid rgba(255, 255, 255, 0.12)",
                  }}
                >
                  <SetRowsTable
                    offerBook={item.orders}
                    type="VENDA"
                    productName={item.name}
                  />
                </Box>
              );
            })}
          </Box>
          {ValueStatus(currentValue, status)}
          <Box
            sx={{
              borderBottom: dataBuy.length
                ? "1px solid rgba(255, 255, 255, 0.12)"
                : "none",
              width: "100%",
              maxHeight: "240px",
              overflow: "auto",
            }}
          >
            {dataBuy?.map((item, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    borderTop: "1px solid rgba(255, 255, 255, 0.12)",
                  }}
                >
                  <SetRowsTable
                    offerBook={item.orders}
                    type="COMPRA"
                    productName={item.name}
                  />
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
    </>
  );
}
