import { balcaoApiInstance } from "../../../../utils/axios";

export interface Asset {
  code: string;
  name: string;
  unit: string;
  type: string;
  precision: number;
  avatar: string;
}
export interface AssetBalance {
  asset: Asset;
  balance: number;
  blockedBalance: number;
}
export interface BalanceData {
  assetBalances: Array<AssetBalance>;
}
export async function getBalanceData() {
  const { data } = await balcaoApiInstance.get<BalanceData>(
    "/wallets/mywallet?groupByAsset=false"
  );

  return data;
}

export interface DepositMethod {
  id: string;
  name: string;
  methodInfo: string;
}
export async function getDepositMethods() {
  const { data } = await balcaoApiInstance.get<Array<DepositMethod>>(
    "/depositmethods"
  );

  return data;
}

export interface CreateFinancialDepositProps {
  depositMethod: string;
  value: number;
}
export async function createFinancialDeposit(
  options: CreateFinancialDepositProps
) {
  await balcaoApiInstance.post("/assetsrequests/deposits/currency", options);
}

export interface CreateEnergyDepositProps {
  code: string;
  submarket: string;
  value: number;
}
export async function createEnergyDeposit(options: CreateEnergyDepositProps) {
  await balcaoApiInstance.post("/assetsrequests/deposits/energy", options);
}

export interface BankList {
  id: string;
  description: string;
}
export async function getBanks() {
  const { data } = await balcaoApiInstance.get<Array<BankList>>("/banks");

  return data;
}

export interface CreateFinancialWithdrawalProps {
  countryBankName: string;
  bankId: string;
  accountOwnerName: string;
  accountType: string;
  accountNumber: string;
  agencyNumber: string;
  value: number;
  password: string;
}
export async function createFinancialWithdrawal(
  options: CreateFinancialWithdrawalProps
) {
  await balcaoApiInstance.post("/assetsrequests/withdraw/currency", options);

  return true;
}

export interface CreateEnergyWithdrawalProps {
  cliqCCEEProfile: string;
  cliqCCEECode: string;
  code: string;
  submarket: string;
  value: number;
  password: string;
}
export async function createEnergyWithdrawal(
  options: CreateEnergyWithdrawalProps
) {
  await balcaoApiInstance.post("/assetsrequests/withdraw/energy", options);

  return true;
}

export interface PaginatedData<T> {
  total: number;
  data: T;
}
export interface Participant {
  id: string | number;
  name: string;
  cnpj: string;
}
export interface Operator {
  id: string | number;
  name: string;
}
export interface CancelStatusReason {
  cancelStatus: {
    id: string;
    description: string;
  };
  details: string;
}
export interface DepositOrWithdrawalRequest {
  id: string;
  status: "CREATED" | "FINISHED" | "CANCELED";
  asset: Asset;
  submarket: string;
  amount: number;
  requestedAt: string;
  requestType: "deposit" | "withdrawal";
  depositType: "currency" | "energy";
  depositMethodId?: string;
  participant: Participant;
  operator: Operator;
  cancelStatusReason?: CancelStatusReason | null;
}
export interface GetDepositsAndWithdrawalsRequestsProps {
  cnpj?: string;
  status?: string;
  itemsPerPage?: number;
  page?: number;
}
export async function getDepositsAndWithdrawalsRequests({
  cnpj,
  status,
  itemsPerPage = 10,
  page = 1,
}: GetDepositsAndWithdrawalsRequestsProps) {
  const { data } = await balcaoApiInstance.get<
    PaginatedData<Array<DepositOrWithdrawalRequest>>
  >("/assetsrequests", {
    params: { cnpj, status, perPage: itemsPerPage, page },
  });

  return data;
}

export async function getReasonsOptions() {
  const { data } = await balcaoApiInstance.get<
    Array<{ id: string; description: string }>
  >("/assetsrequests/cancel-reasons");

  return data;
}

export interface RefuseAssetRequestProps {
  id: string;
  data: {
    reasonId: string | null;
    details: string;
  };
}

export async function refuseAssetRequest(options: RefuseAssetRequestProps) {
  await balcaoApiInstance.put(
    `/assetsrequests/${options.id}/refuse`,
    options.data
  );
  return true;
}

export async function acceptAssetRequest(id: string) {
  await balcaoApiInstance.post(`/assetsrequests/${id}/accept`);
  return true;
}

export async function getAssetsBlocked() {
  const { data } = await balcaoApiInstance.get<
    Array<{ assetCode: string; value: number }>
  >("/operation/assets-blocked");

  return data;
}

export interface ParticipantBankData {
  bank: {
    accountNumber: string;
    accountOwnerName: string;
    agencyNumber: string;
    bankName: string;
    bankId: string;
    cnpj: string;
  };
  ccee: Array<{
    name: string;
    profileCode: string;
    profileKey: string;
  }>;
}

export async function getParticipantBankDataByAssetId(assetRequestId: string) {
  const { data } = await balcaoApiInstance.get<ParticipantBankData>(
    `/assetsrequests/${assetRequestId}/participant-bank-ccee`
  );

  return data;
}

export async function getParticipantBankData() {
  const { data } = await balcaoApiInstance.get<ParticipantBankData>(
    `/assetsrequests/participant-bank-ccee`
  );

  return data;
}

export async function getBankData() {
  const { data } = await balcaoApiInstance.get<ParticipantBankData>(
    `/assetsrequests/system-bank-ccee`
  );

  return data;
}
