import { useQuery } from "@tanstack/react-query";
import { getDepositMethods } from "../infra/api/BankInfra";

export function useGetDepositMethods() {
  const { data: depositMethods, ...rest } = useQuery(
    ["getDepositMethods"],
    getDepositMethods,
    {
      refetchOnWindowFocus: false,
    }
  );

  return { depositMethods, ...rest };
}
