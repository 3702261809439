import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getCurrencySymbol } from "../../utils/string";

export function CurrencyIcon({ size = 16, currency = "BRL" }) {
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <Box
      sx={{
        backgroundColor: "#008F64",
        borderRadius: size,
        width: size,
        height: size,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexShrink: 0,
      }}
    >
      <Typography color="#F9D250" fontSize={size * 0.5}>
        {getCurrencySymbol(currency, language)}
      </Typography>
    </Box>
  );
}
