import React from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  InputAdornment,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";

import {
  getCurrencySymbol,
  toLocaleCurrencyString,
  toLocaleCurrencyStringWithoutSymbol,
} from "../../utils/string";
import { FormTextField } from "../formFields/FormTextField";
import { SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import {
  cancelBid,
  CancelBidProps,
  registerBid,
  RegisterBidProps,
} from "../../services/offers";
import useConfirmationModal from "../../hooks/useConfirmationModal";
import { ApiError } from "../../types/error";

import { StyledModal } from "../StyledModal";
import { PeriodShort, PriceTypeShort } from "../OfferUtils";
import { EnhancedTable, HeadCell } from "../EnhancedTable";
import { Check, Clear } from "@mui/icons-material";
import { SummaryList, SummaryListData } from "../SummaryList";
import { DateTime } from "luxon";
import { useGetOperation } from "./hooks/useGetOperation";
import { useGetBidsFromOffer } from "../../pages/offers/hooks/useGetBidsFromOffer";
import { useAcceptBid } from "./hooks/useAcceptBid";
import { toFixedWithoutRounding } from "../../utils/number";

interface BidModalFormInputs {
  value: string;
  amount: string;
}

const bidModalFormDefaultValues: BidModalFormInputs = {
  value: "",
  amount: "",
};

interface BidModalProps {
  open: boolean;
  onClose: () => void;
  operationId: string | number;
  offerType: "COMPRA" | "VENDA";
  showMoreDetails?: boolean;
  disableBidArea?: boolean;
}
export function BidModal({
  open,
  onClose: onCloseProp,
  operationId,
  offerType,
  showMoreDetails,
  disableBidArea,
}: BidModalProps) {
  const { operationResume } = useGetOperation(String(operationId));
  const offerData = React.useMemo(() => {
    return operationResume
      ? operationResume[
          offerType === "COMPRA" ? "getBuyOffer" : "getSellOffer"
        ]()
      : null;
  }, [offerType, operationResume]);

  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { control, handleSubmit, reset, setValue } =
    useForm<BidModalFormInputs>({
      defaultValues: bidModalFormDefaultValues,
    });

  const onClose = React.useCallback(() => {
    reset();
    onCloseProp();
  }, [onCloseProp, reset]);

  const submitRegisterBid = useMutation<any, unknown, RegisterBidProps>(
    registerBid,
    {
      onSuccess: () => {
        enqueueSnackbar(t("The operation completed successfully"), {
          variant: "success",
        });

        queryClient.invalidateQueries(["/offer/daily/negotiated-offers"]);

        (onClose ?? onCloseProp)();
      },
      onError: (e) => {
        enqueueSnackbar(
          t((e as ApiError | null)?.error ?? "Something went wrong."),
          {
            variant: "error",
          }
        );
      },
    }
  );

  const { showConfirmationModal } = useConfirmationModal();

  const { bids } = useGetBidsFromOffer(
    offerData?.getOperationCode(),
    offerData?.getType() === "COMPRA" ? "SELL" : "BUY"
  );

  const onSubmit = React.useCallback<SubmitHandler<BidModalFormInputs>>(
    (data) => {
      if (!offerData) return;

      showConfirmationModal({
        onSubmit: () => {
          submitRegisterBid.mutate({
            operationId: Number(offerData.getOperationCode()),
            data: {
              value: Number(data.value),
              amount: Number(data.amount),
              type: offerData.getType() === "COMPRA" ? "SELL" : "BUY",
            },
          });
        },
        message: offerData.getIsPldFixed()
          ? t("Do you really want to bid in the spread value of {{value}}?", {
              value: toLocaleCurrencyString(data.value, "BRL", language),
            })
          : t("Do you really want to bid in the value of {{value}}?", {
              value: toLocaleCurrencyString(data.value, "BRL", language),
            }),
        // submitButtonColor: "error",
      });
    },
    [language, offerData, showConfirmationModal, submitRegisterBid, t]
  );

  const onInvalidSubmit = React.useCallback<
    SubmitErrorHandler<BidModalFormInputs>
  >(() => {
    enqueueSnackbar(t("You must fill in all required fields"), {
      variant: "error",
    });
  }, [enqueueSnackbar, t]);

  const { submitAcceptBidMutation } = useAcceptBid();

  const submitCancelBid = useMutation<any, unknown, CancelBidProps>(cancelBid, {
    onSuccess: () => {
      enqueueSnackbar(t("The operation completed successfully"), {
        variant: "success",
      });

      // onClose();
    },
    onError: (e) => {
      enqueueSnackbar(
        t((e as ApiError | null)?.error ?? "Something went wrong."),
        {
          variant: "error",
        }
      );
    },
  });

  const handleAcceptBid = React.useCallback(
    (bidId: string) => {
      if (!offerData) return;

      showConfirmationModal({
        onSubmit: () => {
          submitAcceptBidMutation.mutate(
            { operationId: Number(offerData.getOperationCode()), bidId },
            {
              onSuccess: () => {
                onClose();
              },
            }
          );
        },
        message: t("Do you really want to accept the bid?"),
        // submitButtonColor: "error",
      });
    },
    [offerData, onClose, showConfirmationModal, submitAcceptBidMutation, t]
  );

  const handleCancelBid = React.useCallback(
    (bidId: string) => {
      showConfirmationModal({
        onSubmit: () => {
          if (!offerData) return;

          submitCancelBid.mutate({
            operationId: Number(offerData.getOperationCode()),
            bidId: bidId,
          });
        },
        message: t("Do you really want to cancel the bid?"),
        submitButtonColor: "primary",
      });
    },
    [offerData, showConfirmationModal, submitCancelBid, t]
  );

  const isPLD = React.useMemo(() => offerData?.getIsPldFixed(), [offerData]);

  const summaryData = React.useMemo<SummaryListData>(
    () =>
      !offerData || !operationResume
        ? []
        : [
            [
              {
                label: t("Product"),
                value: (
                  <PriceTypeShort
                    submarket={operationResume.getSubmarketCode()}
                    product={operationResume.getProduct()}
                    priceType={(() => {
                      if (
                        offerData.getValue() !== null &&
                        offerData.getValue() !== undefined
                      ) {
                        return "Fixo";
                      }

                      if (
                        offerData.getSpread() !== null &&
                        offerData.getSpread() !== undefined
                      ) {
                        return "Atrelado ao PLD";
                      }

                      return "Lance livre";
                    })()}
                    freeBid={(() => {
                      if (
                        offerData.getValue() !== null &&
                        offerData.getValue() !== undefined
                      ) {
                        return undefined;
                      }

                      if (
                        offerData.getSpread() !== null &&
                        offerData.getSpread() !== undefined
                      ) {
                        return undefined;
                      }

                      return offerData.getIsPldFixed()
                        ? "Atrelado PLD + Spread"
                        : "Fixo";
                    })()}
                  />
                ),
              },
              {
                label: t("Period"),
                value: (
                  <PeriodShort
                    periodStart={DateTime.fromJSDate(
                      operationResume.getDeliveryPeriodStart()
                    )
                      .plus({ hours: 3 })
                      .toISO()}
                    periodEnd={operationResume
                      .getDeliveryPeriodEnd()
                      .toISOString()}
                  />
                ),
              },
              {
                label: t("Operation Type"),
                value: (
                  <Box
                    display="inline"
                    sx={(theme) => ({
                      color:
                        offerData.getType() === "COMPRA"
                          ? theme.palette.success.main
                          : offerData.getType() === "VENDA"
                          ? theme.palette.error.main
                          : theme.palette.info.main,
                    })}
                  >
                    {t(offerData.getType()).toUpperCase()}
                  </Box>
                ),
              },
            ],
            [
              {
                label: t("Operation Code"),
                value: String(offerData.getOperationCode()),
              },
              {
                label: t("Class"),
                value: t(operationResume.getParticipantClassName()),
              },
              {
                label: t("End of Offer"),
                value: operationResume.getCloseAt()
                  ? operationResume.getCloseAt().toLocaleString(language)
                  : "-",
              },
              {
                label: t("Delivery Period"),
                value: `${operationResume
                  .getDeliveryPeriodStart()
                  .toLocaleDateString(language, {
                    timeZone: "UTC",
                  })} ${t("to")} ${operationResume
                  .getDeliveryPeriodEnd()
                  .toLocaleDateString(language, { timeZone: "UTC" })}`,
              },
              {
                label: t("Volume"),
                value: `${offerData.getRemainingVolume()} ${
                  operationResume.getProductMeasurementUnit()
                    ? ` ${operationResume.getProductMeasurementUnit()}`
                    : ""
                }`,
              },
              // {
              //   label: t("Submarket"),
              //   value: offerData.submarket,
              // },
              {
                label: t("Re-TUSD"),
                value: operationResume.getReTusd()
                  ? t("Applies")
                  : t("Does not apply"),
              },
              // {
              //   label: t("Price"),
              //   value: t(offerData.priceType),
              // },
              {
                label: t("Spread"),
                value: offerData.getSpread()
                  ? Number(offerData.getSpread()).toLocaleString(language, {
                      style: "currency",
                      currency: "BRL",
                    })
                  : null,
              },
              {
                label: t("Value"),
                value: offerData.getValue()
                  ? Number(offerData.getValue()).toLocaleString(language, {
                      style: "currency",
                      currency: "BRL",
                    })
                  : null,
              },
            ],
          ],
    [language, offerData, operationResume, t]
  );

  const headCells = React.useMemo<Array<HeadCell>>(
    () => [
      {
        id: "status",
        alignment: "left",
        label: t("Status"),
        disableSort: true,
      },
      {
        id: "createdAt.seconds",
        alignment: "center",
        label: t("Date and Time"),
        disableSort: true,
      },
      {
        id: "deliveryPeriodStart",
        alignment: "right",
        label: `${t("Bid Value")} (${getCurrencySymbol("BRL", language)})`,
        disableSort: true,
      },
      {
        id: "amount",
        alignment: "right",
        label: `${t("Volume")} (MWh)`,
        disableSort: true,
      },
      {
        id: "actions",
        alignment: "right",
        label: t("Actions"),
        disableSort: true,
      },
    ],
    [language, t]
  );

  if (offerData === null || !operationResume) return <></>;

  return (
    <StyledModal
      open={open}
      onClose={onClose}
      title={t(disableBidArea ? "Offer Details" : "Fill Order")}
      leftBox={operationResume.getOperationId()}
      maxWidth={500}
    >
      <Box>
        <SummaryList
          data={!showMoreDetails ? [summaryData[0]] : summaryData}
          divider={<Divider color="#dbdbdb" sx={{ my: 8 }} />}
        />

        {!disableBidArea && (
          <>
            <Divider color="#dbdbdb" sx={{ my: 8 }} />

            <form onSubmit={handleSubmit(onSubmit, onInvalidSubmit)}>
              <Grid container justifyContent="center" columnSpacing={2}>
                <Grid item xs={6}>
                  {isPLD ? t("Spread") : t("Value")}{" "}
                  {t(offerData.getType()).toUpperCase()} (
                  {getCurrencySymbol("BRL", language)}){" "}
                  {!offerData.isFreeBid()
                    ? offerData.getIsPldFixed()
                      ? `${
                          offerData.getSpread()! < 0 ? "-" : "+"
                        }${toLocaleCurrencyStringWithoutSymbol(
                          Math.abs(offerData.getSpread()!),
                          "BRL",
                          language
                        )}`
                      : toLocaleCurrencyStringWithoutSymbol(
                          offerData.getValue()!,
                          "BRL",
                          language
                        )
                    : "-"}
                </Grid>
                <Grid item xs={6}>
                  {t("Volume")} (MWh){" "}
                  {offerData.getRemainingVolume().toLocaleString(language, {
                    minimumFractionDigits: 3,
                  })}
                </Grid>

                <Grid item xs={12} mt={6}>
                  {t("My Bid")}{" "}
                  {t(
                    offerData.getType() === "COMPRA"
                      ? "VENDA"
                      : offerData.getType() === "VENDA"
                      ? "COMPRA"
                      : ""
                  ).toUpperCase()}
                  :
                </Grid>
                <Grid item xs={6}>
                  <FormTextField
                    control={control}
                    name="value"
                    rules={{
                      required: {
                        value: true,
                        message: t("This field is required"),
                      },
                      onChange: (e) => {
                        if (e.target.value.split(".")[1]?.length > 2)
                          setValue(
                            "value",
                            toFixedWithoutRounding(e.target.value, 2)
                          );
                      },
                    }}
                    label={isPLD ? t("Spread") : t("Value")}
                    fullWidth
                    my={2}
                    type="number"
                    size="small"
                    inputProps={{
                      step: "0.01",
                      min:
                        offerData.getType() === "COMPRA"
                          ? offerData.getSpreadOrValue()?.toFixed(2)
                          : undefined,
                      max:
                        offerData.getType() === "VENDA"
                          ? offerData.getSpreadOrValue()?.toFixed(2)
                          : undefined,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {getCurrencySymbol("BRL", language)}
                        </InputAdornment>
                      ),
                    }}
                    helperText={
                      !offerData.isFreeBid()
                        ? `${
                            offerData.getType() === "COMPRA"
                              ? t("Min")
                              : t("Max")
                          }: ${toLocaleCurrencyString(
                            (offerData.getValue() || offerData.getSpread())!,
                            "BRL",
                            language
                          )}`
                        : " "
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormTextField
                    control={control}
                    name="amount"
                    rules={{
                      required: {
                        value: true,
                        message: t("This field is required"),
                      },
                      validate: (value) =>
                        Number(value) > 0 ||
                        t("The data entered is invalid", {
                          data: t("Volume"),
                        }),
                      onChange: (e) => {
                        if (e.target.value.split(".")[1]?.length > 3)
                          setValue(
                            "amount",
                            toFixedWithoutRounding(e.target.value, 3)
                          );
                      },
                    }}
                    label={t("Volume")}
                    fullWidth
                    my={2}
                    type="number"
                    size="small"
                    inputProps={{
                      min: 0,
                      max: offerData.getRemainingVolume(),
                      step: 0.001,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {operationResume.getProductMeasurementUnit()}
                        </InputAdornment>
                      ),
                    }}
                    helperText={`${t("Max")}: ${offerData
                      .getRemainingVolume()
                      .toLocaleString(language, {
                        minimumFractionDigits: 3,
                        maximumFractionDigits: 3,
                      })} ${operationResume.getProductMeasurementUnit() ?? ""}`}
                  />
                </Grid>
              </Grid>

              <Box
                display="inline-flex"
                width="100%"
                justifyContent="flex-end"
                gap={4}
                mt={8}
              >
                {/* <Button
              type="button"
              variant="outlined"
              color="primary"
              onClick={onClose}
              disabled={submitRegisterBid.isLoading}
            >
              {t("Go Back")}
            </Button> */}
                <Button
                  variant="contained"
                  // color={offerData.operation === "COMPRA" ? "error" : "success"}
                  size="small"
                  type="submit"
                  disabled={submitRegisterBid.isLoading}
                >
                  {t("Register Bid")}
                </Button>
              </Box>
            </form>
          </>
        )}

        <Divider color="#dbdbdb" sx={{ my: 8 }} />

        <Box>
          {bids.length === 0 ? (
            <Box>
              <Typography variant="caption">
                {t("Offer has not been bid yet")}
              </Typography>
            </Box>
          ) : (
            <>
              <Typography variant="h6" fontSize="0.8125rem" mb={4}>
                {t("Bids")}:
              </Typography>

              <EnhancedTable
                // title={t("Bids Made")}
                TableProps={{
                  size: "small",
                  sx: {
                    "& .MuiTableCell-root": {
                      px: 2,
                      fontSize: "0.8125rem",
                    },
                  },
                }}
                data={bids}
                headCells={headCells}
                rowsPerPage={5}
                avoidEmptyRows
                keyExtractor={(row) => String(row.getId())}
                renderRow={(row, index) => {
                  const canAcceptBid = operationResume.getIsOperationOwner();

                  const canCancelBid = row.isMyBid();

                  return (
                    <TableRow hover tabIndex={-1}>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ whiteSpace: "nowrap" }}
                      >
                        {t(row.getStatus())}
                      </TableCell>
                      <TableCell align="center" sx={{ maxWidth: "8ch" }}>
                        {row.getCreatedAt().toLocaleString(language)}
                      </TableCell>
                      <TableCell align="right">
                        {offerData.getIsPldFixed()
                          ? `${
                              row.getValue() - (row.getPldValue() ?? 0) / 100 <
                              0
                                ? "-"
                                : "+"
                            }${toLocaleCurrencyStringWithoutSymbol(
                              Math.abs(
                                row.getValue() - (row.getPldValue() ?? 0) / 100
                              ),
                              "BRL",
                              language
                            )}`
                          : toLocaleCurrencyStringWithoutSymbol(
                              row.getValue(),
                              "BRL",
                              language
                            )}
                      </TableCell>

                      <TableCell align="right">
                        {row.getAmount().toLocaleString(language)}
                      </TableCell>

                      <TableCell padding="none" align="right">
                        <Box mr={2} my={2} whiteSpace="nowrap">
                          {canAcceptBid && (
                            <Tooltip title={t("Accept")}>
                              <span>
                                <Button
                                  size="small"
                                  onClick={() => {
                                    handleAcceptBid(row.getId());
                                  }}
                                  variant="contained"
                                  sx={{ minWidth: "unset", p: 0 }}
                                  disabled={
                                    submitAcceptBidMutation.isLoading ||
                                    row.getStatus() !== "CRIADO"
                                  }
                                >
                                  <Check fontSize="small" />
                                </Button>
                              </span>
                            </Tooltip>
                          )}
                          {canCancelBid && (
                            <Tooltip title={t("Cancel")}>
                              <span>
                                <Button
                                  size="small"
                                  onClick={() => {
                                    handleCancelBid(row.getId());
                                  }}
                                  variant="contained"
                                  color="error"
                                  sx={{ minWidth: "unset", p: 0 }}
                                  disabled={
                                    submitCancelBid.isLoading ||
                                    row.getStatus() !== "CRIADO"
                                  }
                                >
                                  <Clear fontSize="small" />
                                </Button>
                              </span>
                            </Tooltip>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                }}
              />
            </>
          )}
        </Box>
      </Box>
    </StyledModal>
  );
}
