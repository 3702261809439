import React from "react";
import { Box, CircularProgress, Grid } from "@mui/material";
import styled from "@emotion/styled";
import { DirectBuyAndSellBidArea } from "./DirectBuyAndSellBidArea";
import { DirectBuyAndSellOfferBookArea } from "./DirectBuyAndSellOfferBookArea";
import { OffersPanelPldBox } from "../../pages/offers/OffersPanelPldBox";
import { DirectBuyAndSellOfferFaqButton } from "./DirectBuyAndSellOfferFaqButton";
import { useGetOfferBook } from "./hooks/useGetOfferBook";
import { useGetParticipantProducts } from "../products/hooks/useGetParticipantProducts";
import { OfferBookItem } from "./entities/OfferBookItem";

const InfoBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24;
`;

export function DirectBuyAndSellPageContent() {
  const [submarket, setSubmarket] = React.useState("");
  const [productId, setProductId] = React.useState<string | null>(null);
  const [value, setValue] = React.useState<number | null>(null);
  const [volume, setVolume] = React.useState<number | null>(null);

  const { participantProducts } = useGetParticipantProducts();

  const productName = React.useMemo(
    () =>
      participantProducts?.find((item) => item.id === productId)?.name ?? null,
    [participantProducts, productId]
  );

  const { offersData } = useGetOfferBook({
    submarket,
    productId,
    value,
    volume,
  });

  const buyOfferBook = React.useMemo<Array<OfferBookItem>>(() => {
    return (
      offersData?.buyOffers.map((item) => {
        const spread = item.getBuyOffer()?.getSpread() ?? 0;
        const volume = item.getBuyOffer()?.getRemainingVolume() ?? 0;
        const total = (item.getBuyOffer()?.getValue() ?? 0) * volume;
        const closeAt = item.getCloseAt();

        return {
          value: spread,
          volume,
          total,
          offerCloseDateTime: closeAt,
        };
      }) ?? []
    );
  }, [offersData?.buyOffers]);

  const sellOfferBook = React.useMemo<Array<OfferBookItem>>(() => {
    return (
      offersData?.sellOffers.reverse().map((item) => {
        const spread = item.getSellOffer()?.getSpread() ?? 0;
        const volume = item.getSellOffer()?.getRemainingVolume() ?? 0;
        const total = (item.getSellOffer()?.getValue() ?? 0) * volume;
        const closeAt = item.getCloseAt();

        return {
          value: spread,
          volume,
          total,
          offerCloseDateTime: closeAt,
        };
      }) ?? []
    );
  }, [offersData?.sellOffers]);

  if (!offersData || !participantProducts) {
    return (
      <InfoBox>
        <CircularProgress />
      </InfoBox>
    );
  }

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", position: "relative" }}>
      <DirectBuyAndSellOfferFaqButton
        sx={{ position: "fixed", right: 32, bottom: 32 }}
      />

      <Grid
        container
        sx={{ maxWidth: 1200, mx: "auto", "& .MuiGrid-item": { p: 4 } }}
      >
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              flex: 1,
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: 7,
              alignItems: "center",
            }}
          >
            <Box sx={{ width: "fit-content" }}>
              <OffersPanelPldBox />
            </Box>

            <Box>
              <DirectBuyAndSellBidArea
                onChangeSubmarket={setSubmarket}
                onChangeProductId={setProductId}
                onChangeValue={setValue}
                onChangeVolume={setVolume}
                buyOfferBook={buyOfferBook}
                sellOfferBook={sellOfferBook}
                products={participantProducts}
                calculatedValue={offersData.value}
                calculatedVolume={offersData.amount}
                offersReached={offersData.offersReached}
              />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box
            sx={{
              flex: 1,
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 3,
            }}
          >
            <Box sx={{ width: "100%", maxWidth: 480 }}>
              <DirectBuyAndSellOfferBookArea
                type="VENDA"
                submarket={submarket}
                productName={productName ?? ""}
                offerBook={sellOfferBook}
              />
            </Box>

            <Box sx={{ width: "100%", maxWidth: 480 }}>
              <DirectBuyAndSellOfferBookArea
                type="COMPRA"
                submarket={submarket}
                productName={productName ?? ""}
                offerBook={buyOfferBook}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
