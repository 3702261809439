import React from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Alert as MuiAlert,
  CardContent,
  Link as MuiLink,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
  Box,
  CircularProgress,
  Paper,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import {
  submitRegisterOffers,
  SubmitRegisterOffersProps,
} from "../../services/offers";
import useChooseParticipant from "../../hooks/useChooseParticipant";
import { ApiError } from "../../types/error";
import useConfirmationModal from "../../hooks/useConfirmationModal";
import RegisterOfferForm from "./RegisterOfferForm";
import { NewOffer } from "../../types/offer";
import { RegisterOfferConfirmationModalContent } from "./RegisterOfferConfirmationModalContent";
import { useBlocker } from "../../hooks/useBlocker";
import { StyledModal } from "../../components/StyledModal";
import { getParticipantProducts } from "../../components/products/infra/ProductsInfra";
import { useGetSubmarkets } from "../../components/submarket/hooks/useGetSubmarkets";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Alert = styled(MuiAlert)(spacing);

const InfoBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24;
`;

export default function RegisterOffer() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { showConfirmationModal } = useConfirmationModal();

  const {
    currentParticipant,
    isInitialized: isChooseParticipantInitialized,
    isError: isChooseParticipantError,
  } = useChooseParticipant();

  const [newOfferList, setNewOfferList] = React.useState<Array<NewOffer>>([]);

  const {
    submarkets: submarketOptions,
    isLoading: isLoadingSubmarketOptions,
    isError: isErrorSubmarketOptions,
  } = useGetSubmarkets();

  const {
    data: participantProducts,
    isError: isErrorParticipantProducts,
    isLoading: isLoadingParticipantProducts,
  } = useQuery(
    ["getParticipantProducts", currentParticipant?.id ?? null],
    () => getParticipantProducts(currentParticipant?.id ?? null),
    {
      refetchOnWindowFocus: false,
      initialData: [],
    }
  );

  const submitRegisterOfferMutation = useMutation<
    any,
    unknown,
    SubmitRegisterOffersProps
  >(submitRegisterOffers, {
    onSuccess: () => {
      enqueueSnackbar(t("The operation completed successfully"), {
        variant: "success",
      });
      handleClose();
      setNewOfferList([]);
      setTimeout(() => {
        // The redirect is delayed to avoid conflict with the useBlocker
        navigate("/offers/offers-panel");
      }, 400);
    },
    onError: (e) => {
      enqueueSnackbar(
        t((e as ApiError | null)?.error ?? "Something went wrong."),
        {
          variant: "error",
        }
      );
    },
  });

  const handleRegisterOffer = React.useCallback(() => {
    if (!currentParticipant || newOfferList.length < 1) return;

    showConfirmationModal({
      onSubmit: () => {
        submitRegisterOfferMutation.mutate({
          newOfferList,
        });
      },
      message: (
        <>
          {t(
            newOfferList.length === 1
              ? "REGISTER_OFFER_CONFIRMATION_MESSAGE1"
              : "REGISTER_OFFERS_CONFIRMATION_MESSAGE1"
          )}
          <br />
          <br />
          {t(
            newOfferList.length === 1
              ? "REGISTER_OFFER_CONFIRMATION_MESSAGE2"
              : "REGISTER_OFFERS_CONFIRMATION_MESSAGE2"
          )}
        </>
      ),
    });
  }, [
    currentParticipant,
    newOfferList,
    showConfirmationModal,
    submitRegisterOfferMutation,
    t,
  ]);

  const [editOfferId, setEditOfferId] = React.useState<number | null>(null);

  const saveOffer = React.useCallback(
    (newOfferData: NewOffer, idx?: number) => {
      if (idx === undefined) {
        setNewOfferList((oldArray) => [...oldArray, newOfferData]);
      } else {
        setNewOfferList((oldArray) => {
          const newArray = [...oldArray];
          newArray[idx] = newOfferData;
          return newArray;
        });
        setEditOfferId(null);
      }
    },
    []
  );

  const startEditOffer = React.useCallback((idx: number) => {
    setEditOfferId(idx);
  }, []);

  const cancelEditOffer = React.useCallback(() => {
    setEditOfferId(null);
  }, []);

  const deleteOffer = React.useCallback(
    (idx: number) => {
      showConfirmationModal({
        onSubmit: () => {
          setNewOfferList((oldArray) => {
            const newArray = [...oldArray];
            newArray.splice(idx, 1);
            return newArray;
          });
        },
        submitButtonColor: "primary",
        message: t("Do you really want to delete this offer?"),
      });
    },
    [showConfirmationModal, t]
  );

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const handleOpen = () => {
    setIsModalOpen(true);
  };
  const handleClose = () => {
    if (submitRegisterOfferMutation.isLoading) return;
    setIsModalOpen(false);
  };

  useBlocker({
    enabled: newOfferList.length > 0,
    onBlock: (navigation) => {
      showConfirmationModal({
        message: t(
          "You have unsaved data on this page. Do you really want to leave this page?"
        ),
        onSubmit: () => {
          navigation.confirm();
        },
        onClose: () => {
          navigation.cancel();
        },
        submitButtonColor: "primary",
      });
    },
  });

  const pageTitle = t("Register Offer");

  if (
    isErrorSubmarketOptions ||
    isErrorParticipantProducts ||
    isChooseParticipantError
  ) {
    return (
      <Alert mt={2} mb={3} severity="error">
        {t("Something went wrong.")}
      </Alert>
    );
  }

  if (
    isLoadingSubmarketOptions ||
    isLoadingParticipantProducts ||
    !isChooseParticipantInitialized ||
    !currentParticipant ||
    !submarketOptions
  ) {
    return (
      <InfoBox>
        <CircularProgress />
      </InfoBox>
    );
  }

  return (
    <React.Fragment>
      {isModalOpen && newOfferList.length > 0 && (
        <StyledModal open={isModalOpen} onClose={handleClose} disableTitlebar>
          <RegisterOfferConfirmationModalContent
            onClose={handleClose}
            onSubmit={handleRegisterOffer}
            disableButtons={submitRegisterOfferMutation.isLoading}
            newOfferList={newOfferList}
            participantProducts={participantProducts}
            participantData={currentParticipant}
            onDelete={deleteOffer}
            onEdit={startEditOffer}
          />
        </StyledModal>
      )}

      <Helmet title={pageTitle} />

      <Typography variant="h3" gutterBottom display="inline">
        {pageTitle}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <MuiLink component={NavLink} to="/">
          {t("Home")}
        </MuiLink>
        <MuiLink component={NavLink} to="/offers/offers-panel">
          {t("Offers")}
        </MuiLink>
        <Typography>{pageTitle}</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Paper sx={{ maxWidth: 1200, mx: "auto" }}>
        <CardContent>
          <RegisterOfferForm
            submarketOptions={submarketOptions}
            participantProducts={participantProducts}
            onSaveOffer={saveOffer}
            editOfferId={editOfferId}
            editOfferData={
              editOfferId !== null ? newOfferList[editOfferId] : null
            }
            onCancelEditOffer={cancelEditOffer}
            onGoToSummary={handleOpen}
            newOfferListLength={newOfferList.length}
            onEditOffer={startEditOffer}
          />

          {/* <Box mt={4}>
            <RegisterOfferTable
              newOfferList={newOfferList}
              participantProducts={participantProducts}
              onDelete={deleteOffer}
              onEdit={startEditOffer}
              idxToDisable={editOfferId}
            />
          </Box> */}

          {/* <Box display="inline-flex" gap={4} mt={2}>
            <Button
              variant="outlined"
              color="primary"
              component={Link}
              to="/offers/offers-panel"
            >
              {t("Cancel")}
            </Button>

            <Button
              variant="contained"
              color="primary"
              type="button"
              onClick={handleOpen}
              disabled={newOfferList.length === 0 || editOfferId !== null}
            >
              {t("Save")}
            </Button>
          </Box> */}
        </CardContent>
      </Paper>
    </React.Fragment>
  );
}
