import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

import { SummaryTable } from "../../components/SummaryTable";
import { formatCNPJ } from "../../utils/string";
import {
  CompanyPaymentData,
  ContactsData,
  Contract,
} from "../../types/contracts";
import { Participant, ParticipantAddress } from "../../types/participant";
import { ParticipantVsProductWithName } from "../../types/product";
import { StyledModal } from "../../components/StyledModal";
import { parseDate } from "../../utils/date";

interface BuyerOrSellerFormProps {
  buyerOrSeller: "buyer" | "seller";
  participant: Participant | null;
  participantAddress: ParticipantAddress | null;
  product: ParticipantVsProductWithName | null;
  paymentData: CompanyPaymentData | null;
  paymentDate: string | null;
  contacts: ContactsData | null;
}
function BuyerOrSellerForm({
  buyerOrSeller,
  participant,
  participantAddress,
  product,
  paymentData,
  paymentDate,
  contacts,
}: BuyerOrSellerFormProps) {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const participantSummaryData = React.useMemo(
    () => [
      [
        {
          label: t("Corporate Name") + ":",
          value: participant?.companyCorporateName ?? "-",
        },
        {
          label: t("CNPJ") + ":",
          value: participant ? formatCNPJ(participant.companyCnpj) ?? "-" : "-",
        },
        {
          label: t("State Registration") + ":",
          value: participant?.companyStateRegistration ?? "-",
        },
        {
          label: t("Address") + ":",
          value: participantAddress ? (
            <>
              {`${participantAddress.addressLine}, ${participantAddress.addressNumber} - ${participantAddress.addressDistrict}.`}
              <br />
              {`CEP: ${participantAddress.addressCep}. ${participantAddress.addressCity}/${participantAddress.addressState}`}
            </>
          ) : (
            "-"
          ),
          md: 12,
        },
      ],
    ],
    [participant, participantAddress, t]
  );

  const productSummaryData = React.useMemo(
    () => [
      [
        {
          label: t("Tipo de Energia") + ":",
          value: product?.name,
        },
        {
          label: t("Profile Key") + ":",
          value: product?.profileKey,
        },
        {
          label: t("Profile Code") + ":",
          value: product?.profileCode,
        },
      ],
    ],
    [product?.name, product?.profileCode, product?.profileKey, t]
  );

  const paymentSummaryData = React.useMemo(
    () => [
      [
        ...(buyerOrSeller === "seller"
          ? [
              {
                label: t("Corporate Name") + ":",
                value: paymentData?.corporateName,
              },
              {
                label: t("CNPJ") + ":",
                value: paymentData?.cnpj,
              },
              {
                label: t("Address") + ":",
                value: paymentData?.address,
              },
              {
                label: t("Postal Code") + ":",
                value: paymentData?.cep,
              },
              {
                label: t("BankCode") + ":",
                value: paymentData?.bankCode,
              },
              {
                label: t("Agency") + ":",
                value: paymentData?.bankAgency,
              },
              {
                label: t("Account") + ":",
                value: paymentData?.bankAccount,
              },
              {
                label: t("Due Date") + ":",
                value: parseDate(paymentDate)?.toLocaleDateString(language, {
                  timeZone: "UTC",
                }),
              },
            ]
          : []),
      ],
    ],
    [
      buyerOrSeller,
      language,
      paymentData?.address,
      paymentData?.bankAccount,
      paymentData?.bankAgency,
      paymentData?.bankCode,
      paymentData?.cep,
      paymentData?.cnpj,
      paymentData?.corporateName,
      paymentDate,
      t,
    ]
  );

  const contactsSummaryData = React.useMemo(
    () => [
      [
        {
          label: t("Contact Name") + ":",
          value: contacts?.names.reduce(
            (acc, curr, idx) => (idx === 0 ? curr : `${acc}, ${curr}`),
            ""
          ),
        },
        {
          label: t("Email") + ":",
          value: contacts?.emails.reduce(
            (acc, curr, idx) => (idx === 0 ? curr : `${acc}, ${curr}`),
            ""
          ),
        },
        {
          label: t("Telephone Number") + ":",
          value: contacts?.telephoneNumbers.reduce(
            (acc, curr, idx) => (idx === 0 ? curr : `${acc}, ${curr}`),
            ""
          ),
        },
        {
          label: t("Cellphone Number") + ":",
          value: contacts?.cellphoneNumbers.reduce(
            (acc, curr, idx) => (idx === 0 ? curr : `${acc}, ${curr}`),
            ""
          ),
        },
        {
          label: t("WhatsApp, Skype, Telegram") + ":",
          value: contacts?.socialNetworks.reduce(
            (acc, curr, idx) => (idx === 0 ? curr : `${acc}, ${curr}`),
            ""
          ),
        },
      ],
    ],
    [
      contacts?.cellphoneNumbers,
      contacts?.emails,
      contacts?.names,
      contacts?.socialNetworks,
      contacts?.telephoneNumbers,
      t,
    ]
  );

  return (
    <Box>
      <Box px={2} mb={4}>
        <Typography
          variant="h6"
          color={buyerOrSeller === "seller" ? "#E83A3A" : "#00CB85"}
        >
          {t(
            buyerOrSeller === "seller" ? "Parte Vendedora" : "Parte Compradora"
          )}
          :
        </Typography>
      </Box>

      <Accordion defaultExpanded disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography variant="subtitle2">{t("Participant Data")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SummaryTable
            data={[[...participantSummaryData[0], ...productSummaryData[0]]]}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography variant="subtitle2">{t("Contacts")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SummaryTable data={contactsSummaryData} />
        </AccordionDetails>
      </Accordion>

      {buyerOrSeller === "seller" && (
        <Accordion defaultExpanded disableGutters>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography variant="subtitle2">{t("Payment Data")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <SummaryTable data={paymentSummaryData} />
          </AccordionDetails>
        </Accordion>
      )}
    </Box>
  );
}

interface ClosingStatementModalContentProps {
  participantId: number | null;
  contract: Contract;
  onClose?: () => void;
}
function ClosingStatementModalContent({
  participantId,
  contract,
  onClose,
}: ClosingStatementModalContentProps) {
  const [sellerParticipant, buyerParticipant] = React.useMemo(
    () => [
      (contract.offer as any).offerType === "COMPRA"
        ? contract.winnerParticipant?.participant ?? null
        : (contract.offer as any).offerType === "VENDA"
        ? contract.ownerParticipant?.participant ?? null
        : null,
      (contract.offer as any).offerType === "VENDA"
        ? contract.winnerParticipant?.participant ?? null
        : (contract.offer as any).offerType === "COMPRA"
        ? contract.ownerParticipant?.participant ?? null
        : null,
    ],
    [
      (contract.offer as any).offerType,
      contract.ownerParticipant?.participant,
      contract.winnerParticipant?.participant,
    ]
  );

  const [sellerAddress, buyerAddress] = React.useMemo(
    () => [
      (contract.offer as any).offerType === "COMPRA"
        ? contract.winnerAddress ?? null
        : (contract.offer as any).offerType === "VENDA"
        ? contract.ownerAddress ?? null
        : null,
      (contract.offer as any).offerType === "VENDA"
        ? contract.winnerAddress ?? null
        : (contract.offer as any).offerType === "COMPRA"
        ? contract.ownerAddress ?? null
        : null,
    ],
    [
      (contract.offer as any).offerType,
      contract.ownerAddress,
      contract.winnerAddress,
    ]
  );

  const [sellerProduct, buyerProduct] = React.useMemo(() => {
    const ownerParticipantVsProduct: ParticipantVsProductWithName = {
      name: contract.operation.product?.name ?? "-",
      profileCode: contract.operation.product?.profileKey ?? "-",
      profileKey: contract.operation.product?.profileCode ?? "-",
    };
    const winnerParticipantVsProduct: ParticipantVsProductWithName | null =
      contract.winnerBid.participantVsProduct
        ? {
            name: contract.operation.product?.name ?? "-",
            profileCode: contract.winnerBid.participantVsProduct.profileKey,
            profileKey: contract.winnerBid.participantVsProduct.profileCode,
          }
        : null;

    return [
      (contract.offer as any).offerType === "COMPRA"
        ? winnerParticipantVsProduct
        : (contract.offer as any).offerType === "VENDA"
        ? ownerParticipantVsProduct
        : null,
      (contract.offer as any).offerType === "VENDA"
        ? winnerParticipantVsProduct
        : (contract.offer as any).offerType === "COMPRA"
        ? ownerParticipantVsProduct
        : null,
    ];
  }, [
    (contract.offer as any).offerType,
    contract.operation.product,
    contract.winnerBid.participantVsProduct,
  ]);

  const [sellerPaymentData, buyerPaymentData] = React.useMemo(
    () => [
      (contract.offer as any).offerType === "COMPRA"
        ? contract.operationClose?.winnerPaymentData ?? null
        : (contract.offer as any).offerType === "VENDA"
        ? contract.operationClose?.ownerPaymentData ?? null
        : null,
      (contract.offer as any).offerType === "VENDA"
        ? contract.operationClose?.winnerPaymentData ?? null
        : (contract.offer as any).offerType === "COMPRA"
        ? contract.operationClose?.ownerPaymentData ?? null
        : null,
    ],
    [
      (contract.offer as any).offerType,
      contract.operationClose?.ownerPaymentData,
      contract.operationClose?.winnerPaymentData,
    ]
  );

  const companyPaymentData = React.useMemo(
    () => contract.paymentData ?? null,
    [contract.paymentData]
  );

  const [sellerContacts, buyerContacts] = React.useMemo(
    () => [
      (contract.offer as any).offerType === "COMPRA"
        ? contract.operationClose?.winnerContacts ?? null
        : (contract.offer as any).offerType === "VENDA"
        ? contract.operationClose?.ownerContacts ?? null
        : null,
      (contract.offer as any).offerType === "VENDA"
        ? contract.operationClose?.winnerContacts ?? null
        : (contract.offer as any).offerType === "COMPRA"
        ? contract.operationClose?.ownerContacts ?? null
        : null,
    ],
    [
      (contract.offer as any).offerType,
      contract.operationClose?.ownerContacts,
      contract.operationClose?.winnerContacts,
    ]
  );

  if (!contract || !participantId) return <></>;

  return (
    <Grid container spacing={12}>
      <Grid item md={6}>
        <BuyerOrSellerForm
          buyerOrSeller="seller"
          participant={sellerParticipant}
          participantAddress={sellerAddress}
          product={sellerProduct}
          paymentData={companyPaymentData}
          paymentDate={sellerPaymentData?.paymentDate ?? null}
          contacts={sellerContacts}
        />
      </Grid>

      <Grid item md={6}>
        <BuyerOrSellerForm
          buyerOrSeller="buyer"
          participant={buyerParticipant}
          participantAddress={buyerAddress}
          product={buyerProduct}
          paymentData={companyPaymentData}
          paymentDate={buyerPaymentData?.paymentDate ?? null}
          contacts={buyerContacts}
        />
      </Grid>
    </Grid>
  );
}

export interface ClosingStatementModalProps {
  open: boolean;
  onClose?: () => void;
  participantId: number | null;
  contract: Contract;
}
export function ClosingStatementModal({
  open,
  onClose,
  participantId,
  contract,
}: ClosingStatementModalProps) {
  const { t } = useTranslation();

  return (
    <StyledModal
      leftBox={t("Contract")}
      title={t("Closing Statement")}
      open={open}
      onClose={onClose}
      maxWidth={1400}
    >
      <ClosingStatementModalContent
        participantId={participantId}
        contract={contract}
        onClose={onClose}
      />
    </StyledModal>
  );
}
