import React from "react";
import { Subscription } from "rxjs";
import { OperationResume } from "../entities/data-objects/OperationResume";
import {
  GetOffersPanelObservableService,
  PanelOperationsObservable,
} from "../services/GetOffersPanelObservableService";
import useChooseParticipant from "../../../hooks/useChooseParticipant";

const getOffersPanelObservableService = new GetOffersPanelObservableService();

interface useGetOperationsProps {
  page: number;
  itemsPerPage: number;
  productFilterValue: string | null;
  submarketFilterValue: string | null;
  offersToShow: string;
  showFinishedOffers: boolean;
}
export function useGetOperations({
  page,
  itemsPerPage,
  productFilterValue,
  submarketFilterValue,
  offersToShow,
  showFinishedOffers,
}: useGetOperationsProps) {
  const { participantId } = useChooseParticipant();

  const [operations, setOperations] = React.useState<OperationResume[]>([]);
  const [totalItems, setTotalItems] = React.useState(0);

  React.useEffect(() => {
    const options = {
      page: page,
      itemsPerPage: itemsPerPage,
      participant: participantId || -1,
      product: productFilterValue,
      submarket: submarketFilterValue,
      onlyMyOffers: offersToShow === "My Offers",
      showFinishedOffers: showFinishedOffers,
      onlyOffersWithMyBids: offersToShow === "My Bids",
    };

    const subscription = getOffersPanelObservableService
      .getOffersPanelObservable()
      .subscribeWithOptions(options, (panelData) => {
        setTotalItems(panelData.totalItems);
        setOperations(panelData.operations);
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [
    page,
    itemsPerPage,
    participantId,
    productFilterValue,
    submarketFilterValue,
    offersToShow,
    showFinishedOffers,
  ]);

  return { operations, totalItems };
}
