import {
  HistoryEvents,
  HistoryOperation,
  NewOffer,
  Operation,
} from "../types/offer";
import { balcaoApiInstance } from "../utils/axios";

function transformOffer(newOffer: NewOffer) {
  const offer = {
    ...(newOffer.buyVolume
      ? {
          buyOffer: {
            offerType: "COMPRA",
            volume: newOffer.buyVolume,
            priceValue: newOffer.buySpread ?? newOffer.buyValue,
          },
        }
      : {}),
    ...(newOffer.sellVolume
      ? {
          sellOffer: {
            offerType: "VENDA",
            volume: newOffer.sellVolume,
            priceValue: newOffer.sellSpread ?? newOffer.sellValue,
          },
        }
      : {}),
    product: newOffer.product,
    offerCloseDateTime: newOffer.offerCloseDateTime,
    deliveryPeriodStart: newOffer.deliveryPeriodStart,
    deliveryPeriodEnd: newOffer.deliveryPeriodEnd,
    submarket: newOffer.submarket,
    reTusd: newOffer.reTusd,
    priceType:
      newOffer.priceType === "Lance livre"
        ? newOffer.freeBid === "Atrelado PLD + Spread"
          ? "Atrelado ao PLD"
          : "Fixo"
        : newOffer.priceType,
  };

  return offer;
}

export interface SubmitRegisterOffersProps {
  newOfferList: Array<NewOffer>;
}
export async function submitRegisterOffers({
  newOfferList,
}: SubmitRegisterOffersProps) {
  const dto = newOfferList.map(transformOffer);

  await balcaoApiInstance.post("/operation/create-many", dto);
}

export interface ValidateNewOfferProps {
  newOffer: NewOffer;
}
export async function validateNewOffer({ newOffer }: ValidateNewOfferProps) {
  const dto = [transformOffer(newOffer)];

  const { data } = await balcaoApiInstance.post(`/operation/create-many`, dto, {
    params: { validateOnly: true },
  });

  return data;
}

export async function getOperation(operationId: string | number | undefined) {
  if (operationId === undefined) return null;

  const { data } = await balcaoApiInstance.get<Operation>(
    `/offer/one/operation/${operationId}`
  );

  return data;
}

export async function getOfferHistory(
  participantId: number | string | undefined | null,
  paginationOptions?: {
    limit?: number;
    page?: number;
    orderBy?: string;
    order?: "asc" | "desc";
  }
) {
  const { data } = await balcaoApiInstance.get<{
    operations: Array<HistoryOperation>;
    total: number;
  }>("/operation/history", {
    params: {
      participantId,
      limit: paginationOptions?.limit ?? 10,
      page: paginationOptions?.page ?? 0,
      orderBy: paginationOptions?.orderBy ?? "createdAt",
      order: paginationOptions?.order ?? "desc",
    },
  });

  return data;
}

export async function getOfferEvents(
  operationId: number | string | undefined | null
) {
  if (operationId === null || operationId === undefined) return {};

  const { data } = await balcaoApiInstance.get<HistoryEvents>(
    "/offer/all/events",
    { params: { operationId } }
  );

  return data;
}

export interface RegisterBidProps {
  operationId: number;
  data: {
    value: number;
    amount: number;
    type: "SELL" | "BUY";
  };
}
export async function registerBid({ operationId, data }: RegisterBidProps) {
  await balcaoApiInstance.post(`/operation/${operationId}/bids`, data);
}

export interface AccceptBidProps {
  operationId: number;
  bidId: string;
}
export async function acceptBid({ operationId, bidId }: AccceptBidProps) {
  await balcaoApiInstance.patch(
    `/myoperations/${operationId}/bids/${bidId}/accept`
  );
}

export interface CancelBidProps {
  operationId: number;
  bidId: string;
}
export async function cancelBid({ operationId, bidId }: CancelBidProps) {
  await balcaoApiInstance.patch(
    `/myoperations/${operationId}/bids/${bidId}/cancel`
  );
}

export interface CancelOfferProps {
  operationId: number;
  type: "SELL" | "BUY";
}
export async function cancelOffer({ operationId, type }: CancelOfferProps) {
  await balcaoApiInstance.patch(
    `/myoperations/${operationId}/cancel?type=${type}`
  );
}

export interface SubmitChangeOfferPriceProps {
  operationId: number;
  type: "SELL" | "BUY";
  priceValue: number;
}
export async function submitChangeOfferPrice({
  operationId,
  type,
  priceValue,
}: SubmitChangeOfferPriceProps) {
  await balcaoApiInstance.patch(
    `/myoperations/${operationId}/update?type=${type}`,
    {
      value: priceValue,
    }
  );
}
