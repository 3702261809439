import React from "react";
import { Fab, FabProps, Popover } from "@mui/material";
import { QuestionMark } from "@mui/icons-material";
import { DirectBuyAndSellOfferFaqArea } from "./DirectBuyAndSellOfferFaqArea";

export function DirectBuyAndSellOfferFaqButton(props: FabProps) {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const toggleDrawer = React.useCallback((shouldOpen?: boolean) => {
    setOpen((oldValue) => (shouldOpen === undefined ? !oldValue : shouldOpen));
  }, []);

  return (
    <>
      <Fab
        color="primary"
        aria-label="Edit"
        onClick={(e) => {
          toggleDrawer();
          setAnchorEl(e.currentTarget);
        }}
        {...props}
      >
        <QuestionMark />
      </Fab>

      <Popover
        disableScrollLock
        open={open}
        anchorEl={anchorEl}
        onClose={() => toggleDrawer(false)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        PaperProps={{
          sx: {
            bottom:
              window.innerHeight - (anchorEl?.getBoundingClientRect().y ?? 0),
            top: "unset !important",

            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            width: "100%",
            maxWidth: 400,
          },
        }}
      >
        <DirectBuyAndSellOfferFaqArea />
      </Popover>
    </>
  );
}
