import React, { useMemo, useState } from "react";
import styled from "@emotion/styled";

import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeadProps,
  TableProps,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
} from "@mui/material";
import { SxProps } from "@mui/system";
import { useTranslation } from "react-i18next";

const ToolbarTitle = styled.div`
  min-width: 150px;
`;

export function descendingComparator(
  a: Record<string, any>,
  b: Record<string, any>,
  orderBy: string
) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order: "desc" | "asc", orderBy: string) {
  return order === "desc"
    ? (a: Record<string, any>, b: Record<string, any>) =>
        descendingComparator(a, b, orderBy)
    : (a: Record<string, any>, b: Record<string, any>) =>
        -descendingComparator(a, b, orderBy);
}

export function stableSort<T extends Record<string, any> = Record<string, any>>(
  array: Array<T>,
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el: T, index: number) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

export type HeadCell = {
  id: string;
  alignment: "left" | "center" | "right" | "justify" | "inherit" | undefined;
  sx?: SxProps;
  label: string | JSX.Element;
  disablePadding?: boolean;
  colSpan?: number;
  disableSort?: boolean;
};

export type BalanceTableHeadProps = {
  order: "desc" | "asc";
  orderBy: string;
  rowCount: number;
  onRequestSort: (e: any, property: string) => void;
  headCells: HeadCell[];
  TableHeadProps?: TableHeadProps;
};
export const BalanceTableHead: React.FC<BalanceTableHeadProps> = ({
  order,
  orderBy,
  onRequestSort,
  headCells,
  TableHeadProps,
}) => {
  const createSortHandler = (property: string) => (event: any) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead
      // sx={{ opacity: 0.6 }}
      {...TableHeadProps}
      sx={{
        backgroundColor: "#5A6F88",
        th: { py: 2 },
      }}
    >
      <TableRow sx={{ verticalAlign: "bottom" }}>
        {headCells.map((headCell: HeadCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={headCell.sx}
            colSpan={headCell.colSpan}
          >
            {headCell.disableSort ? (
              headCell.label
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export interface BalanceTableTitleProps {
  title?: string;
  subtitle?: string;
}
export const BalanceTableTitle = ({
  title,
  subtitle,
}: BalanceTableTitleProps) => {
  if (!title) return <></>;

  return (
    <Toolbar sx={{ py: 4, flexDirection: "column", minHeight: "0 !important" }}>
      <Grid
        container
        spacing={3}
        justifyContent="space-between"
        alignItems="center"
      >
        {(title || subtitle) && (
          <Grid item>
            <ToolbarTitle>
              <Typography variant="h6" id={`table-${title}`} color="#CDCDD3">
                {title}
                {subtitle && (
                  <>
                    {" "}
                    <Typography component="span" color="#B6B9C0">
                      {subtitle}
                    </Typography>
                  </>
                )}
              </Typography>
            </ToolbarTitle>
          </Grid>
        )}
      </Grid>
    </Toolbar>
  );
};

type OrderingOptions = {
  orderBy?: string;
  order?: "desc" | "asc";
  handleRequestSort?: (event: any, property: string) => void;
};

interface BalanceTableProps<
  T extends Record<string, any> = Record<string, any>
> {
  data: Array<T>;
  title?: string;
  subtitle?: string;
  headCells: HeadCell[];
  renderRow: (row: T, index: number, arr: Array<T>) => JSX.Element;
  keyExtractor: (row: T, index: number) => string;
  rowsPerPage?: number;
  avoidEmptyRows?: boolean;
  disableSort?: boolean;
  TableProps?: TableProps;
  TableHeadProps?: TableHeadProps;
  footer?: JSX.Element;
  orderingOptions?: OrderingOptions;
}

export function BalanceTable<
  T extends Record<string, any> = Record<string, any>
>({
  data,
  title,
  subtitle,
  headCells,
  renderRow,
  keyExtractor,
  disableSort,
  TableProps,
  TableHeadProps,
  footer,
  orderingOptions,
}: BalanceTableProps<T>) {
  const { t } = useTranslation();

  const [viewMore, setViewMore] = useState(false);

  const indexView = useMemo(() => {
    return viewMore ? data.length - 1 : 1;
  }, [viewMore, data]);

  return (
    <div>
      <>
        <BalanceTableTitle title={title} subtitle={subtitle} />
        <TableContainer sx={{ borderRadius: "5px 0 0 0" }}>
          <Table
            aria-labelledby={`table-${title}`}
            aria-label={title}
            sx={{
              ".MuiTableCell-root": {
                paddingX: 2,
              },
              ...(TableProps?.sx ?? {}),
            }}
            {...TableProps}
          >
            <BalanceTableHead
              order={orderingOptions?.order ?? "asc"}
              orderBy={orderingOptions?.orderBy ?? ""}
              onRequestSort={(event, property) => {
                if (orderingOptions?.handleRequestSort) {
                  orderingOptions.handleRequestSort(event, property);
                }
              }}
              rowCount={data.length}
              headCells={
                disableSort
                  ? headCells.map((item) => ({ ...item, disableSort: true }))
                  : headCells
              }
              TableHeadProps={TableHeadProps}
            />
            <TableBody>
              {data.map((row, index, arr) => (
                <React.Fragment key={keyExtractor(row, index)}>
                  {index <= indexView && <>{renderRow(row, index, arr)}</>}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
          {data.length >= 3 && (
            <Button
              variant="outlined"
              fullWidth
              sx={{ mt: 2 }}
              onClick={() => setViewMore(!viewMore)}
            >
              {viewMore ? t("See Less") : t("See More")}
            </Button>
          )}
        </TableContainer>

        {footer}
      </>
    </div>
  );
}
