import React, { useEffect, useMemo } from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import {
  Alert as MuiAlert,
  Autocomplete,
  Box,
  Button,
  InputAdornment,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
  CircularProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { FileDownload } from "@mui/icons-material";
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { useSnackbar } from "notistack";
import { FormTextField } from "../formFields/FormTextField";
import { StyledTextField } from "../StyledTextField";
import useChooseParticipant from "../../hooks/useChooseParticipant";
import {
  formatCNPJ,
  toLocaleCurrencyStringWithoutSymbol,
} from "../../utils/string";
import { getCustomNumberInputProps } from "../directBuyAndSell/BigInput";
import useConfirmationModal from "../../hooks/useConfirmationModal";
import { toFixedWithoutRounding } from "../../utils/number";
import { useNavigate } from "react-router-dom";
import { useSubmitEnergyDeposit } from "./hooks/useSubmitEnergyDeposit";
import { useGetParticipantProducts } from "../products/hooks/useGetParticipantProducts";
import { useGetSubmarkets } from "../submarket/hooks/useGetSubmarkets";
import { useGetBankData } from "./hooks/useGetBankData";
import { useGetParamsWithHash } from "./hooks/useGetParamsWithHash";

const Alert = styled(MuiAlert)(spacing);

const InfoBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24;
`;

interface Inputs {
  code: string | null;
  submarket: string | null;
  value: string;
}

const inputsDefaultValues: Inputs = {
  code: null,
  submarket: null,
  value: "",
};

export function EnergyDepositPageContent() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  const { showConfirmationModal } = useConfirmationModal();

  const { currentParticipant } = useChooseParticipant();

  const { energyCode } = useGetParamsWithHash();

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: inputsDefaultValues,
  });

  const {
    participantProducts,
    isLoading: isLoadingParticipantProducts,
    isError: isErrorParticipantProducts,
  } = useGetParticipantProducts();

  const productsSorted = useMemo(() => {
    if (!participantProducts) return [];

    return participantProducts.sort((a, b) => {
      const assetA = a.name.toString().toUpperCase();
      const assetB = b.name.toString().toUpperCase();
      return assetA.localeCompare(assetB, undefined, { numeric: true });
    });
  }, [participantProducts]);

  const {
    bankData,
    isLoading: isLoadingBankData,
    isError: isErrorBankData,
  } = useGetBankData();

  const {
    submarkets,
    isLoading: isLoadingSubmarkets,
    isError: isErrorSubmarkets,
  } = useGetSubmarkets();

  const [code] = watch(["code"]);

  const nameProduct = React.useMemo(() => {
    if (!code) return null;
    return participantProducts?.find(
      (item) => item.assetsAvailableCode === code
    )?.name;
  }, [code, participantProducts]);

  React.useEffect(() => {
    if (energyCode && !code) {
      setValue("code", energyCode);
    }
  }, [code, setValue, energyCode]);

  const {
    submitEnergyDepositMutate,
    isLoading: isLoadingSubmitEnergyDeposit,
    error,
  } = useSubmitEnergyDeposit({
    onSuccess: () => {
      navigate("/bank/manage-deposits-and-withdrawals");
    },
  });

  const handleSubmitEnergyDeposit = React.useCallback(
    ({ code, submarket, value }: Inputs) => {
      if (code === null || submarket === null) {
        enqueueSnackbar(t("Something went wrong."), {
          variant: "error",
        });
        return;
      }

      showConfirmationModal({
        onSubmit: () => {
          submitEnergyDepositMutate({
            code,
            submarket,
            value: Number(value),
          });
        },
        message: (
          <>
            {t("Confira as informações de sua solicitação de depósito.")}
            <br />
            <br />
            {t('Em conformidade com os dados, basta clicar em "Confirmar".')}
            <br />
            <br />
            {t(
              'Em seguida, a solicitação poderá ser acompanhada na página "Gerenciamento de Depósitos e Saques". Após a análise, o valor depositado será creditado na sua carteira.'
            )}
          </>
        ),
        submitButtonColor: "primary",
      });
    },
    [enqueueSnackbar, showConfirmationModal, submitEnergyDepositMutate, t]
  );

  useEffect(() => {
    if (error) {
      if ((error as any).message.startsWith("Deposit limit reached")) {
        const limit = parseInt((error as any).value);

        enqueueSnackbar(
          t(
            "Deposit limit reached, maximum deposit value with this type of energy is R$ {{ maxDepositValue }}",
            {
              maxDepositValue: toLocaleCurrencyStringWithoutSymbol(
                Math.max(0, limit),
                "BRL",
                language
              ),
            }
          ),
          {
            variant: "error",
          }
        );

        return;
      } else if (
        (error as any).message.startsWith("User does not have a limit set")
      ) {
        enqueueSnackbar(t("User does not have a limit set"), {
          variant: "error",
        });

        return;
      }
    }
  }, [enqueueSnackbar, error, language, t]);

  const onSubmit = React.useCallback<SubmitHandler<Inputs>>(
    (data) => {
      handleSubmitEnergyDeposit(data);
    },
    [handleSubmitEnergyDeposit]
  );

  const onInvalidSubmit = React.useCallback<SubmitErrorHandler<Inputs>>(() => {
    enqueueSnackbar(t("You must fill in all required fields"), {
      variant: "error",
    });
  }, [enqueueSnackbar, t]);

  if (isErrorParticipantProducts || isErrorSubmarkets || isErrorBankData) {
    return (
      <Alert mt={2} mb={3} severity="error">
        {t("Something went wrong.")}
      </Alert>
    );
  }

  if (
    isLoadingParticipantProducts ||
    isLoadingSubmarkets ||
    participantProducts === undefined ||
    submarkets === undefined ||
    isLoadingBankData
  ) {
    return (
      <InfoBox>
        <CircularProgress />
      </InfoBox>
    );
  }

  return (
    <Paper sx={{ maxWidth: 1200, mx: "auto", p: isMdUp ? 12 : 8 }}>
      <Box sx={{ maxWidth: 460, mx: "auto" }}>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit, onInvalidSubmit)}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Controller
            control={control}
            name={"code"}
            rules={{
              required: {
                value: true,
                message: t("This field is required"),
              },
            }}
            render={({ field: { ref, onChange, ...field } }) => (
              <Autocomplete
                {...field}
                onChange={(e, newValue) => {
                  onChange(newValue);
                }}
                openOnFocus
                handleHomeEndKeys
                disablePortal
                options={productsSorted.map((item) => item.assetsAvailableCode)}
                getOptionLabel={(option) =>
                  participantProducts.find(
                    (product) => product.assetsAvailableCode === option
                  )?.name ?? option
                }
                fullWidth
                renderInput={(params) => (
                  <StyledTextField
                    {...params}
                    inputRef={ref}
                    label={t("Energy Type")}
                    error={!!errors.code}
                    helperText={errors.code?.message ?? " "}
                    // size="small"
                  />
                )}
              />
            )}
          />

          <Controller
            control={control}
            name={"submarket"}
            rules={{
              required: {
                value: true,
                message: t("This field is required"),
              },
            }}
            render={({ field: { ref, onChange, ...field } }) => (
              <Autocomplete
                {...field}
                onChange={(e, newValue) => {
                  onChange(newValue);
                }}
                openOnFocus
                handleHomeEndKeys
                disablePortal
                options={submarkets}
                fullWidth
                renderInput={(params) => (
                  <StyledTextField
                    {...params}
                    inputRef={ref}
                    label={t("Submarket")}
                    error={!!errors.submarket}
                    helperText={errors.submarket?.message ?? " "}
                    // size="small"
                  />
                )}
              />
            )}
          />

          <StyledTextField
            label={t("CNPJ")}
            fullWidth
            value={formatCNPJ(currentParticipant?.companyCnpj ?? "")}
            InputProps={{ readOnly: true }}
            helperText=" "
            // size="small"
          />

          <FormTextField
            control={control}
            name={"value"}
            rules={{
              required: {
                value: true,
                message: t("This field is required"),
              },
              min: {
                value: 1,
                message: t("The minimum allowed value is {{value}}", {
                  value:
                    (1).toLocaleString(language, {
                      minimumFractionDigits: 3,
                      maximumFractionDigits: 3,
                    }) + " MWh",
                }),
              },
              max: {
                value: 999999999,
                message: t("The maximum allowed value is {{value}}", {
                  value:
                    (999999999).toLocaleString(language, {
                      minimumFractionDigits: 3,
                      maximumFractionDigits: 3,
                    }) + " MWh",
                }),
              },
              onChange: (e) => {
                if (e.target.value.split(".")[1]?.length > 3)
                  setValue("value", toFixedWithoutRounding(e.target.value, 3));
              },
            }}
            label={t("Deposit Value")}
            fullWidth
            type="number"
            inputProps={{ step: "0.001", min: 1 }}
            InputProps={getCustomNumberInputProps({
              disableArrows: true,
              disableWheel: true,
              disableE: true,
              disableUpDownKeys: true,
              endAdornment: <InputAdornment position="end">MWh</InputAdornment>,
            })}
            helperText={`${t("Min")} ${(1).toLocaleString(language, {
              minimumFractionDigits: 3,
              maximumFractionDigits: 3,
            })} MWh`}
            // size="small"
          />

          {nameProduct && (
            <Box
              sx={(theme) => ({
                backgroundColor:
                  theme.palette.mode === "dark" ? "#293139" : "#deeaf5",
                border: "1px solid #FFFFFF",
                py: 3,
                px: 4,
                borderRadius: 1,
                width: "100%",
                mt: 4,
              })}
            >
              <Typography>
                <strong>{t("Profile Key")}: </strong>
                {
                  bankData?.ccee?.find((item) => item.name === nameProduct)
                    ?.profileKey
                }
              </Typography>
              <Typography mt={4}>
                <strong>{t("Profile Code")}: </strong>
                {
                  bankData?.ccee?.find((item) => item.name === nameProduct)
                    ?.profileCode
                }
              </Typography>
            </Box>
          )}

          <Button
            type="submit"
            variant="contained"
            sx={{ minWidth: "16ch", mt: 8 }}
            disabled={isLoadingSubmitEnergyDeposit}
          >
            <FileDownload sx={{ marginRight: 2 }} /> {t("Deposit")}
          </Button>
        </Box>
      </Box>
    </Paper>
  );
}
