import React from "react";
import { useMutation } from "@tanstack/react-query";
import useAuth from "../../../hooks/useAuth";
import { sendOnlineStatus } from "../infra/api/ChatInfra";
import { setIntervalImmediately } from "../../../utils/utils";

export function useSendOnlineStatus() {
  const { user, isInitialized, isAuthenticated } = useAuth();

  const { mutate: sendOnlineStatusMutate } = useMutation(sendOnlineStatus, {});

  React.useEffect(() => {
    let timer: NodeJS.Timer | null = null;

    if (isInitialized && isAuthenticated && user?.role === "user") {
      timer = setIntervalImmediately(() => {
        sendOnlineStatusMutate();
      }, 10 * 1000);
    } else if (timer) {
      clearInterval(timer);
    }

    return () => {
      if (timer) {
        clearInterval(timer as any);
      }
    };
  }, [isAuthenticated, isInitialized, sendOnlineStatusMutate, user?.role]);
}
