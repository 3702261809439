import React from "react";
import * as firestore from "firebase/firestore";
import { useFirestoreDoc } from "../../../hooks/useFirestoreQuery";
import { Operation } from "../../../types/offer";
import {
  OfferResume,
  OperationResume,
} from "../../../pages/offers/entities/data-objects/OperationResume";
import { parseDate } from "../../../utils/date";
import useChooseParticipant from "../../../hooks/useChooseParticipant";
import { floatingPointFixedOp } from "../../../utils/number";

export function useGetOperation(operationId: string | null | undefined) {
  const { participantId } = useChooseParticipant();

  const {
    data: [operationData],
  } = useFirestoreDoc(() => {
    if (!operationId) return [];

    return [
      firestore.doc(firestore.getFirestore(), `operation/${operationId}`),
    ];
  }, [operationId]);

  const operation = React.useMemo(() => {
    if (operationData === undefined) return undefined;
    return operationData as Operation;
  }, [operationData]);

  const operationResume =
    operationId && operation
      ? new OperationResume(
          operationId,
          operation.participantId === participantId,
          operation.product.name,
          operation.product.measurementUnit,
          operation.submarket,
          operation.buyOffer
            ? new OfferResume(
                `${operationId}b`,
                operationId,
                operation.buyOffer.volume,
                operation.buyOffer.remainingVolumeToBeNegotiated,
                operation.buyOffer.priceType === "Fixo"
                  ? operation.buyOffer.priceValue ?? null
                  : null,
                operation.buyOffer.currentBestBidValue || null,
                operation.buyOffer.currentBestBidVolume || null,
                NaN,
                operation.buyOffer.priceType !== "Fixo"
                  ? floatingPointFixedOp(
                      operation.buyOffer.priceValue ?? 0,
                      "-",
                      (operation.buyOffer.pldValue ?? 0) / 100
                    )
                  : null,
                operation.buyOffer.priceType !== "Fixo",
                null,
                operation.buyOffer.status,
                operation.buyOffer.offerType
              )
            : null,
          operation.sellOffer
            ? new OfferResume(
                `${operationId}s`,
                operationId,
                operation.sellOffer.volume,
                operation.sellOffer.remainingVolumeToBeNegotiated,
                operation.sellOffer.priceType === "Fixo"
                  ? operation.sellOffer.priceValue ?? null
                  : null,
                operation.sellOffer.currentBestBidValue || null,
                operation.sellOffer.currentBestBidVolume || null,
                NaN,
                operation.sellOffer.priceType !== "Fixo"
                  ? floatingPointFixedOp(
                      operation.sellOffer.priceValue ?? 0,
                      "-",
                      (operation.sellOffer.pldValue ?? 0) / 100
                    )
                  : null,
                operation.sellOffer.priceType !== "Fixo",
                null,
                operation.sellOffer.status,
                operation.sellOffer.offerType
              )
            : null,
          parseDate(operation.offerCloseDateTime) ?? new Date(""),
          operation.status,
          new Date(operation.deliveryPeriodStart),
          new Date(operation.deliveryPeriodEnd),
          operation.reTusd,
          operation.participantClassName
        )
      : null;

  return { operation, operationResume };
}
