import { Box, Paper, Typography } from "@mui/material";
import {
  getCurrencySymbol,
  toLocaleCurrencyStringWithoutSymbol,
} from "../../utils/string";
import { useTranslation } from "react-i18next";

interface ResumeBalanceProps {
  color: "default" | "secondary";
  title: string;
  value: string | number | null;
}

const colors = {
  default: "#5A6F88",
  secondary: "#213043",
};

const font = {
  default: "bold",
  secondary: "none",
};

export function ResumeBalance({
  color = "default",
  title,
  value,
}: ResumeBalanceProps) {
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <Paper
      sx={{
        textAlign: "left",
        p: 3,
        width: "224px",
        backgroundColor: colors[color],
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      }}
    >
      <Typography
        lineHeight={1}
        fontSize="1rem"
        color="#CDCDD3"
        fontWeight={font[color]}
      >
        {title}
      </Typography>
      <Box
        display="inline-flex"
        justifyContent="center"
        alignItems="center"
        gap={3}
      >
        <Box display="inline-flex" flexDirection="column" gap={2}>
          <Typography fontSize={25} color="#DAE4FF" lineHeight={0}>
            ~
          </Typography>
          <Typography fontSize={25} color="#DAE4FF" lineHeight={0}>
            ~
          </Typography>
        </Box>
        <Typography fontSize="1.4rem" color="#DAE4FF">
          <strong>
            {value == null && "-"}
            {value !== null && (
              <>
                {getCurrencySymbol("BRL", language)}{" "}
                {toLocaleCurrencyStringWithoutSymbol(
                  Number(value),
                  "BRL",
                  language
                )}
              </>
            )}
          </strong>
        </Typography>
      </Box>
    </Paper>
  );
}
