/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import {
  Alert as MuiAlert,
  Autocomplete,
  Box,
  Button,
  InputAdornment,
  Paper,
  useMediaQuery,
  useTheme,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { FileDownload } from "@mui/icons-material";
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { useSnackbar } from "notistack";
import { FormTextField } from "../formFields/FormTextField";
import { StyledTextField } from "../StyledTextField";
import { getCustomNumberInputProps } from "../directBuyAndSell/BigInput";
import useConfirmationModal from "../../hooks/useConfirmationModal";
import { toFixedWithoutRounding } from "../../utils/number";
import { useNavigate } from "react-router-dom";
import { useSubmitEnergyWithdrawal } from "./hooks/useSubmitEnergyWithdrawal";
import { useGetParticipantProducts } from "../products/hooks/useGetParticipantProducts";
import { useGetSubmarkets } from "../submarket/hooks/useGetSubmarkets";
import { useGetParticipantBankData } from "./hooks/useGetParticipantBankData";
import { useGetParamsWithHash } from "./hooks/useGetParamsWithHash";

const Alert = styled(MuiAlert)(spacing);

const InfoBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24;
`;

interface Inputs {
  cliqCCEEProfile: string;
  cliqCCEECode: string;
  code: string | null;
  submarket: string | null;
  value: string;
  password: string;
}

const inputsDefaultValues: Inputs = {
  cliqCCEEProfile: "",
  cliqCCEECode: "",
  code: null,
  submarket: null,
  value: "",
  password: "",
};

export function EnergyWithdrawalPageContent() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  const { showConfirmationModal } = useConfirmationModal();

  const { energyCode } = useGetParamsWithHash();

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: inputsDefaultValues,
  });

  const {
    participantProducts,
    isLoading: isLoadingParticipantProducts,
    isError: isErrorParticipantProducts,
  } = useGetParticipantProducts();

  const {
    submarkets,
    isLoading: isLoadingSubmarkets,
    isError: isErrorSubmarkets,
  } = useGetSubmarkets();

  const {
    participantBankData,
    isLoading: isLoadingParticipantBankData,
    isError: isErrorParticipantBankData,
  } = useGetParticipantBankData();

  const [code] = watch(["code"]);

  React.useEffect(() => {
    if (energyCode && !code) {
      setValue("code", energyCode);
    }
    if (code) {
      const nameProduct = participantProducts?.find(
        (item) => item.assetsAvailableCode === code
      )?.name;

      setValue(
        "cliqCCEEProfile",
        participantBankData?.ccee?.find((item) => item.name === nameProduct)
          ?.profileKey!
      );

      setValue(
        "cliqCCEECode",
        participantBankData?.ccee?.find((item) => item.name === nameProduct)
          ?.profileCode!
      );
    }
  }, [code, participantProducts, setValue, participantBankData, energyCode]);

  const {
    submitEnergyWithdrawalMutate,
    isLoading: isLoadingSubmitEnergyWithdrawal,
  } = useSubmitEnergyWithdrawal({
    onSuccess: () => {
      enqueueSnackbar(t("Withdrawal request completed successfully"), {
        variant: "success",
      });
      navigate("/bank/manage-deposits-and-withdrawals");
    },
    onError: (error) => {
      enqueueSnackbar(t(error.message), {
        variant: "error",
      });
    },
  });

  const handleSubmitEnergyWithdrawal = React.useCallback(
    ({
      cliqCCEEProfile,
      cliqCCEECode,
      code,
      submarket,
      value,
      password,
    }: Inputs) => {
      if (code === null || submarket === null) {
        enqueueSnackbar(t("Something went wrong."), {
          variant: "error",
        });
        return;
      }

      showConfirmationModal({
        onSubmit: () => {
          submitEnergyWithdrawalMutate({
            cliqCCEEProfile,
            cliqCCEECode,
            code,
            submarket,
            value: Number(value),
            password,
          });
        },
        message: (
          <>
            {t("Confira as informações de sua solicitação de saque.")}
            <br />
            <br />
            {t('Em conformidade com os dados, basta clicar em "Confirmar".')}
            <br />
            <br />
            {t(
              "Em seguida, será gerado um contrato para assinatura dos responsáveis legais da empresa na aba “Contrato”."
            )}
          </>
        ),
        submitButtonColor: "primary",
      });
    },
    [enqueueSnackbar, showConfirmationModal, t, submitEnergyWithdrawalMutate]
  );

  const onSubmit = React.useCallback<SubmitHandler<Inputs>>(
    (data) => {
      handleSubmitEnergyWithdrawal(data);
    },
    [handleSubmitEnergyWithdrawal]
  );

  const onInvalidSubmit = React.useCallback<SubmitErrorHandler<Inputs>>(() => {
    enqueueSnackbar(t("You must fill in all required fields"), {
      variant: "error",
    });
  }, [enqueueSnackbar, t]);

  if (
    isErrorParticipantProducts ||
    isErrorSubmarkets ||
    isErrorParticipantBankData
  ) {
    return (
      <Alert mt={2} mb={3} severity="error">
        {t("Something went wrong.")}
      </Alert>
    );
  }

  if (
    isLoadingParticipantProducts ||
    isLoadingSubmarkets ||
    participantProducts === undefined ||
    submarkets === undefined ||
    isLoadingParticipantBankData
  ) {
    return (
      <InfoBox>
        <CircularProgress />
      </InfoBox>
    );
  }

  return (
    <Paper sx={{ maxWidth: 1200, mx: "auto", p: isMdUp ? 12 : 8 }}>
      <Box component="form" onSubmit={handleSubmit(onSubmit, onInvalidSubmit)}>
        <Grid
          container
          sx={{
            maxWidth: 1000,
            mx: "auto",
          }}
        >
          <Grid item xs={12} md={6} pr={isMdUp ? 4 : 0}>
            <Box
              sx={{
                maxWidth: 460,
                mx: "auto",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography variant="h6" mb={8}>
                {t("CCEE Information")}
              </Typography>

              <Controller
                control={control}
                name={"code"}
                rules={{
                  required: {
                    value: true,
                    message: t("This field is required"),
                  },
                }}
                render={({ field: { ref, onChange, ...field } }) => (
                  <Autocomplete
                    {...field}
                    onChange={(e, newValue) => {
                      onChange(newValue);
                    }}
                    openOnFocus
                    handleHomeEndKeys
                    disablePortal
                    options={participantProducts.map(
                      (item) => item.assetsAvailableCode
                    )}
                    getOptionLabel={(option) =>
                      participantProducts.find(
                        (product) => product.assetsAvailableCode === option
                      )?.name ?? option
                    }
                    fullWidth
                    renderInput={(params) => (
                      <StyledTextField
                        {...params}
                        inputRef={ref}
                        label={t("Energy Type")}
                        error={!!errors.code}
                        helperText={errors.code?.message ?? " "}
                        // size="small"
                      />
                    )}
                  />
                )}
              />

              <FormTextField
                control={control}
                InputProps={{
                  readOnly: true,
                }}
                name={"cliqCCEEProfile"}
                rules={{
                  required: {
                    value: true,
                    message: t("This field is required"),
                  },
                }}
                label={t("Profile Key")}
                fullWidth
                // size="small"
              />

              <FormTextField
                control={control}
                InputProps={{ readOnly: true }}
                name={"cliqCCEECode"}
                rules={{
                  required: {
                    value: true,
                    message: t("This field is required"),
                  },
                }}
                label={t("Profile Code")}
                fullWidth
                // size="small"
              />

              <Controller
                control={control}
                name={"submarket"}
                rules={{
                  required: {
                    value: true,
                    message: t("This field is required"),
                  },
                }}
                render={({ field: { ref, onChange, ...field } }) => (
                  <Autocomplete
                    {...field}
                    onChange={(e, newValue) => {
                      onChange(newValue);
                    }}
                    openOnFocus
                    handleHomeEndKeys
                    disablePortal
                    options={submarkets}
                    fullWidth
                    renderInput={(params) => (
                      <StyledTextField
                        {...params}
                        inputRef={ref}
                        label={t("Submarket")}
                        error={!!errors.submarket}
                        helperText={errors.submarket?.message ?? " "}
                        // size="small"
                      />
                    )}
                  />
                )}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6} pl={isMdUp ? 4 : 0} pt={isMdUp ? 0 : 6}>
            <Box
              sx={{
                maxWidth: 460,
                mx: "auto",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography variant="h6" mb={8}>
                {t("Withdrawal Information")}
              </Typography>

              <FormTextField
                control={control}
                name={"value"}
                rules={{
                  required: {
                    value: true,
                    message: t("This field is required"),
                  },
                  min: {
                    value: 1,
                    message: t("The minimum allowed value is {{value}}", {
                      value:
                        (1).toLocaleString(language, {
                          minimumFractionDigits: 3,
                          maximumFractionDigits: 3,
                        }) + " MWh",
                    }),
                  },
                  max: {
                    value: 999999999,
                    message: t("The maximum allowed value is {{value}}", {
                      value:
                        (999999999).toLocaleString(language, {
                          minimumFractionDigits: 3,
                          maximumFractionDigits: 3,
                        }) + " MWh",
                    }),
                  },
                  onChange: (e) => {
                    if (e.target.value.split(".")[1]?.length > 3)
                      setValue(
                        "value",
                        toFixedWithoutRounding(e.target.value, 3)
                      );
                  },
                }}
                label={`${t("Withdrawal Value")} (MWh)`}
                fullWidth
                type="number"
                inputProps={{ step: "0.001", min: 0 }}
                InputProps={getCustomNumberInputProps({
                  disableArrows: true,
                  disableWheel: true,
                  disableE: true,
                  disableUpDownKeys: true,
                  endAdornment: (
                    <InputAdornment position="end">MWh</InputAdornment>
                  ),
                })}
                helperText={`${t("Min")} ${(1).toLocaleString(language, {
                  minimumFractionDigits: 3,
                  maximumFractionDigits: 3,
                })} MWh`}
                // size="small"
              />

              <FormTextField
                control={control}
                name={"password"}
                rules={{
                  required: {
                    value: true,
                    message: t("This field is required"),
                  },
                }}
                label={t("Password")}
                fullWidth
                type="password"
                autoComplete="new-password"
                // size="small"
              />

              <Button
                type="submit"
                variant="contained"
                sx={{ minWidth: "16ch", mt: 8 }}
                disabled={isLoadingSubmitEnergyWithdrawal}
              >
                <FileDownload sx={{ marginRight: 2 }} /> {t("Withdraw")}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}
