import { MonthlyPld } from "../types/pld";
import { balcaoApiInstance } from "../utils/axios";

export async function getMonthlyPld(
  startMonth: string | null,
  endMonth: string | null
) {
  if (!startMonth || !endMonth) return [];

  const { data } = await balcaoApiInstance.get<Array<MonthlyPld>>(
    `/pld/month/${startMonth}/${endMonth}`
  );

  return data;
}
