import { balcaoApiInstance } from "../../../../utils/axios";

export interface UpdateTunnelLimitsProps {
  firstOperationMaxSpread: number;
  firstOperationMinSpread: number;
  operationMaxDeviation: number;
  operationMinDeviation: number;
}
export async function updateTunnelLimits(options: UpdateTunnelLimitsProps) {
  await balcaoApiInstance.put("/tunnel-limits", options);
}

export async function getTunnelLimits() {
  const { data } = await balcaoApiInstance.get<{
    firstOperationMaxSpread: number;
    firstOperationMinSpread: number;
    operationMaxDeviation: number;
    operationMinDeviation: number;
  }>("/tunnel-limits");

  return data;
}
