import { useMutation } from "@tanstack/react-query";
import { createFinancialWithdrawal } from "../infra/api/BankInfra";

type Error = {
  message: string;
  statusCode: number;
};

export function useSubmitFinancialWithdrawal(options?: {
  onSuccess?: () => void;
  onError?: (error: Error) => void;
}) {
  const { mutate: submitFinancialWithdrawalMutate, ...rest } = useMutation(
    createFinancialWithdrawal,
    options
  );

  return {
    submitFinancialWithdrawalMutate,
    ...rest,
  };
}
