import { useQuery } from "@tanstack/react-query";
import { getBankData } from "../infra/api/BankInfra";

export function useGetBankData() {
  const { data: bankData, ...rest } = useQuery(["getBankData"], () =>
    getBankData()
  );

  return { bankData, ...rest };
}
