import {
  Grid,
  Typography,
  Link,
  Divider as MuiDivider,
  Breadcrumbs as MuiBreadcrumbs,
  Box,
} from "@mui/material";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { NegotiatedOffersStatistics } from "../OffersPanelNegotiatedOffersStatistics";
import { OffersPanelPldBox } from "../OffersPanelPldBox";
import { OffersTable } from "./OffersTable";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Divider = styled(MuiDivider)(spacing);

export const OffersPanel: React.FC<any> = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Helmet title={t("Offers Panel")} />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            {t("Offers Panel")}
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              {t("Home")}
            </Link>
            <Typography>{t("Offers Panel")}</Typography>
          </Breadcrumbs>
        </Grid>

        <Grid item>
          <Box minHeight="5rem">
            <OffersPanelPldBox />
          </Box>
        </Grid>
      </Grid>
      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <OffersTable />
        </Grid>
      </Grid>

      <Grid container spacing={6} mt={4}>
        <Grid item xs={12} lg={10} xl={8}>
          <NegotiatedOffersStatistics />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
