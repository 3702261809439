import { useState } from "react";
import { format } from "d3-format";
import { timeFormat } from "d3-time-format";

import { ChartCanvas, Chart, ZoomButtons } from "react-stockcharts";
import { BarSeries, CandlestickSeries } from "react-stockcharts/lib/series";
import { OHLCTooltip, HoverTooltip } from "react-stockcharts/lib/tooltip";
import { XAxis, YAxis } from "react-stockcharts/lib/axes";

import {
  CrossHairCursor,
  MouseCoordinateY,
  EdgeIndicator,
} from "react-stockcharts/lib/coordinates";

import { discontinuousTimeScaleProvider } from "react-stockcharts/lib/scale";
import { last } from "react-stockcharts/lib/utils";

import { Box, IconButton, Typography, useMediaQuery } from "@mui/material";
import { UnfoldMoreRounded } from "@mui/icons-material";

export function TradingViewGraphic({
  title,
  handleMaximize,
  maximize,
  statistics,
}) {
  const isMdUp = useMediaQuery("(max-width:1280px)");
  const ratio = 1;
  const width = maximize ? 645 : isMdUp ? 340 : 380;
  const height = maximize ? 450 : 240;
  const [suffix, setSuffix] = useState(1);

  const xScaleProvider = discontinuousTimeScaleProvider.inputDateAccessor(
    (d) => d.date
  );
  const { data, xScale, xAccessor, displayXAccessor } =
    xScaleProvider(statistics);

  const start = xAccessor(last(data));
  const end = xAccessor(data[Math.max(0, data.length - 100)]);
  const xExtents = [start, end];

  const margin = { left: 80, right: 80, top: 30, bottom: 30 };
  const gridHeight = height - margin.top - margin.bottom;
  const gridWidth = width - margin.left - margin.right;

  const showGrid = true;
  const yGrid = showGrid
    ? {
        innerTickSize: -1 * gridWidth,
        tickStrokeDasharray: "Solid",
        tickStrokeOpacity: 0.1,
        tickStrokeWidth: 0.5,
      }
    : {};
  const xGrid = showGrid
    ? {
        innerTickSize: -1 * gridHeight,
        tickStrokeDasharray: "Solid",
        tickStrokeOpacity: 0.1,
        tickStrokeWidth: 0.5,
      }
    : {};

  function handleReset() {
    setSuffix(suffix + 1);
  }

  const dateFormat = timeFormat("%Y-%m-%d %H:%M");
  const numberFormat = format(".2f");

  function tooltipContent() {
    return ({ currentItem, xAccessor }) => {
      return {
        x: dateFormat(xAccessor(currentItem)),
        y: [
          {
            label: "Open",
            value: currentItem.open && numberFormat(currentItem.open),
          },
          {
            label: "High",
            value: currentItem.high && numberFormat(currentItem.high),
          },
          {
            label: "Low",
            value: currentItem.low && numberFormat(currentItem.low),
          },
          {
            label: "Close",
            value: currentItem.close && numberFormat(currentItem.close),
          },
          {
            label: "Volume",
            value: currentItem.volume && numberFormat(currentItem.volume),
          },
        ].filter((line) => line.value),
      };
    };
  }

  return (
    <Box
      width={maximize ? "100%" : "calc(50% - 5px)"}
      height={maximize ? "100%" : "calc(50% - 5px)"}
      sx={{
        border: "1px solid #8295b252",
        borderRadius: "10px",
        "div.react-stockchart": {
          width: "100% !important",
          display: "flex",
          justifyContent: "center",
        },
      }}
    >
      <Box width="100%">
        <Box
          width="80%"
          margin="auto"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography fontWeight="bold">{title}</Typography>
          <IconButton
            onClick={() => {
              handleMaximize(title);
              if (maximize) {
                handleReset();
              }
            }}
          >
            <UnfoldMoreRounded sx={{ transform: "rotate(45deg)" }} />
          </IconButton>
        </Box>
      </Box>

      <Box position="relative" width={gridWidth} margin="auto">
        <Box
          // width="30px"
          height="120px"
          position="absolute"
          right={-50}
          fontSize={12}
          color={"#7789a4"}
          sx={{
            writingMode: "vertical-rl",
            textOrientation: "mixed",
            display: "inline-block",
          }}
        >
          <span>PLD + Spread (R$)</span>
        </Box>

        <Box
          height="100px"
          position="absolute"
          left={-40}
          top={gridHeight - 95}
          fontSize={12}
          color={"#7789a4"}
          sx={{
            writingMode: "vertical-rl",
            textOrientation: "mixed",
            display: "inline-block",
            transform: "scale(-1,-1)",
          }}
        >
          <span>Volume</span>
        </Box>
      </Box>

      <ChartCanvas
        height={height}
        ratio={ratio}
        width={width}
        margin={margin}
        type={"svg"}
        seriesName={`MSFT_${suffix}`}
        data={data}
        xScale={xScale}
        xAccessor={xAccessor}
        displayXAccessor={displayXAccessor}
        xExtents={xExtents}
        zoomEvent={true}
      >
        <Chart
          id={1}
          yExtents={(d) => [d.high, d.low]}
          padding={{ top: 0, bottom: 20 }}
        >
          <YAxis
            axisAt="right"
            orient="right"
            ticks={10}
            {...yGrid}
            tickStroke="#8295B2"
            stroke="#8295B2"
          />
          <XAxis
            axisAt="bottom"
            orient="bottom"
            ticks={maximize ? 10 : 0}
            {...xGrid}
            outerTickSize={0}
            tickStroke="#8295B2"
            stroke="#8295B2"
          />
          <MouseCoordinateY
            at="right"
            orient="right"
            displayFormat={format(".2f")}
          />
          <CandlestickSeries
            stroke={(d) => (d.close > d.open ? "#00CB85" : "#EB5757")}
            fill={(d) => (d.close > d.open ? "#00CB85" : "#EB5757")}
            opacity={1}
            wickStroke={(d) => (d.close > d.open ? "#00CB85" : "#EB5757")}
          />
          <EdgeIndicator
            itemType="last"
            orient="right"
            edgeAt="right"
            yAccessor={(d) => d.close}
            fill={(d) =>
              d.close === d.open
                ? "#525252"
                : d.close > d.open
                ? "#00cb85"
                : "#FF0000"
            }
          />
          <HoverTooltip
            tooltipContent={tooltipContent()}
            opacity={1}
            fontSize={15}
            fill={"#ffffff"}
          />
          <ZoomButtons onReset={handleReset} />
          {maximize && (
            <OHLCTooltip
              textFill={"#FFFFFF"}
              labelFill={"#7689a4"}
              fontSize={gridHeight >= 390 ? 12 : 9}
              origin={[-40, -10]}
            />
          )}
        </Chart>
        <Chart
          id={2}
          origin={(w, h) => [0, h - 100]}
          height={100}
          yExtents={(d) => d.volume}
        >
          <YAxis
            axisAt="left"
            orient="left"
            ticks={5}
            tickStroke="#8295B2"
            stroke="#8295B2"
          />
          <MouseCoordinateY
            at="left"
            orient="left"
            displayFormat={format(".2f")}
          />
          <BarSeries
            yAccessor={(d) => d.volume}
            fill={(d) => (d.close > d.open ? "#00CB85" : "#EB5757")}
            opacity={0.3}
            stroke={false}
          />
        </Chart>
        <CrossHairCursor />
      </ChartCanvas>
    </Box>
  );
}
