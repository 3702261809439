import React from "react";
import { InputBase, InputBaseProps } from "@mui/material";

export interface CustomNumberInputBaseProps extends InputBaseProps {
  disableArrows?: boolean;
  disableWheel?: boolean;
  disableE?: boolean;
  disableUpDownKeys?: boolean;
}

export function getCustomNumberInputProps({
  disableArrows,
  disableWheel,
  disableE,
  disableUpDownKeys,
  ...rest
}: CustomNumberInputBaseProps): InputBaseProps {
  return {
    type: "number",
    inputMode: "decimal",
    autoComplete: "off",
    autoCorrect: "off",
    spellCheck: "false",
    ...rest,
    sx: [
      disableArrows
        ? {
            /* Chrome, Safari, Edge, Opera */
            "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
              {
                WebkitAppearance: "none",
                margin: 0,
              },
            /* Firefox */
            "input[type=number]": {
              MozAppearance: "textfield",
            },
          }
        : {},
      ...(Array.isArray(rest.sx) ? rest.sx : [rest.sx]),
    ],
    inputProps: {
      onWheel: disableWheel
        ? (e) => {
            e.currentTarget.blur();
            rest.inputProps?.onWheel && rest.inputProps.onWheel(e);
          }
        : rest.inputProps?.onWheel,
      onKeyDown:
        disableE || disableUpDownKeys
          ? (e) => {
              [
                ...(disableE ? ["e", "E"] : []),
                ...(disableUpDownKeys ? ["ArrowUp", "ArrowDown"] : []),
              ].includes(e.key) && e.preventDefault();
              rest.inputProps?.onKeyDown && rest.inputProps?.onKeyDown(e);
            }
          : rest.inputProps?.onKeyDown,
      ...rest.inputProps,
    },
  };
}

export function CustomNumberInputBase(props: CustomNumberInputBaseProps) {
  return <InputBase {...getCustomNumberInputProps(props)} />;
}

export function BigInput(props: CustomNumberInputBaseProps) {
  return (
    <CustomNumberInputBase
      {...props}
      sx={[
        {
          flex: 1,
          color: "inherit",
          input: { fontSize: "1.5rem", fontWeight: 600 },
        },
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
      ]}
    />
  );
}
