import { Observable, Subscription } from "rxjs";
import { OperationResume } from "../entities/data-objects/OperationResume";
import { PanelOperationsObservableImpl } from "../infra/firestore/PanelOperationsObservableImpl";

export abstract class OffersPanelBus {
  abstract listenLastNOperations(): void;
}

export type panelData = {
  page: number;
  totalItems: number;
  operations: OperationResume[];
};

export type options = {
  page: number;
  itemsPerPage: number;
  participant: number;
  product: string | null;
  submarket: string | null;
  onlyMyOffers: boolean;
  showFinishedOffers: boolean;
  onlyOffersWithMyBids: boolean;
};

export abstract class PanelOperationsObservable extends Observable<panelData> {
  abstract subscribeWithOptions(
    options: options,
    next?: ((value: panelData) => void) | null | undefined,
    error?: ((error: any) => void) | null | undefined,
    complete?: (() => void) | null | undefined
  ): Subscription;

  abstract setOptions(options: options): void;
}

export class GetOffersPanelObservableService {
  private observable: PanelOperationsObservable | null = null;

  public getOffersPanelObservable() {
    if (this.observable === null) {
      this.observable = new PanelOperationsObservableImpl();
    }

    return this.observable;
  }
}
