import React, { ReactNode } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

export type SummaryTableData = Array<
  Array<{
    label: string;
    value: string | ReactNode | null | undefined;
    secondValue?: string | ReactNode | null | undefined;
  }>
>;

export interface SummaryTableProps {
  title?: string;
  subtitle?: string;
  data: SummaryTableData;
  tableHead?: Array<string>;
}

export function SummaryTable({
  data,
  title,
  subtitle,
  tableHead,
}: SummaryTableProps) {
  return (
    <>
      {(title || subtitle) && (
        <Box mb={8} maxWidth={558}>
          {title && (
            <Typography variant="h6" gutterBottom>
              {title}
            </Typography>
          )}
          {subtitle && (
            <Typography variant="body2" gutterBottom>
              {subtitle}
            </Typography>
          )}
        </Box>
      )}
      {data.map((group, idx) => {
        return (
          <React.Fragment key={idx}>
            {idx > 0 && <br />}

            <TableContainer>
              <Table
                size="small"
                aria-label={title}
                sx={(theme) => ({
                  "& .MuiTableCell-root": {
                    border: `1px solid ${theme.palette.text.primary}`,
                  },
                })}
              >
                {tableHead && (
                  <TableHead>
                    <TableRow>
                      {tableHead?.map((item) => (
                        <TableCell style={{ textAlign: "center" }} key={item}>
                          {item}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                )}
                <TableBody>
                  {group.map((item, index) => (
                    <React.Fragment key={item.label}>
                      {item.value && (
                        <TableRow>
                          <TableCell
                            width={"40%"}
                            style={{ fontWeight: "bold", textAlign: "center" }}
                          >
                            {item.label}
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            {item.value}
                          </TableCell>
                          {item?.secondValue && (
                            <TableCell style={{ textAlign: "center" }}>
                              {item?.secondValue}
                            </TableCell>
                          )}
                        </TableRow>
                      )}
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </React.Fragment>
        );
      })}
    </>
  );
}
