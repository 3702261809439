import React from "react";
import { Box, Grid } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { SpotMarketPageContent } from "../../components/spotMarket/SpotMarketPageContent";
import { OffersPanelPldBox } from "../offers/OffersPanelPldBox";
import { FixedDeliveryPeriodBox } from "./FixedDeliveryPeriodBox";
import { ResumeBalanceBox } from "./ResumeBalanceBox";

export function SpotMarket() {
  const { t } = useTranslation();

  const pageName = React.useMemo(() => t("Spot Market"), [t]);

  return (
    <React.Fragment>
      <Helmet title={pageName} />
      <Grid justifyContent="flex-end" container spacing={5} mb={3}>
        <Grid item>
          <Box minHeight="5rem">
            <OffersPanelPldBox />
          </Box>
        </Grid>

        <Grid item>
          <Box minHeight="5rem">
            <FixedDeliveryPeriodBox />
          </Box>
        </Grid>

        <Grid item>
          <Box>
            <ResumeBalanceBox type="currency" />
            <ResumeBalanceBox type="energy" />
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <SpotMarketPageContent />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
