import React from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Chip,
  CircularProgress,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { green, red } from "@mui/material/colors";
import { ContentCopy, Visibility } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";

import { getOfferEvents, getOfferHistory } from "../../services/offers";
import useChooseParticipant from "../../hooks/useChooseParticipant";
import {
  EnhancedTable,
  EnhancedTableManualPagination,
  HeadCell,
} from "../../components/EnhancedTable";
import { SummaryTable, SummaryTableData } from "../../components/SummaryTable";
import { StyledModal } from "../../components/StyledModal";
import { HistoryOperation } from "../../types/offer";
import { parseDate } from "../../utils/date";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Alert = styled(MuiAlert)(spacing);

const InfoBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24;
`;

interface OfferHistoryModalContentProps {
  operation: HistoryOperation;
  onClose: () => void;
}

function OfferHistoryModalContent({
  operation,
  onClose,
}: OfferHistoryModalContentProps) {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const {
    data: offerEventsUnsorted,
    isLoading: isLoadingOfferEvents,
    isError: isErrorOfferEvents,
  } = useQuery(["/offer/all/events", operation.id], () =>
    getOfferEvents(operation.id)
  );

  const offerEventsNew = React.useMemo(() => {
    return {
      buyOfferEvents: offerEventsUnsorted?.buyOfferEvents?.sort((a, b) =>
        b.date === a.date ? 0 : b.date < a.date ? -1 : 1
      ),
      sellOfferEvents: offerEventsUnsorted?.sellOfferEvents?.sort((a, b) =>
        b.date === a.date ? 0 : b.date < a.date ? -1 : 1
      ),
    };
  }, [offerEventsUnsorted]);

  const operationType = React.useMemo(
    () =>
      operation.buyProductDisponibility && operation.sellProductDisponibility
        ? "COMPRA&VENDA"
        : operation.buyProductDisponibility
        ? "COMPRA"
        : operation.sellProductDisponibility
        ? "VENDA"
        : null,
    [operation]
  );

  const offerSummaryData = React.useMemo<SummaryTableData>(
    () =>
      operation
        ? [
            [
              {
                label: t("Source Type"),
                value: operation.product.name,
              },
              {
                label: t("Operation Type"),
                value: t(operationType ?? ""),
              },
              {
                label: t("End of Offer"),
                value:
                  parseDate(operation.expirationDate)?.toLocaleString(
                    language
                  ) ?? "",
              },
              {
                label: t("Operation Code"),
                value: String(operation.id),
              },

              {
                label: t("Class"),
                value: t(operation.participant.participantCategory),
              },

              {
                label: t("Delivery Period"),
                value: `${
                  parseDate(operation.deliveryPeriodStart)?.toLocaleDateString(
                    language,
                    {
                      timeZone: "UTC",
                    }
                  ) ?? "-"
                } ${t("to")} ${
                  parseDate(operation.deliveryPeriodEnd)?.toLocaleDateString(
                    language,
                    { timeZone: "UTC" }
                  ) ?? "-"
                }`,
              },
              {
                label: t("Submarket"),
                value: operation.submarketId,
              },
              {
                label: t("Re-TUSD"),
                value: operation.reTusd ? t("Applies") : t("Does not apply"),
              },

              ...(operation.buyProductDisponibility
                ? [
                    {
                      label: `${t("Volume")} (${t("buy")})`,
                      value:
                        operation.buyProductDisponibility.volume !== undefined
                          ? `${operation.buyProductDisponibility.volume} ${
                              operation.product.measurementUnit
                                ? ` ${operation.product.measurementUnit}`
                                : ""
                            }`
                          : "",
                    },
                    {
                      label: `${t("Price")} (${t("buy")})`,
                      value: `${t(
                        operation.buyProductDisponibility.price.priceType
                      )}${
                        typeof operation.buyProductDisponibility.price.value ===
                        "number"
                          ? ""
                          : ` - ${t("Lance livre")}`
                      }`,
                    },
                    {
                      label: `${t("Spread")} (${t("buy")})`,
                      value:
                        operation.buyProductDisponibility.price.priceType ===
                          "PLD+SPREAD" &&
                        typeof operation.buyProductDisponibility.price.value ===
                          "number"
                          ? Number(
                              operation.buyProductDisponibility.price.value -
                                (operation.buyProductDisponibility.price.pld ??
                                  0) /
                                  100
                            ).toLocaleString(language, {
                              style: "currency",
                              currency: "BRL",
                            })
                          : null,
                    },
                    {
                      label: `${t("Value")} (${t("buy")})`,
                      value:
                        operation.buyProductDisponibility.price.priceType ===
                          "FIXED" &&
                        typeof operation.buyProductDisponibility.price.value ===
                          "number"
                          ? Number(
                              operation.buyProductDisponibility.price.value
                            ).toLocaleString(language, {
                              style: "currency",
                              currency: "BRL",
                            })
                          : null,
                    },
                  ]
                : []),
              ...(operation.sellProductDisponibility
                ? [
                    {
                      label: `${t("Volume")} (${t("sell")})`,
                      value:
                        operation.sellProductDisponibility.volume !== undefined
                          ? `${operation.sellProductDisponibility.volume} ${
                              operation.product.measurementUnit
                                ? ` ${operation.product.measurementUnit}`
                                : ""
                            }`
                          : "",
                    },
                    {
                      label: `${t("Price")} (${t("sell")})`,
                      value: `${t(
                        operation.sellProductDisponibility.price.priceType
                      )}${
                        typeof operation.sellProductDisponibility.price
                          .value === "number"
                          ? ""
                          : ` - ${t("Lance livre")}`
                      }`,
                    },
                    {
                      label: `${t("Spread")} (${t("sell")})`,
                      value:
                        operation.sellProductDisponibility.price.priceType ===
                          "PLD+SPREAD" &&
                        typeof operation.sellProductDisponibility.price
                          .value === "number"
                          ? Number(
                              operation.sellProductDisponibility.price.value -
                                (operation.sellProductDisponibility.price.pld ??
                                  0) /
                                  100
                            ).toLocaleString(language, {
                              style: "currency",
                              currency: "BRL",
                            })
                          : null,
                    },
                    {
                      label: `${t("Value")} (${t("sell")})`,
                      value:
                        operation.sellProductDisponibility.price.priceType ===
                          "FIXED" &&
                        typeof operation.sellProductDisponibility.price
                          .value === "number"
                          ? Number(
                              operation.sellProductDisponibility.price.value
                            ).toLocaleString(language, {
                              style: "currency",
                              currency: "BRL",
                            })
                          : null,
                    },
                  ]
                : []),
            ],
          ]
        : [],
    [language, operation, operationType, t]
  );

  const headCells = React.useMemo<Array<HeadCell>>(
    () => [
      {
        id: "date",
        alignment: "left",
        label: t("Date and Time"),
      },
      {
        id: "description",
        alignment: "left",
        label: t("Description"),
      },
    ],
    [t]
  );

  if (!operation) return <></>;

  return (
    <>
      <Box px={2}>
        <SummaryTable data={offerSummaryData} title={t("Operation Summary")} />
      </Box>

      <Box px={2} mt={8}>
        {isErrorOfferEvents ? (
          <Alert mt={2} mb={3} severity="error">
            {t("Something went wrong.")}
          </Alert>
        ) : isLoadingOfferEvents || offerEventsNew === undefined ? (
          <InfoBox>
            <CircularProgress />
          </InfoBox>
        ) : (
          <>
            <Typography variant="h6" gutterBottom>
              {t("Session History")}
            </Typography>
            {(offerEventsNew?.buyOfferEvents?.length ?? 0) === 0 &&
            (offerEventsNew?.sellOfferEvents?.length ?? 0) === 0 ? (
              <Typography variant="caption">
                {t("There is no data to show at this time")}
              </Typography>
            ) : (
              <>
                {(offerEventsNew?.buyOfferEvents?.length ?? 0) > 0 && (
                  <>
                    <Typography variant="h6" fontSize="0.9375rem" mt={6} pl={2}>
                      {t("Buy Offer")}:
                    </Typography>

                    <EnhancedTable
                      // title={t("Buy Offer")}
                      data={offerEventsNew?.buyOfferEvents ?? []}
                      headCells={headCells}
                      avoidEmptyRows
                      keyExtractor={(row) => String(row.id)}
                      renderRow={(row) => {
                        return (
                          <TableRow hover tabIndex={-1}>
                            <TableCell
                              component="th"
                              scope="row"
                              // sx={{ whiteSpace: "nowrap" }}
                            >
                              {parseDate(row.date)?.toLocaleString(language) ??
                                "-"}
                            </TableCell>
                            <TableCell align="left">
                              {row.description}
                            </TableCell>
                          </TableRow>
                        );
                      }}
                    />
                  </>
                )}

                {(offerEventsNew?.sellOfferEvents?.length ?? 0) > 0 && (
                  <>
                    <Typography variant="h6" fontSize="0.9375rem" mt={6} pl={2}>
                      {t("Sell Offer")}:
                    </Typography>

                    <EnhancedTable
                      // title={t("Sell Offer")}
                      data={offerEventsNew?.sellOfferEvents ?? []}
                      headCells={headCells}
                      avoidEmptyRows
                      keyExtractor={(row) => String(row.id)}
                      renderRow={(row) => {
                        return (
                          <TableRow hover tabIndex={-1}>
                            <TableCell
                              component="th"
                              scope="row"
                              // sx={{ whiteSpace: "nowrap" }}
                            >
                              {parseDate(row.date)?.toLocaleString(language) ??
                                "-"}
                            </TableCell>
                            <TableCell align="left">
                              {row.description}
                            </TableCell>
                          </TableRow>
                        );
                      }}
                    />
                  </>
                )}
              </>
            )}
          </>
        )}
      </Box>

      <Box
        display="inline-flex"
        justifyContent="center"
        width="100%"
        textAlign="center"
        gap={4}
        mt={8}
      >
        <Button
          type="button"
          variant="outlined"
          color="primary"
          onClick={onClose}
        >
          {t("Go Back")}
        </Button>
      </Box>
    </>
  );
}

function OffersHistoryTable() {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { currentParticipant } = useChooseParticipant();

  const [currentPage, setCurrentPage] = React.useState(1);
  const [itemsPerPage, setItemsPerPage] = React.useState(10);

  const [order, setOrder] = React.useState<"asc" | "desc">("desc");
  const [orderBy, setOrderBy] = React.useState("createdAt");

  const {
    data: offersData,
    isLoading,
    isError,
  } = useQuery(
    ["/offer/history", itemsPerPage, currentPage, order, orderBy],
    () =>
      getOfferHistory(currentParticipant?.id, {
        page: currentPage,
        limit: itemsPerPage,
        order,
        orderBy,
      })
  );

  const offers = React.useMemo(
    () => offersData?.operations || [],
    [offersData?.operations]
  );

  const totalItems = React.useMemo(
    () => offersData?.total ?? 0,
    [offersData?.total]
  );

  React.useEffect(() => {
    const lastPage = Math.ceil(totalItems / itemsPerPage);
    setCurrentPage((oldValue) =>
      oldValue > lastPage && !isLoading ? 1 : oldValue
    );
  }, [isLoading, itemsPerPage, totalItems]);

  const headCells = React.useMemo<Array<HeadCell>>(
    () => [
      {
        id: "createdAt",
        alignment: "left",
        label: t("History Offer Date"),
      },
      {
        id: "product.name",
        alignment: "center",
        label: t("History Product Name"),
        disableSort: true,
      },
      {
        id: "codeOperation",
        alignment: "center",
        label: t("History Code Operation"),
      },
      {
        id: "submarket",
        alignment: "center",
        label: t("History Delivery Location"),
        disableSort: true,
      },
      {
        id: "operation",
        alignment: "center",
        label: t("History Operation"),
        disableSort: true,
      },
      {
        id: "buyOffer.status",
        alignment: "center",
        label: `${t("Situation")} (${t("buy")})`,
        disableSort: true,
      },
      {
        id: "sellOffer.status",
        alignment: "center",
        label: `${t("Situation")} (${t("sell")})`,
        disableSort: true,
      },
      {
        id: "actions",
        alignment: "right",
        label: t("Actions"),
        disableSort: true,
      },
    ],
    [t]
  );

  const [isHistoryModalOpen, setIsHistoryModalOpen] = React.useState(false);
  const [selectedOfferId, setSelectedOfferId] = React.useState<
    string | number | null
  >(null);
  const selectedOffer = React.useMemo(() => {
    return selectedOfferId
      ? offers?.find((offer) => offer.id === selectedOfferId) ?? null
      : null;
  }, [selectedOfferId, offers]);
  const handleOpenOfferHistoryModal = React.useCallback(
    (offerId: string | number) => {
      setSelectedOfferId(offerId);
      setIsHistoryModalOpen(true);
    },
    []
  );
  const handleCloseOfferHistoryModal = React.useCallback(() => {
    setSelectedOfferId(null);
    setIsHistoryModalOpen(false);
  }, []);

  function getOfferStatus(offer: HistoryOperation) {
    if (new Date(offer.expirationDate).getTime() < new Date().getTime()) {
      return "EXPIRADA";
    }

    if (false) {
      return "CANCELADA"; //TODO ajustar modelo com flags de cancelamento de disponibilidade de compra e venda
    }

    return "ABERTA";
  }

  function getSellOfferStatus(offer: HistoryOperation) {
    if (new Date(offer.expirationDate).getTime() < new Date().getTime()) {
      return "EXPIRADA";
    }

    if (
      offer.sellProductDisponibility &&
      offer.sellProductDisponibility.remainingVoulme <
        offer.sellProductDisponibility.volume
    ) {
      return "PARCIALMENTE ENCERRADA";
    }

    if (offer.sellProductDisponibility?.remainingVoulme === 0) {
      return "ENCERRADA";
    }

    return "ABERTA";
  }

  function getBuyOfferStatus(offer: HistoryOperation) {
    if (new Date(offer.expirationDate).getTime() < new Date().getTime()) {
      return "EXPIRADA";
    }

    if (
      offer.buyProductDisponibility &&
      offer.buyProductDisponibility.remainingVoulme <
        offer.buyProductDisponibility.volume
    ) {
      return "PARCIALMENTE ENCERRADA";
    }

    if (offer.buyProductDisponibility?.remainingVoulme === 0) {
      return "ENCERRADA";
    }

    return "ABERTA";
  }

  if (isError) {
    return (
      <Alert mt={2} mb={3} severity="error">
        {t("Something went wrong.")}
      </Alert>
    );
  }

  if (isLoading || offers === undefined) {
    return (
      <InfoBox>
        <CircularProgress />
      </InfoBox>
    );
  }

  return (
    <>
      <StyledModal
        leftBox={selectedOffer?.id}
        title={t("History")}
        open={isHistoryModalOpen}
        onClose={handleCloseOfferHistoryModal}
        // maxWidth={800}
      >
        {selectedOffer === null ? (
          <></>
        ) : (
          <OfferHistoryModalContent
            operation={selectedOffer}
            onClose={handleCloseOfferHistoryModal}
          />
        )}
      </StyledModal>

      <EnhancedTableManualPagination
        title={t("Offer History")}
        data={offers}
        // TableProps={{ size: "small" }}
        avoidEmptyRows
        paginationOptions={{
          currentPage: currentPage,
          itemsPerPage,
          totalItems,
          onPageChange: (page) => {
            setCurrentPage(page);
          },
          onRowsPerPageChange: (rows) => {
            setItemsPerPage(rows);
          },
        }}
        orderingOptions={{
          orderBy,
          order,
          handleRequestSort: (e, property) => {
            setOrderBy((oldOrderBy) => {
              if (oldOrderBy === property) {
                setOrder((oldOrder) => (oldOrder === "asc" ? "desc" : "asc"));
              } else {
                setOrder("desc");
              }
              return property;
            });
          },
        }}
        headCells={headCells}
        keyExtractor={(row) => String(row.id)}
        renderRow={(row) => {
          const operationType =
            row.buyProductDisponibility && row.sellProductDisponibility
              ? "COMPRA&VENDA"
              : row.buyProductDisponibility
              ? "COMPRA"
              : "VENDA";

          return (
            <TableRow
              hover
              tabIndex={-1}
              sx={(theme) => ({
                "&:nth-of-type(odd)": {
                  backgroundColor: "rgba(0, 0, 0, 0.02)",
                },
                ...(getOfferStatus(row) === "CANCELADA" ||
                getOfferStatus(row) === "EXPIRADA"
                  ? { "th, td": { color: theme.palette.text.disabled } }
                  : {}),
              })}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  whiteSpace: "nowrap",
                  position: "relative",
                  "&:before": {
                    content: '""',
                    borderRadius: 2,
                    width: 4,
                    height: "80%",
                    backgroundColor: "action.disabled",
                    //   operationType === "COMPRA" ? green[600] : red[600],
                    position: "absolute",
                    top: "50%",
                    left: 0,
                    transform: "translateY(-50%)",
                  },
                }}
              >
                {parseDate(row.createdAt)?.toLocaleString(language) ?? "-"}
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: 500 }}>
                {row.product.name}
              </TableCell>
              <TableCell align="center">{row.id}</TableCell>
              <TableCell align="center">{row.submarketId}</TableCell>
              <TableCell align="center">
                <Chip
                  size="small"
                  label={t(operationType)}
                  sx={(theme) => ({
                    minWidth: "8ch",
                    ...(operationType === "COMPRA"
                      ? {
                          backgroundColor: green[600],
                          color: theme.palette.common.white,
                        }
                      : operationType === "VENDA"
                      ? {
                          backgroundColor: red[600],
                          color: theme.palette.common.white,
                        }
                      : {}),
                  })}
                />
              </TableCell>
              <TableCell align="center">
                {t(getBuyOfferStatus(row) ?? "-")}
              </TableCell>
              <TableCell align="center">
                {t(getSellOfferStatus(row) ?? "-")}
              </TableCell>
              <TableCell padding="none" align="right">
                <Box mr={2} whiteSpace="nowrap">
                  {/* {row.participantId === currentParticipant?.id && ( */}
                  {true && (
                    <Tooltip title={t("Clone")}>
                      <IconButton
                        aria-label={t("Clone")}
                        component={NavLink}
                        to={`/offers/register-offer/${row.id}`}
                        size="medium"
                      >
                        <ContentCopy />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Tooltip title={t("View")}>
                    <IconButton
                      aria-label={t("View")}
                      onClick={() => handleOpenOfferHistoryModal(row.id)}
                      size="medium"
                    >
                      <Visibility />
                    </IconButton>
                  </Tooltip>
                </Box>
              </TableCell>
            </TableRow>
          );
        }}
      />
    </>
  );
}

export default function OfferHistory() {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Helmet title={t("Offer History")} />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            {t("Offer History")}
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              {t("Home")}
            </Link>
            <Typography>{t("Offer History")}</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <OffersHistoryTable />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
