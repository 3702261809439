import React from "react";
import styled from "@emotion/styled";
import {
  Box,
  Button as MuiButton,
  Card,
  CardContent,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useTranslation } from "react-i18next";

import { formatCNPJ, toLocaleCurrencyString } from "../../utils/string";
import { NewOffer } from "../../types/offer";
import { Product } from "../../types/product";
import { Participant } from "../../types/participant";
import {
  ChevronLeft,
  ChevronRight,
  DeleteForever,
  Edit,
} from "@mui/icons-material";
import { getLocaleMonthAndYearPeriodShortString } from "../../utils/date";
import { SummaryData } from "../../types/summaryData";

const Button = styled(MuiButton)(spacing);

export interface RegisterOfferConfirmationModalContentProps {
  onClose: () => void;
  onSubmit: () => void;
  disableButtons?: boolean;
  newOfferList: Array<NewOffer>;
  participantProducts: Array<Product>;
  participantData: Participant;
  onDelete: (idx: number) => void;
  onEdit: (idx: number) => void;
}
export function RegisterOfferConfirmationModalContent({
  onClose,
  onSubmit,
  disableButtons,
  newOfferList,
  participantProducts,
  participantData,
  onDelete,
  onEdit,
}: RegisterOfferConfirmationModalContentProps) {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const offerSummaryData = React.useMemo<Array<SummaryData>>(() => {
    return newOfferList.map((newOffer) => {
      const product = participantProducts.find(
        (item) => item.id === newOffer.product.id
      );

      const period = getLocaleMonthAndYearPeriodShortString(
        newOffer.deliveryPeriodStart,
        newOffer.deliveryPeriodEnd,
        language
      );

      return [
        [
          {
            label: t("CNPJ"),
            value: participantData.companyCnpj
              ? formatCNPJ(participantData.companyCnpj)
              : "-",
          },
          {
            label: t("Class"),
            value: participantData.classes.name
              ? t(participantData.classes.name)
              : "-",
          },
          {
            label: t("CCEE Name"),
            value: participantData.companyTradeName,
          },
          {
            label: t("Corporate Name"),
            value: participantData.companyCorporateName,
          },
        ],
        [
          {
            label: t("Month"),
            value: period,
          },
          {
            label: t("Deliv. Period"),
            value: `${new Date(newOffer.deliveryPeriodStart).toLocaleDateString(
              language,
              { timeZone: "UTC" }
            )} ${t("to")} ${new Date(
              newOffer.deliveryPeriodEnd
            ).toLocaleDateString(language, { timeZone: "UTC" })}`,
          },
          {
            label: `${t("Volume")}${` ${
              newOffer.operation === "COMPRA" ? "" : t("COMPRA").toUpperCase()
            }`}`,
            value:
              newOffer.buyVolume === undefined
                ? null
                : `${Number(newOffer.buyVolume).toLocaleString(language)}${
                    product?.measurementUnit
                      ? ` ${product.measurementUnit}`
                      : ""
                  }`,
          },
          {
            label: `${t("Volume")}${` ${
              newOffer.operation === "VENDA" ? "" : t("VENDA").toUpperCase()
            }`}`,
            value:
              newOffer.sellVolume !== undefined
                ? `${Number(newOffer.sellVolume).toLocaleString(language)}${
                    product?.measurementUnit
                      ? ` ${product.measurementUnit}`
                      : ""
                  }`
                : null,
          },
          {
            label: t("Submarket"),
            value: newOffer.submarket,
          },
          {
            label: t("Source Type Short"),
            value: product?.name ?? "-",
          },
          {
            label: t("Price"),
            value: t(newOffer.priceType),
          },
          {
            label: `${t("Spread")}${` ${
              newOffer.operation === "COMPRA" ? "" : t("COMPRA").toUpperCase()
            }`}`,
            value:
              newOffer.priceType === "Atrelado ao PLD" &&
              newOffer.buySpread !== undefined
                ? toLocaleCurrencyString(
                    Number(newOffer.buySpread),
                    "BRL",
                    language
                  )
                : null,
          },
          {
            label: `${t("Spread")}${` ${
              newOffer.operation === "VENDA" ? "" : t("VENDA").toUpperCase()
            }`}`,
            value:
              newOffer.priceType === "Atrelado ao PLD" &&
              newOffer.sellSpread !== undefined
                ? toLocaleCurrencyString(
                    Number(newOffer.sellSpread),
                    "BRL",
                    language
                  )
                : null,
          },
          {
            label: `${t("Value")}${` ${
              newOffer.operation === "COMPRA" ? "" : t("COMPRA").toUpperCase()
            }`}`,
            value:
              newOffer.priceType === "Fixo" && newOffer.buyValue !== undefined
                ? toLocaleCurrencyString(
                    Number(newOffer.buyValue),
                    "BRL",
                    language
                  )
                : null,
          },
          {
            label: `${t("Value")}${` ${
              newOffer.operation === "VENDA" ? "" : t("VENDA").toUpperCase()
            }`}`,
            value:
              newOffer.priceType === "Fixo" && newOffer.sellValue !== undefined
                ? toLocaleCurrencyString(
                    Number(newOffer.sellValue),
                    "BRL",
                    language
                  )
                : null,
          },
          {
            label: t("Free Bid Type"),
            value:
              newOffer.priceType === "Lance livre"
                ? newOffer.freeBid
                  ? t(newOffer.freeBid)
                  : null
                : null,
          },
          {
            label: t("Re-TUSD"),
            value: newOffer.reTusd ? t("Applies") : t("Does not apply"),
          },
        ],
      ];
    });
  }, [
    t,
    language,
    newOfferList,
    participantProducts,
    participantData.companyCnpj,
    participantData.classes.name,
    participantData.companyCorporateName,
    participantData.companyTradeName,
  ]);

  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const isXlUp = useMediaQuery(theme.breakpoints.up("xl"));

  const carouselItemsShownCount = React.useMemo(
    () => (isXlUp ? 3 : isMdUp ? 2 : 1),
    [isXlUp, isMdUp]
  );
  const carouselItemCount = React.useMemo(
    () => newOfferList.length,
    [newOfferList.length]
  );
  const carouselRef = React.useRef<HTMLDivElement | null>(null);
  const [scrollPosition, setScrollPosition] = React.useState(0);
  React.useEffect(() => {
    const carouselRefValue = carouselRef.current;

    const handleScroll = () => {
      if (carouselRefValue && carouselItemCount > 0) {
        setScrollPosition(
          Math.round(
            carouselRefValue.scrollLeft /
              (carouselRefValue.scrollWidth / carouselItemCount)
          )
        );
      }
    };

    carouselRefValue?.addEventListener("scroll", handleScroll, {
      passive: true,
    });

    return () => {
      carouselRefValue?.removeEventListener("scroll", handleScroll);
    };
  }, [carouselItemCount]);

  const scrollTo = React.useCallback(
    (idx: number) => {
      if (!carouselRef.current || carouselItemCount <= 0) return;

      carouselRef.current.scrollTo({
        left: (carouselRef.current.scrollWidth / carouselItemCount) * idx,
      });
    },
    [carouselItemCount]
  );

  const scrollSteps = React.useCallback(
    (steps: number) => {
      scrollTo(Math.max(0, scrollPosition + steps));
    },
    [scrollTo, scrollPosition]
  );

  return (
    <>
      <Box mb={8}>
        <Typography variant="h6" gutterBottom>
          {t("Operation Summary")}
        </Typography>
        <Typography variant="body2" gutterBottom>
          {t(
            "Please verify that the information below is correct before registering the offer."
          )}
        </Typography>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mt={4}
      >
        <IconButton
          type="button"
          size={isMdUp ? "large" : "small"}
          sx={{ display: isSmUp ? undefined : "none" }}
          onClick={() => scrollSteps(-1)}
          disabled={scrollPosition === 0}
        >
          <ChevronLeft />
        </IconButton>

        {/* Carousel */}
        <Box
          flex={1}
          display="flex"
          sx={{
            justifyContent:
              newOfferList.length < carouselItemsShownCount
                ? "center"
                : "flex-start",
            overflowX: "auto",
            msOverflowStyle: "none",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": { display: "none" },
            scrollSnapType: "x mandatory",
            WebkitOverflowScrolling: "touch",
            scrollBehavior: "smooth",
            "> *": {
              flex: "none",
              width: `calc((100% - 0px * (${carouselItemsShownCount} - 1)) / ${carouselItemsShownCount})`,
              scrollSnapAlign: "start",
              "&:not(:first-of-type)": {
                marginLeft: "0px",
              },
            },
          }}
          ref={carouselRef}
        >
          {offerSummaryData.map((offerSummary, offerIdx) => {
            return (
              <Box key={offerIdx} px={2} display="flex" justifyContent="center">
                <Card
                  sx={{
                    maxWidth: 368,
                    backgroundColor: "#ffffff",
                    color: "#ffffff",
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={(theme) => ({
                      height: 52,
                      backgroundColor:
                        newOfferList[offerIdx].operation === "COMPRA"
                          ? theme.palette.success.main
                          : theme.palette.error.main,
                      backgroundImage:
                        newOfferList[offerIdx].operation === "COMPRA&VENDA"
                          ? "linear-gradient(90deg, #00CB85 13.54%, #E05757 64.58%, #FF4760 100%)"
                          : undefined,
                    })}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box
                      sx={(theme) => ({
                        border: "1px solid #ffffff",
                        padding: 1,
                        borderRadius: 0.5,
                        marginLeft: 2,
                      })}
                    >
                      <Typography variant="h6" fontWeight="700">
                        {t(newOfferList[offerIdx].operation).toUpperCase()}
                      </Typography>
                    </Box>

                    <Box>
                      <Typography variant="h4" fontWeight="700">
                        {offerIdx + 1}
                      </Typography>
                    </Box>

                    <Box>
                      <Tooltip title={t("Edit")}>
                        <IconButton
                          color="inherit"
                          onClick={() => {
                            onEdit(offerIdx);
                            onClose();
                          }}
                        >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={t("Delete")}>
                        <IconButton
                          color="inherit"
                          onClick={() => onDelete(offerIdx)}
                        >
                          <DeleteForever />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>

                  <CardContent
                    sx={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <TableContainer component={Box}>
                      <Table aria-label="simple table" size="small">
                        <TableBody>
                          {offerSummary.map((group, groupIdx) => {
                            return (
                              <React.Fragment key={groupIdx}>
                                {group.map((row, rowIdx) => {
                                  return (
                                    <React.Fragment key={row.label}>
                                      {row.value !== null && (
                                        <TableRow
                                          sx={{
                                            "&:last-child td, &:last-child th":
                                              {
                                                border: 0,
                                              },
                                          }}
                                        >
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            sx={{
                                              pl: 0,
                                              py: "4px",
                                              borderBottom:
                                                rowIdx === group.length - 1
                                                  ? "1px solid #dbdbdb"
                                                  : 0,
                                            }}
                                          >
                                            <Typography
                                              fontSize="0.9375rem"
                                              fontWeight={600}
                                              color="#000000"
                                            >
                                              {row.label}:
                                            </Typography>
                                          </TableCell>
                                          <TableCell
                                            align="right"
                                            sx={{
                                              pr: 0,
                                              py: "4px",
                                              borderBottom:
                                                rowIdx < group.length - 1
                                                  ? 0
                                                  : "1px solid #dbdbdb",
                                            }}
                                          >
                                            <Typography
                                              fontSize="0.9375rem"
                                              fontWeight={600}
                                              color="#3867C2"
                                            >
                                              {row.value}
                                            </Typography>
                                          </TableCell>
                                        </TableRow>
                                      )}
                                    </React.Fragment>
                                  );
                                })}
                              </React.Fragment>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <Box>
                      <Divider orientation="horizontal" color="#dbdbdb" />
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        color="#888888"
                        mt={2}
                      >
                        <Typography>{t("End of Offer")}</Typography>
                        <Typography>
                          {new Date(
                            newOfferList[offerIdx].offerCloseDateTime
                          ).toLocaleString(language)}
                        </Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            );
          })}
        </Box>

        <IconButton
          type="button"
          size={isMdUp ? "large" : "small"}
          sx={{ display: isSmUp ? undefined : "none" }}
          onClick={() => scrollSteps(1)}
          disabled={
            scrollPosition >= newOfferList.length - carouselItemsShownCount
          }
        >
          <ChevronRight />
        </IconButton>
      </Box>

      <Box
        display="inline-flex"
        justifyContent="flex-end"
        width="100%"
        textAlign="center"
        gap={4}
        mt={8}
      >
        <Button
          variant="outlined"
          color="primary"
          onClick={onClose}
          disabled={disableButtons}
        >
          {t("Add More Products")}
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={onSubmit}
          disabled={disableButtons}
        >
          {offerSummaryData.length > 1
            ? `${t("Register Offers")} (${offerSummaryData.length})`
            : t("Register Offer")}
        </Button>
      </Box>
    </>
  );
}
