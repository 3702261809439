import { SwapVert } from "@mui/icons-material";
import { IconButton, IconButtonProps } from "@mui/material";

export function SwapButton(props: IconButtonProps) {
  return (
    <IconButton
      sx={[
        (theme) => ({
          backgroundColor: theme.palette.background.default,
        }),
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
      ]}
      {...props}
    >
      <SwapVert />
    </IconButton>
  );
}
