import i18next from "i18next";
import { HeadCell } from "../../../../../components/EnhancedTable";
import { BuyOfferBestBidValueHeader } from "./BuyOfferBestBidValueHeader";
import { BuyOfferNetHeader } from "./BuyOfferNetHeader";
import { BuyOfferStatusHeader } from "./BuyOfferStatusHeader";
import { BuyOfferValueHeader } from "./BuyOfferValueHeader";
import { SellOfferBestBidValueHeader } from "./SellOfferBestBidValueHeader";
import { SellOfferNetHeader } from "./SellOfferNetHeader";
import { SellOfferStatusHeader } from "./SellOfferStatusHeader";
import { SellOfferValueHeader } from "./SellOfferValueHeader";

export const getHeadCells = (locale: string) => {
  const t = i18next.getFixedT(locale);

  return [
    {
      id: "submarket",
      alignment: "left",
      label: t("Product"),
      disableSort: true,
    },
    {
      id: "deliveryPeriodStart",
      alignment: "center",
      label: t("Period"),
      disableSort: true,
    },
    {
      id: "buyOffer.priceValue",
      alignment: "right",
      label: <BuyOfferValueHeader />,
      colSpan: 2,
      disableSort: true,
    },
    {
      id: "buyOffer.actions",
      alignment: "center",
      label: "",
      disableSort: true,
    },
    {
      id: "buyOffer.currentBestBidValue",
      alignment: "right",
      label: <BuyOfferBestBidValueHeader />,
      colSpan: 2,
      disableSort: true,
    },
    {
      id: "buyOffer.net",
      alignment: "right",
      label: <BuyOfferNetHeader />,
      disableSort: true,
    },
    {
      id: "buyOffer.status",
      alignment: "center",
      label: <BuyOfferStatusHeader />,
      disableSort: true,
    },
    {
      id: "sellOffer.priceValue",
      alignment: "right",
      label: <SellOfferValueHeader />,
      colSpan: 2,
      disableSort: true,
    },
    {
      id: "sellOffer.actions",
      alignment: "center",
      label: "",
      disableSort: true,
    },
    {
      id: "sellOffer.currentBestBidValue",
      alignment: "right",
      label: <SellOfferBestBidValueHeader />,
      colSpan: 2,
      disableSort: true,
    },
    {
      id: "sellOffer.net",
      alignment: "right",
      label: <SellOfferNetHeader />,
      disableSort: true,
    },
    {
      id: "sellOffer.status",
      alignment: "center",
      label: <SellOfferStatusHeader />,
      disableSort: true,
    },
    {
      id: "offerCloseDateTime",
      alignment: "center",
      label: t("Remaining Time"),
      disableSort: true,
    },
    {
      id: "actions",
      alignment: "right",
      label: t("Actions"),
      disableSort: true,
    },
  ] as HeadCell[];
};
