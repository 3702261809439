// https://github.com/remix-run/react-router/issues/8139#issuecomment-1343016626
import { History } from "history";
import { useContext, useEffect, useLayoutEffect, useRef } from "react";
import { UNSAFE_NavigationContext } from "react-router-dom";

interface BlockerControl {
  confirm: () => void;
  cancel: () => void;
}

interface Blocker {
  onBlock: (control: BlockerControl) => void;
  enabled?: boolean;
}

export const useBlocker = ({ onBlock, enabled }: Blocker) => {
  const { block } = useContext(UNSAFE_NavigationContext).navigator as History;

  const onBlockRef = useRef(onBlock);
  useLayoutEffect(() => {
    onBlockRef.current = onBlock;
  });

  useEffect(() => {
    if (!enabled) {
      return;
    }

    let isActive = false;

    const unblock = block(({ retry }) => {
      if (isActive) {
        unblock();
        return retry();
      }

      onBlockRef.current({
        confirm: retry,
        cancel: () => {
          isActive = false;
        },
      });

      isActive = true;
    });

    return unblock;
  }, [block, enabled]);
};
