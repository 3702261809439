import React from "react";
import { useLocation, useParams } from "react-router-dom";

export function useGetParamsWithHash() {
  const { hash } = useLocation();
  const { energyType } = useParams();

  const energyCode = React.useMemo(() => {
    if (hash && energyType) return energyType + hash;
    if (!hash && energyType) return energyType;
    return null;
  }, [energyType, hash]);

  return { energyCode };
}
