import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { CircularProgress, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(6)};
  text-align: center;
  /* background-color: ${(props) => {
    return props.theme.palette.background.default;
  }}; */
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

export function LoadingPage() {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Helmet title={`${t("Loading")}...`} />
      <Typography component="h1" variant="h1" align="center" gutterBottom>
        <CircularProgress />
      </Typography>
      <Typography component="h2" variant="h5" align="center" gutterBottom>
        {t("Loading")}...
      </Typography>
    </Wrapper>
  );
}
