/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useMemo } from "react";
import styled from "@emotion/styled";
import {
  Box,
  Autocomplete,
  TextField,
  TableRow,
  TableCell,
  Button,
  IconButton,
  Tooltip,
} from "@mui/material";
import { BsArrowDownRightCircle, BsArrowUpRightCircle } from "react-icons/bs";
import { IoIosClose } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { EnhancedTableManualPagination, HeadCell } from "../EnhancedTable";
import { useFirestoreQuery } from "../../hooks/useFirestoreQuery";
import * as firestore from "firebase/firestore";
import { submarketColors } from "../../constants";
import { getSplitedTimeDifference, parseDate } from "../../utils/date";
import { useCurrentTime } from "../../hooks/useCurrentTime";
import { TimeBox } from "../TimeBox";
import { CloseOutlined } from "@mui/icons-material";
import { useGetTransactions } from "./hooks/useGetTransactions";
import { useGetMyOrders } from "./hooks/useGetMyOrders";
import { useGetMyHistoric } from "./hooks/useGetMyHistoric";
import { toLocaleCurrencyStringWithoutSymbol } from "../../utils/string";
import { OperatorDataModal } from "./OperatorDataModal";
import { useDeleteOrder } from "./hooks/useDeleteOrder";
import { useSnackbar } from "notistack";
import { useDeleteAllOrders } from "./hooks/useDeleteAllOrders";
import useConfirmationModal from "../../hooks/useConfirmationModal";
import { SxProps } from "@mui/system";

const stylesCellTableBody = {
  td: {
    // paddingY: 0,
    padding: "4px !important",
    // fontSize: "12px",
  },
};
const stylesCellTableHead: SxProps = {
  paddingTop: 0,
  paddingBottom: 2,
  paddingX: "4px !important",
};

const ButtonAction = styled(Button)`
  color: #ffffff;
  border-radius: 0;
  font-size: 0.8rem;

  &:hover {
    background-color: transparent !important;
  }
`;

export function HistoricTable() {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { currentTime } = useCurrentTime();
  const { enqueueSnackbar } = useSnackbar();
  const { showConfirmationModal } = useConfirmationModal();

  const [tableActive, setTableActive] = React.useState<number>(0);
  const [isOpenOperatorModal, setIsOpenOperatorModal] = React.useState(false);

  const [page, setPage] = React.useState(1);
  const [itemsPerPage, setItemsPerPage] = React.useState(10);

  const [productFilterValue, setProductFilterValue] = React.useState<
    string | null
  >(null);
  const [submarketFilterValue, setSubmarketFilterValue] = React.useState<
    string | null
  >(null);

  const [operatorId, setOperatorId] = React.useState<string | null>(null);

  const {
    data: [products],
  } = useFirestoreQuery<{ name: string; assetsAvailableCode: string }>(() => {
    return [
      firestore.query(
        firestore.collection(firestore.getFirestore(), "products")
      ),
    ];
  }, []);

  const productsSorted = useMemo(() => {
    if (!products) return [];

    return products.sort((a, b) => {
      const assetA = a.name.toString().toUpperCase();
      const assetB = b.name.toString().toUpperCase();
      return assetA.localeCompare(assetB, undefined, { numeric: true });
    });
  }, [products]);

  const { orders: transactions, totalItems: totalItemsTransactions } =
    useGetTransactions({
      page: page,
      itemsPerPage,
      productFilterValue,
      submarketFilterValue,
    });

  const { orders, totalItems: totalItemsMyOrders } = useGetMyOrders({
    page: page,
    itemsPerPage,
    productFilterValue,
    submarketFilterValue,
  });

  const { orders: historic, totalItems: totalItemsMyHistoric } =
    useGetMyHistoric({
      page: page,
      itemsPerPage,
      productFilterValue,
      submarketFilterValue,
    });

  const { deleteOrderMutate } = useDeleteOrder({
    onSuccess: () => {
      enqueueSnackbar(t("Successfully canceled order"), {
        variant: "success",
      });
    },
    onError: (error) => {
      enqueueSnackbar(t(error.message), {
        variant: "error",
      });
    },
  });

  const { deleteAllOrdersMutate } = useDeleteAllOrders({
    onSuccess: () => {
      enqueueSnackbar(t("Successfully canceled orders"), {
        variant: "success",
      });
    },
    onError: (error) => {
      enqueueSnackbar(t(error.message), {
        variant: "error",
      });
    },
  });

  function handleActiveTable(index: number) {
    setTableActive(index);
    setPage(1);
    setItemsPerPage(10);
    if (index === 1) {
      setProductFilterValue(null);
      setSubmarketFilterValue(null);
    }
  }

  function handleOpenOperatorModal() {
    setIsOpenOperatorModal(true);
  }

  function handleCloseOperatorModal() {
    setIsOpenOperatorModal(false);
    setOperatorId(null);
  }

  function handleCancelModal(type: "all" | "single", orderId?: string) {
    if (type === "single" && orderId) {
      showConfirmationModal({
        onSubmit: () => deleteOrderMutate(orderId),
        message: (
          <>
            {t('To cancel this order click "CONFIRM"')}
            <br />
            <br />
            {t("After confirmation, your spot market order will be removed.")}
          </>
        ),
      });
    }
    if (type === "all") {
      showConfirmationModal({
        onSubmit: () => deleteAllOrdersMutate(),
        message: (
          <>
            {t('To cancel all your orders click "CONFIRM"')}
            <br />
            <br />
            {t(
              "After confirmation, all your open orders will be removed from the spot market."
            )}
          </>
        ),
      });
    }
  }

  const tableTransactions: Array<HeadCell> = [
    {
      id: "order",
      alignment: "center",
      label: t("Order"),
      disableSort: true,
      sx: stylesCellTableHead,
    },
    {
      id: "amount",
      alignment: "center",
      label: `${t("Quantity")} (MWh)`,
      disableSort: true,
      sx: stylesCellTableHead,
    },
    {
      id: "price",
      alignment: "center",
      label: (
        <Box width="120px" margin="auto">
          {t("Price")} (PLD + R$)
        </Box>
      ),
      disableSort: true,
      sx: stylesCellTableHead,
    },
    {
      id: "total",
      alignment: "center",
      label: `${t("Total")} (R$)`,
      disableSort: true,
      sx: stylesCellTableHead,
    },
    {
      id: "sourceType",
      alignment: "center",
      label: (
        <Box width="100px" margin="auto">
          {t("Source Type")}
        </Box>
      ),
      disableSort: true,
      sx: stylesCellTableHead,
    },
    {
      id: "submarket",
      alignment: "center",
      label: t("Submarket"),
      disableSort: true,
      sx: stylesCellTableHead,
    },
    {
      id: "time",
      alignment: "center",
      label: t("Trading Time"),
      disableSort: true,
      sx: stylesCellTableHead,
    },
  ];

  const tableMyOrders: Array<HeadCell> = [
    {
      id: "start",
      alignment: "center",
      label: t("Start"),
      disableSort: true,
      sx: stylesCellTableHead,
    },
    {
      id: "order",
      alignment: "center",
      label: t("Order"),
      disableSort: true,
      sx: stylesCellTableHead,
    },
    {
      id: "amount",
      alignment: "center",
      label: `${t("Quantity")} (MWh)`,
      disableSort: true,
      sx: stylesCellTableHead,
    },
    {
      id: "price",
      alignment: "center",
      label: (
        <Box width="120px" margin="auto">
          {t("Price")} (PLD + R$)
        </Box>
      ),
      disableSort: true,
      sx: stylesCellTableHead,
    },
    {
      id: "total",
      alignment: "center",
      label: `${t("Total")} (R$)`,
      disableSort: true,
    },
    {
      id: "sourceType",
      alignment: "center",
      label: (
        <Box width="100px" margin="auto">
          {t("Source Type")}
        </Box>
      ),
      disableSort: true,
      sx: stylesCellTableHead,
    },
    {
      id: "submarket",
      alignment: "center",
      label: t("Submarket"),
      disableSort: true,
      sx: stylesCellTableHead,
    },
    {
      id: "time",
      alignment: "center",
      label: t("Remaining Time"),
      disableSort: true,
      sx: stylesCellTableHead,
    },
    {
      id: "action",
      alignment: "center",
      label: t("Options"),
      disableSort: true,
      sx: stylesCellTableHead,
    },
  ];

  const tableMyHistoric: Array<HeadCell> = [
    {
      id: "start",
      alignment: "center",
      label: t("Start"),
      disableSort: true,
      sx: stylesCellTableHead,
    },
    {
      id: "order",
      alignment: "center",
      label: t("Order"),
      disableSort: true,
      sx: stylesCellTableHead,
    },
    {
      id: "amount",
      alignment: "center",
      label: `${t("Quantity")} MWh`,
      disableSort: true,
      sx: stylesCellTableHead,
    },
    {
      id: "price",
      alignment: "center",
      label: (
        <Box width="120px" margin="auto">
          {t("Price")} (PLD + R$)
        </Box>
      ),
      disableSort: true,
      sx: stylesCellTableHead,
    },
    {
      id: "total",
      alignment: "center",
      label: `${t("Total")} (R$)`,
      disableSort: true,
      sx: stylesCellTableHead,
    },
    {
      id: "sourceType",
      alignment: "center",
      label: (
        <Box width="100px" margin="auto">
          {t("Source Type")}
        </Box>
      ),
      disableSort: true,
      sx: stylesCellTableHead,
    },
    {
      id: "submarket",
      alignment: "center",
      label: t("Submarket"),
      disableSort: true,
      sx: stylesCellTableHead,
    },
    {
      id: "time",
      alignment: "center",
      label: t("Trading Time"),
      disableSort: true,
      sx: stylesCellTableHead,
    },
    {
      id: "operator",
      alignment: "center",
      label: t("Operator"),
      disableSort: true,
      sx: stylesCellTableHead,
    },
  ];

  const buttonsActions = [
    `MWh ${t("Transactions")}`,
    t("My Orders"),
    t("My History"),
  ];

  return (
    <>
      {operatorId && (
        <OperatorDataModal
          open={isOpenOperatorModal}
          onClose={() => handleCloseOperatorModal()}
          operatorId={operatorId}
        />
      )}
      {tableActive === 0 && (
        <EnhancedTableManualPagination
          // title={t("Manage Deposits and Withdrawals")}
          height="250px"
          data={transactions}
          headCells={tableTransactions}
          avoidEmptyRows
          paginationOptions={{
            onPageChange: (page) => {
              setPage(page);
            },
            onRowsPerPageChange: (rows) => {
              setItemsPerPage(rows);
            },
            currentPage: page,
            itemsPerPage: itemsPerPage,
            totalItems: totalItemsTransactions,
          }}
          toolbarFiltersArea={
            <Box
              display="flex"
              justifyContent="space-between"
              // flexWrap="wrap"
              alignItems="center"
              height="65px"
            >
              <Box display="flex" gap={5}>
                {buttonsActions.map((item, index) => {
                  const isActive = tableActive === index;
                  return (
                    <ButtonAction
                      key={index}
                      sx={{
                        borderBottom: `3px solid ${
                          isActive ? "#72bf65" : "transparent"
                        }`,
                      }}
                      onClick={() => handleActiveTable(index)}
                    >
                      {item}
                    </ButtonAction>
                  );
                })}
              </Box>
              <Box
                width="50%"
                display="flex"
                gap={3}
                justifyContent="flex-end"
                flexWrap="wrap"
              >
                <Box minWidth={120}>
                  <Autocomplete
                    value={productFilterValue}
                    onChange={(e, data) => {
                      setPage(1);
                      setProductFilterValue(data);
                    }}
                    openOnFocus
                    handleHomeEndKeys
                    disablePortal
                    options={productsSorted?.map((item) => item.id) ?? []}
                    getOptionLabel={(option) =>
                      products?.find((product) => product.id === option)
                        ?.name ?? option
                    }
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("Source Type")}
                        placeholder={t("All")}
                        InputLabelProps={{ shrink: true }}
                        variant="standard"
                      />
                    )}
                  />
                </Box>
                <Box minWidth={120}>
                  <Autocomplete
                    value={submarketFilterValue}
                    onChange={(e, data) => {
                      setPage(1);
                      setSubmarketFilterValue(data);
                    }}
                    openOnFocus
                    handleHomeEndKeys
                    disablePortal
                    options={Object.keys(submarketColors)}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("Submarket")}
                        placeholder={t("All")}
                        InputLabelProps={{ shrink: true }}
                        variant="standard"
                      />
                    )}
                  />
                </Box>
              </Box>
            </Box>
          }
          keyExtractor={(row, index) => String(index)}
          renderRow={(row) => {
            const startedAt = parseDate(row.getStartAt());
            const startedAtString = startedAt
              ? `${startedAt.toLocaleDateString(language, {
                  timeZone: "UTC",
                })} ${startedAt.toLocaleTimeString(language, {
                  // timeZone: "UTC",
                  hour: language.includes("pt") ? "2-digit" : "numeric",
                  minute: "2-digit",
                })}`
              : "-";

            const closedAt = parseDate(row.getCloseAt());
            const closedAtString = closedAt
              ? `${closedAt.toLocaleDateString(language, {
                  timeZone: "UTC",
                })} ${closedAt.toLocaleTimeString(language, {
                  // timeZone: "UTC",
                  hour: language.includes("pt") ? "2-digit" : "numeric",
                  minute: "2-digit",
                })}`
              : "-";

            const offer =
              row.getBuyOffer() === null
                ? row.getSellOffer()
                : row.getBuyOffer();
            const typeOrder = offer?.getType();
            const colorRow =
              typeOrder === "COMPRA" ? "success.main" : "error.main";

            return (
              <>
                <TableRow hover tabIndex={-1} sx={stylesCellTableBody}>
                  {tableActive !== 0 && (
                    <TableCell align="center">{startedAtString}</TableCell>
                  )}
                  <TableCell align="center">
                    <Box
                      display="inline-flex"
                      alignItems="center"
                      gap={2}
                      color={colorRow}
                    >
                      {typeOrder === "COMPRA" ? (
                        <BsArrowUpRightCircle color={colorRow} />
                      ) : (
                        <BsArrowDownRightCircle color={colorRow} />
                      )}
                      {offer?.getType()}
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    {Number(offer?.getVolume()).toLocaleString(language, {
                      minimumFractionDigits: 3,
                    })}
                  </TableCell>
                  <TableCell align="center">
                    PLD {Number(offer?.getSpread()) > 0 ? "+" : "-"}{" "}
                    {toLocaleCurrencyStringWithoutSymbol(
                      Math.abs(Number(offer?.getSpread())),
                      "BRL",
                      language
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {toLocaleCurrencyStringWithoutSymbol(
                      Number(offer?.getValue()! * offer?.getVolume()!),
                      "BRL",
                      language
                    )}
                  </TableCell>
                  <TableCell align="center">{row.getProduct()}</TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: submarketColors[row.getSubmarketCode()] }}
                  >
                    {row.getSubmarketCode()}
                  </TableCell>
                  <TableCell align="center">{closedAtString}</TableCell>
                </TableRow>
              </>
            );
          }}
        />
      )}
      {tableActive === 1 && (
        <EnhancedTableManualPagination
          // title={t("Manage Deposits and Withdrawals")}
          height="250px"
          data={orders}
          headCells={tableMyOrders}
          avoidEmptyRows
          paginationOptions={{
            onPageChange: (page) => {
              setPage(page);
            },
            onRowsPerPageChange: (rows) => {
              setItemsPerPage(rows);
            },
            currentPage: page,
            itemsPerPage: itemsPerPage,
            totalItems: totalItemsMyOrders,
          }}
          toolbarFiltersArea={
            <Box
              display="flex"
              justifyContent="space-between"
              // flexWrap="wrap"
              alignItems="center"
              height="65px"
            >
              <Box display="flex" gap={5}>
                {buttonsActions.map((item, index) => {
                  const isActive = tableActive === index;
                  return (
                    <ButtonAction
                      key={index}
                      sx={{
                        borderBottom: `3px solid ${
                          isActive ? "#72bf65" : "transparent"
                        }`,
                      }}
                      onClick={() => handleActiveTable(index)}
                    >
                      {item}
                    </ButtonAction>
                  );
                })}
              </Box>
              <Box
                width="50%"
                display="flex"
                gap={3}
                justifyContent="flex-end"
                flexWrap="wrap"
              >
                <Button
                  sx={{
                    color: "#EC5656",
                  }}
                  onClick={() => handleCancelModal("all")}
                >
                  {t("Cancel")} {t("All")} <CloseOutlined />
                </Button>
              </Box>
            </Box>
          }
          keyExtractor={(row, index) => String(index)}
          renderRow={(row) => {
            const startedAt = parseDate(row.getStartAt());
            const startedAtString = startedAt
              ? `${startedAt.toLocaleDateString(language, {
                  timeZone: "UTC",
                })} ${startedAt.toLocaleTimeString(language, {
                  // timeZone: "UTC",
                  hour: language.includes("pt") ? "2-digit" : "numeric",
                  minute: "2-digit",
                })}`
              : "-";

            const offer =
              row.getBuyOffer() === null
                ? row.getSellOffer()
                : row.getBuyOffer();
            const typeOrder = offer?.getType();
            const colorRow =
              typeOrder === "COMPRA" ? "success.main" : "error.main";
            const remainingTime = getSplitedTimeDifference(
              currentTime,
              row.getCloseAt()
            );

            return (
              <>
                <TableRow hover tabIndex={-1} sx={stylesCellTableBody}>
                  <TableCell align="center">{startedAtString}</TableCell>

                  <TableCell align="center">
                    <Box
                      display="inline-flex"
                      alignItems="center"
                      gap={2}
                      color={colorRow}
                    >
                      {typeOrder === "COMPRA" ? (
                        <BsArrowUpRightCircle color={colorRow} />
                      ) : (
                        <BsArrowDownRightCircle color={colorRow} />
                      )}
                      {offer?.getType()}
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    {Number(offer?.getVolume()).toLocaleString(language, {
                      minimumFractionDigits: 3,
                    })}
                  </TableCell>
                  <TableCell align="center">
                    PLD {Number(offer?.getSpread()) > 0 ? "+" : "-"}{" "}
                    {toLocaleCurrencyStringWithoutSymbol(
                      Math.abs(Number(offer?.getSpread())),
                      "BRL",
                      language
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {toLocaleCurrencyStringWithoutSymbol(
                      Number(offer?.getValue()! * offer?.getVolume()!),
                      "BRL",
                      language
                    )}
                  </TableCell>
                  <TableCell align="center">{row.getProduct()}</TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: submarketColors[row.getSubmarketCode()] }}
                  >
                    {row.getSubmarketCode()}
                  </TableCell>
                  <TableCell align="center">
                    <TimeBox time={remainingTime} />
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title={`${t("Cancel")} ${t("Order")}`}>
                      <IconButton
                        sx={{ p: 0 }}
                        color="error"
                        onClick={() => {
                          handleCancelModal("single", row.getOperationId());
                        }}
                      >
                        <IoIosClose />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              </>
            );
          }}
        />
      )}

      {tableActive === 2 && (
        <EnhancedTableManualPagination
          // title={t("Manage Deposits and Withdrawals")}
          height="250px"
          data={historic}
          headCells={tableMyHistoric}
          avoidEmptyRows
          paginationOptions={{
            onPageChange: (page) => {
              setPage(page);
            },
            onRowsPerPageChange: (rows) => {
              setItemsPerPage(rows);
            },
            currentPage: page,
            itemsPerPage: itemsPerPage,
            totalItems: totalItemsMyHistoric,
          }}
          toolbarFiltersArea={
            <Box
              display="flex"
              justifyContent="space-between"
              // flexWrap="wrap"
              alignItems="center"
              height="65px"
            >
              <Box display="flex" gap={5}>
                {buttonsActions.map((item, index) => {
                  const isActive = tableActive === index;
                  return (
                    <ButtonAction
                      key={index}
                      sx={{
                        borderBottom: `3px solid ${
                          isActive ? "#72bf65" : "transparent"
                        }`,
                      }}
                      onClick={() => handleActiveTable(index)}
                    >
                      {item}
                    </ButtonAction>
                  );
                })}
              </Box>
              <Box
                width="50%"
                display="flex"
                gap={3}
                justifyContent="flex-end"
                flexWrap="wrap"
              >
                <Box minWidth={120}>
                  <Autocomplete
                    value={productFilterValue}
                    onChange={(e, data) => {
                      setPage(1);
                      setProductFilterValue(data);
                    }}
                    openOnFocus
                    handleHomeEndKeys
                    disablePortal
                    options={products?.map((item) => item.id) ?? []}
                    getOptionLabel={(option) =>
                      products?.find((product) => product.id === option)
                        ?.name ?? option
                    }
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("Source Type")}
                        placeholder={t("All")}
                        InputLabelProps={{ shrink: true }}
                        variant="standard"
                      />
                    )}
                  />
                </Box>
                <Box minWidth={120}>
                  <Autocomplete
                    value={submarketFilterValue}
                    onChange={(e, data) => {
                      setPage(1);
                      setSubmarketFilterValue(data);
                    }}
                    openOnFocus
                    handleHomeEndKeys
                    disablePortal
                    options={Object.keys(submarketColors)}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("Submarket")}
                        placeholder={t("All")}
                        InputLabelProps={{ shrink: true }}
                        variant="standard"
                      />
                    )}
                  />
                </Box>
              </Box>
            </Box>
          }
          keyExtractor={(row, index) => String(index)}
          renderRow={(row) => {
            const startedAt = parseDate(row.getStartAt());
            const startedAtString = startedAt
              ? `${startedAt.toLocaleDateString(language, {
                  timeZone: "UTC",
                })} ${startedAt.toLocaleTimeString(language, {
                  // timeZone: "UTC",
                  hour: language.includes("pt") ? "2-digit" : "numeric",
                  minute: "2-digit",
                })}`
              : "-";

            const closedAt = parseDate(row.getCloseAt());
            const closedAtString = closedAt
              ? `${closedAt.toLocaleDateString(language, {
                  timeZone: "UTC",
                })} ${closedAt.toLocaleTimeString(language, {
                  // timeZone: "UTC",
                  hour: language.includes("pt") ? "2-digit" : "numeric",
                  minute: "2-digit",
                })}`
              : "-";

            const offer =
              row.getBuyOffer() === null
                ? row.getSellOffer()
                : row.getBuyOffer();
            const typeOrder = offer?.getType();
            const colorRow =
              typeOrder === "COMPRA" ? "success.main" : "error.main";

            return (
              <>
                <TableRow hover tabIndex={-1} sx={stylesCellTableBody}>
                  <TableCell align="center">{startedAtString}</TableCell>
                  <TableCell align="center">
                    <Box
                      display="inline-flex"
                      alignItems="center"
                      gap={2}
                      color={colorRow}
                    >
                      {typeOrder === "COMPRA" ? (
                        <BsArrowUpRightCircle color={colorRow} />
                      ) : (
                        <BsArrowDownRightCircle color={colorRow} />
                      )}
                      {offer?.getType()}
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    {Number(offer?.getVolume()).toLocaleString(language, {
                      minimumFractionDigits: 3,
                    })}
                  </TableCell>
                  <TableCell align="center">
                    PLD {Number(offer?.getSpread()) > 0 ? "+" : "-"}{" "}
                    {toLocaleCurrencyStringWithoutSymbol(
                      Math.abs(Number(offer?.getSpread())),
                      "BRL",
                      language
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {toLocaleCurrencyStringWithoutSymbol(
                      Number(offer?.getValue()! * offer?.getVolume()!),
                      "BRL",
                      language
                    )}
                  </TableCell>
                  <TableCell align="center">{row.getProduct()}</TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: submarketColors[row.getSubmarketCode()] }}
                  >
                    {row.getSubmarketCode()}
                  </TableCell>
                  <TableCell align="center">{closedAtString}</TableCell>

                  <TableCell align="center">
                    <Button
                      onClick={() => {
                        handleOpenOperatorModal();
                        setOperatorId(row.getOperatorId().toString());
                      }}
                      variant="text"
                      sx={{
                        py: 0,
                        "&:hover": { backgroundColor: "transparent" },
                      }}
                    >
                      Ver Dados
                    </Button>
                  </TableCell>
                </TableRow>
              </>
            );
          }}
        />
      )}
    </>
  );
}
