import styled from "@emotion/styled";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button as MuiButton,
  CircularProgress,
  Alert as MuiAlert,
} from "@mui/material";
import { spacing } from "@mui/system";
import { enqueueSnackbar } from "notistack";
import React from "react";
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormTextField } from "../../formFields/FormTextField";
import { StyledModal } from "../../StyledModal";
import { StyledTextField } from "../../StyledTextField";
import { useGetReasonsOptions } from "../hooks/useGetReasonsOptions";
import { useSubmitRefuseAssetRequest } from "../hooks/useSubmitRefuseAssetRequest";

const Button = styled(MuiButton)(spacing);
const Alert = styled(MuiAlert)(spacing);

const InfoBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24;
`;

export interface RefuseModalProps {
  open: boolean;
  onClose: () => void;
  transaction: any;
  reloadData?: () => void;
}

interface Form {
  reasonId: string | null;
  details: string;
}

const formDefaultValues: Form = {
  reasonId: null,
  details: "",
};

export function RefuseModal({
  open,
  onClose,
  transaction,
  reloadData,
}: RefuseModalProps) {
  const { t } = useTranslation();

  const [maxLengthDetails, setMaxLengthDetails] = React.useState<number>(0);

  const {
    data: reasonsOptions,
    isLoading: isLoadingReasonsOptions,
    isError: isErrorReasonsOptions,
  } = useGetReasonsOptions();

  const {
    submitRefuseAssetRequestMutate,
    isLoading: isLoadingRefuseAssetRequest,
  } = useSubmitRefuseAssetRequest({
    onSuccess: () => {
      handleClose();
      if (reloadData) {
        reloadData();
      }
    },
  });

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<Form>({
    defaultValues: formDefaultValues,
  });

  const [reasonId] = watch(["reasonId"]);

  const handleSubmitRefuseAssetRequest = React.useCallback(
    (id: string, data: Form) => {
      if (data.reasonId === null || data.details === null) {
        enqueueSnackbar(t("Something went wrong."), {
          variant: "error",
        });
        return;
      }

      submitRefuseAssetRequestMutate({
        id: id,
        data,
      });
    },
    [t, submitRefuseAssetRequestMutate]
  );

  const onSubmit = React.useCallback<SubmitHandler<Form>>(
    (data) => {
      handleSubmitRefuseAssetRequest(transaction.id, data);
    },
    [handleSubmitRefuseAssetRequest, transaction]
  );

  const onInvalidSubmit = React.useCallback<SubmitErrorHandler<Form>>(() => {
    enqueueSnackbar(t("You must fill in all required fields"), {
      variant: "error",
    });
  }, [t]);

  if (isErrorReasonsOptions) {
    return (
      <Alert mt={2} mb={3} severity="error">
        {t("Something went wrong.")}
      </Alert>
    );
  }

  function isSelectOptionOther() {
    return reasonsOptions?.find((item) => item.id === reasonId)?.description ===
      "Outros"
      ? true
      : false;
  }

  function handleClose() {
    onClose();
    reset({
      reasonId: null,
      details: "",
    });
  }

  return (
    <StyledModal
      title="Recusar"
      open={open}
      onClose={handleClose}
      maxWidth={500}
    >
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit, onInvalidSubmit)}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {reasonsOptions === undefined || isLoadingReasonsOptions ? (
          <InfoBox>
            <CircularProgress />
          </InfoBox>
        ) : (
          <>
            <Controller
              control={control}
              name={"reasonId"}
              rules={{
                required: {
                  value: true,
                  message: t("This field is required"),
                },
              }}
              render={({ field: { ref, onChange, ...field } }) => (
                <Autocomplete
                  {...field}
                  onChange={(e, newValue) => {
                    onChange(newValue);
                  }}
                  openOnFocus
                  handleHomeEndKeys
                  disablePortal
                  options={reasonsOptions.map((item) => item.id)}
                  getOptionLabel={(option) =>
                    reasonsOptions.find((item) => item.id === option)
                      ?.description ?? option
                  }
                  fullWidth
                  renderInput={(params) => (
                    <StyledTextField
                      {...params}
                      inputRef={ref}
                      label={t("Reason")}
                      error={!!errors.reasonId}
                      helperText={errors.reasonId?.message ?? " "}
                      // size="small"
                    />
                  )}
                />
              )}
            />
            <FormTextField
              control={control}
              name={"details"}
              inputProps={{ maxLength: 500 }}
              rules={{
                required: {
                  value: isSelectOptionOther(),
                  message: t("This field is required"),
                },
                onChange: (e) => {
                  setMaxLengthDetails(e.target.value.length);
                },
              }}
              label={t("Justification")}
              fullWidth
              multiline
              rows={6}
              helperText={`${maxLengthDetails} / 500`}
            />
            <Box
              display="inline-flex"
              gap={2}
              width="100%"
              justifyContent="flex-end"
            >
              <Button variant="outlined" mt={3} onClick={handleClose}>
                {t("Cancel")}
              </Button>
              <LoadingButton
                variant="contained"
                color="primary"
                type="submit"
                loading={isLoadingRefuseAssetRequest}
                sx={{ marginTop: 3 }}
              >
                {t("Confirm")}
              </LoadingButton>
            </Box>
          </>
        )}
      </Box>
    </StyledModal>
  );
}
