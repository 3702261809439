import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Button, Paper, Typography } from "@mui/material";

import { StyledLogo } from "./StyledLogo";
import ChooseParticipantComponent from "../../components/auth/ChooseParticipant";
import useAuth from "../../hooks/useAuth";

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function ChooseParticipantPage() {
  const { t } = useTranslation();

  const { signOut } = useAuth();

  return (
    <React.Fragment>
      <StyledLogo />

      <Wrapper>
        <Helmet title={t("Select Participant")} />

        <Typography component="h1" variant="h4" align="center" gutterBottom>
          {t("Select Participant")}
        </Typography>
        <Typography component="h2" variant="body1" align="center">
          {t("Choose a participant to continue")}
        </Typography>

        <br />

        <ChooseParticipantComponent />

        <Button
          onClick={signOut}
          fullWidth
          color="primary"
          sx={{ marginTop: 8 }}
        >
          {t("Sign out")}
        </Button>
      </Wrapper>
    </React.Fragment>
  );
}

export default ChooseParticipantPage;
