import { useMutation } from "@tanstack/react-query";
import { createFinancialDeposit } from "../infra/api/BankInfra";

export function useSubmitFinancialDeposit(options?: {
  onSuccess?: () => void;
}) {
  const { mutate: submitFinancialDepositMutate, ...rest } = useMutation(
    createFinancialDeposit,
    options
  );

  return { submitFinancialDepositMutate, ...rest };
}
