import React from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  CircularProgress,
  Divider as MuiDivider,
  Grid,
  Link,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";

import { EnhancedTable, HeadCell } from "../../components/EnhancedTable";
import { getProducts } from "../../components/products/infra/ProductsInfra";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Alert = styled(MuiAlert)(spacing);

const InfoBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24;
`;

function ProductMaintenanceTable() {
  const { t } = useTranslation();

  const {
    data: products,
    isLoading,
    isError,
  } = useQuery(["/product"], getProducts);

  const headCells = React.useMemo<Array<HeadCell>>(
    () => [
      {
        id: "name",
        alignment: "left",
        label: t("Name"),
      },
      {
        id: "measurementUnit",
        alignment: "right",
        label: t("Measurement Unit"),
        disableSort: true,
      },
    ],
    [t]
  );

  if (isError) {
    return (
      <Alert mt={2} mb={3} severity="error">
        {t("Something went wrong.")}
      </Alert>
    );
  }

  if (isLoading || products === undefined) {
    return (
      <InfoBox>
        <CircularProgress />
      </InfoBox>
    );
  }

  return (
    <EnhancedTable
      title={t("Product List")}
      data={products}
      headCells={headCells}
      avoidEmptyRows
      initialOrderBy={headCells[0].id}
      // TableProps={{ size: "small" }}
      keyExtractor={(row) => String(row.id)}
      renderRow={(row) => {
        return (
          <TableRow hover tabIndex={-1}>
            <TableCell component="th" scope="row" align="left">
              {row.name}
            </TableCell>
            <TableCell align="right">{row.measurementUnit}</TableCell>
          </TableRow>
        );
      }}
    />
  );
}

export default function ProductList() {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Helmet title={t("Product Maintenance")} />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            {t("Product Maintenance")}
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              {t("Home")}
            </Link>
            <Typography>{t("Product Maintenance")}</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
      <Divider my={6} />
      <Grid container spacing={6} justifyContent="center">
        <Grid item xs={12} md={6} lg={4}>
          <ProductMaintenanceTable />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
