import React from "react";
import useChooseParticipant from "../../../hooks/useChooseParticipant";
import { MyOrderResume } from "../entities/data-objects/OrderResume";
import { GetMyHistoricObservableService } from "../services/GetMyHistoricObservableService";

const getMyHistoricObservableService = new GetMyHistoricObservableService();

interface useGetMyOrdersProps {
  page: number;
  itemsPerPage: number;
  productFilterValue: string | null;
  submarketFilterValue: string | null;
}
export function useGetTransactions({
  page,
  itemsPerPage,
  productFilterValue,
  submarketFilterValue,
}: useGetMyOrdersProps) {
  const { participantId } = useChooseParticipant();

  const [orders, setOrders] = React.useState<MyOrderResume[]>([]);
  const [totalItems, setTotalItems] = React.useState(0);

  React.useEffect(() => {
    const options = {
      page: page,
      itemsPerPage: itemsPerPage,
      participant: null,
      product: productFilterValue,
      submarket: submarketFilterValue,
    };

    const subscription = getMyHistoricObservableService
      .getMyHistoricObservable()
      .subscribeWithOptions(options, (panelData) => {
        setTotalItems(panelData.totalItems);
        setOrders(panelData.orders);
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [
    page,
    itemsPerPage,
    participantId,
    productFilterValue,
    submarketFilterValue,
  ]);

  return { orders, totalItems };
}
