import { Box, Typography } from "@mui/material";

const colors = {
  success: {
    backgroundColor: "#CBFBEA",
    color: "#006323",
  },
  warning: {
    backgroundColor: "#FFF7DA",
    color: "#4d2002",
  },
};

export interface StatusChipProps {
  children: string | JSX.Element;
  color: keyof typeof colors;
}
export function StatusChip({ children, color }: StatusChipProps) {
  return (
    <Box
      sx={{
        backgroundColor: colors[color].backgroundColor,
        color: colors[color].color,
        borderRadius: 1,
        px: 2,
      }}
    >
      {children}
    </Box>
  );
}

export interface StyledFooterChipProps {
  label: string;
  value: string;
  color: keyof typeof colors;
}
export function StyledFooterChip({
  label,
  value,
  color,
}: StyledFooterChipProps) {
  return (
    <Box
      sx={(theme) => ({
        borderBottom: `1px solid ${theme.palette.text.primary}`,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      })}
    >
      <Typography variant="caption" fontSize={14}>
        {label}
      </Typography>

      <StatusChip color={color}>{value}</StatusChip>
    </Box>
  );
}
