export function setIntervalImmediately(
  callback: (args: void) => void,
  ms?: number
) {
  callback();
  return setInterval(callback, ms);
}

export async function sleep(ms = 0) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
