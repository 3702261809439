export class AssetsPriceTable {
  constructor(
    private assetsPrices: {
      assetCode: string;
      value: number;
      submarket: string | null;
    }[]
  ) {}

  public getAssetPrice(assetCode: string, submarket?: string): number | null {
    if (assetCode === "BRL") {
      return 1;
    }

    const asset = this.assetsPrices.find(
      (assetPrice) =>
        assetPrice.assetCode === assetCode && assetPrice.submarket === submarket
    );

    return asset ? asset.value / 100 : null;
  }
}
