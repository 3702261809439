import { balcaoApiInstance } from "../../../../utils/axios";
import { sleep } from "../../../../utils/utils";

export async function getTransactions() {
  // const{ data } = await balcaoApiInstance.get<BankListByCountry>("/bank/bank-list");

  // TODO: implement
  await sleep(2000);

  const data = [
    {
      sellOffer: {
        volume: 10,
        value: 69.04,
        type: "VENDA",
        spread: 0.5,
      },
      buyOffer: null,
      closeAt: new Date("2023-06-20"),
      startAt: new Date(),
      operationId: "123",
      product: "Conv",
      submarketCode: "NE",
    },
  ];

  return data;
}

export async function getMyHistoric() {
  // const{ data } = await balcaoApiInstance.get<BankListByCountry>("/bank/bank-list");

  // TODO: implement
  await sleep(2000);

  const data = [
    {
      buyOffer: {
        volume: 10,
        value: 69.04,
        type: "COMPRA",
        spread: 0.5,
      },
      sellOffer: null,
      closeAt: new Date("2023-06-20"),
      startAt: new Date(),
      operationId: "123",
      product: "Conv",
      submarketCode: "NE",
    },
  ];

  return data;
}

export interface Asset {
  code: string;
  name: string;
  unit: string;
  type: string;
  precision: number;
  avatar: string;
}
export interface AssetBalance {
  asset: Asset;
  balance: number;
  blockedBalance: number;
  submarket: string;
}
export interface BalanceData {
  assetBalances: Array<AssetBalance>;
}
export async function getBalanceBySubmarket() {
  const { data } = await balcaoApiInstance.get<BalanceData>(
    "/wallets/mywallet?groupByAsset=false"
  );

  return data;
}

export async function getBalanceInOrdersBySubmarket() {
  const { data } = await balcaoApiInstance.get<BalanceData>(
    "/operation/assets-blocked?groupBySubmarket=true"
  );

  return data;
}

export interface FixedDeliveryPeriod {
  firstDay: string;
  lastDay: string;
  referenceMonth: string;
}

export async function getFixedDeliveryPeriod() {
  const { data } = await balcaoApiInstance.get<FixedDeliveryPeriod>(
    "/operation/supply-period"
  );

  return data;
}

export interface CreateNewOrder {
  submarket: string | null;
  offerCloseDateTime: string | null;
  product: string | null;
  buyOffer?: {
    priceValue: number;
    volume: number;
  };
  sellOffer?: {
    priceValue: number;
    volume: number;
  };
}

export async function createNewOrder(options: CreateNewOrder) {
  await balcaoApiInstance.post("operation/spot", options);
  return true;
}

export async function deleteOrder(operationId: string) {
  await balcaoApiInstance.delete(`/operation/${operationId}/spot`);
  return true;
}

export async function deleteAllOrders() {
  await balcaoApiInstance.delete("/operation/spot");
  return true;
}

export interface OperatorData {
  fullName: string;
  email: string;
  alwaysSign: boolean;
  jobRole: string;
  phoneNumber: string;
  cellphoneNumber: string;
  cpf: string;
  citizenship: string;
  birthDate: string;
  documentType: string;
  documentNumber: string;
  documentIssueDate: Date;
  documentDispatchingEntity: string;
  documentUf: string | null;
}

export async function getOperatorData(operatorId: string) {
  const { data } = await balcaoApiInstance.get<OperatorData>(
    `users/${operatorId}`
  );

  return data;
}
