import React from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FinancialDepositPageContent } from "../../components/bank/FinancialDepositPageContent";
import { getCurrencySymbol } from "../../utils/string";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Divider = styled(MuiDivider)(spacing);

export function FinancialDeposit() {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const pageName = React.useMemo(
    () => `${t("Financial Deposit")} (${getCurrencySymbol("BRL", language)})`,
    [language, t]
  );

  return (
    <React.Fragment>
      <Helmet title={pageName} />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            {pageName}
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              {t("Home")}
            </Link>
            <Typography>{pageName}</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <FinancialDepositPageContent />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
