import React from "react";
import styled from "@emotion/styled";
import { useParams } from "react-router-dom";
import {
  Alert as MuiAlert,
  Grid,
  Typography,
  Box,
  CircularProgress,
  Button as MuiButton,
  FormControl,
  FormLabel,
  FormHelperText,
  InputAdornment,
  IconButton,
  Tooltip,
  Skeleton,
  Autocomplete,
} from "@mui/material";
import { darken, spacing } from "@mui/system";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from "react-hook-form";

import { FormTextField } from "../../components/formFields/FormTextField";
import { getCurrencySymbol } from "../../utils/string";
import { getOperation, validateNewOffer } from "../../services/offers";
import { InfoOutlined } from "@mui/icons-material";
import { NewOffer } from "../../types/offer";
import { Product } from "../../types/product";
import {
  StyledToggleButton,
  StyledToggleButtonGroup,
} from "../../components/StyledToggleButton";
import { StyledTextField } from "../../components/StyledTextField";
import { DateTime } from "luxon";
import { DateTimePicker, DesktopDatePicker } from "@mui/x-date-pickers";
import { parseDate } from "../../utils/date";

const Button = styled(MuiButton)(spacing);

const Alert = styled(MuiAlert)(spacing);

const InfoBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24;
`;

const priceTypeOptions = [
  { value: "Atrelado ao PLD", label: "Atrelado ao PLD" },
  { value: "Fixo", label: "Fixo" },
  { value: "Lance livre", label: "Lance livre" },
];

const freeBidTypeOptions = [
  { value: "Atrelado PLD + Spread", label: "Atrelado PLD + Spread" },
  { value: "Fixo", label: "Fixo" },
];

interface RegisterOfferFormInputs {
  operation: string | null;
  submarket: string | null;
  reTusd: string | null;
  productId: string | null;
  deliveryPeriodStart: Date | null;
  deliveryPeriodEnd: Date | null;
  offerCloseDateTime: Date | null;
  priceType: string | null;
  buyVolume?: string;
  sellVolume?: string;
  buySpread?: string;
  sellSpread?: string;
  buyValue?: string;
  sellValue?: string;
  freeBid?: string;
}

type Inputs = RegisterOfferFormInputs;

const inputsDefaultValues: Inputs = {
  operation: null,
  submarket: null,
  reTusd: null,
  productId: null,
  deliveryPeriodStart: null,
  deliveryPeriodEnd: null,
  offerCloseDateTime: null,
  priceType: null,
  buyVolume: "",
  sellVolume: "",
  buySpread: "",
  sellSpread: "",
  buyValue: "",
  sellValue: "",
  freeBid: "",
};

export interface RegisterOfferFormProps {
  submarketOptions: Array<string>;
  participantProducts: Array<Product>;
  onSaveOffer: (newOfferData: NewOffer, idx?: number) => void;
  editOfferId: number | null;
  editOfferData: NewOffer | null;
  onCancelEditOffer: () => void;
  onGoToSummary: () => void;
  newOfferListLength: number;
  onEditOffer: (idx: number) => void;
}
export default function RegisterOfferForm({
  submarketOptions,
  participantProducts,
  onSaveOffer,
  editOfferId,
  editOfferData,
  onCancelEditOffer,
  onGoToSummary,
  newOfferListLength,
  onEditOffer,
}: RegisterOfferFormProps) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isSubmitted },
    reset,
    watch,
    trigger,
    setValue,
    setError,
  } = useForm<Inputs>({ defaultValues: inputsDefaultValues });

  const handleClear = React.useCallback(() => {
    reset(inputsDefaultValues);
  }, [reset]);

  const [selectedProduct, setSelectedProduct] = React.useState<Product | null>(
    null
  );

  const { operationId } = useParams();

  const {
    data: cloneOfferData,
    isError: isErrorCloneOfferData,
    isLoading: isLoadingCloneOfferData,
  } = useQuery(
    ["/offer/one/operation", operationId],
    () => getOperation(operationId),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data !== null) {
          const priceType =
            (data.buyOffer ?? data.sellOffer)?.priceType ?? null;

          const freeBid =
            (data.buyOffer && typeof data.buyOffer.priceValue === "number") ||
            (data.sellOffer && typeof data.sellOffer.priceValue === "number")
              ? null
              : priceType === "Atrelado ao PLD"
              ? "Atrelado PLD + Spread"
              : priceType;

          const operation =
            data.buyOffer && data.sellOffer
              ? "COMPRA&VENDA"
              : data.buyOffer
              ? "COMPRA"
              : data.sellOffer
              ? "VENDA"
              : null;

          setValue("operation", operation);
          setValue("submarket", data.submarket);
          setValue("reTusd", String(data.reTusd));
          setValue("productId", data.product.id);
          setValue("deliveryPeriodStart", parseDate(data.deliveryPeriodStart));
          setValue("deliveryPeriodEnd", parseDate(data.deliveryPeriodEnd));
          setValue("offerCloseDateTime", null);
          setValue("priceType", freeBid ? "Lance livre" : priceType);

          setValue("buyVolume", data.buyOffer?.volume?.toString() ?? "");
          setValue("sellVolume", data.sellOffer?.volume?.toString() ?? "");
          setValue(
            "buySpread",
            data.buyOffer?.priceValue !== undefined &&
              data.buyOffer?.pldValue !== undefined
              ? (
                  (data.buyOffer?.priceValue ?? 0) -
                  (data.buyOffer?.pldValue ?? 0) / 100
                ).toString()
              : ""
          );
          setValue(
            "sellSpread",
            data.sellOffer?.priceValue !== undefined &&
              data.sellOffer?.pldValue !== undefined
              ? (
                  (data.sellOffer?.priceValue ?? 0) -
                  (data.sellOffer?.pldValue ?? 0) / 100
                ).toString()
              : ""
          );
          setValue("buyValue", data.buyOffer?.priceValue?.toString() ?? "");
          setValue("sellValue", data.sellOffer?.priceValue?.toString() ?? "");
          setValue("freeBid", freeBid ?? "");
        } else {
          handleClear();
        }
      },
    }
  );

  const onInvalidSubmit = React.useCallback<SubmitErrorHandler<Inputs>>(() => {
    enqueueSnackbar(t("You must fill in all required fields"), {
      variant: "error",
    });
  }, [enqueueSnackbar, t]);

  const validateNewOfferMutation = useMutation(validateNewOffer);

  const onSubmit = React.useCallback<SubmitHandler<Inputs>>(
    async (offerData) => {
      const productObj = participantProducts.find(
        (item) => item.id === offerData.productId
      );

      if (
        !(
          offerData.operation === "COMPRA" ||
          offerData.operation === "VENDA" ||
          offerData.operation === "COMPRA&VENDA"
        ) ||
        // !submarketObj?.value ||
        !offerData.submarket ||
        !(offerData.reTusd === "true" || offerData.reTusd === "false") ||
        !offerData.productId ||
        !productObj?.profileKey ||
        !productObj?.profileCode ||
        !(
          offerData.priceType === "Atrelado ao PLD" ||
          offerData.priceType === "Fixo" ||
          offerData.priceType === "Lance livre"
        ) ||
        !(
          offerData.freeBid === "Fixo" ||
          offerData.freeBid === "Atrelado PLD + Spread" ||
          offerData.freeBid === "" ||
          offerData.freeBid === undefined
        )
      ) {
        enqueueSnackbar(t("Something went wrong."), {
          variant: "error",
        });
        return;
      }

      const newOfferData: NewOffer = {
        operation: offerData.operation,
        submarket: offerData.submarket,
        reTusd: offerData.reTusd === "true",
        product: {
          id: offerData.productId,
          profileKey: productObj.profileKey,
          profileCode: productObj.profileCode,
        },
        deliveryPeriodStart: offerData.deliveryPeriodStart?.toISOString() ?? "",
        deliveryPeriodEnd: offerData.deliveryPeriodEnd?.toISOString() ?? "",
        offerCloseDateTime: offerData.offerCloseDateTime?.toISOString() ?? "",
        priceType: offerData.priceType,
        buyVolume:
          offerData.operation === "COMPRA" ||
          offerData.operation === "COMPRA&VENDA"
            ? Number(offerData.buyVolume)
            : undefined,
        sellVolume:
          offerData.operation === "VENDA" ||
          offerData.operation === "COMPRA&VENDA"
            ? Number(offerData.sellVolume)
            : undefined,
        buySpread:
          offerData.priceType === "Atrelado ao PLD" &&
          (offerData.operation === "COMPRA" ||
            offerData.operation === "COMPRA&VENDA")
            ? Number(offerData.buySpread)
            : undefined,
        sellSpread:
          offerData.priceType === "Atrelado ao PLD" &&
          (offerData.operation === "VENDA" ||
            offerData.operation === "COMPRA&VENDA")
            ? Number(offerData.sellSpread)
            : undefined,
        buyValue:
          offerData.priceType === "Fixo" &&
          (offerData.operation === "COMPRA" ||
            offerData.operation === "COMPRA&VENDA")
            ? Number(offerData.buyValue)
            : undefined,
        sellValue:
          offerData.priceType === "Fixo" &&
          (offerData.operation === "VENDA" ||
            offerData.operation === "COMPRA&VENDA")
            ? Number(offerData.sellValue)
            : undefined,
        freeBid:
          offerData.priceType === "Lance livre" && offerData.freeBid !== ""
            ? offerData.freeBid
            : undefined,
      };

      try {
        await validateNewOfferMutation.mutateAsync({
          newOffer: newOfferData,
        });

        onSaveOffer(newOfferData, editOfferId ?? undefined);
        handleClear();
      } catch (e) {
        const invalidFields = (e as any)?.data?.invalidFields as
          | Record<string, string>
          | undefined;

        if (invalidFields) {
          (Object.keys(invalidFields) as Array<keyof Inputs>).forEach((key) => {
            setError(key, { message: invalidFields[key] });
          });
        }

        enqueueSnackbar(
          t(
            "Please verify that the information provided is correct and try again."
          ),
          {
            variant: "error",
          }
        );
      }
    },
    [
      editOfferId,
      enqueueSnackbar,
      handleClear,
      onSaveOffer,
      participantProducts,
      setError,
      t,
      validateNewOfferMutation,
    ]
  );

  const handleCancelEditOffer = React.useCallback(() => {
    onCancelEditOffer();
    handleClear();
  }, [handleClear, onCancelEditOffer]);

  React.useEffect(() => {
    if (editOfferId !== null && editOfferData !== null) {
      reset({
        operation: editOfferData.operation,
        submarket: editOfferData.submarket,
        reTusd: String(editOfferData.reTusd),
        productId: editOfferData.product.id,
        deliveryPeriodStart: parseDate(editOfferData.deliveryPeriodStart),
        deliveryPeriodEnd: parseDate(editOfferData.deliveryPeriodEnd),
        offerCloseDateTime: parseDate(editOfferData.offerCloseDateTime),
        priceType: editOfferData.priceType,
        buyVolume: editOfferData.buyVolume?.toString() ?? "",
        sellVolume: editOfferData.sellVolume?.toString() ?? "",
        buySpread: editOfferData.buySpread?.toString() ?? "",
        sellSpread: editOfferData.sellSpread?.toString() ?? "",
        buyValue: editOfferData.buyValue?.toString() ?? "",
        sellValue: editOfferData.sellValue?.toString() ?? "",
        freeBid: editOfferData.freeBid ?? "",
      });
    }
  }, [editOfferData, editOfferId, reset]);

  if (isErrorCloneOfferData) {
    return (
      <Alert mt={2} mb={3} severity="error">
        {t("Something went wrong.")}
      </Alert>
    );
  }

  if (isLoadingCloneOfferData || cloneOfferData === undefined) {
    return (
      <InfoBox>
        <CircularProgress />
      </InfoBox>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit, onInvalidSubmit)}>
      <Typography variant="h6" gutterBottom mb={4}>
        {editOfferId === null ? t("New Offer") : t("Edit Offer")}
      </Typography>

      <Grid container rowSpacing={1} columnSpacing={6}>
        {/* Operation */}
        <Grid item xs={12}>
          <Controller
            control={control}
            name={"operation"}
            rules={{
              required: {
                value: true,
                message: t("This field is required"),
              },
            }}
            render={({ field: { ref, ...field } }) => (
              <FormControl error={!!errors.operation}>
                <FormLabel
                  id="operation-toggle-button-group-label"
                  sx={(theme) => ({ mb: 2, color: theme.palette.text.primary })}
                >
                  {t("Operation")}
                </FormLabel>
                <StyledToggleButtonGroup
                  {...field}
                  aria-labelledby="operation-toggle-button-group-label"
                  size="medium"
                  toggleButtonMinWidth={150}
                  exclusive
                >
                  <StyledToggleButton
                    ref={ref}
                    value="COMPRA"
                    bgColor="COMPRA"
                    initialBgColor="#ADADAD"
                    sx={{ "@media(max-width: 600px)": { width: "100%" } }}
                  >
                    {t("COMPRA").toUpperCase()}
                  </StyledToggleButton>
                  <StyledToggleButton
                    value="VENDA"
                    bgColor="VENDA"
                    initialBgColor="#ADADAD"
                    sx={{ "@media(max-width: 600px)": { width: "100%" } }}
                  >
                    {t("VENDA").toUpperCase()}
                  </StyledToggleButton>
                  <StyledToggleButton
                    value="COMPRA&VENDA"
                    bgColor="COMPRA&VENDA"
                    initialBgColor="#ADADAD"
                    sx={{ "@media(max-width: 600px)": { width: "100%" } }}
                  >
                    {t("COMPRA&VENDA").toUpperCase()}
                  </StyledToggleButton>
                </StyledToggleButtonGroup>
                <FormHelperText sx={{ mt: "4px" }}>
                  {errors.operation?.message || " "}
                </FormHelperText>
              </FormControl>
            )}
          />
        </Grid>

        {/* Submarket */}
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name={"submarket"}
            rules={{
              required: {
                value: true,
                message: t("This field is required"),
              },
            }}
            render={({ field: { ref, ...field } }) => (
              <FormControl error={!!errors.submarket}>
                <FormLabel
                  id="submarket-toggle-button-group-label"
                  sx={(theme) => ({ mb: 2, color: theme.palette.text.primary })}
                >
                  {t("Submarket")}
                </FormLabel>
                <StyledToggleButtonGroup
                  {...field}
                  aria-labelledby="submarket-toggle-button-group-label"
                  size="small"
                  toggleButtonMinWidth={80}
                  exclusive
                >
                  {submarketOptions === undefined
                    ? Array.from(Array(4).keys()).map((item) => (
                        <Skeleton height={30} width={80} />
                      ))
                    : submarketOptions.map((item, idx) => {
                        return (
                          <StyledToggleButton
                            key={item}
                            ref={idx === 0 ? ref : undefined}
                            value={item}
                          >
                            {item}
                          </StyledToggleButton>
                        );
                      })}
                </StyledToggleButtonGroup>
                <FormHelperText sx={{ mt: "4px" }}>
                  {errors.submarket?.message || " "}
                </FormHelperText>
              </FormControl>
            )}
          />
        </Grid>

        {/* Re-TUSD */}
        <Grid item xs={12} md={4}>
          <Controller
            control={control}
            name={"reTusd"}
            rules={{
              required: {
                value: true,
                message: t("This field is required"),
              },
            }}
            render={({ field: { ref, ...field } }) => (
              <FormControl error={!!errors.reTusd}>
                <FormLabel
                  id="reTusd-toggle-button-group-label"
                  sx={(theme) => ({ mb: 2, color: theme.palette.text.primary })}
                >
                  <Box display="inline-flex" alignItems="center">
                    {t("Re-TUSD")}
                    <Tooltip
                      title={
                        <>
                          {/* TODO: Add to i18n and translate properly */}
                          <Typography>
                            {t(
                              "É o valor de referência para ressarcimento da Tarifa de Uso do Sistema de Distribuição (TUSD), para compensar a diferença do desconto da energia entregue em relação ao efetivamente contratado."
                            )}
                          </Typography>
                        </>
                      }
                    >
                      <IconButton type="button" size="small" sx={{ my: -5 }}>
                        <InfoOutlined fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </FormLabel>
                <StyledToggleButtonGroup
                  {...field}
                  aria-labelledby="reTusd-toggle-button-group-label"
                  size="small"
                  toggleButtonMinWidth={110}
                  exclusive
                >
                  <StyledToggleButton ref={ref} value={"true"}>
                    {t("Applies")}
                  </StyledToggleButton>
                  <StyledToggleButton value={"false"}>
                    {t("Does not apply")}
                  </StyledToggleButton>
                </StyledToggleButtonGroup>
                <FormHelperText sx={{ mt: "4px" }}>
                  {errors.reTusd?.message || " "}
                </FormHelperText>
              </FormControl>
            )}
          />
        </Grid>

        {/* Product */}
        <Grid item xs={12}>
          <Controller
            control={control}
            name={"productId"}
            rules={{
              required: {
                value: true,
                message: t("This field is required"),
              },
              onChange: (e) => {
                setSelectedProduct(
                  participantProducts.find(
                    (item) => item.id === e.target.value
                  ) ?? null
                );
              },
            }}
            render={({ field: { ref, ...field } }) => (
              <FormControl error={!!errors.productId}>
                <FormLabel
                  id="productId-toggle-button-group-label"
                  sx={(theme) => ({ mb: 2, color: theme.palette.text.primary })}
                >
                  {t("Source Type")}
                </FormLabel>
                <StyledToggleButtonGroup
                  {...field}
                  aria-labelledby="productId-toggle-button-group-label"
                  size="small"
                  toggleButtonMinWidth={80}
                  exclusive
                >
                  {participantProducts === undefined
                    ? Array.from(Array(7).keys()).map((item) => (
                        <Skeleton height={30} width={80} />
                      ))
                    : participantProducts.map((item, idx) => {
                        return (
                          <StyledToggleButton
                            key={item.id}
                            ref={idx === 0 ? ref : undefined}
                            value={item.id}
                          >
                            {item.name}
                          </StyledToggleButton>
                        );
                      })}
                </StyledToggleButtonGroup>
                <FormHelperText sx={{ mt: "4px" }}>
                  {errors.productId?.message || " "}
                </FormHelperText>
              </FormControl>
            )}
          />
        </Grid>

        {/* Delivery Period (Start) */}
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          // md={
          //   watch("operation") === "COMPRA" || watch("operation") === "VENDA"
          //     ? 3
          //     : 4
          // }
        >
          <Controller
            control={control}
            name={"deliveryPeriodStart"}
            rules={{
              required: {
                value: true,
                message: t("This field is required"),
              },
              onChange: () => {
                if (isSubmitted) trigger("deliveryPeriodEnd");
              },
              validate: (value) => {
                if (!value) {
                  return " ";
                }

                const now = DateTime.now();

                const maxDateToFixed = now.plus({ year: 1 }).endOf("day");

                // regras caso preço seja atrelado ao PLD
                if (
                  watch("priceType") === "Atrelado ao PLD" ||
                  (watch("priceType") === "Lance livre" &&
                    watch("freeBid") === "Atrelado PLD + Spread")
                ) {
                  const startOfLastMonth = now
                    .startOf("month")
                    .minus({ day: 1 })
                    .startOf("month");
                  const endOfLastMonth = now
                    .startOf("month")
                    .minus({ day: 1 })
                    .endOf("month");

                  const startDeliveyDateLowerThanLastMonthStart =
                    value.getTime() <
                    now
                      .startOf("month")
                      .minus({ day: 1 })
                      .startOf("month")
                      .toMillis();

                  const startDeliveyDateBiggerThanLastMonthEnd =
                    value.getTime() >= endOfLastMonth.toMillis();

                  if (startDeliveyDateLowerThanLastMonthStart) {
                    return t("Min date", {
                      data: startOfLastMonth.toFormat("dd/MM/yyyy"),
                    });
                  }

                  if (startDeliveyDateBiggerThanLastMonthEnd) {
                    return t("Max date", {
                      data: endOfLastMonth.toFormat("dd/MM/yyyy"),
                    });
                  }
                } else {
                  // regras p/ preço fixo
                  const startOfLastMonth = now
                    .startOf("month")
                    .minus({ day: 1 })
                    .startOf("month");

                  if (
                    startOfLastMonth.toMillis() >
                    DateTime.fromJSDate(value).startOf("month").toMillis()
                  ) {
                    return t("Min date", {
                      data: startOfLastMonth.toFormat("dd/MM/yyyy"),
                    });
                  } else if (
                    maxDateToFixed.toMillis() <=
                    DateTime.fromJSDate(value).startOf("month").toMillis()
                  ) {
                    return t("Max date", {
                      data: maxDateToFixed.toFormat("dd/MM/yyyy"),
                    });
                  }
                }

                const deliveryPeriodEnd = watch("deliveryPeriodEnd");

                return (
                  deliveryPeriodEnd !== null &&
                  value.getTime() <= new Date(deliveryPeriodEnd).getTime()
                );
              },
            }}
            render={({ field: { ref, ...field } }) => (
              <DesktopDatePicker
                {...field}
                inputRef={ref}
                label={`${t("Delivery Period")} (${t("Start")})`}
                slotProps={{
                  openPickerButton: {
                    size: "small",
                    sx: {
                      "& svg": {
                        transform: "scale(0.75)",
                      },
                    },
                  },
                  textField: {
                    fullWidth: true,
                    size: "small",
                    error: !!errors.deliveryPeriodStart,
                    helperText: errors.deliveryPeriodStart?.message || " ",
                  },
                }}
                slots={{
                  textField: StyledTextField,
                }}
              />
            )}
          />
        </Grid>

        {/* Delivery Period (End) */}
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          // md={
          //   watch("operation") === "COMPRA" || watch("operation") === "VENDA"
          //     ? 3
          //     : 4
          // }
        >
          <Controller
            control={control}
            name={"deliveryPeriodEnd"}
            rules={{
              required: {
                value: true,
                message: t("This field is required"),
              },
              onChange: () => {
                if (isSubmitted) trigger("deliveryPeriodStart");
              },
              validate: (value) => {
                if (!value) {
                  return true;
                }

                const now = DateTime.now();

                const startOfLastMonth = now
                  .startOf("month")
                  .minus({ day: 1 })
                  .startOf("month");
                const endOfLastMonth = now
                  .startOf("month")
                  .minus({ day: 1 })
                  .endOf("month");
                const maxDateToFixed = now.plus({ year: 1 }).endOf("day");

                // regras caso preço seja atrelado ao PLD
                if (
                  watch("priceType") === "Atrelado ao PLD" ||
                  (watch("priceType") === "Lance livre" &&
                    watch("freeBid") === "Atrelado PLD + Spread")
                ) {
                  const startDeliveyDateLowerThanLastMonthStart =
                    value.getTime() <
                    now
                      .startOf("month")
                      .minus({ day: 1 })
                      .startOf("month")
                      .toMillis();

                  const startDeliveyDateBiggerThanLastMonthEnd =
                    value.getTime() >= endOfLastMonth.toMillis();

                  if (startDeliveyDateLowerThanLastMonthStart) {
                    return t("Min date", {
                      data: startOfLastMonth.toFormat("dd/MM/yyyy"),
                    });
                  }

                  if (startDeliveyDateBiggerThanLastMonthEnd) {
                    return t("Max date", {
                      data: endOfLastMonth.toFormat("dd/MM/yyyy"),
                    });
                  }
                } else {
                  if (value.getTime() >= maxDateToFixed.toMillis()) {
                    return t("Max date", {
                      data: maxDateToFixed.toFormat("dd/MM/yyyy"),
                    });
                  }

                  if (value.getTime() <= startOfLastMonth.toMillis()) {
                    return t("Min date", {
                      data: startOfLastMonth.toFormat("dd/MM/yyyy"),
                    });
                  }
                }

                const deliveryPeriodStart = watch("deliveryPeriodStart");

                return (
                  deliveryPeriodStart === null ||
                  (value.getTime() >= deliveryPeriodStart.getTime() ?? 0) ||
                  t("The data entered is invalid", {
                    data: t("Delivery Period"),
                  })
                );
              },
            }}
            render={({ field: { ref, ...field } }) => (
              <DesktopDatePicker
                {...field}
                inputRef={ref}
                label={`${t("Delivery Period")} (${t("End")})`}
                slotProps={{
                  openPickerButton: {
                    size: "small",
                    sx: {
                      "& svg": {
                        transform: "scale(0.75)",
                      },
                    },
                  },
                  textField: {
                    fullWidth: true,
                    size: "small",
                    error: !!errors.deliveryPeriodEnd,
                    helperText: errors.deliveryPeriodEnd?.message || " ",
                  },
                }}
                slots={{
                  textField: StyledTextField,
                }}
              />
            )}
          />
        </Grid>

        {watch("operation") !== "COMPRA" && watch("operation") !== "VENDA" && (
          <Box width="100%" />
        )}

        {/* Volume COMPRA */}
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          sx={{
            ...(watch("operation") === "COMPRA" ||
            watch("operation") === "COMPRA&VENDA" ||
            watch("operation") === null
              ? {}
              : { display: "none" }),
          }}
        >
          <FormTextField
            disabled={watch("operation") === null}
            control={control}
            name={"buyVolume"}
            rules={
              watch("operation") === "COMPRA" ||
              watch("operation") === "COMPRA&VENDA"
                ? {
                    required: {
                      value: true,
                      message: t("This field is required"),
                    },
                    validate: (value) =>
                      Number(value) > 0 ||
                      t("The data entered is invalid", {
                        data: t("Volume"),
                      }),
                    onChange: (e) => {
                      if (e.target.value.split(".")[1]?.length > 3)
                        setValue(
                          "buyVolume",
                          Number(e.target.value).toFixed(3)
                        );
                    },
                  }
                : { required: undefined, validate: undefined }
            }
            label={`${t("Volume")}${
              watch("operation") === "COMPRA"
                ? ""
                : ` ${t("COMPRA").toUpperCase()}`
            }${
              selectedProduct?.measurementUnit
                ? ` (${selectedProduct?.measurementUnit})`
                : ""
            }`}
            fullWidth
            size="small"
            type="number"
            inputProps={{ min: 0, step: "0.001" }}
          />
        </Grid>

        {/* Volume VENDA */}
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          sx={{
            ...(watch("operation") === "VENDA" ||
            watch("operation") === "COMPRA&VENDA" ||
            watch("operation") === null
              ? {}
              : { display: "none" }),
          }}
        >
          <FormTextField
            disabled={watch("operation") === null}
            control={control}
            name={"sellVolume"}
            rules={
              watch("operation") === "VENDA" ||
              watch("operation") === "COMPRA&VENDA"
                ? {
                    required: {
                      value: true,
                      message: t("This field is required"),
                    },
                    validate: (value) =>
                      Number(value) > 0 ||
                      t("The data entered is invalid", {
                        data: t("Volume"),
                      }),
                    onChange: (e) => {
                      if (e.target.value.split(".")[1]?.length > 3)
                        setValue(
                          "sellVolume",
                          Number(e.target.value).toFixed(3)
                        );
                    },
                  }
                : { required: undefined, validate: undefined }
            }
            label={`${t("Volume")}${
              watch("operation") === "VENDA"
                ? ""
                : ` ${t("VENDA").toUpperCase()}`
            }${
              selectedProduct?.measurementUnit
                ? ` (${selectedProduct?.measurementUnit})`
                : ""
            }`}
            fullWidth
            size="small"
            type="number"
            inputProps={{ min: 0, step: "0.001" }}
          />
        </Grid>

        {/* End of Offer */}
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            control={control}
            name={"offerCloseDateTime"}
            rules={{
              required: {
                value: true,
                message: t("This field is required"),
              },
            }}
            render={({ field: { ref, ...field } }) => (
              <DateTimePicker
                {...field}
                inputRef={ref}
                label={t("End of Offer")}
                slotProps={{
                  openPickerButton: {
                    size: "small",
                    sx: {
                      "& svg": {
                        transform: "scale(0.75)",
                      },
                    },
                  },
                  textField: {
                    fullWidth: true,
                    size: "small",
                    error: !!errors.offerCloseDateTime,
                    helperText: errors.offerCloseDateTime?.message || " ",
                  },
                }}
                slots={{
                  textField: StyledTextField,
                }}
              />
            )}
          />
        </Grid>

        {/* Price */}
        <Grid item xs={12}>
          <Controller
            control={control}
            name={"priceType"}
            rules={{
              required: {
                value: true,
                message: t("This field is required"),
              },
            }}
            render={({ field: { ref, ...field } }) => (
              <FormControl error={!!errors.priceType}>
                <FormLabel
                  id="priceType-toggle-button-group-label"
                  sx={(theme) => ({ mb: 2, color: theme.palette.text.primary })}
                >
                  <Box display="inline-flex" alignItems="center">
                    {`${t("Price")}${
                      selectedProduct?.measurementUnit
                        ? ` (${getCurrencySymbol("BRL", language)}/${
                            selectedProduct?.measurementUnit
                          })`
                        : ""
                    }`}
                    <Tooltip
                      title={
                        <>
                          {/* TODO: Add to i18n and translate properly */}
                          <Typography>
                            <strong>
                              {t("Atrelado ao PLD")} (Preço de Liquidação das
                              Diferenças)
                            </strong>
                            :{" "}
                            {t(
                              `A oferta será do tipo "PLD + Spread" (positivo ou negativo) e poderá receber lances com spread ${
                                watch("operation") === "COMPRA"
                                  ? "maior"
                                  : watch("operation") === "VENDA"
                                  ? "menor"
                                  : "maior (oferta de compra)/menor (oferta de venda)"
                              } ou igual ao spread definido.`
                            )}{" "}
                            {t(
                              "Se for feito um lance com spread igual ao da oferta, ele será aceito automaticamente."
                            )}
                          </Typography>
                          <br />
                          <Typography>
                            <strong>{t("Fixo")}</strong>:{" "}
                            {t(
                              `A oferta poderá receber lances com valor ${
                                watch("operation") === "COMPRA"
                                  ? "maior"
                                  : watch("operation") === "VENDA"
                                  ? "menor"
                                  : "maior (oferta de compra)/menor (oferta de venda)"
                              } ou igual ao valor definido.`
                            )}{" "}
                            {t(
                              "Se for feito um lance com valor igual ao da oferta, ele será aceito automaticamente."
                            )}
                          </Typography>
                          <br />
                          <Typography>
                            <strong>{t("Lance livre")}</strong>:{" "}
                            {t(
                              'A oferta poderá receber lances livremente. Você precisará definir em seguida se ela será do tipo "PLD + Spread" ou "Fixo".'
                            )}{" "}
                            {t("Nenhum lance será aceito automaticamente.")}
                          </Typography>
                        </>
                      }
                    >
                      <IconButton type="button" size="small" sx={{ my: -5 }}>
                        <InfoOutlined fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </FormLabel>

                <StyledToggleButtonGroup
                  {...field}
                  aria-labelledby="priceType-toggle-button-group-label"
                  size="small"
                  toggleButtonMinWidth={80}
                  exclusive
                >
                  {priceTypeOptions.map((item, idx) => {
                    return (
                      <StyledToggleButton
                        key={item.value}
                        ref={idx === 0 ? ref : undefined}
                        value={item.value}
                      >
                        {t(item.label)}
                      </StyledToggleButton>
                    );
                  })}
                </StyledToggleButtonGroup>
                <FormHelperText sx={{ mt: "4px" }}>
                  {errors.priceType?.message || " "}
                </FormHelperText>
              </FormControl>
            )}
          />
        </Grid>

        {/* Spread COMPRA */}
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          sx={{
            ...(watch("priceType") === "Atrelado ao PLD" &&
            (watch("operation") === "COMPRA" ||
              watch("operation") === "COMPRA&VENDA" ||
              watch("operation") === null)
              ? {}
              : { display: "none" }),
          }}
        >
          <FormTextField
            disabled={watch("operation") === null}
            control={control}
            name={"buySpread"}
            rules={
              watch("priceType") === "Atrelado ao PLD" &&
              (watch("operation") === "COMPRA" ||
                watch("operation") === "COMPRA&VENDA")
                ? {
                    required: {
                      value: true,
                      message: t("This field is required"),
                    },
                    validate: (value) =>
                      !isNaN(Number(value)) ||
                      t("The data entered is invalid", {
                        data: t("Spread"),
                      }),
                    onChange: (e) => {
                      if (e.target.value.split(".")[1]?.length > 2)
                        setValue(
                          "buySpread",
                          Number(e.target.value).toFixed(2)
                        );
                    },
                  }
                : { required: undefined, validate: undefined }
            }
            label={`${t("Spread")}${
              watch("operation") === "COMPRA"
                ? ""
                : ` ${t("COMPRA").toUpperCase()}`
            }`}
            variant="outlined"
            fullWidth
            size="small"
            type="number"
            inputProps={{
              step: "0.01",
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {getCurrencySymbol("BRL", language)}
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        {/* Spread VENDA */}
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          sx={{
            ...(watch("priceType") === "Atrelado ao PLD" &&
            (watch("operation") === "VENDA" ||
              watch("operation") === "COMPRA&VENDA" ||
              watch("operation") === null)
              ? {}
              : { display: "none" }),
          }}
        >
          <FormTextField
            disabled={watch("operation") === null}
            control={control}
            name={"sellSpread"}
            rules={
              watch("priceType") === "Atrelado ao PLD" &&
              (watch("operation") === "VENDA" ||
                watch("operation") === "COMPRA&VENDA")
                ? {
                    required: {
                      value: true,
                      message: t("This field is required"),
                    },
                    validate: (value) =>
                      !isNaN(Number(value)) ||
                      t("The data entered is invalid", {
                        data: t("Spread"),
                      }),
                    onChange: (e) => {
                      if (e.target.value.split(".")[1]?.length > 2)
                        setValue(
                          "sellSpread",
                          Number(e.target.value).toFixed(2)
                        );
                    },
                  }
                : { required: undefined, validate: undefined }
            }
            label={`${t("Spread")}${
              watch("operation") === "VENDA"
                ? ""
                : ` ${t("VENDA").toUpperCase()}`
            }`}
            variant="outlined"
            fullWidth
            size="small"
            type="number"
            inputProps={{
              step: "0.01",
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {getCurrencySymbol("BRL", language)}
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        {/* Value COMPRA */}
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          sx={{
            ...((watch("priceType") === "Fixo" ||
              watch("priceType") === null) &&
            (watch("operation") === "COMPRA" ||
              watch("operation") === "COMPRA&VENDA" ||
              watch("operation") === null)
              ? {}
              : { display: "none" }),
          }}
        >
          <FormTextField
            disabled={
              watch("operation") === null || watch("priceType") !== "Fixo"
            }
            control={control}
            name={"buyValue"}
            rules={
              watch("priceType") === "Fixo" &&
              (watch("operation") === "COMPRA" ||
                watch("operation") === "COMPRA&VENDA")
                ? {
                    required: {
                      value: true,
                      message: t("This field is required"),
                    },
                    validate: (value) =>
                      Number(value) > 0 ||
                      t("The data entered is invalid", {
                        data: t("Value"),
                      }),
                    onChange: (e) => {
                      if (e.target.value.split(".")[1]?.length > 2)
                        setValue("buyValue", Number(e.target.value).toFixed(2));
                    },
                  }
                : { required: undefined, validate: undefined }
            }
            label={`${t("Value")}${
              watch("operation") === "COMPRA"
                ? ""
                : ` ${t("COMPRA").toUpperCase()}`
            }`}
            variant="outlined"
            fullWidth
            size="small"
            type="number"
            inputProps={{ step: "0.01", min: 0 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {getCurrencySymbol("BRL", language)}
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        {/* Value VENDA */}
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          sx={{
            ...((watch("priceType") === "Fixo" ||
              watch("priceType") === null) &&
            (watch("operation") === "VENDA" ||
              watch("operation") === "COMPRA&VENDA" ||
              watch("operation") === null)
              ? {}
              : { display: "none" }),
          }}
        >
          <FormTextField
            disabled={
              watch("operation") === null || watch("priceType") !== "Fixo"
            }
            control={control}
            name={"sellValue"}
            rules={
              watch("priceType") === "Fixo" &&
              (watch("operation") === "VENDA" ||
                watch("operation") === "COMPRA&VENDA")
                ? {
                    required: {
                      value: true,
                      message: t("This field is required"),
                    },
                    validate: (value) =>
                      Number(value) > 0 ||
                      t("The data entered is invalid", {
                        data: t("Value"),
                      }),
                    onChange: (e) => {
                      if (e.target.value.split(".")[1]?.length > 2)
                        setValue(
                          "sellValue",
                          Number(e.target.value).toFixed(2)
                        );
                    },
                  }
                : { required: undefined, validate: undefined }
            }
            label={`${t("Value")}${
              watch("operation") === "VENDA"
                ? ""
                : ` ${t("VENDA").toUpperCase()}`
            }`}
            variant="outlined"
            fullWidth
            size="small"
            type="number"
            inputProps={{ step: "0.01", min: 0 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {getCurrencySymbol("BRL", language)}
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        {/* Free Bid Type */}
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            ...(watch("priceType") === "Lance livre"
              ? {}
              : { display: "none" }),
          }}
        >
          <Controller
            control={control}
            name={"freeBid"}
            rules={
              watch("priceType") === "Lance livre"
                ? {
                    required: {
                      value: true,
                      message: t("This field is required"),
                    },
                  }
                : { required: undefined }
            }
            render={({ field: { ref, ...field } }) => (
              <FormControl error={!!errors.freeBid}>
                <FormLabel
                  id="freeBid-toggle-button-group-label"
                  sx={(theme) => ({ mb: 2, color: theme.palette.text.primary })}
                >
                  {t("Free Bid Type")}
                </FormLabel>
                <StyledToggleButtonGroup
                  {...field}
                  aria-labelledby="freeBid-toggle-button-group-label"
                  size="small"
                  toggleButtonMinWidth={110}
                  exclusive
                >
                  {freeBidTypeOptions.map((item, idx) => {
                    return (
                      <StyledToggleButton
                        key={item.value}
                        ref={idx === 0 ? ref : undefined}
                        value={item.value}
                      >
                        {t(item.label)}
                      </StyledToggleButton>
                    );
                  })}
                </StyledToggleButtonGroup>
                <FormHelperText sx={{ mt: "4px" }}>
                  {errors.freeBid?.message || " "}
                </FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
      </Grid>

      <Typography variant="body1">
        {t("Register offer footer message")}
      </Typography>

      {/* Buttons */}
      <Box
        display="inline-flex"
        gap={4}
        mt={4}
        width="100%"
        justifyContent="flex-end"
        alignItems="center"
        flexWrap="wrap"
      >
        <Button
          size="small"
          color="primary"
          type="button"
          {...(editOfferId === null
            ? { onClick: handleClear, disabled: !isDirty }
            : { onClick: handleCancelEditOffer })}
        >
          {editOfferId === null ? t("Clear") : t("Cancel")}
        </Button>

        <Button
          size="large"
          variant="contained"
          color={watch("operation") === "COMPRA" ? "success" : "error"}
          sx={{
            minWidth: 192,
            ...(watch("operation") === "COMPRA&VENDA"
              ? {
                  backgroundImage:
                    "linear-gradient(90deg, #00CB85 13.54%, #E05757 64.58%, #FF4760 100%)",
                  "&:hover": {
                    backgroundImage: `linear-gradient(90deg, ${darken(
                      "#00CB85",
                      0.2
                    )} 13.54%, ${darken("#E05757", 0.2)} 64.58%, ${darken(
                      "#FF4760",
                      0.2
                    )} 100%)`,
                  },
                }
              : {}),
          }}
          type="submit"
          disabled={!isDirty || validateNewOfferMutation.isLoading}
        >
          {t("Save Product")}
        </Button>

        <Button
          size="small"
          variant="contained"
          color="primary"
          type="button"
          onClick={onGoToSummary}
          disabled={!newOfferListLength || editOfferId !== null}
        >
          {`${t("Go to Summary")}${
            newOfferListLength ? ` (${newOfferListLength})` : ""
          }`}
        </Button>

        <Autocomplete
          value={typeof editOfferId === "number" ? String(editOfferId) : null}
          onChange={(e, data) => {
            if (data === null) {
              handleCancelEditOffer();
            } else {
              onEditOffer(Number(data));
            }
          }}
          openOnFocus
          handleHomeEndKeys
          disablePortal
          options={Array.from(Array(newOfferListLength).keys()).map(String)}
          getOptionLabel={(option) => {
            return t("Edit") + " " + String(Number(option) + 1);
          }}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              variant="outlined"
              size="small"
              placeholder={`(${t("New Offer")})`}
              sx={{ minWidth: 140 }}
            />
          )}
          disabled={!newOfferListLength}
        />
      </Box>
    </form>
  );
}
