import React from "react";
import styled from "@emotion/styled";

import {
  Grid,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeadProps,
  TablePagination,
  TableProps,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
} from "@mui/material";
import { spacing, SxProps } from "@mui/system";

const ToolbarTitle = styled.div`
  min-width: 150px;
`;

const Paper = styled(MuiPaper)(spacing);

export function descendingComparator(
  a: Record<string, any>,
  b: Record<string, any>,
  orderBy: string
) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order: "desc" | "asc", orderBy: string) {
  return order === "desc"
    ? (a: Record<string, any>, b: Record<string, any>) =>
        descendingComparator(a, b, orderBy)
    : (a: Record<string, any>, b: Record<string, any>) =>
        -descendingComparator(a, b, orderBy);
}

export function stableSort<T extends Record<string, any> = Record<string, any>>(
  array: Array<T>,
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el: T, index: number) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

export type HeadCell = {
  id: string;
  alignment: "left" | "center" | "right" | "justify" | "inherit" | undefined;
  sx?: SxProps;
  label: string | JSX.Element;
  disablePadding?: boolean;
  colSpan?: number;
  disableSort?: boolean;
};

export type EnhancedTableHeadProps = {
  order: "desc" | "asc";
  orderBy: string;
  rowCount: number;
  height?: boolean;
  onRequestSort: (e: any, property: string) => void;
  headCells: HeadCell[];
  TableHeadProps?: TableHeadProps;
};
export const EnhancedTableHead: React.FC<EnhancedTableHeadProps> = ({
  order,
  orderBy,
  height,
  onRequestSort,
  headCells,
  TableHeadProps,
}) => {
  const createSortHandler = (property: string) => (event: any) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead
      // sx={{ opacity: 0.6 }}
      {...TableHeadProps}
      sx={{
        position: height ? "sticky" : "initial",
        top: 0,
        backgroundColor: "#233044",
        zIndex: 5,
      }}
    >
      <TableRow sx={{ verticalAlign: "bottom" }}>
        {headCells.map((headCell: HeadCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={headCell.sx}
            colSpan={headCell.colSpan}
          >
            {headCell.disableSort ? (
              headCell.label
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export interface EnhancedTableToolbarProps {
  title?: string;
  subtitle?: string;
  toolbarActionButton?: JSX.Element;
  toolbarFiltersArea?: JSX.Element;
}
export const EnhancedTableToolbar = ({
  title,
  subtitle,
  toolbarActionButton,
  toolbarFiltersArea,
}: EnhancedTableToolbarProps) => {
  if (!title && !toolbarActionButton && !toolbarFiltersArea) return <></>;

  return (
    <Toolbar
      sx={{
        py: 4,
        flexDirection: "column",
        minHeight: "0 !important",
        paddingTop: 0,
      }}
    >
      <Grid
        container
        spacing={3}
        justifyContent="space-between"
        alignItems="center"
      >
        {(title || subtitle) && (
          <Grid item>
            <ToolbarTitle>
              <Typography variant="h6" id={`table-${title}`}>
                {title}
                {subtitle && (
                  <>
                    {" "}
                    <Typography component="span" color="#B6B9C0">
                      {subtitle}
                    </Typography>
                  </>
                )}
              </Typography>
            </ToolbarTitle>
          </Grid>
        )}

        {toolbarActionButton && <Grid item>{toolbarActionButton}</Grid>}

        {toolbarFiltersArea && (
          <Grid item xs={12}>
            {toolbarFiltersArea}
          </Grid>
        )}
      </Grid>
    </Toolbar>
  );
};

type PaginationOptions = {
  totalItems: number;
  itemsPerPage: number;
  currentPage: number;
  onPageChange: (newPage: number) => void;
  onRowsPerPageChange: (rows: number) => void;
};

type OrderingOptions = {
  orderBy?: string;
  order?: "desc" | "asc";
  handleRequestSort?: (event: any, property: string) => void;
};

interface EnhancedTableManualPaginationProps<
  T extends Record<string, any> = Record<string, any>
> {
  data: Array<T>;
  title?: string;
  subtitle?: string;
  height?: string;
  headCells: HeadCell[];
  renderRow: (row: T, index: number, arr: Array<T>) => JSX.Element;
  keyExtractor: (row: T, index: number) => string;
  toolbarActionButton?: JSX.Element;
  toolbarFiltersArea?: JSX.Element;
  rowsPerPage?: number;
  avoidEmptyRows?: boolean;
  disableSort?: boolean;
  disablePagination?: boolean;
  TableProps?: TableProps;
  TableHeadProps?: TableHeadProps;
  footer?: JSX.Element;
  paginationOptions: PaginationOptions;
  orderingOptions?: OrderingOptions;
}

export function EnhancedTableManualPagination<
  T extends Record<string, any> = Record<string, any>
>({
  data,
  title,
  subtitle,
  height,
  headCells,
  renderRow,
  keyExtractor,
  toolbarActionButton,
  toolbarFiltersArea,
  rowsPerPage: initialRowsPerPage,
  avoidEmptyRows,
  disableSort,
  disablePagination,
  TableProps,
  TableHeadProps,
  footer,
  paginationOptions,
  orderingOptions,
}: EnhancedTableManualPaginationProps<T>) {
  const emptyRows = avoidEmptyRows
    ? 0
    : paginationOptions.itemsPerPage -
      Math.min(
        paginationOptions.itemsPerPage,
        paginationOptions.totalItems -
          paginationOptions.currentPage * paginationOptions.itemsPerPage
      );

  return (
    <div>
      <Paper>
        <EnhancedTableToolbar
          title={title}
          subtitle={subtitle}
          toolbarActionButton={toolbarActionButton}
          toolbarFiltersArea={toolbarFiltersArea}
        />
        <TableContainer
          sx={{
            height: height,
          }}
        >
          <Table
            aria-labelledby={`table-${title}`}
            aria-label={title}
            sx={{
              ".MuiTableCell-root": {
                paddingX: 2,
              },
              ...(TableProps?.sx ?? {}),
            }}
            {...TableProps}
          >
            <EnhancedTableHead
              height={height ? true : false}
              order={orderingOptions?.order ?? "asc"}
              orderBy={orderingOptions?.orderBy ?? ""}
              onRequestSort={(event, property) => {
                if (orderingOptions?.handleRequestSort) {
                  orderingOptions.handleRequestSort(event, property);
                }
              }}
              rowCount={data.length}
              headCells={
                disableSort
                  ? headCells.map((item) => ({ ...item, disableSort: true }))
                  : headCells
              }
              TableHeadProps={TableHeadProps}
            />
            <TableBody>
              {data.map((row, index, arr) => (
                <React.Fragment key={keyExtractor(row, index)}>
                  {renderRow(row, index, arr)}
                </React.Fragment>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={7} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {!disablePagination && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
            component="div"
            count={paginationOptions.totalItems}
            rowsPerPage={paginationOptions.itemsPerPage}
            page={paginationOptions.currentPage - 1}
            onPageChange={(event, page) => {
              paginationOptions.onPageChange(page + 1);
            }}
            onRowsPerPageChange={(event) => {
              paginationOptions.onRowsPerPageChange(
                parseInt(event.target.value, 10)
              );
            }}
          />
        )}

        {footer}
      </Paper>
    </div>
  );
}

interface EnhancedTableProps<
  T extends Record<string, any> = Record<string, any>
> {
  data: Array<T>;
  title?: string;
  subtitle?: string;
  headCells: HeadCell[];
  renderRow: (row: T, index: number, arr: Array<T>) => JSX.Element;
  keyExtractor: (row: T, index: number) => string;
  toolbarActionButton?: JSX.Element;
  toolbarFiltersArea?: JSX.Element;
  rowsPerPage?: number;
  avoidEmptyRows?: boolean;
  initialOrderBy?: string;
  disableSort?: boolean;
  disablePagination?: boolean;
  TableProps?: TableProps;
  TableHeadProps?: TableHeadProps;
  footer?: JSX.Element;
}

export function EnhancedTable<
  T extends Record<string, any> = Record<string, any>
>(props: EnhancedTableProps<T>) {
  const [order, setOrder] = React.useState<"desc" | "asc">("asc");
  const [orderBy, setOrderBy] = React.useState(props.initialOrderBy ?? "");

  const [currentPage, setCurrentPage] = React.useState(1);
  const [itemsPerPage, setItemsPerPage] = React.useState(20);

  return (
    <EnhancedTableManualPagination
      {...props}
      data={(props.disableSort
        ? props.data
        : stableSort(props.data, getComparator(order, orderBy))
      ).slice(
        (currentPage - 1) * itemsPerPage,
        (currentPage - 1) * itemsPerPage + itemsPerPage
      )}
      paginationOptions={{
        currentPage: currentPage,
        itemsPerPage: itemsPerPage,
        onPageChange: setCurrentPage,
        onRowsPerPageChange: setItemsPerPage,
        totalItems: props.data.length,
      }}
      orderingOptions={{
        order: "asc",
        orderBy: "tradeName",
        handleRequestSort: (event: any, property: string) => {
          const isAsc = orderBy === property && order === "asc";
          setOrder(isAsc ? "desc" : "asc");
          setOrderBy(property);
        },
      }}
    />
  );
}
