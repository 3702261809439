import {
  Box,
  BoxProps,
  Paper,
  TableCell,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useCurrentTime } from "../../hooks/useCurrentTime";
import { getSplitedTimeDifference } from "../../utils/date";
import {
  getCurrencySymbol,
  toLocaleCurrencyString,
  toLocaleCurrencyStringWithoutSymbol,
} from "../../utils/string";
import { EnhancedTable, HeadCell } from "../EnhancedTable";
import { TimeBox } from "../TimeBox";
import { OfferBookItem } from "./entities/OfferBookItem";

interface FloatingBoxProps extends BoxProps {
  anchorEl?: null | Element;
  anchorOffsetY?: "top" | "bottom";
}
function FloatingBox({
  anchorEl,
  anchorOffsetY = "top",
  ...rest
}: FloatingBoxProps) {
  return (
    <Box
      {...rest}
      sx={[
        (theme) => ({
          position: "fixed",
          visibility: "hidden",
          zIndex: 1,
          ...(anchorEl
            ? {
                top:
                  anchorEl.getBoundingClientRect().y +
                  (anchorOffsetY === "bottom"
                    ? anchorEl.getBoundingClientRect().height
                    : 0),
                left: anchorEl.getBoundingClientRect().x,
                transform: "translate(calc(-100% - 24px), -50%)",
                visibility: "visible",
              }
            : {}),
          backgroundColor:
            theme.palette.mode === "dark" ? "#78808B" : "#EBEBEB",
          p: 2,
          borderTopLeftRadius: 6,
          borderBottomLeftRadius: 6,
          transformStyle: "preserve-3d",
          "&::after": {
            content: '""',
            position: "absolute",
            right: 0,
            top: 0,
            borderRadius: "6px",
            height: "100%",
            aspectRatio: "1 / 1",
            transform:
              "translateX(25%) scaleX(0.5) translateX(50%) translateY(-0.5px) scale(0.73) rotate(45deg) translateZ(-1px)",
            backgroundColor: "inherit",
          },
        }),
        ...(Array.isArray(rest.sx) ? rest.sx : [rest.sx]),
      ]}
    />
  );
}

interface DirectBuyAndSellOfferBookAreaProps {
  type: "COMPRA" | "VENDA";
  submarket: string;
  productName: string;
  offerBook: Array<OfferBookItem>;
}
export function DirectBuyAndSellOfferBookArea({
  type,
  submarket,
  productName,
  offerBook,
}: DirectBuyAndSellOfferBookAreaProps) {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  const { currentTime } = useCurrentTime();

  const headCells = React.useMemo<Array<HeadCell>>(
    () => [
      {
        id: "value",
        alignment: "left",
        label: `${t("Price")} (${getCurrencySymbol("BRL", language)})`,
        disableSort: true,
      },
      {
        id: "volume",
        alignment: "right",
        label: `${t("Volume")} (MWh)`,
        disableSort: true,
      },
      {
        id: "total",
        alignment: "right",
        label: `${t("Total")} (${getCurrencySymbol("BRL", language)})`,
        disableSort: true,
      },
      {
        id: "offerCloseDateTime",
        alignment: "right",
        label: t("Remaining Time"),
        disableSort: true,
      },
    ],
    [language, t]
  );

  const [hoveredRow, setHoveredRow] = React.useState<number | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLTableRowElement | null>(
    null
  );

  const { averageValue, totalVolume, totalValue } = React.useMemo(() => {
    const defaultValue = { averageValue: 0, totalVolume: 0, totalValue: 0 };

    if (hoveredRow === null) {
      return defaultValue;
    }

    const offerBookData =
      type === "COMPRA" ? offerBook : [...offerBook].reverse();

    return offerBookData
      .slice(
        0,
        type === "COMPRA"
          ? hoveredRow + 1
          : Math.min(7, offerBookData.length) - hoveredRow
      )
      .reduce((acc, curr, idx, arr) => {
        return {
          averageValue:
            (acc.averageValue * acc.totalVolume + curr.value * curr.volume) /
            (acc.totalVolume + curr.volume),
          totalVolume: acc.totalVolume + curr.volume,
          totalValue: acc.totalValue + curr.total,
        };
      }, defaultValue);
  }, [hoveredRow, offerBook, type]);

  return (
    <>
      <Paper
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          borderRadius: 5,
          px: isMdUp ? 10 : 4,
          py: 6,
          gap: 6,
        }}
      >
        {isMdUp && (
          <FloatingBox
            anchorEl={anchorEl}
            anchorOffsetY={type === "COMPRA" ? "bottom" : "top"}
          >
            {t("Average Price")}: PLD{" "}
            {`${averageValue < 0 ? "-" : "+"} ${toLocaleCurrencyString(
              Math.abs(averageValue),
              "BRL",
              language
            )}`}
            <br />
            {`${t("Sum")} ${productName}: `}
            {totalVolume.toLocaleString(language, {
              minimumFractionDigits: 3,
              maximumFractionDigits: 3,
            })}{" "}
            MWh
            <br />
            {`${t("Sum")} ${getCurrencySymbol("BRL", language)}: `}
            {toLocaleCurrencyStringWithoutSymbol(totalValue, "BRL", language)}
          </FloatingBox>
        )}

        <Box sx={{ textAlign: "center" }}>
          <Typography variant="h6" fontWeight={700}>
            {productName}
          </Typography>
          <Typography>
            {language === "pt" ? t("Orders2") : t("Created2")}{" "}
            <Typography
              component="span"
              color={type === "COMPRA" ? "success.main" : "error.main"}
            >
              {t(type).toUpperCase()}
            </Typography>{" "}
            {language === "pt" ? t("Created2") : t("Orders2")}
          </Typography>

          {offerBook.length === 0 ? (
            <Typography variant="caption">
              {t("There are no orders of this type available at the moment")}
            </Typography>
          ) : (
            <EnhancedTable
              TableProps={{
                size: "small",
                sx: {
                  "& .MuiTableCell-root": {
                    px: 2,
                    fontSize: "0.8125rem",
                    // borderBottomStyle: "none",
                  },
                },
              }}
              data={offerBook.slice(
                type === "COMPRA" ? 0 : Math.max(offerBook.length - 7, 0),
                type === "COMPRA" ? 7 : undefined
              )}
              headCells={headCells}
              rowsPerPage={5}
              avoidEmptyRows
              disablePagination
              keyExtractor={(row, idx) => String(row.value)}
              renderRow={(row, idx, arr) => {
                const remainingTime = getSplitedTimeDifference(
                  currentTime,
                  row.offerCloseDateTime
                );

                const largestTotal = arr.reduce(
                  (acc, curr) => (curr.total > acc ? curr.total : acc),
                  0
                );

                return (
                  <TableRow
                    // hover
                    tabIndex={-1}
                    onMouseOver={(event) => {
                      setHoveredRow(idx);
                      setAnchorEl(event.currentTarget);
                    }}
                    onMouseOut={() => {
                      setHoveredRow(null);
                      setAnchorEl(null);
                    }}
                    sx={[
                      (theme) => {
                        if (
                          hoveredRow !== null &&
                          ((type === "COMPRA" && idx <= hoveredRow) ||
                            (type === "VENDA" && idx >= hoveredRow))
                        ) {
                          return {
                            backgroundColor:
                              theme.palette.mode === "dark"
                                ? "rgba(255, 255, 255, 0.08)"
                                : "rgba(0, 0, 0, 0.04)",
                          };
                        }
                        return {};
                      },
                      // {
                      //   "& .MuiTableCell-root": {
                      //     ...(idx === hoveredRow
                      //       ? {
                      //           borderBottomStyle: "dashed",
                      //         }
                      //       : {
                      //           borderBottomColor: "transparent"
                      //         }),
                      //   },
                      // },
                    ]}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ whiteSpace: "nowrap" }}
                      align="left"
                    >
                      {`PLD ${
                        row.value < 0 ? "-" : "+"
                      } ${toLocaleCurrencyStringWithoutSymbol(
                        Math.abs(row.value),
                        "BRL",
                        language
                      )}`}
                    </TableCell>
                    <TableCell
                      align="right"
                      // sx={{ maxWidth: "8ch" }}
                    >
                      {row.volume.toLocaleString(language, {
                        minimumFractionDigits: 3,
                        maximumFractionDigits: 3,
                      })}
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        position: "relative",
                        transformStyle: "preserve-3d",
                        "&::before": {
                          content: '""',
                          position: "absolute",
                          right: 0,
                          top: "50%",
                          transform: "translateY(-50%) translateZ(-1px)",
                          height: "80%",
                          width: `${(row.total * 100) / largestTotal}%`,
                          backgroundColor:
                            type === "COMPRA" ? "success.main" : "error.main",
                          opacity: 0.2,
                        },
                      }}
                    >
                      {toLocaleCurrencyStringWithoutSymbol(
                        row.total,
                        "BRL",
                        language
                      )}
                    </TableCell>

                    <TableCell padding="none" align="right">
                      {remainingTime.totalSeconds > 0 ? (
                        <TimeBox time={remainingTime} />
                      ) : (
                        "-"
                      )}
                    </TableCell>
                  </TableRow>
                );
              }}
            />
          )}
        </Box>
      </Paper>
    </>
  );
}
