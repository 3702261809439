import { Observable, Subscription } from "rxjs";
import { AssetsPriceTable } from "../entities/AssetsPriceTable";
import { AssetsPriceObservableImpl } from "../infra/firestore/AssetsPriceObservableImpl";

export type options = {};

export abstract class AssetsPriceObservable extends Observable<AssetsPriceTable> {
  abstract subscribeWithOptions(
    options: options,
    next?: ((value: AssetsPriceTable) => void) | null | undefined,
    error?: ((error: any) => void) | null | undefined,
    complete?: (() => void) | null | undefined
  ): Subscription;

  abstract setOptions(options: options): void;
}

export class GetAssetsQuotesObservableService {
  private observable: AssetsPriceObservable | null = null;

  public getAssetsPriceObservable() {
    if (this.observable === null) {
      this.observable = new AssetsPriceObservableImpl();
    }

    return this.observable;
  }
}
