import axios from "axios";

const balcaoApiInstance = axios.create({
  baseURL: process.env.REACT_APP_BALCAO_API_URL,
});

balcaoApiInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong."
    )
);

const registrationApiInstance = axios.create({
  baseURL: process.env.REACT_APP_REGISTRATION_API_URL,
});

registrationApiInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong."
    )
);

export { balcaoApiInstance, registrationApiInstance };
