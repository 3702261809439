import { useQuery } from "@tanstack/react-query";
import { getBanks } from "../infra/api/BankInfra";

export function useGetBanks() {
  const { data: banks, ...rest } = useQuery(["getBanks"], getBanks, {
    refetchOnWindowFocus: false,
  });

  return { banks, ...rest };
}
