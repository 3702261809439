import { ButtonProps } from "@mui/material";
import { createContext, ReactNode, useCallback, useState } from "react";

import ConfirmationModal from "../components/ConfirmationModal";

interface ConfirmationModalOptions {
  onSubmit: () => void;
  onClose?: () => void;
  message?: string | JSX.Element;
  submessage?: string | JSX.Element;
  submitButtonColor?: ButtonProps["color"];
}

interface ConfirmationModalContextProps {
  showConfirmationModal: (options: ConfirmationModalOptions) => void;
}

const ConfirmationModalContext =
  createContext<ConfirmationModalContextProps | null>(null);

function ConfirmationModalProvider({ children }: { children: ReactNode }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [options, setOptions] = useState<ConfirmationModalOptions>({
    onSubmit: () => {},
  });

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const showConfirmationModal = useCallback(
    (options: ConfirmationModalOptions) => {
      setOptions(options);
      handleOpenModal();
    },
    [handleOpenModal]
  );

  return (
    <ConfirmationModalContext.Provider
      value={{
        showConfirmationModal,
      }}
    >
      <>
        <ConfirmationModal
          open={isModalOpen}
          onClose={() => {
            handleCloseModal();
            if (options.onClose) options.onClose();
          }}
          onSubmit={() => {
            options.onSubmit();
            handleCloseModal();
          }}
          message={options.message}
          subtitle={options.submessage}
          submitButtonColor={options.submitButtonColor}
        />

        {children}
      </>
    </ConfirmationModalContext.Provider>
  );
}

export { ConfirmationModalContext, ConfirmationModalProvider };
