import React from "react";
import useChooseParticipant from "../../../hooks/useChooseParticipant";
import { Message } from "../../../types/chat";
import { GetChatroomMessagesObservableService } from "../services/GetChatroomMessagesObservableService";

const getChatroomMessagesObservableService =
  new GetChatroomMessagesObservableService();

export function useGetChatroomMessages(chatroomId: string | null) {
  const { currentParticipant } = useChooseParticipant();

  const [chatRoomMessages, setChatRoomMessages] = React.useState<Message[]>([]);

  React.useEffect(() => {
    const options = {
      chatroomId: chatroomId,
      participant: currentParticipant ? currentParticipant.id : -1,
    };

    const subscription = getChatroomMessagesObservableService
      .getChatroomMessagesObservable()
      .subscribeWithOptions(options, (messagesData) => {
        setChatRoomMessages(
          messagesData.messages.map((message) => ({
            createdAt: message.getCreatedAt().toISOString(),
            id: message.getId(),
            sentByMe: message.getSenderId() !== null,
            text: message.getText(),
          }))
        );
      });
    return () => {
      subscription.unsubscribe();
    };
  }, [chatroomId, currentParticipant]);

  return { messages: chatRoomMessages };
}
