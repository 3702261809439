/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useMemo } from "react";
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { submarketColors } from "../../constants";
import { useTranslation } from "react-i18next";
import { TradingViewGraphic } from "./TradingViewGraphic";
import * as firestore from "firebase/firestore";
import { useFirestoreQuery } from "../../hooks/useFirestoreQuery";
import {
  getISOYearAndMonth,
  getPastDateBasedOnPeriod,
  parseDate,
} from "../../utils/date";
import { DateTime, DurationLike } from "luxon";
import { subMonths } from "date-fns";
import { useQuery } from "@tanstack/react-query";
import { getMonthlyPld } from "../../services/pld";

interface StatsProps {
  closeValuePLDPlusSpread: number;
  closeValueSpread: number;
  counter: number;
  maximumNegotiatedPLDPlusSpread: number;
  maximumNegotiatedSpread: number;
  minimumNegotiatedPLDPlusSpread: number;
  minimumNegotiatedSpread: number;
  openValueAveragePLDPlusSpread: number;
  openValueAverageSpread: number;
  openValuePLDPlusSpread: number;
  openValueSpread: number;
  productId: string;
  submarket: string;
  totalPLDPlusSpread: number;
  totalSpread: number;
  volume: number;
}

// const nameProducts: any = {
//   Convencional: "Conv",
//   "Cogeração Qualificada 50%": "CQ5",
//   "Cogeração Qualificada 100%": "CQ1",
//   "Incentivada 0%": "I0",
//   "Incentivada 50%": "I5",
//   "Incentivada 80%": "I8",
//   "Incentivada 100%": "I1",
// };

const range: any = {
  hour: "Hora",
  day: "Dia",
  week: "Semana",
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

export function BoxTradingView() {
  const { t } = useTranslation();

  const [maximizeGraph, setMaximizeGraph] = React.useState(true);
  const [graphSelected, setGraphSelected] = React.useState(
    "Cogeração Qualificada 50%"
  );

  const [viewGraphics, setViewGraphics] = React.useState<string[]>(["CQ5"]);
  const [rangeFilter, setRangeFilter] = React.useState<string>("hour");

  const [submarketFilterValue, setSubmarketFilterValue] =
    React.useState<string>("SE/CO");

  React.useEffect(() => {
    const boxTradingView = document.querySelector(".box-graphics");
    boxTradingView?.addEventListener(
      "wheel",
      (event) => {
        event.preventDefault();
      },
      { passive: false }
    );
  }, []);

  const {
    data: [hourStatistics],
  } = useFirestoreQuery<{
    id: string;
    createdAt: Date;
    stats: Array<StatsProps>;
  }>(() => {
    return [
      firestore.query(
        firestore.collection(firestore.getFirestore(), "hourStatistics")
      ),
    ];
  }, []);

  const {
    data: [dayStatistics],
  } = useFirestoreQuery<{
    id: string;
    createdAt: Date;
    stats: Array<StatsProps>;
  }>(() => {
    return [
      firestore.query(
        firestore.collection(firestore.getFirestore(), "dayStatistics")
      ),
    ];
  }, []);

  const {
    data: [weekStatistics],
  } = useFirestoreQuery<{
    id: string;
    createdAt: Date;
    stats: Array<StatsProps>;
  }>(() => {
    return [
      firestore.query(
        firestore.collection(firestore.getFirestore(), "weekStatistics")
      ),
    ];
  }, []);

  const statistics = React.useMemo(() => {
    switch (rangeFilter) {
      case "hour":
        return hourStatistics ?? [];
      case "day":
        return dayStatistics ?? [];
      case "week":
        return weekStatistics ?? [];
    }
  }, [rangeFilter, hourStatistics, dayStatistics, weekStatistics]);

  const {
    data: [products],
  } = useFirestoreQuery<{ name: string; assetsAvailableCode: string }>(() => {
    return [
      firestore.query(
        firestore.collection(firestore.getFirestore(), "products")
      ),
    ];
  }, []);

  const productsSorted = useMemo(() => {
    if (!products) return [];

    return products.sort((a, b) => {
      const assetA = a.name.toString().toUpperCase();
      const assetB = b.name.toString().toUpperCase();
      return assetA.localeCompare(assetB, undefined, { numeric: true });
    });
  }, [products]);

  function handleSelectSumarket(submarket: string) {
    setSubmarketFilterValue(submarket);
    setMaximizeGraph(false);
    setGraphSelected("");
  }

  const handleStatisticsFilteredByProduct = React.useCallback(
    (
      statistics: Array<{
        id: string;
        createdAt: Date;
        stats: Array<StatsProps>;
      }>,
      submarket: string,
      productId: string
    ) => {
      return statistics
        ?.map((item) => {
          return {
            date: item.createdAt,
            stats: item.stats.filter(
              (statsItem) =>
                statsItem.submarket === submarket &&
                statsItem.productId === productId
            ),
          };
        })
        .filter((item) => item.stats.length !== 0)
        .map((item) => {
          return {
            date: parseDate(item.date),
            open: item.stats[0].openValuePLDPlusSpread,
            close: item.stats[0].closeValuePLDPlusSpread,
            high: item.stats[0].maximumNegotiatedPLDPlusSpread,
            low: item.stats[0].minimumNegotiatedPLDPlusSpread,
            volume: item.stats[0].volume,
          };
        })
        .sort((a, b) => {
          return a.date! > b.date! ? 1 : a.date! < b.date! ? -1 : 0;
        });
    },
    []
  );

  const lastMonth = React.useMemo(
    () => getISOYearAndMonth(subMonths(new Date(), 1)),
    []
  );

  const { data: pldData } = useQuery(["/pld/month", lastMonth, lastMonth], () =>
    getMonthlyPld(lastMonth, lastMonth)
  );

  const lastMonthPld = React.useMemo(
    () =>
      pldData && pldData.length > 0 ? pldData[0].monthlyPldBySubmarket : {},
    [pldData]
  );

  function completeGraph(
    statistics: {
      date: Date | null;
      open: number;
      close: number;
      high: number;
      low: number;
      volume: number;
    }[],
    groupPeriod: "hour" | "day" | "week" = "hour"
  ) {
    if (statistics.length === 1) {
      const pld = Math.floor(Number(lastMonthPld[submarketFilterValue]) * 100);
      const statisticsDate = statistics[0].date
        ? statistics[0].date
        : new Date();
      const date = getPastDateBasedOnPeriod(statisticsDate, groupPeriod);

      statistics.unshift({
        date,
        open: pld,
        close: pld,
        high: pld,
        low: pld,
        volume: 1000,
      });
    }

    const reversedStatistics = statistics.slice().reverse();
    const BARS = 1000;
    const currentTime = DateTime.now();

    let values = [];
    for (let i = BARS; i > 0; i--) {
      let subTime: DurationLike = { hour: BARS - i };
      let groupTimeFormat = "HH-MM-dd-yyyy";
      if (groupPeriod === "day") {
        subTime = { day: BARS - i };
        groupTimeFormat = "MM-dd-yyyy";
      }

      if (groupPeriod === "week") {
        subTime = { week: BARS - i };
        groupTimeFormat = "MM-yyyy";
      }

      const currentTimeLoop = currentTime.minus(subTime);
      const currentTimeFormated =
        currentTimeLoop.toFormat(groupTimeFormat) +
        "-" +
        currentTimeLoop.weekNumber;

      const valueThatMatchWithTime = reversedStatistics.find((value) => {
        const creationTime =
          DateTime.fromJSDate(value.date!).toFormat(groupTimeFormat) +
          "-" +
          DateTime.fromJSDate(value.date!).weekNumber;

        if (creationTime === currentTimeFormated) {
          return true;
        }

        return false;
      });

      if (valueThatMatchWithTime) {
        values.push({
          date: currentTimeLoop.toJSDate(),
          open: valueThatMatchWithTime.open / 100,
          close: valueThatMatchWithTime.close / 100,
          low: valueThatMatchWithTime.low / 100,
          high: valueThatMatchWithTime.high / 100,
          volume: valueThatMatchWithTime.volume / 1000,
        });
        continue;
      }

      const valueThatFitsWithTime = reversedStatistics.find((value) => {
        if (value.date! < currentTimeLoop.toJSDate()) {
          return true;
        }

        return false;
      });

      if (valueThatFitsWithTime) {
        values.push({
          date: currentTimeLoop.toJSDate(),
          open: valueThatFitsWithTime.close / 100,
          close: valueThatFitsWithTime.close / 100,
          low: valueThatFitsWithTime.close / 100,
          high: valueThatFitsWithTime.close / 100,
          volume: 0,
        });
      }
    }

    return values.reverse();
  }

  const statisticCQ5 = React.useMemo(() => {
    if (!statistics) return [];
    return handleStatisticsFilteredByProduct(
      statistics,
      submarketFilterValue,
      "default1"
    );
  }, [statistics, submarketFilterValue, handleStatisticsFilteredByProduct]);

  const statisticConv = React.useMemo(() => {
    if (!statistics) return [];
    return handleStatisticsFilteredByProduct(
      statistics,
      submarketFilterValue,
      "default2"
    );
  }, [statistics, submarketFilterValue, handleStatisticsFilteredByProduct]);

  const statisticI0 = React.useMemo(() => {
    if (!statistics) return [];
    return handleStatisticsFilteredByProduct(
      statistics,
      submarketFilterValue,
      "default3"
    );
  }, [statistics, submarketFilterValue, handleStatisticsFilteredByProduct]);

  const statisticCQ1 = React.useMemo(() => {
    if (!statistics) return [];
    return handleStatisticsFilteredByProduct(
      statistics,
      submarketFilterValue,
      "default4"
    );
  }, [statistics, submarketFilterValue, handleStatisticsFilteredByProduct]);

  const statisticI1 = React.useMemo(() => {
    if (!statistics) return [];
    return handleStatisticsFilteredByProduct(
      statistics,
      submarketFilterValue,
      "default5"
    );
  }, [statistics, submarketFilterValue, handleStatisticsFilteredByProduct]);

  const statisticI5 = React.useMemo(() => {
    if (!statistics) return [];
    return handleStatisticsFilteredByProduct(
      statistics,
      submarketFilterValue,
      "default6"
    );
  }, [statistics, submarketFilterValue, handleStatisticsFilteredByProduct]);

  const statisticI8 = React.useMemo(() => {
    if (!statistics) return [];
    return handleStatisticsFilteredByProduct(
      statistics,
      submarketFilterValue,
      "default7"
    );
  }, [statistics, submarketFilterValue, handleStatisticsFilteredByProduct]);

  function handleMaximizeGraph(title: string) {
    if (maximizeGraph) {
      setGraphSelected("");
      setMaximizeGraph(false);
    } else {
      setGraphSelected(title);
      setMaximizeGraph(true);
    }
  }

  const handleChange = (event: SelectChangeEvent<typeof viewGraphics>) => {
    const {
      target: { value },
    } = event;
    if (value.length <= 4 && value.length > 0) {
      setViewGraphics(typeof value === "string" ? value.split(",") : value);
    }
    if (value.length > 1) {
      setMaximizeGraph(false);
      setGraphSelected("");
    }
    if (value.length === 1) {
      setMaximizeGraph(true);
    }
  };

  return (
    <Paper sx={{ p: 2, height: "100%" }}>
      <Box display="inline-flex" justifyContent="space-between" width="100%">
        <Typography
          width="max-content"
          fontWeight="bold"
          display="flex"
          alignItems="center"
        >
          Gráfico De Preços
        </Typography>
        <Box display="inline-flex" gap={3}>
          <Box>
            <FormControl variant="standard" sx={{ width: 200 }}>
              <InputLabel id="demo-multiple-checkbox-label">
                {t("Source Type")}
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={viewGraphics}
                onChange={handleChange}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
              >
                {productsSorted?.map((item) => (
                  <MenuItem key={item.id} value={item.name} sx={{ py: 0 }}>
                    <Checkbox checked={viewGraphics.indexOf(item.name) > -1} />
                    <ListItemText primary={item.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box minWidth={100}>
            <Autocomplete
              value={submarketFilterValue}
              onChange={(e, data) => {
                handleSelectSumarket(data);
              }}
              openOnFocus
              handleHomeEndKeys
              disablePortal
              disableClearable
              options={Object.keys(submarketColors)}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("Submarket")}
                  placeholder={t("All")}
                  InputLabelProps={{ shrink: true }}
                  variant="standard"
                />
              )}
            />
          </Box>
          <Box minWidth={100}>
            <Autocomplete
              value={rangeFilter}
              onChange={(e, data) => {
                setRangeFilter(data);
              }}
              openOnFocus
              handleHomeEndKeys
              disablePortal
              disableClearable
              options={Object.keys(range)}
              getOptionLabel={(option) => range[option]}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("Interval")}
                  placeholder={t("All")}
                  InputLabelProps={{ shrink: true }}
                  variant="standard"
                />
              )}
            />
          </Box>
        </Box>
      </Box>
      <Box
        width="100%"
        height="calc(100% - 52px)"
        display="flex"
        flexWrap="wrap"
        gap={1}
        justifyContent={maximizeGraph ? "center" : "space-between"}
        alignItems={maximizeGraph ? "center" : "initial"}
        py={5}
        pb={0}
        className="box-graphics"
      >
        {completeGraph(statisticConv, rangeFilter as "hour" | "day" | "week")
          .length > 1 && (
          <>
            {["Convencional", ""].includes(graphSelected) &&
              viewGraphics.includes("Conv") && (
                <TradingViewGraphic
                  title="Convencional"
                  handleMaximize={handleMaximizeGraph}
                  maximize={maximizeGraph}
                  statistics={completeGraph(
                    statisticConv,
                    rangeFilter as "hour" | "day" | "week"
                  )}
                />
              )}
          </>
        )}
        {completeGraph(statisticCQ5, rangeFilter as "hour" | "day" | "week")
          .length > 1 && (
          <>
            {["Cogeração Qualificada 50%", ""].includes(graphSelected) &&
              viewGraphics.includes("CQ5") && (
                <TradingViewGraphic
                  title="Cogeração Qualificada 50%"
                  handleMaximize={handleMaximizeGraph}
                  maximize={maximizeGraph}
                  statistics={completeGraph(
                    statisticCQ5,
                    rangeFilter as "hour" | "day" | "week"
                  )}
                />
              )}
          </>
        )}
        {completeGraph(statisticCQ1, rangeFilter as "hour" | "day" | "week")
          .length > 1 && (
          <>
            {["Cogeração Qualificada 100%", ""].includes(graphSelected) &&
              viewGraphics.includes("CQ1") && (
                <TradingViewGraphic
                  title="Cogeração Qualificada 100%"
                  handleMaximize={handleMaximizeGraph}
                  maximize={maximizeGraph}
                  statistics={completeGraph(
                    statisticCQ1,
                    rangeFilter as "hour" | "day" | "week"
                  )}
                />
              )}
          </>
        )}
        {completeGraph(statisticI0, rangeFilter as "hour" | "day" | "week")
          .length > 1 && (
          <>
            {["Incentivada 0%", ""].includes(graphSelected) &&
              viewGraphics.includes("I0") && (
                <TradingViewGraphic
                  title="Incentivada 0%"
                  handleMaximize={handleMaximizeGraph}
                  maximize={maximizeGraph}
                  statistics={completeGraph(
                    statisticI0,
                    rangeFilter as "hour" | "day" | "week"
                  )}
                />
              )}
          </>
        )}
        {completeGraph(statisticI5, rangeFilter as "hour" | "day" | "week")
          .length > 1 && (
          <>
            {["Incentivada 50%", ""].includes(graphSelected) &&
              viewGraphics.includes("I5") && (
                <TradingViewGraphic
                  title="Incentivada 50%"
                  handleMaximize={handleMaximizeGraph}
                  maximize={maximizeGraph}
                  statistics={completeGraph(
                    statisticI5,
                    rangeFilter as "hour" | "day" | "week"
                  )}
                />
              )}
          </>
        )}
        {completeGraph(statisticI8, rangeFilter as "hour" | "day" | "week")
          .length > 1 && (
          <>
            {["Incentivada 80%", ""].includes(graphSelected) &&
              viewGraphics.includes("I8") && (
                <TradingViewGraphic
                  title="Incentivada 80%"
                  handleMaximize={handleMaximizeGraph}
                  maximize={maximizeGraph}
                  statistics={completeGraph(
                    statisticI8,
                    rangeFilter as "hour" | "day" | "week"
                  )}
                />
              )}
          </>
        )}
        {completeGraph(statisticI1, rangeFilter as "hour" | "day" | "week")
          .length > 1 && (
          <>
            {["Incentivada 100%", ""].includes(graphSelected) &&
              viewGraphics.includes("I1") && (
                <TradingViewGraphic
                  title="Incentivada 100%"
                  handleMaximize={handleMaximizeGraph}
                  maximize={maximizeGraph}
                  statistics={completeGraph(
                    statisticI1,
                    rangeFilter as "hour" | "day" | "week"
                  )}
                />
              )}
          </>
        )}
      </Box>
    </Paper>
  );
}
