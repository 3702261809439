import { Observable, Subscription } from "rxjs";
import * as firestore from "firebase/firestore";
import {
  NotificationsData,
  options,
} from "../../services/GetNotificationsObservableService";
import {
  Notification,
  TradingLostLimitReached,
} from "../../entities/Notification";
import Dinero from "dinero.js";

export class NotificationsObservableImpl
  extends Observable<NotificationsData>
  implements NotificationsObservableImpl
{
  private firestoreListener: () => void = () => {};
  private options: options = {
    operatorId: 0,
  };
  private subscribers: {
    subscription: Subscription;
    next: ((value: NotificationsData) => void) | null | undefined;
  }[] = [];

  subscribeWithOptions(
    options: options,
    next?: ((value: NotificationsData) => void) | null | undefined,
    error?: ((error: any) => void) | null | undefined,
    complete?: (() => void) | null | undefined
  ): Subscription {
    this.setOptions(options);
    this.options = options;
    const subscription = this.subscribe(next, error, complete);
    this.subscribers.push({
      subscription,
      next,
    });

    subscription.add(() => {
      this.subscribers = this.subscribers.filter(
        (s) => s.subscription !== subscription
      );
      if (this.subscribers.length === 0) {
        this.firestoreListener();
      }
    });

    return subscription;
  }

  setOptions(options: options) {
    this.options = options;
    this.startFirestoreListener();
  }

  private startFirestoreListener() {
    const queryFilters: firestore.QueryConstraint[] = [
      firestore.where("operatorId", "==", this.options.operatorId),
      firestore.where("read", "==", false),
    ];

    const query = firestore.query(
      firestore.collection(
        firestore.getFirestore(),
        `notifications_notifications`
      ),
      ...queryFilters
    );

    this.firestoreListener = firestore.onSnapshot(query, async (snapshot) => {
      this.subscribers.forEach((subscriber) => {
        if (!subscriber.next) {
          return;
        }

        const notifications = snapshot.docs.map((doc) => {
          return this.firestoreDocToNotification(doc);
        });

        subscriber.next({
          notifications: notifications,
          unreadedNotificationsCounter: notifications.length,
        });
      });
    });
  }

  private firestoreDocToNotification(
    doc: firestore.QueryDocumentSnapshot<firestore.DocumentData>
  ): Notification {
    const docData = doc.data();

    if (docData.notificationType === "LOST_LIMIT") {
      return new TradingLostLimitReached(
        this.options.operatorId,
        docData.read,
        docData.createdAt.toDate(),
        docData.data.participantId,
        docData.data.participantName,
        Dinero({
          amount: docData.data.participantLostLimit,
        }),
        Dinero({
          amount: docData.data.lostReached,
        })
      );
    }

    throw new Error("unkown notification type");
  }
}
