import { useMutation } from "@tanstack/react-query";
import { refuseAssetRequest } from "../infra/api/BankInfra";

export function useSubmitRefuseAssetRequest(options?: {
  onSuccess?: () => void;
}) {
  const { mutate: submitRefuseAssetRequestMutate, ...rest } = useMutation(
    refuseAssetRequest,
    options
  );

  return { submitRefuseAssetRequestMutate, ...rest };
}
