import React from "react";

export function useCurrentTime() {
  const [currentTime, setCurrentTime] = React.useState(new Date());

  React.useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(() => new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return { currentTime };
}
