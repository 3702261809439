import React from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import {
  Alert as MuiAlert,
  Box,
  Button,
  InputAdornment,
  Paper,
  useMediaQuery,
  useTheme,
  CircularProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { FormTextField } from "../formFields/FormTextField";
import { getCurrencySymbol } from "../../utils/string";
import { getCustomNumberInputProps } from "../directBuyAndSell/BigInput";
import useConfirmationModal from "../../hooks/useConfirmationModal";
import { toFixedWithoutRounding } from "../../utils/number";
import { useSubmitUpdateTunnelLimits } from "./hooks/useSubmitUpdateTunnelLimits";
import { useGetTunnelLimits } from "./hooks/useGetTunnelLimits";

const Alert = styled(MuiAlert)(spacing);

const InfoBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24;
`;

interface Inputs {
  firstOperationMaxSpread: string;
  firstOperationMinSpread: string;
  operationMaxDeviation: string;
  operationMinDeviation: string;
}

const inputsDefaultValues: Inputs = {
  firstOperationMaxSpread: "",
  firstOperationMinSpread: "",
  operationMaxDeviation: "",
  operationMinDeviation: "",
};

export function TunnelLimitsUpdatePageContent() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  const { showConfirmationModal } = useConfirmationModal();

  const { control, handleSubmit, setValue } = useForm<Inputs>({
    defaultValues: inputsDefaultValues,
  });

  const {
    tunnelLimits,
    isLoading: isLoadingTunnelLimits,
    isError: isErrorTunnelLimits,
  } = useGetTunnelLimits();

  React.useEffect(() => {
    if (tunnelLimits) {
      setValue(
        "firstOperationMaxSpread",
        toFixedWithoutRounding(tunnelLimits.firstOperationMaxSpread / 100, 2)
      );
      setValue(
        "firstOperationMinSpread",
        toFixedWithoutRounding(tunnelLimits.firstOperationMinSpread / 100, 2)
      );
      setValue(
        "operationMaxDeviation",
        toFixedWithoutRounding(tunnelLimits.operationMaxDeviation, 2)
      );
      setValue(
        "operationMinDeviation",
        toFixedWithoutRounding(tunnelLimits.operationMinDeviation, 2)
      );
    }
  }, [tunnelLimits, setValue]);

  const {
    submitUpdateTunnelLimitsMutate,
    isLoading: isLoadingSubmitEnergyDeposit,
  } = useSubmitUpdateTunnelLimits({
    onSuccess: () => {
      enqueueSnackbar(t("Tunnel Limit updated successfully"), {
        variant: "success",
      });
    },
    onError: (error) => {
      if (error.statusCode === 500) {
        enqueueSnackbar(
          t(
            "Não foi possível realizar a operação, por favor tente novamente em alguns minutos"
          ),
          {
            variant: "error",
          }
        );
      } else {
        enqueueSnackbar(t(error.message), {
          variant: "error",
        });
      }
    },
  });

  const handleSubmitUpdateTunnelLimits = React.useCallback(
    ({
      firstOperationMaxSpread,
      firstOperationMinSpread,
      operationMaxDeviation,
      operationMinDeviation,
    }: Inputs) => {
      if (Number(firstOperationMinSpread) >= Number(firstOperationMaxSpread)) {
        enqueueSnackbar(
          t("The Maximum Value must be higher than the minimum"),
          {
            variant: "error",
          }
        );
        return;
      }

      showConfirmationModal({
        onSubmit: () => {
          submitUpdateTunnelLimitsMutate({
            firstOperationMaxSpread: Number(firstOperationMaxSpread),
            firstOperationMinSpread: Number(firstOperationMinSpread),
            operationMaxDeviation: Number(operationMaxDeviation),
            operationMinDeviation: Number(operationMinDeviation),
          });
        },
        message: (
          <>
            {t("Tem certeza que dezeja realizar esta alteração ?")}
            <br />
            <br />
          </>
        ),
        submitButtonColor: "primary",
      });
    },
    [enqueueSnackbar, showConfirmationModal, submitUpdateTunnelLimitsMutate, t]
  );

  const onSubmit = React.useCallback<SubmitHandler<Inputs>>(
    (data) => {
      handleSubmitUpdateTunnelLimits(data);
    },
    [handleSubmitUpdateTunnelLimits]
  );

  const onInvalidSubmit = React.useCallback<SubmitErrorHandler<Inputs>>(() => {
    enqueueSnackbar(t("You must fill in all required fields"), {
      variant: "error",
    });
  }, [enqueueSnackbar, t]);

  if (isErrorTunnelLimits) {
    return (
      <Alert mt={2} mb={3} severity="error">
        {t("Something went wrong.")}
      </Alert>
    );
  }

  if (isLoadingTunnelLimits || tunnelLimits === undefined) {
    return (
      <InfoBox>
        <CircularProgress />
      </InfoBox>
    );
  }

  return (
    <Paper sx={{ maxWidth: 1200, mx: "auto", p: isMdUp ? 12 : 8 }}>
      <Box sx={{ maxWidth: 460, mx: "auto" }}>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit, onInvalidSubmit)}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <FormTextField
            control={control}
            name={"firstOperationMaxSpread"}
            rules={{
              required: {
                value: true,
                message: t("This field is required"),
              },
              onChange: (e) => {
                if (e.target.value.split(".")[1]?.length > 2)
                  setValue(
                    "firstOperationMaxSpread",
                    toFixedWithoutRounding(e.target.value, 2)
                  );
              },
            }}
            label={t("Max Spread Value On First Offer")}
            fullWidth
            type="number"
            inputProps={{ step: "0.01", min: 0 }}
            InputProps={getCustomNumberInputProps({
              disableArrows: true,
              disableWheel: true,
              disableE: true,
              disableUpDownKeys: true,
              startAdornment: (
                <InputAdornment position="start">
                  {getCurrencySymbol("BRL", language)}
                </InputAdornment>
              ),
            })}
          />

          <FormTextField
            control={control}
            name={"firstOperationMinSpread"}
            rules={{
              required: {
                value: true,
                message: t("This field is required"),
              },
              onChange: (e) => {
                if (e.target.value.split(".")[1]?.length > 2)
                  setValue(
                    "firstOperationMinSpread",
                    toFixedWithoutRounding(e.target.value, 2)
                  );
              },
            }}
            label={t("Min Spread Value On First Offer")}
            fullWidth
            type="number"
            inputProps={{ step: "0.01", min: 0 }}
            InputProps={getCustomNumberInputProps({
              disableArrows: true,
              disableWheel: true,
              disableE: true,
              disableUpDownKeys: true,
              startAdornment: (
                <InputAdornment position="start">
                  {getCurrencySymbol("BRL", language)}
                </InputAdornment>
              ),
            })}
          />

          <FormTextField
            control={control}
            name={"operationMaxDeviation"}
            rules={{
              required: {
                value: true,
                message: t("This field is required"),
              },
              min: {
                value: 1,
                message: t("The minimum allowed value is {{value}}", {
                  value: "1%",
                }),
              },
              max: {
                value: 100,
                message: t("The maximum allowed value is {{value}}", {
                  value: "100%",
                }),
              },
              onChange: (e) => {
                if (e.target.value.split(".")[1]?.length > 3)
                  setValue(
                    "operationMaxDeviation",
                    toFixedWithoutRounding(e.target.value, 3)
                  );
              },
            }}
            label={t("Maximum Positive Deviation when Creating the Offer")}
            fullWidth
            type="number"
            inputProps={{ step: "0.001", min: 1 }}
            InputProps={getCustomNumberInputProps({
              disableArrows: true,
              disableWheel: true,
              disableE: true,
              disableUpDownKeys: true,
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            })}
            helperText={
              "O desvio positivo é calculado com base no valor da cotação da energia"
            }
          />

          <FormTextField
            control={control}
            name={"operationMinDeviation"}
            rules={{
              required: {
                value: true,
                message: t("This field is required"),
              },
              min: {
                value: 1,
                message: t("The minimum allowed value is {{value}}", {
                  value: "1%",
                }),
              },
              max: {
                value: 100,
                message: t("The maximum allowed value is {{value}}", {
                  value: "100%",
                }),
              },
              onChange: (e) => {
                if (e.target.value.split(".")[1]?.length > 3)
                  setValue(
                    "operationMinDeviation",
                    toFixedWithoutRounding(e.target.value, 3)
                  );
              },
            }}
            label={t("Maximum Negative Deviation when Creating the Offer")}
            fullWidth
            type="number"
            inputProps={{ step: "0.001", min: 1 }}
            InputProps={getCustomNumberInputProps({
              disableArrows: true,
              disableWheel: true,
              disableE: true,
              disableUpDownKeys: true,
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            })}
            helperText={
              "O desvio negativo é calculado com base no valor da cotação da energia"
            }
          />

          <Button
            type="submit"
            variant="contained"
            sx={{ minWidth: "16ch", mt: 8 }}
            disabled={isLoadingSubmitEnergyDeposit}
          >
            {t("Update")}
          </Button>
        </Box>
      </Box>
    </Paper>
  );
}
