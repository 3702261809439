import { useQuery } from "@tanstack/react-query";
import { getSubmarketOptions } from "../infra/SubmarketInfra";

export function useGetSubmarkets() {
  const { data: submarkets, ...rest } = useQuery(
    ["/submarkets"],
    getSubmarketOptions,
    {
      refetchOnWindowFocus: false,
    }
  );

  return { submarkets, ...rest };
}
