import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import {
  Alert as MuiAlert,
  Autocomplete,
  Box,
  Button,
  InputAdornment,
  Paper,
  useMediaQuery,
  useTheme,
  CircularProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { FileDownload } from "@mui/icons-material";
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
  useWatch,
} from "react-hook-form";
import { useSnackbar } from "notistack";
import { FormTextField } from "../formFields/FormTextField";
import { StyledTextField } from "../StyledTextField";
import useChooseParticipant from "../../hooks/useChooseParticipant";
import {
  formatCNPJ,
  toLocaleCurrencyStringWithoutSymbol,
} from "../../utils/string";
import { getCustomNumberInputProps } from "../directBuyAndSell/BigInput";
import { useGetDepositMethods } from "./hooks/useGetDepositMethods";
import useConfirmationModal from "../../hooks/useConfirmationModal";
import { toFixedWithoutRounding } from "../../utils/number";
import { getCurrencySymbol, toLocaleCurrencyString } from "../../utils/string";
import { useNavigate } from "react-router-dom";
import { useSubmitFinancialDeposit } from "./hooks/useSubmitFinancialDeposit";

const Alert = styled(MuiAlert)(spacing);

const InfoBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24;
`;

interface Inputs {
  depositMethod: string | null;
  value: string;
}

const inputsDefaultValues: Inputs = {
  depositMethod: null,
  value: "",
};

export function FinancialDepositPageContent() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  const { showConfirmationModal } = useConfirmationModal();

  const { currentParticipant } = useChooseParticipant();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: inputsDefaultValues,
  });

  const selectedDepositMethod = useWatch({ control, name: "depositMethod" });

  const {
    depositMethods,
    isLoading: isLoadingDepositMethods,
    isError: isErrorDepositMethods,
  } = useGetDepositMethods();

  const {
    submitFinancialDepositMutate,
    isLoading: isLoadingSubmitFinancialDeposit,
    error,
  } = useSubmitFinancialDeposit({
    onSuccess: () => {
      navigate("/bank/manage-deposits-and-withdrawals");
    },
  });

  useEffect(() => {
    if (error) {
      if ((error as any).message.startsWith("Deposit limit reached")) {
        const limit = parseInt((error as any).value);

        enqueueSnackbar(
          t(
            "Deposit limit reached, maximum deposit value is {{ maxDepositValue }}",
            {
              maxDepositValue: toLocaleCurrencyStringWithoutSymbol(
                Math.max(0, limit),
                "BRL",
                language
              ),
            }
          ),
          {
            variant: "error",
          }
        );

        return;
      } else if (
        (error as any).message.startsWith("User does not have a limit set")
      ) {
        enqueueSnackbar(t("User does not have a limit set"), {
          variant: "error",
        });

        return;
      }
    }
  }, [enqueueSnackbar, error, language, t]);

  const handleSubmitFinancialDeposit = React.useCallback(
    ({ depositMethod, value }: Inputs) => {
      if (depositMethod === null) {
        enqueueSnackbar(t("Something went wrong."), {
          variant: "error",
        });
        return;
      }

      showConfirmationModal({
        onSubmit: () => {
          submitFinancialDepositMutate({
            depositMethod: depositMethod,
            value: Number(value),
          });
        },
        message: (
          <>
            {t("Confira as informações de sua solicitação de depósito.")}
            <br />
            <br />
            {t('Em conformidade com os dados, basta clicar em "Confirmar".')}
            <br />
            <br />
            {t(
              'Em seguida, a solicitação poderá ser acompanhada na página "Gerenciamento de Depósitos e Saques". Após a análise, o valor depositado será creditado na sua carteira.'
            )}
          </>
        ),
        submitButtonColor: "primary",
      });
    },
    [enqueueSnackbar, showConfirmationModal, submitFinancialDepositMutate, t]
  );

  const onSubmit = React.useCallback<SubmitHandler<Inputs>>(
    (data) => {
      handleSubmitFinancialDeposit(data);
    },
    [handleSubmitFinancialDeposit]
  );

  const onInvalidSubmit = React.useCallback<SubmitErrorHandler<Inputs>>(() => {
    enqueueSnackbar(t("You must fill in all required fields"), {
      variant: "error",
    });
  }, [enqueueSnackbar, t]);

  if (isErrorDepositMethods) {
    return (
      <Alert mt={2} mb={3} severity="error">
        {t("Something went wrong.")}
      </Alert>
    );
  }

  if (isLoadingDepositMethods || depositMethods === undefined) {
    return (
      <InfoBox>
        <CircularProgress />
      </InfoBox>
    );
  }

  return (
    <Paper sx={{ maxWidth: 1200, mx: "auto", p: isMdUp ? 12 : 8 }}>
      <Box sx={{ maxWidth: 460, mx: "auto" }}>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit, onInvalidSubmit)}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Controller
            control={control}
            name={"depositMethod"}
            rules={{
              required: {
                value: true,
                message: t("This field is required"),
              },
            }}
            render={({ field: { ref, onChange, ...field } }) => (
              <Autocomplete
                {...field}
                onChange={(e, newValue) => {
                  onChange(newValue);
                }}
                openOnFocus
                handleHomeEndKeys
                disablePortal
                options={depositMethods.map((item) => item.id)}
                getOptionLabel={(option) =>
                  depositMethods.find((item) => item.id === option)?.name ??
                  option
                }
                fullWidth
                renderInput={(params) => (
                  <StyledTextField
                    {...params}
                    inputRef={ref}
                    label={t("Payment Method")}
                    error={!!errors.depositMethod}
                    helperText={errors.depositMethod?.message ?? " "}
                    // size="small"
                  />
                )}
              />
            )}
          />

          <StyledTextField
            label={t("CNPJ")}
            fullWidth
            value={formatCNPJ(currentParticipant?.companyCnpj ?? "")}
            InputProps={{ readOnly: true }}
            helperText=" "
            // size="small"
          />

          <FormTextField
            control={control}
            name={"value"}
            rules={{
              required: {
                value: true,
                message: t("This field is required"),
              },
              min: {
                value: 100,
                message: t("The minimum allowed value is {{value}}", {
                  value: toLocaleCurrencyString(100, "BRL", language),
                }),
              },
              max: {
                value: 999999999,
                message: t("The maximum allowed value is {{value}}", {
                  value: toLocaleCurrencyString(999999999, "BRL", language),
                }),
              },
              onChange: (e) => {
                if (e.target.value.split(".")[1]?.length > 2)
                  setValue("value", toFixedWithoutRounding(e.target.value, 2));
              },
            }}
            label={t("Deposit Value")}
            fullWidth
            type="number"
            inputProps={{ step: "0.01", min: 0 }}
            InputProps={getCustomNumberInputProps({
              disableArrows: true,
              disableWheel: true,
              disableE: true,
              disableUpDownKeys: true,
              startAdornment: (
                <InputAdornment position="start">
                  {getCurrencySymbol("BRL", language)}
                </InputAdornment>
              ),
            })}
            helperText={`${t("Min")} ${toLocaleCurrencyString(
              100,
              "BRL",
              language
            )}`}
            // size="small"
          />

          {selectedDepositMethod && (
            <Box
              sx={(theme) => ({
                backgroundColor:
                  theme.palette.mode === "dark" ? "#293139" : "#deeaf5",
                border: "1px solid #FFFFFF",
                py: 3,
                px: 4,
                borderRadius: 1,
                width: "100%",
                mt: 4,
              })}
              dangerouslySetInnerHTML={{
                __html:
                  depositMethods.find(
                    (item) => item.id === selectedDepositMethod
                  )?.methodInfo ?? "",
              }}
            />
          )}

          <Button
            type="submit"
            variant="contained"
            sx={{ minWidth: "16ch", mt: 8 }}
            disabled={isLoadingSubmitFinancialDeposit}
          >
            <FileDownload sx={{ marginRight: 2 }} /> {t("Deposit")}
          </Button>
        </Box>
      </Box>
    </Paper>
  );
}
