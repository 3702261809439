import { Observable, Subscription } from "rxjs";
import {
  panelData,
  MyOrdersObservable,
} from "../../services/GetMyOrdersObservableService";
import * as firestore from "firebase/firestore";
import type { options } from "../../services/GetMyOrdersObservableService";
import {
  OfferResume,
  OrderResume,
} from "../../entities/data-objects/OrderResume";

export class MyOrdersObservableImpl
  extends Observable<panelData>
  implements MyOrdersObservable
{
  private firestoreListener: () => void = () => {};
  private options: options = {
    page: 1,
    itemsPerPage: 20,
    participant: -1,
    product: null,
    submarket: null,
    // onlyMyOffers: false,
    // showFinishedOffers: false,
    // onlyOffersWithMyBids: false,
  };
  private subscribers: {
    subscription: Subscription;
    next: ((value: panelData) => void) | null | undefined;
  }[] = [];

  private pageStartBaseElements: {
    [key: number]:
      | firestore.QueryDocumentSnapshot<firestore.DocumentData>
      | number;
  } = {
    1: 0,
  };

  subscribeWithOptions(
    options: options,
    next?: ((value: panelData) => void) | null | undefined,
    error?: ((error: any) => void) | null | undefined,
    complete?: (() => void) | null | undefined
  ): Subscription {
    this.setOptions(options);
    this.options = options;
    const subscription = this.subscribe(next, error, complete);
    this.subscribers.push({
      subscription,
      next,
    });

    subscription.add(() => {
      this.subscribers = this.subscribers.filter(
        (s) => s.subscription !== subscription
      );
      if (this.subscribers.length === 0) {
        this.firestoreListener();
      }
    });

    return subscription;
  }

  setOptions(options: options) {
    this.options = options;
    this.startFirestoreListener();
  }

  private startFirestoreListener() {
    this.firestoreListener();

    const queryFilters: firestore.QueryConstraint[] = [
      firestore.where("market", "==", "SPOT"),
      firestore.where("participantId", "==", this.options.participant),
    ];

    const offerStatusToShow = ["ABERTA", "PARCIALMENTE ENCERRADA"];

    // if (this.options.showFinishedOffers) {
    //   offerStatusToShow.push("ENCERRADA");
    //   offerStatusToShow.push("CANCELADA");
    // }

    // if (!this.options.showFinishedOffers) {
    //   queryFilters.push(firestore.where("expired", "==", false));
    // }

    if (this.options.product !== null) {
      queryFilters.push(
        firestore.where("product.name", "==", this.options.product)
      );
    }

    if (this.options.submarket !== null) {
      queryFilters.push(
        firestore.where("submarket", "==", this.options.submarket)
      );
    }

    // if (this.options.onlyMyOffers) {
    //   queryFilters.push(
    //     firestore.where("participantId", "==", this.options.participant)
    //   );
    // }

    // if (this.options.onlyOffersWithMyBids) {
    //   queryFilters.push(
    //     firestore.where(
    //       "participantsWithBids",
    //       "array-contains",
    //       this.options.participant.toString()
    //     )
    //   );
    // }

    queryFilters.push(firestore.where("status", "in", offerStatusToShow));

    const query = firestore.query(
      firestore.collection(firestore.getFirestore(), "operation"),
      firestore.orderBy("createdAt", "desc"),
      ...queryFilters
    );

    const queryWithLimits = firestore.query(
      firestore.collection(firestore.getFirestore(), "operation"),
      firestore.orderBy("createdAt", "desc"),
      ...(this.pageStartBaseElements[this.options.page]
        ? [firestore.startAfter(this.pageStartBaseElements[this.options.page])]
        : []),
      firestore.limit(this.options.itemsPerPage * 3), // heurística, pega sempre 3x mais que o necessário p/ filtrar as expiradas, uma melhoria pro futuro seria adicionar uma flag expired: true nas operações e de tempos em tempos atualizar essa flag para dar mais precisão na contagem das páginas seguintes
      ...queryFilters
    );

    this.firestoreListener = firestore.onSnapshot(
      queryWithLimits,
      async (snapshot) => {
        const snapshotToCount = await firestore.getCountFromServer(query);
        const totalItems = snapshotToCount.data().count;

        this.subscribers.forEach((subscriber) => {
          if (!subscriber.next) {
            return;
          }

          const docsToConsider = snapshot.docs
            .filter(
              (operation) =>
                operation.data().offerCloseDateTime.toDate().getTime() >
                new Date().getTime()
            )
            .slice(0, this.options.itemsPerPage);

          const resume = docsToConsider.map((doc) => {
            return this.firestoreDocToOperationResume(
              doc,
              this.options.participant
            );
          });

          const expiredCounter =
            docsToConsider.length > 0 // considera apenas a primeira página
              ? snapshot.docs.findIndex(
                  (doc) =>
                    doc.id === docsToConsider[docsToConsider.length - 1].id
                ) -
                this.options.itemsPerPage +
                1
              : 0;

          const expiredCounterTotal =
            snapshot.docs.length -
            snapshot.docs.filter(
              (
                operation // filtro de operações expiradas na aplicação por limitação do firestore
              ) =>
                operation.data().offerCloseDateTime.toDate().getTime() >
                new Date().getTime()
            ).length;

          this.pageStartBaseElements[this.options.page + 1] =
            snapshot.docs[docsToConsider.length - 1 + expiredCounter];

          subscriber.next({
            page: 1,
            orders: resume,
            totalItems: totalItems - expiredCounterTotal,
          });
        });
      }
    );
  }

  private firestoreDocToOperationResume(
    doc: firestore.QueryDocumentSnapshot<firestore.DocumentData>,
    participantInterested: number
  ): OrderResume {
    const docData = doc.data();

    const participantBuyOfferBid = docData.participantsBidsBuyOffer
      ? docData.participantsBidsBuyOffer.find(
          (bid: any) => bid.participantId === participantInterested
        )
      : null;

    const participantSellOfferBid = docData.participantsBidsSellOffer
      ? docData.participantsBidsSellOffer.find(
          (bid: any) => bid.participantId === participantInterested
        )
      : null;

    const orderResume = new OrderResume(
      doc.id,
      docData.participantId,
      docData.participantId === participantInterested,
      docData.product.name,
      docData.product.measurementUnit,
      docData.submarket,
      docData.buyOffer !== null
        ? new OfferResume(
            `${doc.id}b`,
            doc.id,
            docData.buyOffer.volume,
            docData.buyOffer.remainingVolumeToBeNegotiated,
            docData.buyOffer.priceType !== "Fixo"
              ? docData.buyOffer.priceValue ?? null
              : null,
            this.getOfferBestBid(docData.participantsBidsBuyOffer, "BUY")
              ? this.getOfferBestBid(docData.participantsBidsBuyOffer, "BUY")!
                  .value -
                (docData.buyOffer.priceType !== "Fixo"
                  ? docData.buyOffer.pldValue / 100
                  : 0)
              : null,
            this.getOfferBestBid(docData.participantsBidsBuyOffer, "BUY")
              ? this.getOfferBestBid(docData.participantsBidsBuyOffer, "BUY")!
                  .amount
              : null,
            docData.buyOffer.remainingVolumeToBeNegotiated -
              (participantBuyOfferBid ? participantBuyOfferBid.amount : 0), // toDo -> realizar calculo baseado no melhor lance do participante (quando trazer esses dados de lance p/ operação)
            docData.buyOffer.priceType !== "Fixo"
              ? docData.buyOffer.priceValue - docData.buyOffer.pldValue / 100
              : null,
            docData.buyOffer.priceType !== "Fixo",
            null,
            docData.buyOffer.status,
            docData.buyOffer.offerType
          )
        : null,
      docData.sellOffer !== null
        ? new OfferResume(
            `${doc.id}s`,
            doc.id,
            docData.sellOffer.volume,
            docData.sellOffer.remainingVolumeToBeNegotiated,
            docData.sellOffer.priceType !== "Fixo"
              ? docData.sellOffer.priceValue ?? null
              : null,
            this.getOfferBestBid(docData.participantsBidsSellOffer, "SELL")
              ? this.getOfferBestBid(docData.participantsBidsSellOffer, "SELL")!
                  .value -
                (docData.sellOffer.priceType !== "Fixo"
                  ? docData.sellOffer.pldValue / 100
                  : 0)
              : null,
            this.getOfferBestBid(docData.participantsBidsSellOffer, "SELL")
              ? this.getOfferBestBid(docData.participantsBidsSellOffer, "SELL")!
                  .amount
              : null,
            docData.sellOffer.remainingVolumeToBeNegotiated -
              (participantSellOfferBid ? participantSellOfferBid.amount : 0), // toDo -> realizar calculo baseado no melhor lance do participante (quando trazer esses dados de lance p/ operação)
            docData.sellOffer.priceType !== "Fixo"
              ? docData.sellOffer.priceValue - docData.sellOffer.pldValue / 100
              : null,
            docData.sellOffer.priceType !== "Fixo",
            null,
            docData.sellOffer.status,
            docData.sellOffer.offerType
          )
        : null,
      docData.createdAt.toDate(),
      docData.offerCloseDateTime.toDate(),
      docData.status,
      new Date(docData.deliveryPeriodStart),
      new Date(docData.deliveryPeriodEnd),
      docData.reTusd,
      docData.participantClassName
    );

    return orderResume;
  }

  private getOfferBestBid(
    participantsBidsBuyOffer: {
      amount: number;
      value: 11;
    }[],
    type: "BUY" | "SELL"
  ) {
    if (participantsBidsBuyOffer.length === 0) {
      return null;
    }
    return participantsBidsBuyOffer.sort((a, b) =>
      type === "BUY" ? a.value - b.value : b.value - a.value
    )[0];
  }
}
