import React, { useRef, useState } from "react";
import styled from "@emotion/styled";

import {
  Badge,
  Box,
  IconButton,
  Popover as MuiPopover,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Briefcase } from "react-feather";
import { useTranslation } from "react-i18next";
import useChooseParticipant from "../../hooks/useChooseParticipant";
import useAuth from "../../hooks/useAuth";
import ChooseParticipantComponent from "../auth/ChooseParticipant";

const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 300px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const MessageHeader = styled(Box)`
  text-align: center;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
`;

function ChooseParticipantDropdown() {
  const { t } = useTranslation();

  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  const { user } = useAuth();

  const {
    participantList,
    currentParticipant,
    isChooseParticipantDataNew,
    setIsChooseParticipantDataNew,
  } = useChooseParticipant();

  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
    setIsChooseParticipantDataNew(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (!(user?.role === "user")) {
    return <></>;
  }

  return (
    <React.Fragment>
      <Box
        display="inline-flex"
        alignItems="center"
        justifyContent="center"
        sx={{ verticalAlign: "middle" }}
      >
        {currentParticipant && isMdUp && (
          <Typography>
            {`${t("Participant")}: `}
            {`${currentParticipant.companyTradeName} - ${currentParticipant.companyCnpj}`}
          </Typography>
        )}

        <Tooltip title={t("Select Participant")}>
          <IconButton
            color="inherit"
            ref={ref}
            onClick={handleOpen}
            size="large"
          >
            <Badge
              badgeContent={
                isChooseParticipantDataNew &&
                participantList &&
                participantList.length > 1
                  ? participantList.length
                  : undefined
              }
              color="primary"
            >
              <Briefcase />
            </Badge>
          </IconButton>
        </Tooltip>

        <Popover
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          anchorEl={ref.current}
          onClose={handleClose}
          open={isOpen}
        >
          <MessageHeader p={2}>
            <Typography variant="subtitle1" color="textPrimary">
              {t("Select Participant")}
            </Typography>
          </MessageHeader>

          <React.Fragment>
            <ChooseParticipantComponent />
          </React.Fragment>
        </Popover>
      </Box>
    </React.Fragment>
  );
}

export default ChooseParticipantDropdown;
