import React from "react";
import {
  GetNotificationsObservableService,
  options,
} from "../services/GetNotificationsObservableService";
import { Notification } from "../entities/Notification";
import useChooseParticipant from "../../../../hooks/useChooseParticipant";
import useAuth from "../../../../hooks/useAuth";

const getNotificationsObservableService =
  new GetNotificationsObservableService();

export function useGetNotifications() {
  const { currentParticipant } = useChooseParticipant();
  const { externalId } = useAuth();

  const [notifications, setNotifications] = React.useState<Array<Notification>>(
    []
  );
  const [unreadedNotifications, setUnreadedNotifications] =
    React.useState<number>(0);

  React.useEffect(() => {
    const options: options = {
      operatorId: externalId || 0,
    };

    const subscription = getNotificationsObservableService
      .getNotificationsObservable()
      .subscribeWithOptions(options, (notifications) => {
        setNotifications(notifications.notifications);
        setUnreadedNotifications(notifications.unreadedNotificationsCounter);
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [currentParticipant, externalId]);

  return { notifications, unreadedNotifications };
}
