import React, { useMemo } from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import {
  Alert as MuiAlert,
  Box,
  Button,
  Divider,
  Grid,
  TableCell,
  TableRow,
  useMediaQuery,
  useTheme,
  CircularProgress,
  Typography,
  Avatar,
  Tooltip,
  TooltipProps,
  tooltipClasses,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
} from "@mui/material";
import {
  getCurrencySymbol,
  toLocaleCurrencyStringWithoutSymbol,
} from "../../utils/string";
import { useTranslation } from "react-i18next";
import { useGetBalanceData } from "./hooks/useGetBalanceData";
import { ResumeBalance } from "./ResumeBalance";
import { HeadCell, BalanceTable } from "./BalanceTable";
import { useGetAssetsBlocked } from "./hooks/useGetAssetsBlocked";
import { toFixedDecimalPlaces } from "../../utils/number";
import { Link, useNavigate } from "react-router-dom";
import { useGetPricesTable } from "./hooks/useGetPricesTable";
import { enqueueSnackbar } from "notistack";
import { useGetParticipantProducts } from "../products/hooks/useGetParticipantProducts";
import useChooseParticipant from "../../hooks/useChooseParticipant";
import * as firestore from "firebase/firestore";
import { useFirestoreQuery } from "../../hooks/useFirestoreQuery";
import { InfoRounded } from "@mui/icons-material";
import { submarketColors } from "../../constants";

const Alert = styled(MuiAlert)(spacing);

const InfoBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24;
`;

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#233044",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    minWidth: "350px",
  },
}));

export function MyWalletPageContent() {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { participantId } = useChooseParticipant();

  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("sm"));
  const priceTable = useGetPricesTable();
  const navigate = useNavigate();

  const [order, setOrder] = React.useState<"asc" | "desc">("desc");
  const [orderBy, setOrderBy] = React.useState("createdAt");

  const {
    balanceData,
    isLoading: isLoadingBalanceData,
    isError: isErrorBalanceData,
  } = useGetBalanceData();

  const {
    data: assetsBlocked,
    isLoading: isLoadingAssetsBlocked,
    isError: isErrorAssetsBlocked,
  } = useGetAssetsBlocked();

  const {
    participantProducts,
    isLoading: isLoadingParticipantProducts,
    isError: isErrorParticipantProducts,
  } = useGetParticipantProducts();

  const balanceCurrencyData = React.useMemo(() => {
    return (
      balanceData?.assetBalances.filter((item) => {
        return item.asset.type === "currency";
      }) ?? []
    );
  }, [balanceData]);

  const tableResumeEnergyBody = React.useMemo(() => {
    const array: Array<{
      name: string;
      submarkets: { S: number; "SE/CO": number; N: number; NE: number };
    }> = [];
    if (!balanceData || !balanceData.assetBalances?.length) return [];
    const objeto =
      balanceData.assetBalances
        ?.filter((item) => item.asset.type === "energy" && item.balance > 0)
        ?.reduce((acc, curr) => {
          return {
            ...acc,
            [curr.asset.code]: {
              S:
                (curr as any).submarket === "S"
                  ? curr.balance
                  : acc[curr.asset.code]?.S
                  ? acc[curr.asset.code]?.S
                  : 0,
              "SE/CO":
                (curr as any).submarket === "SE/CO"
                  ? curr.balance
                  : acc[curr.asset.code]?.["SE/CO"]
                  ? acc[curr.asset.code]?.["SE/CO"]
                  : 0,
              N:
                (curr as any).submarket === "N"
                  ? curr.balance
                  : acc[curr.asset.code]?.N
                  ? acc[curr.asset.code]?.N
                  : 0,
              NE:
                (curr as any).submarket === "NE"
                  ? curr.balance
                  : acc[curr.asset.code]?.NE
                  ? acc[curr.asset.code]?.NE
                  : 0,
            },
          };
        }, [] as any) ?? [];

    for (const chave in objeto) {
      array.push({
        name: chave,
        submarkets: objeto[chave],
      });
    }
    return array;
  }, [balanceData]);

  const groupedBalances = useMemo(() => {
    if (!balanceData) {
      return {
        assetBalances: [],
      };
    }
    const reducedBalances = balanceData?.assetBalances.reduce(
      (acumulator, current) => {
        if (acumulator[current.asset.code]) {
          acumulator[current.asset.code].balance += current.balance;
          acumulator[current.asset.code].blockedBalance +=
            current.blockedBalance;
        } else {
          acumulator[current.asset.code] = {
            asset: {
              code: current.asset.code,
              name: current.asset.name,
              unit: current.asset.unit,
              type: current.asset.type,
              precision: current.asset.precision,
              avatar: current.asset.avatar,
            },
            balance: current.balance,
            blockedBalance: current.blockedBalance,
          };
        }
        return acumulator;
      },
      {} as any
    );

    return {
      assetBalances: Object.keys(reducedBalances).map((key) => ({
        asset: {
          code: reducedBalances[key].asset.code,
          name: reducedBalances[key].asset.name,
          unit: reducedBalances[key].asset.unit,
          type: reducedBalances[key].asset.type,
          precision: reducedBalances[key].asset.precision,
          avatar: reducedBalances[key].asset.avatar,
        },
        balance: reducedBalances[key].balance,
        blockedBalance: reducedBalances[key].blockedBalance,
      })),
    };
  }, [balanceData]);

  const balanceEnergyData = React.useMemo(() => {
    return (
      groupedBalances?.assetBalances
        .filter((item) => {
          return item.asset.type === "energy";
        })
        .sort((a, b) => {
          const assetsInOrdersA =
            assetsBlocked?.find((item) => item.assetCode === a.asset.code)
              ?.value ?? 0;
          const assetsInOrdersB =
            assetsBlocked?.find((item) => item.assetCode === b.asset.code)
              ?.value ?? 0;

          return a.balance + assetsInOrdersA < b.balance + assetsInOrdersB
            ? 1
            : a.balance + assetsInOrdersA > b.balance + assetsInOrdersB
            ? -1
            : 0;
        }) ?? []
    );
  }, [groupedBalances, assetsBlocked]);

  const totalAvaliableBalance = React.useMemo(() => {
    if (!balanceData?.assetBalances.length || !priceTable) {
      return { currency: 0, energy: 0 };
    }
    return (
      balanceData?.assetBalances.reduce(
        (acc, curr) => {
          const assetsInOrders =
            assetsBlocked?.find(
              (item) =>
                item.assetCode === curr.asset.code &&
                (item as any).submarket === (curr as any).submarket
            )?.value ?? 0;

          const balance =
            curr.balance / 10 ** curr.asset.precision + assetsInOrders;

          const currencyPrice = priceTable.getAssetPrice(
            curr.asset.code,
            (curr as any).submarket
          );

          return {
            ...acc,
            [curr.asset.type]:
              currencyPrice && currencyPrice !== 0
                ? (acc[curr.asset.type] ?? 0) + balance * currencyPrice
                : null,
          };
        },
        {
          currency: 0,
          energy: null,
        } as Record<string, number | null>
      ) ?? {}
    );
  }, [balanceData?.assetBalances, assetsBlocked, priceTable]);

  const totalValueByAsset = React.useMemo(() => {
    if (!balanceData?.assetBalances.length || !priceTable) {
      return {};
    }

    const values = balanceData?.assetBalances.reduce((acc, curr) => {
      const assetsInOrders =
        assetsBlocked?.find(
          (item) =>
            item.assetCode === curr.asset.code &&
            (item as any).submarket === (curr as any).submarket
        )?.value ?? 0;

      const balance =
        curr.balance / 10 ** curr.asset.precision + assetsInOrders;

      const currencyPrice = priceTable.getAssetPrice(
        curr.asset.code,
        (curr as any).submarket
      );

      return {
        ...acc,
        [curr.asset.code]:
          currencyPrice && currencyPrice !== 0
            ? (acc[curr.asset.code] ?? 0) + balance * currencyPrice
            : null,
      };
    }, {} as Record<string, number | null>);
    return values;
  }, [assetsBlocked, balanceData?.assetBalances, priceTable]);

  function handleWithdrawal(balance: number, url: string) {
    if (balance === 0) {
      enqueueSnackbar(t("Insufficient funds"), {
        variant: "error",
      });
      return;
    }
    navigate(url);
  }

  function handleBalaceBySubmarket(value: number) {
    if (value === 0) return "-";
    return toFixedDecimalPlaces(value, 3)?.toLocaleString(language, {
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    });
  }

  const tableResumeEnergyHead = [
    {
      name: "S",
      color: submarketColors["S"],
    },
    {
      name: "SE/CO",
      color: submarketColors["SE/CO"],
    },
    {
      name: "NE",
      color: submarketColors["NE"],
    },
    {
      name: "N",
      color: submarketColors["N"],
    },
  ];

  const headCells = React.useMemo<Array<HeadCell>>(
    () => [
      {
        id: "coin",
        alignment: "left",
        label: "Moeda",
        disableSort: true,
      },
      {
        id: "total",
        alignment: "left",
        label: "Total",
        disableSort: true,
      },
      {
        id: "available",
        alignment: "left",
        label: "Disponível",
        disableSort: true,
      },
      {
        id: "inOrders",
        alignment: "left",
        label: "Em Ordens",
        disableSort: true,
      },
      {
        id: "amount",
        alignment: "left",
        label: "Valor Total",
        disableSort: true,
      },
      {
        id: "action",
        alignment: "left",
        label: "Ação",
        disableSort: true,
      },
    ],
    []
  );

  if (
    isErrorBalanceData ||
    isErrorAssetsBlocked ||
    isErrorParticipantProducts
  ) {
    return (
      <Alert mt={2} mb={3} severity="error">
        {t("Something went wrong.")}
      </Alert>
    );
  }

  if (
    isLoadingBalanceData ||
    balanceData === undefined ||
    isLoadingAssetsBlocked ||
    isLoadingParticipantProducts
  ) {
    return (
      <InfoBox>
        <CircularProgress />
      </InfoBox>
    );
  }

  return (
    <Grid container spacing={10}>
      <Grid
        item
        xs={12}
        display="inline-flex"
        flexDirection={isMdUp ? "row" : "column"}
        gap={!isMdUp ? 5 : 0}
        alignItems="center"
        justifyContent="space-around"
        width="100%"
      >
        <ResumeBalance
          title={t("Estimated Total Balance (Fiat + Spot)")}
          value={
            totalAvaliableBalance.energy === null
              ? null
              : (totalAvaliableBalance.currency || 0) +
                totalAvaliableBalance.energy
          }
          color="default"
        />
        <ResumeBalance
          title={t("Spot Balance (Energy Balance)")}
          value={totalAvaliableBalance.energy}
          color="secondary"
        />
        <ResumeBalance
          title={t("Fiat Balance (Cash balance)")}
          value={totalAvaliableBalance.currency || 0}
          color="secondary"
        />
      </Grid>
      <Grid item xs={12}>
        <Divider orientation="horizontal" sx={{ borderWidth: 1, mx: "auto" }} />
      </Grid>
      <Grid item xs={12}>
        <BalanceTable
          title={t("Fiat Balance (Cash balance)")}
          data={balanceCurrencyData}
          headCells={headCells}
          avoidEmptyRows
          orderingOptions={{
            orderBy,
            order,
            handleRequestSort: (e, property) => {
              setOrderBy((oldOrderBy) => {
                if (oldOrderBy === property) {
                  setOrder((oldOrder) => (oldOrder === "asc" ? "desc" : "asc"));
                } else {
                  setOrder("desc");
                }
                return property;
              });
            },
          }}
          keyExtractor={(row, index) => String(index)}
          renderRow={(row) => {
            const assetsInOrders =
              assetsBlocked?.find((item) => item.assetCode === row.asset.code)
                ?.value ?? 0;

            const assetsTotal =
              row.balance + assetsInOrders * 10 ** row.asset.precision;

            return (
              <TableRow hover tabIndex={-1}>
                <TableCell align="left">
                  <Box display="inline-flex" alignItems="center" gap={3}>
                    <Avatar
                      alt="Remy Sharp"
                      src={`data:image/svg+xml;base64,${row.asset.avatar}`}
                    />
                    <Box>
                      <Typography fontWeight="bold">
                        {row.asset.unit}
                      </Typography>
                      <Typography color="#CDCDD3">{row.asset.name}</Typography>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell align="left">
                  {toLocaleCurrencyStringWithoutSymbol(
                    Number(toFixedDecimalPlaces(assetsTotal, 2)),
                    "BRL",
                    language
                  )}
                </TableCell>
                <TableCell align="left">
                  {toLocaleCurrencyStringWithoutSymbol(
                    Number(
                      toFixedDecimalPlaces(row.balance, row.asset.precision)
                    ),
                    "BRL",
                    language
                  )}
                </TableCell>
                <TableCell align="left">
                  {toLocaleCurrencyStringWithoutSymbol(
                    Number(toFixedDecimalPlaces(assetsInOrders, 0)),
                    "BRL",
                    language
                  )}
                </TableCell>
                <TableCell align="left">
                  <Box display="inline-flex" alignItems="center" gap={2}>
                    <Box display="inline-flex" flexDirection="column" gap={1}>
                      <Typography fontSize={22} color="#DAE4FF" lineHeight={0}>
                        ~
                      </Typography>
                      <Typography fontSize={22} color="#DAE4FF" lineHeight={0}>
                        ~
                      </Typography>
                    </Box>
                    <Typography
                      fontWeight="bold"
                      fontSize="0.875rem"
                      color="#DAE4FF"
                    >
                      {getCurrencySymbol("BRL", language)}{" "}
                      {toLocaleCurrencyStringWithoutSymbol(
                        Number(toFixedDecimalPlaces(assetsTotal, 2)),
                        "BRL",
                        language
                      )}
                    </Typography>
                  </Box>
                </TableCell>

                <TableCell padding="none" align="left" width="400px">
                  <Box
                    whiteSpace="nowrap"
                    width="100%"
                    display="inline-flex"
                    gap={8}
                  >
                    <Button
                      component={Link}
                      variant="text"
                      sx={{ color: "#FFC700" }}
                      to="/bank/financial-deposit"
                    >
                      {t("Deposit")}
                    </Button>

                    <Button
                      variant="text"
                      sx={{ color: "#FFC700" }}
                      onClick={() =>
                        handleWithdrawal(
                          row.balance ?? 0,
                          "/bank/financial-withdrawal"
                        )
                      }
                    >
                      {t("withdrawal")}
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            );
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <BalanceTable
          title={t("Spot Balance (Energy Balance)")}
          data={balanceEnergyData}
          headCells={headCells}
          avoidEmptyRows
          orderingOptions={{
            orderBy,
            order,
            handleRequestSort: (e, property) => {
              setOrderBy((oldOrderBy) => {
                if (oldOrderBy === property) {
                  setOrder((oldOrder) => (oldOrder === "asc" ? "desc" : "asc"));
                } else {
                  setOrder("desc");
                }
                return property;
              });
            },
          }}
          keyExtractor={(row, index) => String(index)}
          renderRow={(row) => {
            const assetsInOrders =
              assetsBlocked?.find((item) => item.assetCode === row.asset.code)
                ?.value ?? 0;

            const assetValue = priceTable
              ? priceTable.getAssetPrice(row.asset.code)
              : 0;
            const assetsTotal =
              row.balance + assetsInOrders * 10 ** row.asset.precision;

            const assetsTotalValue = totalValueByAsset[row.asset.code] || 0;

            const nameEnergy = participantProducts?.find(
              (item) => item.assetsAvailableCode === row.asset.code
            )?.name;

            return (
              <TableRow hover tabIndex={-1}>
                <TableCell align="left">
                  <Box display="inline-flex" alignItems="center" gap={3}>
                    <Avatar
                      alt="Remy Sharp"
                      src={`data:image/svg+xml;base64,${row.asset.avatar}`}
                    />
                    <Box>
                      <Typography fontWeight="bold">{nameEnergy}</Typography>
                      <Tooltip title={row.asset.name}>
                        <Typography color="#CDCDD3">
                          {row.asset.name}
                        </Typography>
                      </Tooltip>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell align="left">
                  <Box display="flex" alignItems="center" gap={2}>
                    {toFixedDecimalPlaces(
                      assetsTotal,
                      row.asset.precision
                    ).toLocaleString(language, {
                      minimumFractionDigits: 3,
                      maximumFractionDigits: 3,
                    })}{" "}
                    MWh
                    <LightTooltip
                      title={
                        <Paper>
                          <TableContainer>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  {tableResumeEnergyHead.map((item, index) => (
                                    <TableCell
                                      key={index}
                                      sx={{
                                        fontSize: "12px",
                                        color: item.color,
                                        fontWeight: "bold",
                                      }}
                                      align="center"
                                    >
                                      {item.name}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {tableResumeEnergyBody
                                  .filter(
                                    (item) => item.name === row.asset.code
                                  )
                                  .map((item, index) => (
                                    <TableRow key={index}>
                                      <TableCell
                                        sx={{
                                          fontSize: "12px",
                                          color: "#8295B2",
                                        }}
                                        align="center"
                                      >
                                        {handleBalaceBySubmarket(
                                          item.submarkets?.["S"] ?? 0
                                        )}
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          fontSize: "12px",
                                          color: "#8295B2",
                                        }}
                                        align="center"
                                      >
                                        {handleBalaceBySubmarket(
                                          item.submarkets?.["SE/CO"] ?? 0
                                        )}
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          fontSize: "12px",
                                          color: "#8295B2",
                                        }}
                                        align="center"
                                      >
                                        {handleBalaceBySubmarket(
                                          item.submarkets?.["NE"] ?? 0
                                        )}
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          fontSize: "12px",
                                          color: "#8295B2",
                                        }}
                                        align="center"
                                      >
                                        {handleBalaceBySubmarket(
                                          item.submarkets?.["N"] ?? 0
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Paper>
                      }
                    >
                      <InfoRounded sx={{ width: "12px" }} />
                    </LightTooltip>
                  </Box>
                </TableCell>
                <TableCell align="left">
                  {toFixedDecimalPlaces(
                    row.balance,
                    row.asset.precision
                  ).toLocaleString(language, {
                    minimumFractionDigits: 3,
                    maximumFractionDigits: 3,
                  })}{" "}
                  MWh
                </TableCell>
                <TableCell align="left">
                  {toFixedDecimalPlaces(assetsInOrders, 0).toLocaleString(
                    language,
                    {
                      minimumFractionDigits: 3,
                      maximumFractionDigits: 3,
                    }
                  )}{" "}
                  MWh
                </TableCell>
                <TableCell align="left">
                  <Box display="inline-flex" alignItems="center" gap={2}>
                    {assetValue !== null && assetValue !== 0 && (
                      <Box display="inline-flex" flexDirection="column" gap={1}>
                        <Typography
                          fontSize={22}
                          color="#DAE4FF"
                          lineHeight={0}
                        >
                          ~
                        </Typography>
                        <Typography
                          fontSize={22}
                          color="#DAE4FF"
                          lineHeight={0}
                        >
                          ~
                        </Typography>
                      </Box>
                    )}
                    <Typography
                      fontWeight="bold"
                      fontSize="0.875rem"
                      color="#DAE4FF"
                    >
                      {(assetsTotalValue === 0 ||
                        assetsTotalValue === null) && <>-</>}
                      {assetsTotalValue !== null && assetsTotalValue !== 0 && (
                        <>
                          {getCurrencySymbol("BRL", language)}{" "}
                          {toLocaleCurrencyStringWithoutSymbol(
                            Number(toFixedDecimalPlaces(assetsTotalValue, 0)),
                            "BRL",
                            language
                          )}
                        </>
                      )}
                    </Typography>
                  </Box>
                </TableCell>

                <TableCell padding="none" align="left" width="400px">
                  <Box
                    whiteSpace="nowrap"
                    width="100%"
                    display="inline-flex"
                    gap={8}
                  >
                    <Button
                      variant="text"
                      sx={{ color: "#FFC700" }}
                      onClick={() =>
                        handleWithdrawal(
                          row.balance ?? 0,
                          `/bank/spot-trading/${row.asset.code}`
                        )
                      }
                    >
                      {t("Buy")}/{t("Sell")}
                    </Button>

                    <Button
                      component={Link}
                      variant="text"
                      sx={{ color: "#FFC700" }}
                      to={`/bank/energy-deposit/${row.asset.code}`}
                    >
                      {t("Deposit")}
                    </Button>

                    <Button
                      variant="text"
                      sx={{ color: "#FFC700" }}
                      onClick={() =>
                        handleWithdrawal(
                          row.balance ?? 0,
                          `/bank/energy-withdrawal/${row.asset.code}`
                        )
                      }
                    >
                      {t("withdrawal")}
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            );
          }}
        />
      </Grid>
    </Grid>
  );
}
