import { balcaoApiInstance } from "../../../utils/axios";

export interface EnumItem {
  value: string;
  name: string;
  id: string;
}

export async function getSubmarketOptions() {
  const { data } = await balcaoApiInstance.get<Array<EnumItem>>("/submarkets");

  const submarkets = data
    .map((item) => item.name)
    .sort()
    .reverse();

  return submarkets;
}
