import React from "react";
import { Navigate, RouteObject } from "react-router-dom";

// All pages that rely on 3rd party components (other than MUI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";

// Guards
import AuthGuard from "./components/guards/AuthGuard";
import ChooseParticipantGuard from "./components/guards/ChooseParticipantGuard";
import AdminGuard from "./components/guards/AdminGuard";
import UserGuard from "./components/guards/UserGuard";

// Auth components
import SignIn from "./pages/auth/SignIn";
import ChooseParticipantPage from "./pages/auth/ChooseParticipantPage";
// import SignUp from "./pages/auth/SignUp";
import ResetPassword from "./pages/auth/ResetPassword";
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";

// Protected routes
import ProductList from "./pages/products/ProductList";
import AssociateParticipants from "./pages/products/AssociateParticipants";
import { OffersPanel } from "./pages/offers/OffersPanel/index";
import RegisterOffer from "./pages/offers/RegisterOffer";
import { DirectBuyAndSell } from "./pages/offers/DirectBuyAndSell";
import OfferHistory from "./pages/offers/OfferHistory";
import ContractList from "./pages/contracts/ContractList";
import PldWeeklyAverage from "./pages/pld/PldWeeklyAverage";
import PldMonthlyAverage from "./pages/pld/PldMonthlyAverage";
import { AvailableBalance } from "./pages/bank/AvailableBalance";
import { FinancialDeposit } from "./pages/bank/FinancialDeposit";
import { EnergyDeposit } from "./pages/bank/EnergyDeposit";
import { FinancialWithdrawal } from "./pages/bank/FinancialWithdrawal";
import { EnergyWithdrawal } from "./pages/bank/EnergyWithdrawal";
import { ManageDepositsAndWithdrawals } from "./pages/bank/ManageDepositsAndWithdrawals";
import { MyWallet } from "./pages/bank/MyWallet";
import { SpotMarket } from "./pages/spotMarket/SpotMarket";
import { TunnelLimitsUpdate } from "./pages/tunnelLimits/TunnelLimitsUpdate";

const routes: RouteObject[] = [
  {
    path: "/",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Navigate to="/offers/offers-panel" replace />,
      },
    ],
  },
  {
    path: "/",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Navigate to="/offers/offers-panel" replace />,
      },
    ],
  },
  {
    path: "offers",
    element: (
      <AuthGuard>
        <ChooseParticipantGuard>
          <DashboardLayout />
        </ChooseParticipantGuard>
      </AuthGuard>
    ),
    children: [
      {
        path: "offers-panel",
        element: <OffersPanel />,
      },
      {
        path: "register-offer",
        element: (
          <UserGuard>
            <RegisterOffer />
          </UserGuard>
        ),
      },
      {
        path: "register-offer/:operationId",
        element: (
          <UserGuard>
            <RegisterOffer />
          </UserGuard>
        ),
      },
      {
        path: "direct-buy-and-sell",
        element: (
          <UserGuard>
            <DirectBuyAndSell />
          </UserGuard>
        ),
      },
      {
        path: "offer-history",
        element: <OfferHistory />,
      },
    ],
  },
  {
    path: "settings",
    element: (
      <AuthGuard>
        <AdminGuard>
          <DashboardLayout />
        </AdminGuard>
      </AuthGuard>
    ),
    children: [
      {
        path: "products",
        children: [
          {
            path: "list",
            element: <ProductList />,
          },
          {
            path: "associate-participants",
            element: <AssociateParticipants />,
          },
        ],
      },
      {
        path: "tunnel-limits",
        children: [
          {
            path: "update",
            element: <TunnelLimitsUpdate />,
          },
        ],
      },
    ],
  },
  {
    path: "contracts",
    element: (
      <AuthGuard>
        <ChooseParticipantGuard>
          <DashboardLayout />
        </ChooseParticipantGuard>
      </AuthGuard>
    ),
    children: [
      {
        path: "list",
        element: <ContractList />,
      },
    ],
  },
  {
    path: "pld",
    element: (
      <AuthGuard>
        <ChooseParticipantGuard>
          <DashboardLayout />
        </ChooseParticipantGuard>
      </AuthGuard>
    ),
    children: [
      {
        path: "weekly-average",
        element: <PldWeeklyAverage />,
      },
      {
        path: "monthly-average",
        element: <PldMonthlyAverage />,
      },
    ],
  },
  {
    path: "bank",
    element: (
      <AuthGuard>
        <ChooseParticipantGuard>
          <DashboardLayout />
        </ChooseParticipantGuard>
      </AuthGuard>
    ),
    children: [
      {
        path: "spot-trading",
        element: (
          <UserGuard>
            <SpotMarket />
          </UserGuard>
        ),
      },
      {
        path: "spot-trading/:energyType",
        element: (
          <UserGuard>
            <SpotMarket />
          </UserGuard>
        ),
      },
      {
        path: "my-Wallet",
        element: (
          <UserGuard>
            <MyWallet />
          </UserGuard>
        ),
      },
      {
        path: "available-balance",
        element: (
          <UserGuard>
            <AvailableBalance />
          </UserGuard>
        ),
      },
      {
        path: "financial-deposit",
        element: (
          <UserGuard>
            <FinancialDeposit />
          </UserGuard>
        ),
      },
      {
        path: "energy-deposit",
        element: (
          <UserGuard>
            <EnergyDeposit />
          </UserGuard>
        ),
      },
      {
        path: "energy-deposit/:energyType",
        element: (
          <UserGuard>
            <EnergyDeposit />
          </UserGuard>
        ),
      },
      {
        path: "financial-withdrawal",
        element: (
          <UserGuard>
            <FinancialWithdrawal />
          </UserGuard>
        ),
      },
      {
        path: "energy-withdrawal",
        element: (
          <UserGuard>
            <EnergyWithdrawal />
          </UserGuard>
        ),
      },
      {
        path: "energy-withdrawal/:energyType",
        element: (
          <UserGuard>
            <EnergyWithdrawal />
          </UserGuard>
        ),
      },
      {
        path: "manage-deposits-and-withdrawals",
        element: <ManageDepositsAndWithdrawals />,
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "choose-participant",
        element: (
          <AuthGuard>
            <ChooseParticipantPage />
          </AuthGuard>
        ),
      },
      // {
      //   path: "sign-up",
      //   element: <SignUp />,
      // },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
