/* eslint-disable @typescript-eslint/no-non-null-assertion */
import styled from "@emotion/styled";
import {
  Box,
  Alert as MuiAlert,
  Checkbox,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useTranslation } from "react-i18next";
import { parseDate } from "../../utils/date";
import { StyledModal } from "../StyledModal";
import { StyledTextField } from "../StyledTextField";
import { useGetOperatorData } from "./hooks/useGetOperatorData";

const Alert = styled(MuiAlert)(spacing);

const InfoBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24;
`;

export interface OperatorModalProps {
  open: boolean;
  onClose: () => void;
  operatorId: string;
}

export function OperatorDataModal({
  open,
  onClose,
  operatorId,
}: OperatorModalProps) {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const {
    operatorData,
    isLoading: isLoadingOperatorData,
    isError: isErrorOperatorData,
  } = useGetOperatorData(operatorId);

  if (isErrorOperatorData) {
    return (
      <Alert mt={2} mb={3} severity="error">
        {t("Something went wrong.")}
      </Alert>
    );
  }

  return (
    <StyledModal
      title="Dados do Operador"
      open={open}
      onClose={onClose}
      maxWidth={700}
    >
      {isLoadingOperatorData ? (
        <Box>
          <InfoBox>
            <CircularProgress />
          </InfoBox>
        </Box>
      ) : (
        <Box>
          <StyledTextField
            InputLabelProps={{
              sx: [
                {
                  whiteSpace: "initial",
                  fontSize: "12px",
                },
              ],
            }}
            label={`${t("Name")}`}
            fullWidth
            value={operatorData?.fullName ?? "-"}
            InputProps={{ readOnly: true }}
            helperText=" "
            // size="small"
          />
          <StyledTextField
            InputLabelProps={{
              sx: [
                {
                  whiteSpace: "initial",
                  fontSize: "12px",
                },
              ],
            }}
            label={`${t("E-mail")}`}
            fullWidth
            value={operatorData?.email ?? "-"}
            InputProps={{ readOnly: true }}
            helperText=" "
            // size="small"
          />
          <Box
            display="inline-flex"
            justifyContent="space-between"
            width="100%"
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={operatorData?.alwaysSign ?? false}
                  disabled
                />
              }
              label={t("Always Sign")}
            />
            <StyledTextField
              InputLabelProps={{
                sx: [
                  {
                    whiteSpace: "initial",
                    fontSize: "12px",
                  },
                ],
              }}
              label={`${t("Position")}`}
              sx={{ width: "70%" }}
              value={operatorData?.jobRole ?? "-"}
              InputProps={{ readOnly: true }}
              helperText=" "
              // size="small"
            />
          </Box>
          <Box display="inline-flex" width="100%" gap={3}>
            <StyledTextField
              InputLabelProps={{
                sx: [
                  {
                    whiteSpace: "initial",
                    fontSize: "12px",
                  },
                ],
              }}
              label={`${t("Telephone Number")}`}
              sx={{ width: "calc(50% - 6px)" }}
              value={operatorData?.phoneNumber ?? "-"}
              InputProps={{ readOnly: true }}
              helperText=" "
              // size="small"
            />
            <StyledTextField
              InputLabelProps={{
                sx: [
                  {
                    whiteSpace: "initial",
                    fontSize: "12px",
                  },
                ],
              }}
              label={`${t("Cellphone Number")}`}
              sx={{ width: "calc(50% - 6px)" }}
              value={operatorData?.cellphoneNumber ?? "-"}
              InputProps={{ readOnly: true }}
              helperText=" "
              // size="small"
            />
          </Box>

          <Box
            display="inline-flex"
            justifyContent="space-between"
            width="100%"
            gap={3}
          >
            <StyledTextField
              InputLabelProps={{
                sx: [
                  {
                    whiteSpace: "initial",
                    fontSize: "12px",
                  },
                ],
              }}
              label={`${t("CPF")}`}
              fullWidth
              value={operatorData?.cpf ?? "-"}
              InputProps={{ readOnly: true }}
              helperText=" "
              // size="small"
            />
            <StyledTextField
              InputLabelProps={{
                sx: [
                  {
                    whiteSpace: "initial",
                    fontSize: "12px",
                  },
                ],
              }}
              label={`${t("Nationality")}`}
              fullWidth
              value={operatorData?.citizenship ?? "-"}
              InputProps={{ readOnly: true }}
              helperText=" "
              // size="small"
            />
            <StyledTextField
              InputLabelProps={{
                sx: [
                  {
                    whiteSpace: "initial",
                    fontSize: "12px",
                  },
                ],
              }}
              label={`${t("Birth Date")}`}
              fullWidth
              value={
                parseDate(operatorData?.birthDate)?.toLocaleDateString(
                  language,
                  {
                    timeZone: "UTC",
                  }
                ) ?? "-"
              }
              InputProps={{ readOnly: true }}
              helperText=" "
              // size="small"
            />
          </Box>

          <Box
            display="inline-flex"
            justifyContent="space-between"
            width="100%"
            gap={3}
          >
            <StyledTextField
              InputLabelProps={{
                sx: [
                  {
                    whiteSpace: "initial",
                    fontSize: "12px",
                  },
                ],
              }}
              label={`${t("Document Type")}`}
              fullWidth
              value={operatorData?.documentType ?? "-"}
              InputProps={{ readOnly: true }}
              helperText=" "
              // size="small"
            />
            <StyledTextField
              InputLabelProps={{
                sx: [
                  {
                    whiteSpace: "initial",
                    fontSize: "12px",
                  },
                ],
              }}
              label={`${t("Document Number")}`}
              fullWidth
              value={operatorData?.documentNumber ?? "-"}
              InputProps={{ readOnly: true }}
              helperText=" "
              // size="small"
            />
            <StyledTextField
              InputLabelProps={{
                sx: [
                  {
                    whiteSpace: "initial",
                    fontSize: "12px",
                  },
                ],
              }}
              label={`${t("Date of Issue")}`}
              fullWidth
              value={
                parseDate(operatorData?.documentIssueDate)?.toLocaleDateString(
                  language,
                  {
                    timeZone: "UTC",
                  }
                ) ?? "-"
              }
              InputProps={{ readOnly: true }}
              helperText=" "
              // size="small"
            />
          </Box>

          <Box
            display="inline-flex"
            justifyContent="space-between"
            width="100%"
            gap={3}
          >
            <StyledTextField
              InputLabelProps={{
                sx: [
                  {
                    whiteSpace: "initial",
                    fontSize: "12px",
                  },
                ],
              }}
              label={`${t("Issuing Agency")}`}
              fullWidth
              value={operatorData?.documentDispatchingEntity ?? "-"}
              InputProps={{ readOnly: true }}
              helperText=" "
              // size="small"
            />
            <StyledTextField
              InputLabelProps={{
                sx: [
                  {
                    whiteSpace: "initial",
                    fontSize: "12px",
                  },
                ],
              }}
              label={`${t("Federated Unit")}`}
              fullWidth
              value={operatorData?.documentUf ?? "-"}
              InputProps={{ readOnly: true }}
              helperText=" "
              // size="small"
            />
          </Box>

          <StyledTextField
            InputLabelProps={{
              sx: [
                {
                  whiteSpace: "initial",
                  fontSize: "12px",
                },
              ],
            }}
            sx={{ width: "calc(50% - 6px)" }}
            label={"Whatsapp"}
            value={operatorData?.cellphoneNumber ?? "-"}
            InputProps={{ readOnly: true }}
            helperText=" "
            // size="small"
          />
        </Box>
      )}
    </StyledModal>
  );
}
