import { ChatroomRepositoryImpl } from "../infra/api/ChatroomRepositoryImpl";

export abstract class ChatroomRepository {
  abstract getChatroomId(
    operationId: string,
    offerType: string
  ): Promise<string>;
}

export class GetChatroomIdService {
  private chatroomRepository: ChatroomRepository;
  constructor() {
    this.chatroomRepository = new ChatroomRepositoryImpl();
  }

  public getChatroomId(
    operationId: string,
    offerType: string
  ): Promise<string> {
    return this.chatroomRepository.getChatroomId(operationId, offerType);
  }
}
