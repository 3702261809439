import React from "react";
import { AssetsPriceTable } from "../entities/AssetsPriceTable";
import { GetAssetsQuotesObservableService } from "../services/GetAssetsPricesObservableService";

const getMyHistoricObservableService = new GetAssetsQuotesObservableService();

export function useGetPricesTable() {
  const [priceTable, setPriceTable] = React.useState<AssetsPriceTable>();

  React.useEffect(() => {
    const subscription = getMyHistoricObservableService
      .getAssetsPriceObservable()
      .subscribeWithOptions({}, (assetsPriceTable) => {
        setPriceTable(assetsPriceTable);
      });

    return () => {
      subscription.unsubscribe();
    };
  });

  return priceTable;
}
