import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { AccceptBidProps, acceptBid } from "../../../services/offers";
import { ApiError } from "../../../types/error";

export function useAcceptBid() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const submitAcceptBidMutation = useMutation<any, unknown, AccceptBidProps>(
    acceptBid,
    {
      onSuccess: () => {
        enqueueSnackbar(t("The operation completed successfully"), {
          variant: "success",
        });

        queryClient.invalidateQueries(["/offer/daily/negotiated-offers"]);
      },
      onError: (e) => {
        enqueueSnackbar(
          t((e as ApiError | null)?.error ?? "Something went wrong."),
          {
            variant: "error",
          }
        );
      },
    }
  );

  return { submitAcceptBidMutation };
}
