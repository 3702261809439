import { useQuery } from "@tanstack/react-query";
import { getReasonsOptions } from "../infra/api/BankInfra";

export function useGetReasonsOptions() {
  const { data, ...rest } = useQuery(["getReasonsOptions"], () =>
    getReasonsOptions()
  );

  return { data, ...rest };
}
