import { Observable, Subscription } from "rxjs";
import { OperationResume } from "../../../pages/offers/entities/data-objects/OperationResume";
import { OffersObservableImpl } from "../infra/firestore/OffersObservableImpl";

export type OffersData = {
  sellOffers: OperationResume[];
  buyOffers: OperationResume[];
  amount: number | null;
  value: number | null;
  offersReached: number;
};

export type options = {
  value: number | null;
  amount: number | null;
  productId: string | null;
  submarket: string;
};

export abstract class OffersObservable extends Observable<OffersData> {
  abstract subscribeWithOptions(
    options: options,
    next?: ((value: OffersData) => void) | null | undefined,
    error?: ((error: any) => void) | null | undefined,
    complete?: (() => void) | null | undefined
  ): Subscription;

  abstract setOptions(options: options): void;
}

export class GetOffersObservableService {
  private observable: OffersObservable | null = null;

  public getOffersObservable() {
    if (this.observable === null) {
      this.observable = new OffersObservableImpl();
    }

    return this.observable;
  }
}
