import React from "react";
import useChooseParticipant from "../../../hooks/useChooseParticipant";
import { OrderResume } from "../entities/data-objects/OrderResume";
import { GetMyOrdersObservableService } from "../services/GetMyOrdersObservableService";

const getMyOrdersObservableService = new GetMyOrdersObservableService();

interface useGetMyOrdersProps {
  page: number;
  itemsPerPage: number;
  productFilterValue: string | null;
  submarketFilterValue: string | null;
}
export function useGetMyOrders({
  page,
  itemsPerPage,
  productFilterValue,
  submarketFilterValue,
}: useGetMyOrdersProps) {
  const { participantId } = useChooseParticipant();

  const [orders, setOrders] = React.useState<OrderResume[]>([]);
  const [totalItems, setTotalItems] = React.useState(0);

  React.useEffect(() => {
    const options = {
      page: page,
      itemsPerPage: itemsPerPage,
      participant: participantId || -1,
      product: productFilterValue,
      submarket: submarketFilterValue,
    };

    const subscription = getMyOrdersObservableService
      .getMyOrdersObservable()
      .subscribeWithOptions(options, (panelData) => {
        setTotalItems(panelData.totalItems);
        setOrders(panelData.orders);
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [
    page,
    itemsPerPage,
    participantId,
    productFilterValue,
    submarketFilterValue,
  ]);

  return { orders, totalItems };
}
