import { Observable, Subscription } from "rxjs";
import { balcaoApiInstance } from "../../../../utils/axios";
import {
  options,
  StatusObservable,
} from "../../services/GetChatCounterpartStatusObservableService";

export class StatusObservableImpl
  extends Observable<boolean>
  implements StatusObservable
{
  private interval: NodeJS.Timer | null = null;
  private options: options = {
    chatroomId: null,
    updateLastReadingDate: false,
  };
  private subscribers: {
    subscription: Subscription;
    next: ((value: boolean) => void) | null | undefined;
  }[] = [];

  subscribeWithOptions(
    options: options,
    next?: ((value: boolean) => void) | null | undefined,
    error?: ((error: any) => void) | null | undefined,
    complete?: (() => void) | null | undefined
  ): Subscription {
    this.setOptions(options);
    this.options = options;
    const subscription = this.subscribe(next, error, complete);
    this.subscribers.push({
      subscription,
      next,
    });

    subscription.add(() => {
      this.subscribers = this.subscribers.filter(
        (s) => s.subscription !== subscription
      );
      if (this.subscribers.length === 0 && this.interval !== null) {
        clearInterval(this.interval as any);
      }
    });

    return subscription;
  }

  setOptions(options: options) {
    this.options = options;
    if (options.chatroomId === null && this.interval !== null) {
      clearInterval(this.interval as any);
      return;
    }
    this.startVerification();
  }

  private startVerification() {
    if (this.interval !== null) {
      clearInterval(this.interval as any);
    }

    if (this.options.chatroomId === null) {
      return;
    }

    this.notifyStatus();
    this.interval = setInterval(async () => {
      await this.notifyStatus();
    }, 20000);
  }

  private async notifyStatus() {
    if (!this.options.chatroomId) {
      return;
    }

    const { data } = await balcaoApiInstance.get(`chat-room/metadata`, {
      params: {
        chatRoomId: this.options.chatroomId,
        updateLastReadingDate: this.options.updateLastReadingDate,
      },
    });
    this.subscribers.forEach((subscriber) => {
      if (!subscriber.next) {
        return;
      }
      subscriber.next(data.online);
    });
  }

  forceUpdateLastReadingDate(): void {
    this.notifyStatus();
  }
}
