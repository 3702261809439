import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";

import { registrationApiInstance } from "../utils/axios";
import useAuth from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { Participant } from "../types/participant";

import { balcaoApiInstance } from "../utils/axios";
import * as firebaseAuth from "firebase/auth";

interface ChooseParticipantContextProps {
  isInitialized: boolean;
  isError: boolean;
  participantList: Array<Participant> | null | undefined;
  currentParticipant: Participant | null;
  participantId: number | undefined;
  changeParticipant: (
    participantId: string | number | null,
    avoidRedirect?: boolean
  ) => void;
  isChooseParticipantDataNew: boolean;
  setIsChooseParticipantDataNew: Dispatch<SetStateAction<boolean>>;
}

const ChooseParticipantContext =
  createContext<ChooseParticipantContextProps | null>(null);

function ChooseParticipantProvider({ children }: { children: ReactNode }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { isAuthenticated, isInitialized: isAuthInitialized, user } = useAuth();

  const {
    data: participantList,
    isLoading,
    isError,
  } = useQuery(
    ["participant-list", isAuthInitialized, isAuthenticated, user?.id],
    async () => {
      if (!isAuthInitialized || !isAuthenticated || !user?.id) {
        return null;
      }
      const { data } = await registrationApiInstance.get<
        Array<{ participant: Participant }>
      >("/participant-users/participants/me");

      return data.map((item) => item.participant);
    },
    {
      onError: () => {
        enqueueSnackbar(t("Something went wrong."), {
          variant: "error",
        });
      },
      // refetchOnWindowFocus: false,
    }
  );

  const [isInitialized, setIsInitialized] = useState(false);

  const [currentParticipant, setCurrentParticipant] =
    useState<Participant | null>(null);

  const [isChooseParticipantDataNew, setIsChooseParticipantDataNew] =
    useState(false);

  const exchangeUserToken = useCallback(async (participantId: number) => {
    const { data } = await balcaoApiInstance.post("/auth/exchange-token", {
      participantId,
    });
    firebaseAuth.signInWithCustomToken(firebaseAuth.getAuth(), data.token);
  }, []);

  useEffect(() => {
    if (currentParticipant !== null) {
      exchangeUserToken(currentParticipant.id);
    }
  }, [currentParticipant, exchangeUserToken]);

  const changeParticipant = useCallback(
    async (participantId: string | number | null, avoidRedirect = false) => {
      setIsInitialized(false);

      if (participantId === null) {
        localStorage.removeItem("currentParticipant");
      }

      const newParticipant =
        participantId === null
          ? null
          : participantList?.find(
              (item) => String(item.id) === String(participantId)
            ) ?? null;

      if (newParticipant) {
        // await exchangeUserToken(newParticipant.id);
        setCurrentParticipant(newParticipant);
      }

      if (!avoidRedirect && newParticipant) {
        navigate("/offers/offers-panel");
      }
    },
    [participantList] // navigate apesar do alerta sempre muda e faz essa função disparar o manageLocalStorage causando problemas
  );

  useEffect(() => {
    const manageLocalStorage = async () => {
      if (isAuthInitialized && !isLoading && user?.id) {
        setIsInitialized(false);

        if (currentParticipant) {
          localStorage.setItem(
            "currentParticipant",
            JSON.stringify({
              userId: user.id,
              participantId: currentParticipant.id,
            })
          );
          await exchangeUserToken(currentParticipant.id);
        } else {
          const data = JSON.parse(
            localStorage.getItem("currentParticipant") ?? "{}"
          );

          const userId = data?.userId;
          const participantId = data?.participantId;
          if (userId) {
            if (String(user.id) === String(userId)) {
              await changeParticipant(participantId, true);
            } else {
              localStorage.removeItem("currentParticipant");
            }
          }
        }

        setIsInitialized(true);
      }
    };

    manageLocalStorage();
  }, [
    changeParticipant,
    currentParticipant,
    exchangeUserToken,
    isAuthInitialized,
    isLoading,
    user?.id,
  ]);

  useEffect(() => {
    if (participantList && participantList.length > 0) {
      setCurrentParticipant((oldValue) => {
        if (
          oldValue &&
          participantList.find(
            (item) => String(item.id) === String(oldValue.id)
          )
        ) {
          return oldValue;
        }

        setIsChooseParticipantDataNew(true);

        return null;
      });
    }
  }, [participantList]);

  const participantId = useMemo(
    () => (isInitialized && !isError ? currentParticipant?.id : undefined),
    [currentParticipant?.id, isError, isInitialized]
  );

  return (
    <ChooseParticipantContext.Provider
      value={{
        isInitialized,
        isError,
        participantList: participantList,
        currentParticipant,
        participantId,
        changeParticipant,
        isChooseParticipantDataNew,
        setIsChooseParticipantDataNew,
      }}
    >
      {children}
    </ChooseParticipantContext.Provider>
  );
}

export { ChooseParticipantContext, ChooseParticipantProvider };
