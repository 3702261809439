import { Observable, Subscription } from "rxjs";
import * as firestore from "firebase/firestore";
import {
  ChatroomsMessagesObservable,
  options,
  MessagesData,
} from "../../services/GetChatroomMessagesObservableService";
import { ChatMessage } from "../../entities/ChatMessage";

export class ChatroomsMessagesObservableImpl
  extends Observable<MessagesData>
  implements ChatroomsMessagesObservable
{
  private firestoreListener: () => void = () => {};
  private options: options = {
    participant: -1,
    chatroomId: null,
  };
  private subscribers: {
    subscription: Subscription;
    next: ((value: MessagesData) => void) | null | undefined;
  }[] = [];

  subscribeWithOptions(
    options: options,
    next?: ((value: MessagesData) => void) | null | undefined,
    error?: ((error: any) => void) | null | undefined,
    complete?: (() => void) | null | undefined
  ): Subscription {
    this.setOptions(options);
    this.options = options;
    const subscription = this.subscribe(next, error, complete);
    this.subscribers.push({
      subscription,
      next,
    });

    subscription.add(() => {
      this.subscribers = this.subscribers.filter(
        (s) => s.subscription !== subscription
      );
      if (this.subscribers.length === 0) {
        this.firestoreListener();
      }
    });

    return subscription;
  }

  setOptions(options: options) {
    this.options = options;
    if (options.chatroomId !== null) {
      this.startFirestoreListener();
    } else {
      this.firestoreListener();
    }
  }

  private startFirestoreListener() {
    this.firestoreListener();

    const queryFilters: firestore.QueryConstraint[] = [];

    const query = firestore.query(
      firestore.collection(
        firestore.getFirestore(),
        `chatRooms/${this.options.chatroomId}/messages`
      ),
      firestore.orderBy("createdAt", "asc"),
      ...queryFilters
    );

    this.firestoreListener = firestore.onSnapshot(query, async (snapshot) => {
      this.subscribers.forEach((subscriber) => {
        if (!subscriber.next) {
          return;
        }

        const messages = snapshot.docs.map((doc) => {
          return this.firestoreDocToChatRoomMessage(
            doc,
            this.options.participant
          );
        });

        subscriber.next({
          messages,
          totalItems: messages.length,
        });
      });
    });
  }

  private firestoreDocToChatRoomMessage(
    doc: firestore.QueryDocumentSnapshot<firestore.DocumentData>,
    participantInterested: number
  ): ChatMessage {
    const docData = doc.data();

    const chatroom = new ChatMessage(
      doc.id,
      docData.text,
      docData.createdAt.toDate(),
      docData.senderId === participantInterested ? participantInterested : null
    );

    return chatroom;
  }
}
