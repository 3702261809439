import { useQuery } from "@tanstack/react-query";
import { getBalanceData } from "../infra/api/BankInfra";

export function useGetBalanceData() {
  const { data: balanceData, ...rest } = useQuery(
    ["getBalanceData"],
    getBalanceData
  );

  return { balanceData, ...rest };
}
