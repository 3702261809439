import { Observable, Subscription } from "rxjs";
import { ChatMessage } from "../entities/ChatMessage";
import { ChatroomsMessagesObservableImpl } from "../infra/firestore/ChatMessagesObservableImpl";

export type MessagesData = {
  totalItems: number;
  messages: ChatMessage[];
};

export type options = {
  participant: number;
  chatroomId: string | null;
};

export abstract class ChatroomsMessagesObservable extends Observable<MessagesData> {
  abstract subscribeWithOptions(
    options: options,
    next?: ((value: MessagesData) => void) | null | undefined,
    error?: ((error: any) => void) | null | undefined,
    complete?: (() => void) | null | undefined
  ): Subscription;

  abstract setOptions(options: options): void;
}

export class GetChatroomMessagesObservableService {
  private observable: ChatroomsMessagesObservable | null = null;

  public getChatroomMessagesObservable() {
    if (this.observable === null) {
      this.observable = new ChatroomsMessagesObservableImpl();
    }

    return this.observable;
  }
}
