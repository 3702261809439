import { Dinero } from "dinero.js";

export class Notification {
  constructor(
    private operatorId: number,
    private readed: boolean,
    private createdAt: Date
  ) {}

  public getOperatorId() {
    return this.operatorId;
  }

  public getReaded() {
    return this.readed;
  }

  public getCreatedAt() {
    return this.createdAt;
  }
}

export class TradingLostLimitReached extends Notification {
  constructor(
    operatorId: number,
    readed: boolean,
    createdAt: Date,
    private participantId: number,
    private participantName: string,
    private participantLostLimit: Dinero,
    private lostReached: Dinero
  ) {
    super(operatorId, readed, createdAt);
  }

  public getParticipantId() {
    return this.participantId;
  }

  public getParticipantName() {
    return this.participantName;
  }

  public getParticipantLostLimit() {
    return this.participantLostLimit;
  }

  public getLostReached() {
    return this.lostReached;
  }
}
