import { Box } from "@mui/material";
import { ReactComponent as Icon } from "../../vendor/logo-light.svg";

export function EnergyProductIcon({ size = 16 }) {
  return (
    <Box
      sx={{
        backgroundColor: "#FFD64E",
        borderRadius: size,
        width: size,
        height: size,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexShrink: 0,
      }}
    >
      <Icon width={size * 0.75} height={size * 0.75} />
    </Box>
  );
}
