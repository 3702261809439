import { Box, Button, Tooltip, Typography } from "@mui/material";
import React from "react";
import { BarChart2, Calendar, DollarSign, Map } from "react-feather";
import { useTranslation } from "react-i18next";
import useChooseParticipant from "../../../hooks/useChooseParticipant";
import useConfirmationModal from "../../../hooks/useConfirmationModal";
import { Bid, Operation, SingleOffer } from "../../../types/offer";
import { getLocaleMonthAndYearPeriodShortString } from "../../../utils/date";
import { toLocaleCurrencyString } from "../../../utils/string";
import { BidModal } from "../../bidModal/BidModal";
import { useAcceptBid } from "../../bidModal/hooks/useAcceptBid";
import RayIcon from "../../Icons/RayIcon";
import { TextWithArrowIndicator } from "../../TextWithArrowIndicator";

interface StyledIconPropertyProps {
  icon: JSX.Element;
  label: JSX.Element | string;
  value: JSX.Element | string;
}
const StyledIconProperty = ({
  icon,
  label,
  value,
}: StyledIconPropertyProps) => {
  return (
    <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
      <Box
        sx={{
          width: 28,
          height: 28,
          borderRadius: 1,
          backgroundColor: "common.white",
          color: "primary.main",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexShrink: 0,
        }}
      >
        {icon}
      </Box>

      <Box sx={{ textAlign: "initial" }}>
        <Box lineHeight={1.3}>{label}</Box>
        <Box lineHeight={1.3} fontWeight="bold">
          {value}
        </Box>
      </Box>
    </Box>
  );
};

export interface ChatModalBidAreaContentProps {
  hasSelectedChatroom: boolean;
  operation: Operation | null | undefined;
  offer: SingleOffer | null | undefined;
  bid: Bid | null | undefined;
  isBidButtonDisabled: boolean;
  // operationType: "COMPRA" | "VENDA" | "COMPRA&VENDA" | null | undefined;
}

export function ChatModalBidAreaContent({
  hasSelectedChatroom,
  operation,
  offer,
  bid,
  isBidButtonDisabled,
}: ChatModalBidAreaContentProps) {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { showConfirmationModal } = useConfirmationModal();

  const { currentParticipant } = useChooseParticipant();

  const isMeOperationOwner = React.useMemo(() => {
    if (!operation || !currentParticipant) return false;
    return operation?.participantId === currentParticipant?.id;
  }, [currentParticipant, operation]);

  const operationType = React.useMemo(() => {
    if (!operation) return null;
    return operation.buyOffer && operation.sellOffer
      ? "COMPRA&VENDA"
      : operation.buyOffer
      ? "COMPRA"
      : operation.sellOffer
      ? "VENDA"
      : null;
  }, [operation]);

  const [isBidModalOpen, setIsBidModalOpen] = React.useState(false);
  const handleOpenBidModal = React.useCallback(() => {
    setIsBidModalOpen(true);
  }, []);
  const handleCloseBidModal = React.useCallback(() => {
    setIsBidModalOpen(false);
  }, []);

  const { submitAcceptBidMutation } = useAcceptBid();
  const handleAcceptBid = React.useCallback(() => {
    if (!offer || !bid) return;

    const bidId = bid.id;

    showConfirmationModal({
      onSubmit: () => {
        submitAcceptBidMutation.mutate({
          operationId: Number(offer.codeOperation),
          bidId,
        });
      },
      message: t("Do you really want to accept the bid?"),
      // submitButtonColor: "error",
    });
  }, [bid, offer, showConfirmationModal, submitAcceptBidMutation, t]);

  if (!hasSelectedChatroom) {
    return (
      <Typography
        variant="caption"
        px={7}
        mt={6}
        display="block"
        textAlign="center"
      >
        {t("No chat selected")}
      </Typography>
    );
  }

  if (!operation || !offer || !operationType) {
    return (
      <Typography
        variant="caption"
        px={7}
        mt={6}
        display="block"
        textAlign="center"
      >
        {t("Unable to load data")}
      </Typography>
    );
  }

  return (
    <>
      {isBidModalOpen && (
        <BidModal
          open={isBidModalOpen}
          onClose={handleCloseBidModal}
          operationId={operation.codeOperation}
          offerType={offer.offerType}
          showMoreDetails={isMeOperationOwner}
          disableBidArea={isMeOperationOwner}
        />
      )}

      <Box
        sx={{
          pt: 4,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography fontSize="1.5rem" fontWeight="600">
          {t("My Order")}
        </Typography>

        <Box
          sx={{
            width: "fit-content",
            backgroundColor:
              operationType === "COMPRA"
                ? "success.main"
                : operationType === "VENDA"
                ? "error.main"
                : undefined,
            backgroundImage:
              operationType === "COMPRA&VENDA"
                ? "linear-gradient(90deg, #00CB85 13.54%, #E05757 64.58%, #FF4760 100%)"
                : undefined,
            color: "common.white",
            fontSize: "1rem",
            borderRadius: 1,
            px: 4,
            py: 2,
            lineHeight: 1,
          }}
        >
          {t(operationType)}
        </Box>

        <Typography mt={2}>
          {operation.pldValues && operation.pldValues[operation.submarket]
            ? `${t("PLD")}: ${toLocaleCurrencyString(
                operation.pldValues[operation.submarket],
                "BRL",
                language
              )}/MWh`
            : " "}
        </Typography>
      </Box>

      <Box
        sx={{
          width: "100%",
          backgroundColor:
            offer.offerType === "COMPRA"
              ? "rgba(16, 193, 130, 0.1)"
              : "rgba(254, 71, 96, 0.1)",
          borderRadius: 1,
          py: 2,
          px: 3,
          mt: 3,
        }}
      >
        <Box
          sx={{
            backgroundColor:
              offer.offerType === "COMPRA" ? "success.main" : "error.main",
            color: "common.white",
            width: "fit-content",
            borderRadius: 1,
            mx: "auto",
            px: 2,
            mb: 3,
          }}
        >
          {isMeOperationOwner
            ? offer.offerType === "COMPRA"
              ? t("I'm buying")
              : t("I'm selling")
            : offer.offerType === "COMPRA"
            ? t("They're buying")
            : t("They're selling")}
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 4,
          }}
        >
          <StyledIconProperty
            icon={<BarChart2 size={16} />}
            label={t("Remaining Order Volume")}
            value={`${offer.remainingVolumeToBeNegotiated.toLocaleString(
              language,
              { minimumFractionDigits: 3 }
            )} ${operation.product.measurementUnit}`}
          />
          <StyledIconProperty
            icon={<DollarSign size={16} />}
            label={offer.priceType === "Fixo" ? t("Price") : t("Spread")}
            // label={`${t("Price")} (${offer.priceType === "Fixo" ? t("Fixo")})`}
            value={
              offer.priceValue
                ? offer.priceType === "Fixo"
                  ? toLocaleCurrencyString(offer.priceValue, "BRL", language)
                  : `${
                      offer.priceValue - (offer.pldValue ?? 0) / 100 < 0
                        ? "-"
                        : "+"
                    } ${toLocaleCurrencyString(
                      Math.abs(offer.priceValue - (offer.pldValue ?? 0) / 100),
                      "BRL",
                      language
                    )}`
                : t("Free Bid")
            }
          />
          <StyledIconProperty
            icon={<RayIcon />}
            label={t("Source Type")}
            value={operation.product.name}
          />
          <StyledIconProperty
            icon={<Map size={16} />}
            label={t("Submarket")}
            value={operation.submarket}
          />
          <StyledIconProperty
            icon={<Calendar size={16} />}
            label={t("Delivery Period")}
            value={getLocaleMonthAndYearPeriodShortString(
              operation.deliveryPeriodStart,
              operation.deliveryPeriodEnd,
              language
            )}
          />
        </Box>
      </Box>

      {bid &&
      bid.status === "CRIADO" &&
      (offer.status === "ABERTA" ||
        offer.status === "PARCIALMENTE ENCERRADA") ? (
        <>
          <Box
            sx={{
              width: "100%",
              backgroundColor:
                offer.offerType === "COMPRA"
                  ? "rgba(254, 71, 96, 0.1)"
                  : "rgba(16, 193, 130, 0.1)",
              borderRadius: 1,
              py: 2,
              px: 3,
              mt: 2,
            }}
          >
            <Box
              sx={{
                backgroundColor:
                  offer.offerType === "COMPRA" ? "error.main" : "success.main",
                color: "common.white",
                width: "fit-content",
                borderRadius: 1,
                mx: "auto",
                px: 2,
                mb: 3,
              }}
            >
              {isMeOperationOwner
                ? offer.offerType === "COMPRA"
                  ? t("They're selling")
                  : t("They're buying")
                : offer.offerType === "COMPRA"
                ? t("I'm selling")
                : t("I'm buying")}
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 4,
              }}
            >
              <StyledIconProperty
                icon={<BarChart2 size={16} />}
                label={t("Offered Volume")}
                value={
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <Box>
                      {`${bid.amount.toLocaleString(language, {
                        minimumFractionDigits: 3,
                      })} MWh`}
                    </Box>

                    {offer.remainingVolumeToBeNegotiated - bid.amount !== 0 && (
                      <TextWithArrowIndicator
                        mode={
                          offer.remainingVolumeToBeNegotiated - bid.amount > 0
                            ? "up"
                            : offer.remainingVolumeToBeNegotiated - bid.amount <
                              0
                            ? "down"
                            : "equals"
                        }
                        // invertColors
                      >
                        <Tooltip title={t("Difference")}>
                          <Typography lineHeight={1}>
                            {`${Math.abs(
                              offer.remainingVolumeToBeNegotiated - bid.amount
                            ).toLocaleString(language, {
                              minimumFractionDigits: 3,
                            })} MWh`}
                          </Typography>
                        </Tooltip>
                      </TextWithArrowIndicator>
                    )}
                  </Box>
                }
              />
              <StyledIconProperty
                icon={<DollarSign size={16} />}
                label={offer.priceType === "Fixo" ? t("Price") : t("Spread")}
                value={
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <Box>
                      {offer.priceType === "Fixo"
                        ? toLocaleCurrencyString(bid.value, "BRL", language)
                        : `${
                            bid.value - (offer.pldValue ?? 0) / 100 < 0
                              ? "-"
                              : "+"
                          } ${toLocaleCurrencyString(
                            Math.abs(bid.value - (offer.pldValue ?? 0) / 100),
                            "BRL",
                            language
                          )}`}
                    </Box>
                    {offer.priceValue && (
                      <TextWithArrowIndicator
                        mode={
                          offer.priceValue - bid.value > 0
                            ? "up"
                            : offer.priceValue - bid.value < 0
                            ? "down"
                            : "equals"
                        }
                        invertColors={offer.offerType === "COMPRA"}
                      >
                        <Tooltip title={t("Difference")}>
                          <Typography lineHeight={1}>
                            {toLocaleCurrencyString(
                              Math.abs(offer.priceValue - bid.value),
                              "BRL",
                              language
                            )}
                          </Typography>
                        </Tooltip>
                      </TextWithArrowIndicator>
                    )}
                  </Box>
                }
              />
              <StyledIconProperty
                icon={<RayIcon />}
                label={t("Source Type")}
                value={operation.product.name}
              />
              <StyledIconProperty
                icon={<Map size={16} />}
                label={t("Submarket")}
                value={operation.submarket}
              />
              <StyledIconProperty
                icon={<Calendar size={16} />}
                label={t("Delivery Period")}
                value={getLocaleMonthAndYearPeriodShortString(
                  operation.deliveryPeriodStart,
                  operation.deliveryPeriodEnd,
                  language
                )}
              />
            </Box>
          </Box>
        </>
      ) : (
        <Typography
          variant="caption"
          px={7}
          mt={6}
          display="block"
          textAlign="center"
        >
          {isMeOperationOwner
            ? t("Your counterpart hasn't bid yet")
            : t("You haven't bid yet")}
        </Typography>
      )}

      <Box my={4} display="flex" gap={2}>
        {isMeOperationOwner ? (
          bid &&
          (offer.status === "ABERTA" ||
            offer.status === "PARCIALMENTE ENCERRADA") ? (
            <Button
              variant="outlined"
              color={Boolean(bid) ? "success" : undefined}
              onClick={handleAcceptBid}
            >
              {t("Accept")}
            </Button>
          ) : (
            <Button
              variant="outlined"
              color={Boolean(bid) ? "success" : undefined}
              onClick={handleOpenBidModal}
            >
              {t("Offer Details")}
            </Button>
          )
        ) : (
          <Button
            variant="outlined"
            onClick={handleOpenBidModal}
            disabled={isBidButtonDisabled}
          >
            {t("New Bid")}
          </Button>
        )}
      </Box>
    </>
  );
}
