import React, { useMemo } from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import {
  Alert as MuiAlert,
  Box,
  CircularProgress,
  Autocomplete,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { getISOYearAndMonth } from "../../utils/date";
import { subMonths } from "date-fns";
import { useQuery } from "@tanstack/react-query";
import { getMonthlyPld } from "../../services/pld";
import * as firestore from "firebase/firestore";
import { useFirestoreQuery } from "../../hooks/useFirestoreQuery";
import { submarketColors } from "../../constants";
import { OrderBookProps, TableOrderBook } from "./OrderBook/TableOrderBook";
import { OfferBookItem } from "../directBuyAndSell/entities/OfferBookItem";
import { OperationOrderBookResume } from "./entities/data-objects/OfferOrderBookResume";
import { useGetOffersOrderBook } from "./hooks/useGetOffersOrderBook";
// import { useGetLastTransactionValue } from "./hooks/useGetLastTransactionValue";

const Alert = styled(MuiAlert)(spacing);

const InfoBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24;
`;

const productNames: any = {
  "E-CQ1": "CQ1",
  "E-CQ5": "CQ5",
  "E-CON": "Conv",
  "E-#I0": "Inc 0%",
  "E-#I5": "Inc 50%",
  "E-#I8": "Inc 80%",
  "E-#I1": "Inc 100%",
};

export function BoxOrderBook() {
  const { t } = useTranslation();

  const lastMonth = React.useMemo(
    () => getISOYearAndMonth(subMonths(new Date(), 1)),
    []
  );

  const [productFilterValue, setProductFilterValue] =
    React.useState<string>("default1");
  const [submarket, setSubmarket] = React.useState<string>("SE/CO");

  const {
    data: pldData,
    isLoading: isLoadingPld,
    isError: isErrorPld,
  } = useQuery(["/pld/month", lastMonth, lastMonth], () =>
    getMonthlyPld(lastMonth, lastMonth)
  );

  const {
    data: [products],
  } = useFirestoreQuery<{ name: string; assetsAvailableCode: string }>(() => {
    return [
      firestore.query(
        firestore.collection(firestore.getFirestore(), "products")
      ),
    ];
  }, []);

  const {
    data: [quotes],
  } = useFirestoreQuery<{
    assetCode: string;
    quote: number;
    submarket: string;
    status: "valued" | "devalued" | "remained";
    currentSpread: string;
  }>(() => {
    return [
      firestore.query(firestore.collection(firestore.getFirestore(), "quotes")),
    ];
  }, []);

  // products, productFilterValue, submarket, quotes

  const product = products?.find(
    (product) => product.id === productFilterValue
  );

  const quote = quotes?.find(
    (quote) =>
      quote.assetCode === product?.assetsAvailableCode &&
      quote.submarket === submarket
  );

  const currentSpread = quote?.currentSpread
    ? parseInt(quote?.currentSpread) / 100
    : 0;

  const currentStatus = quote?.status ? quote?.status : "remained";

  const productsSorted = useMemo(() => {
    if (!products) return [];

    return products.sort((a, b) => {
      const assetA = a.name.toString().toUpperCase();
      const assetB = b.name.toString().toUpperCase();
      return assetA.localeCompare(assetB, undefined, { numeric: true });
    });
  }, [products]);

  // const { orders: transactions } = useGetLastTransactionValue({
  //   page: 1,
  //   itemsPerPage: 10,
  //   productFilterValue,
  //   submarketFilterValue: submarket,
  // });

  const { offersData } = useGetOffersOrderBook({
    submarket,
    productId: productFilterValue,
    limit: 200,
  });

  const handleChooseNameProduct = React.useCallback(
    (productId: string) => {
      return products?.find((item) => item.id === productId)
        ?.assetsAvailableCode;
    },
    [products]
  );

  function handleBuyOffer(data: OperationOrderBookResume[] | undefined) {
    return (
      data?.map((item) => {
        const spread = item.getBuyOffer()?.getSpread() ?? 0;
        const volume = item.getBuyOffer()?.getRemainingVolume() ?? 0;
        const total = (item.getBuyOffer()?.getValue() ?? 0) * volume;
        const closeAt = item.getCloseAt();

        return {
          value: spread,
          volume,
          total,
          offerCloseDateTime: closeAt,
        };
      }) ?? []
    );
  }

  function handleSellOffer(data: OperationOrderBookResume[] | undefined) {
    return (
      data?.map((item) => {
        const spread = item.getSellOffer()?.getSpread() ?? 0;
        const volume = item.getSellOffer()?.getRemainingVolume() ?? 0;
        const total = (item.getSellOffer()?.getValue() ?? 0) * volume;
        const closeAt = item.getCloseAt();

        return {
          value: spread,
          volume,
          total,
          offerCloseDateTime: closeAt,
        };
      }) ?? []
    );
  }

  const buyOffers = React.useMemo<Array<OfferBookItem>>(() => {
    return handleBuyOffer(offersData?.buyOffers);
  }, [offersData?.buyOffers]);

  const sellOffers = React.useMemo<Array<OfferBookItem>>(() => {
    return handleSellOffer(offersData?.sellOffers);
  }, [offersData?.sellOffers]);

  const dataBuyOrderBook = React.useMemo<OrderBookProps[]>(
    () =>
      [
        {
          name: productNames[
            handleChooseNameProduct(productFilterValue) ?? "E-CQ5"
          ],
          orders: buyOffers,
        },
      ].filter((item) => item.orders.length),
    [buyOffers, productFilterValue, handleChooseNameProduct]
  );

  const dataSellOrderBook = React.useMemo<OrderBookProps[]>(
    () =>
      [
        {
          name: productNames[
            handleChooseNameProduct(productFilterValue) ?? "E-CQ5"
          ],
          orders: sellOffers,
        },
      ].filter((item) => item.orders.length),
    [sellOffers, productFilterValue, handleChooseNameProduct]
  );

  // const lastPriceOrder: {
  //   value: number;
  //   status: "valued" | "devalued" | "remained";
  // } = React.useMemo(() => {
  //   const lastPriceOrder =
  //     (transactions?.[0]?.getBuyOffer()
  //       ? transactions?.[0]?.getBuyOffer()
  //       : transactions?.[0]?.getSellOffer()
  //     )?.getSpread() ?? 0;
  //   const penultimatePriceOrder =
  //     (transactions?.[1]?.getBuyOffer()
  //       ? transactions?.[1]?.getBuyOffer()
  //       : transactions?.[1]?.getSellOffer()
  //     )?.getSpread() ?? 0;
  //   if (lastPriceOrder > penultimatePriceOrder)
  //     return {
  //       value: lastPriceOrder,
  //       status: "valued",
  //     };
  //   if (lastPriceOrder < penultimatePriceOrder)
  //     return {
  //       value: lastPriceOrder,
  //       status: "devalued",
  //     };
  //   return {
  //     value: lastPriceOrder,
  //     status: "remained",
  //   };
  // }, [transactions]);

  if (isErrorPld) {
    return (
      <Alert mt={2} mb={3} severity="error">
        {t("Something went wrong.")}
      </Alert>
    );
  }

  if (isLoadingPld || pldData === undefined) {
    return (
      <InfoBox>
        <CircularProgress />
      </InfoBox>
    );
  }

  return (
    <Paper sx={{ p: 2 }}>
      <Box
        display="inline-flex"
        justifyContent="space-between"
        width="100%"
        mb={5}
      >
        <Typography
          width="max-content"
          fontWeight="bold"
          display="flex"
          alignItems="center"
        >
          Livro de Ordens
        </Typography>
        <Box display="inline-flex" gap={3}>
          <Box minWidth={80}>
            <Autocomplete
              value={productFilterValue}
              onChange={(e, data) => {
                setProductFilterValue(data);
              }}
              openOnFocus
              handleHomeEndKeys
              disablePortal
              disableClearable
              options={productsSorted?.map((item) => item.id) ?? []}
              getOptionLabel={(option) =>
                products?.find((product) => product.id === option)?.name ??
                option
              }
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("Source Type")}
                  placeholder={t("All")}
                  InputLabelProps={{ shrink: true }}
                  variant="standard"
                />
              )}
            />
          </Box>
          <Box minWidth={80}>
            <Autocomplete
              value={submarket}
              onChange={(e, data) => {
                setSubmarket(data);
              }}
              openOnFocus
              handleHomeEndKeys
              disablePortal
              disableClearable
              options={Object.keys(submarketColors)}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("Submarket")}
                  placeholder={t("All")}
                  InputLabelProps={{ shrink: true }}
                  variant="standard"
                />
              )}
            />
          </Box>
        </Box>
      </Box>
      <TableOrderBook
        dataBuy={dataBuyOrderBook}
        currentValue={currentSpread}
        status={currentStatus}
        dataSell={dataSellOrderBook}
      />
    </Paper>
  );
}
