import { useQuery } from "@tanstack/react-query";
import { getFixedDeliveryPeriod } from "../infra/api/SpotMarketInfra";

export function useGetFixedDeliveryPeriod() {
  const { data: fixedDeliveryPeriod, ...rest } = useQuery(
    ["getFixedDeliveryPeriod"],
    getFixedDeliveryPeriod
  );

  return { fixedDeliveryPeriod, ...rest };
}
