import { useQuery } from "@tanstack/react-query";
import { getOperatorData } from "../infra/api/SpotMarketInfra";

export function useGetOperatorData(operatorId: string) {
  const { data: operatorData, ...rest } = useQuery(
    ["getOperatorData", operatorId],
    () => getOperatorData(operatorId)
  );

  return { operatorData, ...rest };
}
