import React from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card,
  CardContent,
  CircularProgress,
  Divider as MuiDivider,
  Grid,
  Link,
  Tab,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useTranslation } from "react-i18next";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { EnhancedTable, HeadCell } from "../../components/EnhancedTable";
import {
  getCurrencySymbol,
  toLocaleCurrencyStringWithoutSymbol,
} from "../../utils/string";
import { submarketColors } from "../../constants";
import LineChart from "./LineChart";
import { useQuery } from "@tanstack/react-query";
import { getMonthlyPld } from "../../services/pld";
import { getLocaleMonthAndYearShortString, parseDate } from "../../utils/date";
import { subMonths } from "date-fns";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Alert = styled(MuiAlert)(spacing);

const InfoBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24;
`;

export default function PldMonthlyAverage() {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const [now] = React.useState(new Date());

  const [endMonth, setEndMonth] = React.useState(
    subMonths(now, 1).toISOString().slice(0, 7)
  );

  const [startMonth, setStartMonth] = React.useState(
    subMonths(now, 12).toISOString().slice(0, 7)
  );

  const {
    data: pldData,
    isLoading,
    isError,
  } = useQuery(["/pld/month", startMonth, endMonth], () =>
    getMonthlyPld(startMonth, endMonth)
  );

  const submarkets = React.useMemo(
    () =>
      pldData && pldData.length > 0
        ? Object.keys(pldData[0].monthlyPldBySubmarket).sort().reverse()
        : [],
    [pldData]
  );

  const [value, setValue] = React.useState("0");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    setValue(pldData ? String(pldData.length - 1) : "0");
  }, [pldData]);

  const headCells = React.useMemo<Array<HeadCell>>(
    () => [
      {
        id: "submarket",
        alignment: "left",
        label: t("Submarket"),
        disableSort: true,
      },
      {
        id: "monthlyAverage",
        alignment: "right",
        label: `${t("Monthly Average")} (${getCurrencySymbol(
          "BRL",
          language
        )}/MWh)`,
        disableSort: true,
      },
    ],
    [language, t]
  );

  if (isError) {
    return (
      <Alert mt={2} mb={3} severity="error">
        {t("Something went wrong.")}
      </Alert>
    );
  }

  return (
    <React.Fragment>
      <Helmet title={t("Monthly Average")} />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            {t("Monthly Average")}
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              {t("Home")}
            </Link>
            <Typography>{t("Monthly Average")}</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6} justifyContent="center">
        <Grid item xs={12} lg={4}>
          <Card sx={{ height: "100%" }}>
            <CardContent>
              <Box textAlign="center">
                <Typography variant="h6">{t("Monthly Average")}</Typography>
              </Box>

              <Box
                textAlign="center"
                display="flex"
                flexDirection="column"
                alignItems="center"
                p={2}
                gap={1}
              >
                <Box display="flex" gap={2} mt={1}>
                  <TextField
                    name="periodStart"
                    type="month"
                    value={startMonth}
                    onChange={(e) => setStartMonth(e.target.value)}
                  />
                  <Box mt={6}>{t("to")}</Box>
                  <TextField
                    name="periodEnd"
                    type="month"
                    value={endMonth}
                    onChange={(e) => setEndMonth(e.target.value)}
                  />
                </Box>
              </Box>

              <Box sx={{ width: "100%", typography: "body1" }}>
                {isLoading || pldData === undefined ? (
                  <InfoBox>
                    <CircularProgress />
                  </InfoBox>
                ) : (
                  <TabContext value={value}>
                    <TabList
                      onChange={handleChange}
                      aria-label="lab API tabs example"
                      variant="scrollable"
                      scrollButtons="auto"
                      allowScrollButtonsMobile
                      // variant="fullWidth"
                      // sx={{
                      //   ".MuiTabs-flexContainer": { justifyContent: "center" },
                      // }}
                    >
                      {pldData.map((item, idx) => {
                        const startMonthDate = parseDate(
                          typeof item.to === "string"
                            ? item.to
                            : item.to._seconds * 1000 +
                                item.to._nanoseconds / 1000000
                        );

                        return (
                          <Tab
                            label={
                              startMonthDate
                                ? getLocaleMonthAndYearShortString(
                                    startMonthDate,
                                    language
                                  )
                                : "-"
                            }
                            value={String(idx)}
                            key={String(idx)}
                            sx={{ minWidth: 56 }}
                          />
                        );
                      })}
                    </TabList>
                    {pldData.map((item, idx) => {
                      return (
                        <TabPanel value={String(idx)} key={String(idx)}>
                          <EnhancedTable
                            data={submarkets.map((submarket) => ({
                              submarket: submarket,
                              value: item.monthlyPldBySubmarket[submarket],
                            }))}
                            headCells={headCells}
                            avoidEmptyRows
                            disablePagination
                            keyExtractor={(row) => `${idx}:${row.submarket}`}
                            renderRow={(row) => {
                              return (
                                <TableRow hover tabIndex={-1}>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{
                                      fontWeight: 500,
                                      color: submarketColors[row.submarket],
                                    }}
                                  >
                                    {row.submarket}
                                  </TableCell>
                                  <TableCell align="right">
                                    {toLocaleCurrencyStringWithoutSymbol(
                                      Number(row.value),
                                      "BRL",
                                      language
                                    )}
                                  </TableCell>
                                </TableRow>
                              );
                            }}
                            footer={
                              <Box px={2} py={4}>
                                <Typography color="text.disabled">
                                  {t("Reference")}:{" "}
                                  {parseDate(
                                    typeof item.from === "string"
                                      ? item.from
                                      : item.from._seconds * 1000 +
                                          item.from._nanoseconds / 1000000
                                  )?.toLocaleDateString(language, {
                                    timeZone: "UTC",
                                  }) ?? "-"}{" "}
                                  {t("to")}{" "}
                                  {parseDate(
                                    typeof item.to === "string"
                                      ? item.to
                                      : item.to._seconds * 1000 +
                                          item.to._nanoseconds / 1000000
                                  )?.toLocaleDateString(language, {
                                    timeZone: "UTC",
                                  }) ?? "-"}
                                </Typography>
                              </Box>
                            }
                          />
                        </TabPanel>
                      );
                    })}
                  </TabContext>
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} lg={8}>
          {isLoading || pldData === undefined ? (
            <InfoBox>
              <CircularProgress />
            </InfoBox>
          ) : (
            <Grid container spacing={6} justifyContent="center">
              {submarkets.map((submarket) => {
                const datasetData = pldData.map((item) =>
                  Number(item.monthlyPldBySubmarket[submarket])
                );
                const labels = pldData.map((item) => {
                  const date = parseDate(
                    typeof item.to === "string"
                      ? item.to
                      : item.to._seconds * 1000 + item.to._nanoseconds / 1000000
                  );

                  return date !== null
                    ? getLocaleMonthAndYearShortString(date, language) ?? "-"
                    : "-";
                });

                return (
                  <Grid item xs={12} sm={6} key={submarket}>
                    <LineChart
                      data={datasetData}
                      labels={labels}
                      title={
                        <span style={{ color: submarketColors[submarket] }}>
                          {submarket}
                        </span>
                      }
                      color={submarketColors[submarket]}
                      label={`PLD (${getCurrencySymbol("BRL", language)}/MWh)`}
                      height={165}
                    />
                  </Grid>
                );
              })}
            </Grid>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
