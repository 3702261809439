import { useMutation } from "@tanstack/react-query";
import { updateTunnelLimits } from "../infra/api/TunnelLimitsInfra";

type Error = {
  message: string;
  statusCode: number;
};

export function useSubmitUpdateTunnelLimits(options?: {
  onSuccess?: () => void;
  onError?: (error: Error) => void;
}) {
  const { mutate: submitUpdateTunnelLimitsMutate, ...rest } = useMutation(
    updateTunnelLimits,
    options
  );

  return { submitUpdateTunnelLimitsMutate, ...rest };
}
