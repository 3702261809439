import { ChatroomRepositoryImpl } from "../infra/api/ChatroomRepositoryImpl";

export abstract class ChatroomRepository {
  abstract deleteChatroom(chatroomId: string): Promise<void>;
}

export class DeleteChatroomService {
  private chatroomRepository: ChatroomRepository;

  constructor() {
    this.chatroomRepository = new ChatroomRepositoryImpl();
  }

  public async deleteChatroom(chatroomId: string) {
    return this.chatroomRepository.deleteChatroom(chatroomId);
  }
}
