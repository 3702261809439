import { useQuery } from "@tanstack/react-query";
import {
  getParticipantBankData,
  getParticipantBankDataByAssetId,
} from "../infra/api/BankInfra";

export function useGetParticipantBankDataByAssetId(assetRequestId: string) {
  const { data: participantBankData, ...rest } = useQuery(
    ["getParticipantBankData", assetRequestId],
    () => getParticipantBankDataByAssetId(assetRequestId)
  );

  return { participantBankData, ...rest };
}

export function useGetParticipantBankData() {
  const { data: participantBankData, ...rest } = useQuery(
    ["getParticipantBankData"],
    () => getParticipantBankData()
  );

  return { participantBankData, ...rest };
}
