import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

export const BuyOfferStatusHeader: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box
      display="inline-flex"
      flexDirection="column"
      alignItems="center"
      lineHeight={1}
    >
      <span>{t("Status")}</span>

      <Box mt={2}>
        <Box
          sx={{
            minWidth: "7ch",
            p: 1,
            border: "1px solid #00AC30",
            fontSize: "0.75rem",
            textAlign: "center",
          }}
        >
          {t("COMPRA")}
        </Box>
      </Box>
    </Box>
  );
};
