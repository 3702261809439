import React from "react";
import { Navigate } from "react-router-dom";

import useChooseParticipant from "../../hooks/useChooseParticipant";
import Page500 from "../../pages/auth/Page500";
import useAuth from "../../hooks/useAuth";
import { LoadingPage } from "../LoadingPage";

interface ChooseParticipantType {
  children: React.ReactNode;
}

function ChooseParticipant({ children }: ChooseParticipantType) {
  const { currentParticipant, isInitialized, isError } = useChooseParticipant();
  const { user } = useAuth();

  if (!isInitialized) {
    return <LoadingPage />;
  }

  if (isError) {
    return <Page500 />;
  }

  if (!currentParticipant && user?.role === "user") {
    return <Navigate to="/auth/choose-participant" />;
  }

  return <React.Fragment>{children}</React.Fragment>;
}

export default ChooseParticipant;
