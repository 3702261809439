import { Box } from "@mui/material";

export interface TimeBoxProps {
  time: {
    hours: string;
    minutes: string;
    seconds: string;
  };
}

export function TimeBox({ time }: TimeBoxProps) {
  return (
    <Box display="inline-flex" textAlign="center">
      <div>{time.hours}</div>
      <div>:</div>
      <div style={{ width: "2ch" }}>{time.minutes}</div>
      <div>:</div>
      <div style={{ width: "2ch", textAlign: "left" }}>{time.seconds}</div>
    </Box>
  );
}
