import { Observable, Subscription } from "rxjs";
import { NotificationsObservableImpl } from "../infra/firestore/NotificationsObservableImpl";
import { Notification } from "../entities/Notification";

export type NotificationsData = {
  notifications: Notification[];
  unreadedNotificationsCounter: number;
};

export type options = {
  operatorId: number;
};

export abstract class NotificationsObservable extends Observable<NotificationsData> {
  abstract subscribeWithOptions(
    options: options,
    next?: ((value: NotificationsData) => void) | null | undefined,
    error?: ((error: any) => void) | null | undefined,
    complete?: (() => void) | null | undefined
  ): Subscription;

  abstract setOptions(options: options): void;
}

export class GetNotificationsObservableService {
  private observable: NotificationsObservable | null = null;

  public getNotificationsObservable() {
    if (this.observable === null) {
      this.observable = new NotificationsObservableImpl();
    }

    return this.observable;
  }
}
