import { useQuery } from "@tanstack/react-query";
import {
  GetDepositsAndWithdrawalsRequestsProps,
  getDepositsAndWithdrawalsRequests,
} from "../infra/api/BankInfra";

export function useGetDepositsAndWithdrawalsRequests(
  props: GetDepositsAndWithdrawalsRequestsProps
) {
  const { data: depositsAndWithdrawalsRequestsData, ...rest } = useQuery(
    ["getDepositsAndWithdrawalsRequests", ...Object.values(props)],
    () => getDepositsAndWithdrawalsRequests(props)
  );

  return { depositsAndWithdrawalsRequestsData, ...rest };
}
