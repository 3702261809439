/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Alert as MuiAlert,
  CardContent,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
  Box,
  CircularProgress,
  Button as MuiButton,
  Autocomplete,
  Paper,
  TableRow,
  TableCell,
  Tooltip,
  Switch,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import { Done as DoneIcon } from "@mui/icons-material";
import { EnhancedTable, HeadCell } from "../../components/EnhancedTable";
import { formatCNPJ } from "../../utils/string";
import { ApiError } from "../../types/error";
import { StyledTextField } from "../../components/StyledTextField";
import {
  getActiveParticipants,
  getActiveProducts,
  getBalanceBySubmarket,
  getParticipantProductIds,
  updateAssociateParticipantProducts,
} from "../../components/products/infra/ProductsInfra";
import { submarketColors } from "../../constants";
import { toFixedDecimalPlaces } from "../../utils/number";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import { getISOYearAndMonth } from "../../utils/date";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Button = styled(MuiButton)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Alert = styled(MuiAlert)(spacing);

const InfoBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24;
`;

export default function EditProduct() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const previousMonth = DateTime.fromISO(DateTime.now().toISO()!)
    .minus({ month: 1 })
    .toFormat("yyyy-MM-dd");

  const profileKeyByProductId = React.useRef<any>({});

  const profileCodeByProductId = React.useRef<any>({});

  const [selectedParticipant, setSelectedParticipant] = React.useState<
    string | null
  >(null);

  const [editingParticipantProducts, setEditingParticipantProducts] =
    React.useState<Array<string> | null>(null);

  const [dateSelected, setDateSelected] = React.useState<Date | null>(
    new Date(previousMonth)
  );

  const {
    data: activeParticipants,
    isError,
    isLoading,
  } = useQuery(["/active-participants"], () => getActiveParticipants());

  const {
    data: activeProducts,
    isError: isErrorActiveProducts,
    isLoading: isLoadingActiveProducts,
  } = useQuery(["/active-products"], () => getActiveProducts());

  const {
    data: balanceBySubmarket,
    isError: isErrorBalanceBySubmarket,
    isLoading: isLoadingBalanceBySubmarket,
  } = useQuery(
    ["getBalanceBySubmarket", selectedParticipant, dateSelected],
    () => getBalanceBySubmarket(selectedParticipant, handleDate(dateSelected)),
    {
      refetchOnWindowFocus: false,
    }
  );

  const {
    // data: participantProducts,
    isError: isErrorParticipantProducts,
    isFetching: isFetchingParticipantProducts,
  } = useQuery(
    ["getParticipantProducts", selectedParticipant],
    () => getParticipantProductIds(selectedParticipant),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data) {
          const productIds: Array<string> = data?.map((p) => p.id);
          const profileKeyByProductIdObj: any = {};
          const profileCodeByProductIdObj: any = {};
          data.forEach((d) => {
            profileKeyByProductIdObj[d.id] = d.profileKey;
            profileCodeByProductIdObj[d.id] = d.profileCode;
          });
          setEditingParticipantProducts(productIds);
          profileKeyByProductId.current = profileKeyByProductIdObj;
          profileCodeByProductId.current = profileCodeByProductIdObj;
        }
      },
      onError: () => {
        setEditingParticipantProducts(null);
      },
    }
  );

  const submitUpdateAssociateParticipantProducts = useMutation<
    any,
    unknown,
    {
      productList: Array<string>;
      participantId: number | string;
      profileKeyByProductId: any;
      profileCodeByProductId: any;
    }
  >(updateAssociateParticipantProducts, {
    onSuccess: (_, { participantId }) => {
      queryClient.invalidateQueries(["getParticipantProducts", participantId]);

      enqueueSnackbar(t("The operation completed successfully"), {
        variant: "success",
      });
    },
    onError: (e) => {
      enqueueSnackbar(
        t((e as ApiError | null)?.error ?? "Something went wrong."),
        {
          variant: "error",
        }
      );
    },
  });

  const onInvalidSubmit = React.useCallback(() => {
    enqueueSnackbar(t("You must fill in all required fields"), {
      variant: "error",
    });
  }, [enqueueSnackbar, t]);

  const onSubmit = React.useCallback(() => {
    if (!selectedParticipant || editingParticipantProducts === null) {
      onInvalidSubmit();
      return;
    }

    submitUpdateAssociateParticipantProducts.mutate({
      participantId: selectedParticipant,
      productList: editingParticipantProducts,
      profileKeyByProductId: profileKeyByProductId.current,
      profileCodeByProductId: profileCodeByProductId.current,
    });
  }, [
    editingParticipantProducts,
    onInvalidSubmit,
    profileCodeByProductId,
    profileKeyByProductId,
    selectedParticipant,
    submitUpdateAssociateParticipantProducts,
  ]);

  const handleCheck = React.useCallback(
    (productId: string) => {
      if (editingParticipantProducts === null) return;

      const idx = editingParticipantProducts.findIndex(
        (item) => item === productId
      );

      if (idx === -1) {
        setEditingParticipantProducts((oldArray) => {
          if (oldArray === null) return null;
          const newArray = [...oldArray, productId];
          return newArray;
        });
      } else {
        setEditingParticipantProducts((oldArray) => {
          if (oldArray === null) return null;
          const newArray = [...oldArray];
          newArray.splice(idx, 1);
          delete profileKeyByProductId.current[productId];
          delete profileCodeByProductId.current[productId];
          return newArray;
        });
      }
    },
    [editingParticipantProducts]
  );

  const handleCheckAll = React.useCallback(() => {
    if (editingParticipantProducts === null) return;

    setEditingParticipantProducts(activeProducts?.map((item) => item.id) ?? []);
  }, [activeProducts, editingParticipantProducts]);

  function handleBalaceBySubmarket(value: number) {
    if (value === 0) return "-";
    return toFixedDecimalPlaces(value, 3).toLocaleString(language, {
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    });
  }

  function handleDate(date: Date | null) {
    return getISOYearAndMonth(date)?.split("-").reverse().join("-");
  }

  function handleSelectedParticipant(data: any) {
    setSelectedParticipant(data);
    setDateSelected(new Date(previousMonth));
  }

  const headCells = React.useMemo<Array<HeadCell>>(
    () => [
      {
        id: "name",
        alignment: "left",
        label: t("Product Name"),
        disableSort: true,
      },
      {
        id: "profileKey",
        alignment: "center",
        label: t("Profile Key"),
        disableSort: true,
      },
      {
        id: "profileCode",
        alignment: "center",
        label: t("Profile Code"),
        disableSort: true,
      },
      {
        id: "midWestCurrentQuantity",
        alignment: "center",
        label: (
          <Box display="inline-flex" flexDirection="column" alignItems="center">
            <Typography
              fontSize={14}
              fontWeight="600"
              color={submarketColors["SE/CO"]}
            >
              SE/CO
            </Typography>
            <Typography fontSize={14} fontWeight="500" textAlign={"center"}>
              {t("Current Quantity")} SE/CO (MWh)
            </Typography>
          </Box>
        ),
        disableSort: true,
      },
      {
        id: "southCurrentQuantity",
        alignment: "center",
        label: (
          <Box display="inline-flex" flexDirection="column" alignItems="center">
            <Typography
              fontSize={14}
              fontWeight="600"
              color={submarketColors.S}
            >
              S
            </Typography>
            <Typography fontSize={14} fontWeight="500" textAlign={"center"}>
              {t("Current Quantity")} {t("South")} (MWh)
            </Typography>
          </Box>
        ),
        disableSort: true,
      },
      {
        id: "northWestCurrentQuantity",
        alignment: "center",
        label: (
          <Box display="inline-flex" flexDirection="column" alignItems="center">
            <Typography
              fontSize={14}
              fontWeight="600"
              color={submarketColors.NE}
            >
              NE
            </Typography>
            <Typography fontSize={14} fontWeight="500" textAlign={"center"}>
              {t("Current Quantity")} {t("Northeast")} (MWh)
            </Typography>
          </Box>
        ),
        disableSort: true,
      },
      {
        id: "northCurrentQuantity",
        alignment: "center",
        label: (
          <Box display="inline-flex" flexDirection="column" alignItems="center">
            <Typography
              fontSize={14}
              fontWeight="600"
              color={submarketColors.N}
            >
              N
            </Typography>
            <Typography fontSize={14} fontWeight="500" textAlign={"center"}>
              {t("Current Quantity")} {t("North")} (MWh)
            </Typography>
          </Box>
        ),
        disableSort: true,
      },
      {
        id: "actions",
        alignment: "right",
        label: t("Actions"),
        disableSort: true,
      },
    ],
    [t]
  );

  if (isError || isErrorActiveProducts) {
    return (
      <Alert mt={2} mb={3} severity="error">
        {t("Something went wrong.")}
      </Alert>
    );
  }

  if (
    isLoading ||
    isLoadingActiveProducts ||
    activeParticipants === undefined ||
    activeProducts === undefined
    // product === undefined
  ) {
    return (
      <InfoBox>
        <CircularProgress />
      </InfoBox>
    );
  }

  const setParticipantVsProductProfileCode = (
    productId: string,
    value: string
  ) => {
    profileCodeByProductId.current[productId] = value;
  };

  const setParticipantVsProductProfileKey = (
    productId: string,
    value: string
  ) => {
    profileKeyByProductId.current[productId] = value;
  };

  return (
    <React.Fragment>
      <Helmet title={t("Associate Participants")} />

      <Typography variant="h3" gutterBottom display="inline">
        {t("Associate Participants")}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          {t("Home")}
        </Link>
        <Link component={NavLink} to="/settings/products/list">
          {t("Product Maintenance")}
        </Link>
        <Typography>{t("Associate Participants")}</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Paper>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit();
          }}
        >
          <CardContent>
            <Typography variant="h6" gutterBottom>
              {t("Participant")}
            </Typography>

            <Autocomplete
              value={selectedParticipant}
              onChange={(e, data) => handleSelectedParticipant(data)}
              openOnFocus
              handleHomeEndKeys
              disablePortal
              options={activeParticipants.map((item) => item.id)}
              getOptionLabel={(option) => {
                const participant = activeParticipants.find(
                  (item) => item.id === option
                );
                if (participant === undefined) return option;
                return `${participant.tradeName} - ${formatCNPJ(
                  participant.cnpj
                )}`;
              }}
              sx={{ my: 2 }}
              fullWidth
              renderInput={(params) => (
                <StyledTextField
                  {...params}
                  placeholder={t("Choose a Participant")}
                  // error={!!errors.measurementUnit}
                  // helperText={errors.measurementUnit?.message}
                />
              )}
            />
          </CardContent>

          {selectedParticipant !== null && (
            <CardContent sx={{ mt: 4 }}>
              {isErrorParticipantProducts || isErrorBalanceBySubmarket ? (
                <Alert mt={2} mb={3} severity="error">
                  {t("Something went wrong.")}
                </Alert>
              ) : isFetchingParticipantProducts ||
                isLoadingBalanceBySubmarket ||
                editingParticipantProducts === undefined ||
                editingParticipantProducts === null ? (
                <InfoBox>
                  <CircularProgress />
                </InfoBox>
              ) : (
                <>
                  <Typography pl="12px" variant="h6" gutterBottom>
                    {t("Associate Products")}
                  </Typography>

                  <EnhancedTable
                    title={t("Product List")}
                    subtitle={t("Energetic balance")}
                    data={activeProducts}
                    headCells={headCells}
                    initialOrderBy={headCells[0].id}
                    avoidEmptyRows
                    toolbarFiltersArea={
                      <>
                        <Box
                          display="inline-flex"
                          gap={3}
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Typography variant="h6">{t("Month")}:</Typography>

                          <DesktopDatePicker
                            defaultValue={dateSelected}
                            onChange={(e) => setDateSelected(e)}
                            views={["year", "month"]}
                            maxDate={new Date(previousMonth)}
                            slotProps={{
                              openPickerButton: {
                                size: "small",
                                sx: {
                                  "& svg": {
                                    transform: "scale(0.75)",
                                  },
                                },
                              },
                            }}
                            slots={{
                              textField: StyledTextField,
                            }}
                          />
                        </Box>
                      </>
                    }
                    toolbarActionButton={
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleCheckAll}
                      >
                        <DoneIcon />
                        {t("Select All")}
                      </Button>
                    }
                    keyExtractor={(row, index) => row.id}
                    renderRow={(row, index) => {
                      const isProductChecked =
                        editingParticipantProducts.find(
                          (productId) => row.id === productId
                        ) !== undefined;

                      return (
                        <TableRow hover tabIndex={-1}>
                          <TableCell align="left">{row.name}</TableCell>
                          <TableCell align="left">
                            {isProductChecked && (
                              <StyledTextField
                                size="small"
                                required
                                onChange={(event) => {
                                  setParticipantVsProductProfileKey(
                                    row.id,
                                    event.target.value
                                  );
                                }}
                                defaultValue={
                                  profileKeyByProductId.current[row.id]
                                }
                              />
                            )}
                          </TableCell>
                          <TableCell align="left">
                            {isProductChecked && (
                              <StyledTextField
                                size="small"
                                required
                                defaultValue={
                                  profileCodeByProductId.current[row.id]
                                }
                                onChange={(event) => {
                                  setParticipantVsProductProfileCode(
                                    row.id,
                                    event.target.value
                                  );
                                }}
                              />
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {isProductChecked && (
                              <Typography>
                                {handleBalaceBySubmarket(
                                  balanceBySubmarket?.assetBalances.find(
                                    (item) =>
                                      item.asset.code ===
                                        row.assetsAvailableCode &&
                                      item.submarket === "SE/CO"
                                  )?.balance ?? 0
                                )}
                              </Typography>
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {isProductChecked && (
                              <Typography>
                                {handleBalaceBySubmarket(
                                  balanceBySubmarket?.assetBalances.find(
                                    (item) =>
                                      item.asset.code ===
                                        row.assetsAvailableCode &&
                                      item.submarket === "S"
                                  )?.balance ?? 0
                                )}
                              </Typography>
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {isProductChecked && (
                              <Typography>
                                {handleBalaceBySubmarket(
                                  balanceBySubmarket?.assetBalances.find(
                                    (item) =>
                                      item.asset.code ===
                                        row.assetsAvailableCode &&
                                      item.submarket === "NE"
                                  )?.balance ?? 0
                                )}
                              </Typography>
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {isProductChecked && (
                              <Typography>
                                {handleBalaceBySubmarket(
                                  balanceBySubmarket?.assetBalances.find(
                                    (item) =>
                                      item.asset.code ===
                                        row.assetsAvailableCode &&
                                      item.submarket === "N"
                                  )?.balance ?? 0
                                )}
                              </Typography>
                            )}
                          </TableCell>
                          <TableCell padding="none" align="right">
                            <Box mr={0} my={2} whiteSpace="nowrap">
                              <Tooltip
                                title={
                                  isProductChecked ? t("Disable") : t("Enable")
                                }
                              >
                                <Switch
                                  checked={isProductChecked}
                                  onChange={() => {
                                    handleCheck(row.id);
                                  }}
                                />
                              </Tooltip>
                            </Box>
                          </TableCell>
                        </TableRow>
                      );
                    }}
                  />

                  <Button
                    variant="contained"
                    color="primary"
                    mt={3}
                    type="submit"
                    disabled={
                      submitUpdateAssociateParticipantProducts.isLoading
                    }
                  >
                    {t("Save")}
                  </Button>
                </>
              )}
            </CardContent>
          )}
        </form>
      </Paper>
    </React.Fragment>
  );
}
