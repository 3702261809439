import { Observable, Subscription } from "rxjs";
import { PublicBid } from "../entities/Bid";
import { PanelOperationsObservableImpl } from "../infra/firestore/BidsFromOfferObservableInfra";

export type options = {
  page: number;
  participant: number;
  // offerId: string;
  operationId: number;
  bidType: "BUY" | "SELL";
};

export type bidsData = {
  bids: PublicBid[];
};

export abstract class BidsFromOfferObservable extends Observable<bidsData> {
  abstract subscribeWithOptions(
    options: options,
    next?: ((value: bidsData) => void) | null | undefined,
    error?: ((error: any) => void) | null | undefined,
    complete?: (() => void) | null | undefined
  ): Subscription;

  abstract setOptions(options: options): void;
}

export class GetBidsFromOfferObservableService {
  private observable: BidsFromOfferObservable | null = null;

  public getBidsFromOfferObservable() {
    if (this.observable === null) {
      this.observable = new PanelOperationsObservableImpl();
    }

    return this.observable;
  }
}
