import { Contract } from "../types/contracts";
import { balcaoApiInstance } from "../utils/axios";

export async function getChildrenOfferAndContracts(
  participantId: number | string | undefined | null,
  filterOptions: {
    origin: null | "PANEL" | "SPOT";
    product: null | string;
    situation: null | "SIGNED" | "PENDING" | "ACCEPTANCE_TERMS";
    dateOfIssueBegin: null | Date;
    dateOfIssueEnd: null | Date;
    submarket: null | string;
  },
  paginationOptions?: {
    limit?: number;
    page?: number;
  }
) {
  const filters = [];

  if (filterOptions.product) {
    filters.push({
      productId: filterOptions.product,
    });
  }

  if (filterOptions.submarket) {
    filters.push({
      submarket: filterOptions.submarket,
    });
  }

  if (
    filterOptions.situation &&
    filterOptions.situation !== "ACCEPTANCE_TERMS"
  ) {
    filters.push({
      isContractSigned: filterOptions.situation === "SIGNED" ? true : false,
    });
  }

  if (
    filterOptions.situation &&
    filterOptions.situation === "ACCEPTANCE_TERMS"
  ) {
    filters.push({
      isAcceptanceTermAccepted: false,
    });
    filters.push({
      isContractSigned: false,
    });
  } else if (filterOptions.situation && filterOptions.situation === "PENDING") {
    filters.push({
      isAcceptanceTermAccepted: true,
    });
    filters.push({
      isContractSigned: false,
    });
  }

  if (filterOptions.origin) {
    filters.push({
      market: filterOptions.origin === "PANEL" ? "CONTRACT" : "SPOT",
    });
  }

  if (filterOptions.dateOfIssueBegin) {
    filters.push({
      dateOfIssueBegin: filterOptions.dateOfIssueBegin,
    });
  }

  if (filterOptions.dateOfIssueEnd) {
    filters.push({
      dateOfIssueEnd: filterOptions.dateOfIssueEnd,
    });
  }

  const { data } = await balcaoApiInstance.get<{
    childrenOffersAndOffers: Array<Contract>;
    counter: number;
  }>(`/contracts?participantId=${participantId}`, {
    params: {
      limit: paginationOptions?.limit ?? 10,
      page: paginationOptions?.page ?? 0,
      filters: JSON.stringify(filters),
    },
  });

  return {
    total: data.counter,
    childrenOffersAndOffers: data.childrenOffersAndOffers,
  };
}

export interface AcceptAcceptanceTermProps {
  offerId: number | string | undefined | null;
  childrenOfferId: number | string | undefined | null;
  ownerOrWinner: "owner" | "winner";
}
export async function acceptAcceptanceTerm({
  offerId,
  childrenOfferId,
  ownerOrWinner,
}: AcceptAcceptanceTermProps) {
  await balcaoApiInstance.post(
    `/contracts/${ownerOrWinner}/accept-terms/${offerId}/${childrenOfferId}`
  );
}

export interface AcceptClosingStatementProps {
  offerId: number | string | undefined | null;
  childrenOfferId: number | string | undefined | null;
  ownerOrWinner: "owner" | "winner";
  data: Record<string, any>;
}
export async function acceptClosingStatement({
  offerId,
  childrenOfferId,
  ownerOrWinner,
  data,
}: AcceptClosingStatementProps) {
  await balcaoApiInstance.patch(
    `/contracts/${ownerOrWinner}/operation-close/${offerId}/${childrenOfferId}`,
    data
  );
}
