import React, { ReactNode } from "react";
import { Box, Typography } from "@mui/material";

export type SummaryListData = Array<
  Array<{
    label: string;
    value: ReactNode;
  }>
>;

export interface SummaryListProps {
  title?: string;
  subtitle?: string;
  divider?: JSX.Element;
  data: SummaryListData;
}

export function SummaryList({
  data,
  title,
  subtitle,
  divider,
}: SummaryListProps) {
  return (
    <>
      {(title || subtitle) && (
        <Box mb={8} maxWidth={558}>
          {title && (
            <Typography variant="h6" gutterBottom>
              {title}
            </Typography>
          )}
          {subtitle && (
            <Typography variant="body2" gutterBottom>
              {subtitle}
            </Typography>
          )}
        </Box>
      )}

      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        {data.map((group, idx) => {
          return (
            <React.Fragment key={idx}>
              {idx > 0 && <>{divider}</>}

              {group.map((item) => {
                return (
                  <React.Fragment key={item.label}>
                    {item.value === null ? (
                      <></>
                    ) : (
                      <Box>
                        <span>
                          {item.label}: {item.value}
                        </span>
                      </Box>
                    )}
                  </React.Fragment>
                );
              })}
            </React.Fragment>
          );
        })}
      </Box>
    </>
  );
}
