import { useMutation } from "@tanstack/react-query";
import { acceptAssetRequest } from "../infra/api/BankInfra";

export function useSubmitAcceptAssetRequest(id?: { onSuccess?: () => void }) {
  const { mutate: submitAcceptAssetRequestMutate, ...rest } = useMutation(
    acceptAssetRequest,
    id
  );

  return { submitAcceptAssetRequestMutate, ...rest };
}
