import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getCurrencySymbol } from "../../../../../utils/string";

export const SellOfferBestBidValueHeader: React.FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  return (
    <Box
      display="inline-flex"
      flexDirection="column"
      alignItems="center"
      lineHeight={1}
    >
      <span>{t("Best Bid")}</span>

      <Box display="flex" gap={8} mt={2}>
        <Box
          sx={(theme) => ({
            minWidth: "6ch",
            p: 1,
            border: `1px solid ${theme.palette.text.primary}`,
            fontSize: "0.75rem",
            textAlign: "center",
          })}
        >
          {getCurrencySymbol("BRL", language)}
        </Box>
        <Box
          sx={(theme) => ({
            minWidth: "6ch",
            p: 1,
            border: `1px solid ${theme.palette.text.primary}`,
            fontSize: "0.75rem",
            textAlign: "center",
          })}
        >
          MWh
        </Box>
      </Box>
    </Box>
  );
};
