import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from "react";

import * as firebaseApp from "firebase/app";
import * as firebaseAuth from "firebase/auth";
import * as firestore from "firebase/firestore";

import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "@tanstack/react-query";

import {
  FirebaseAuthContextType,
  ActionMap,
  AuthState,
  AuthUser,
} from "../types/auth";
import { firebaseConfig } from "../config";
import { balcaoApiInstance, registrationApiInstance } from "../utils/axios";

const INITIALIZE = "INITIALIZE";

if (!firebaseApp.getApps().length) {
  firebaseApp.initializeApp(firebaseConfig);
  firestore.initializeFirestore(firebaseApp.getApp(), {});
}

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  userExternalId: null,
};

type AuthActionTypes = {
  [INITIALIZE]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
};

type FirebaseActions =
  ActionMap<AuthActionTypes>[keyof ActionMap<AuthActionTypes>];

const reducer = (state: AuthState, action: FirebaseActions) => {
  if (action.type === INITIALIZE) {
    const { isAuthenticated, user } = action.payload;

    // getting user externalId
    const userToken = user?.accessToken.split(".")[1];
    const userExternalId = userToken
      ? JSON.parse(atob(userToken))["externalId"]
      : null;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      userExternalId: userExternalId ? parseInt(userExternalId) : null,
    };
  }
  return state;
};

const AuthContext = createContext<FirebaseAuthContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [interceptorBalcao, setInterceptorBalcao] = useState<number | null>(
    null
  );
  const [interceptorRegistration, setInterceptorRegistration] = useState<
    number | null
  >(null);

  const [profile, setProfile] = useState<firestore.DocumentData | undefined>();
  const [userIdToken, setUserIdToken] = useState("");
  const [state, dispatch] = useReducer(reducer, initialState);
  const queryClient = useQueryClient();

  const signOut = useCallback(async () => {
    await firebaseAuth.getAuth().signOut();
    queryClient.resetQueries();

    if (interceptorBalcao !== null) {
      balcaoApiInstance.interceptors.request.eject(interceptorBalcao);
      setInterceptorBalcao(null);
    }

    if (interceptorRegistration !== null) {
      registrationApiInstance.interceptors.request.eject(
        interceptorRegistration
      );
      setInterceptorRegistration(null);
    }
  }, [interceptorBalcao, interceptorRegistration, queryClient]);

  useEffect(
    () =>
      firebaseAuth.getAuth().onIdTokenChanged(async (user) => {
        if (user) {
          const { claims } = await user.getIdTokenResult();
          const systems = claims.systems as Array<string> | undefined;

          if (
            systems?.find(
              (system) => system === process.env.REACT_APP_SYSTEM_NAME
            )
          ) {
            user.getIdToken().then((token) => {
              setUserIdToken(token);
            });
            dispatch({
              type: INITIALIZE,
              payload: {
                isAuthenticated: true,
                user: {
                  ...user,
                  systems,
                },
              },
            });
          } else {
            signOut();
            enqueueSnackbar(
              t("User does not have permission to access this system."),
              {
                variant: "error",
              }
            );
          }
        } else {
          dispatch({
            type: INITIALIZE,
            payload: { isAuthenticated: false, user: null },
          });
        }

        setInterceptorBalcao((oldValue) => {
          if (oldValue !== null || !user) return oldValue;

          return balcaoApiInstance.interceptors.request.use(
            async (config) => {
              config.headers = {
                ...config.headers,
                "token-id": await firebaseAuth
                  .getAuth()!
                  .currentUser!.getIdToken(),
                Authorization:
                  "Bearer " +
                  (await firebaseAuth.getAuth()!.currentUser!.getIdToken()),
              };
              return config;
            },
            (error) => {
              return Promise.reject(error);
            }
          );
        });

        setInterceptorRegistration((oldValue) => {
          if (oldValue !== null || !user) return oldValue;

          return registrationApiInstance.interceptors.request.use(
            async (config) => {
              config.headers = {
                ...config.headers,
                "token-id": await user.getIdToken(),
                Authorization:
                  "Bearer " +
                  (await firebaseAuth.getAuth()!.currentUser!.getIdToken()),
              };
              return config;
            },
            (error) => {
              return Promise.reject(error);
            }
          );
        });
      }),
    [dispatch, enqueueSnackbar, signOut, t]
  );

  const signIn = (email: string, password: string) =>
    firebaseAuth.signInWithEmailAndPassword(
      firebaseAuth.getAuth(),
      email,
      password
    );

  const signInWithGoogle = () => {
    const provider = new firebaseAuth.GoogleAuthProvider();
    return firebaseAuth.signInWithPopup(firebaseAuth.getAuth(), provider);
  };

  const signInWithFaceBook = () => {
    const provider = new auth.FacebookAuthProvider();
    return firebaseAuth.signInWithPopup(firebaseAuth.getAuth(), provider);
  };

  const signInWithTwitter = () => {
    const provider = new firebaseAuth.TwitterAuthProvider();
    return firebaseAuth.signInWithPopup(firebaseAuth.getAuth(), provider);
  };

  const signUp = (
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ) =>
    firebaseAuth
      .createUserWithEmailAndPassword(firebaseAuth.getAuth(), email, password)
      .then((res) => {
        firestore.setDoc(
          firestore.doc(firestore.getFirestore(), `users/${res.user?.uid}`),
          {
            uid: res.user?.uid,
            email,
            displayName: `${firstName} ${lastName}`,
          }
        );
      });

  const resetPassword = async (email: string) => {
    await firebaseAuth.sendPasswordResetEmail(firebaseAuth.getAuth(), email);
  };

  const auth = { ...state.user };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "firebase",
        user: {
          id: auth.uid,
          email: auth.email,
          avatar: auth.avatar || profile?.avatar,
          displayName: auth.displayName || profile?.displayName,
          // role: "user",
          role: Boolean(
            (auth.systems as Array<string> | null)?.find(
              (system) => system === "REGISTRATION_PLATFORM"
            )
          )
            ? "admin"
            : "user",
          systems: auth.systems,
        },
        externalId: state.userExternalId,
        signIn,
        signUp,
        signInWithGoogle,
        signInWithFaceBook,
        signInWithTwitter,
        signOut,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
