import React from "react";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { BoxBuyOrSell } from "./BoxBuyOrSell";
import { HistoricTable } from "./HistoricTable";
import { BoxOrderBook } from "./BoxOrderBook";
import { BoxTradingView } from "./BoxTradingView";

export function SpotMarketPageContent() {
  // const {
  //   t,
  //   i18n: { language },
  // } = useTranslation();

  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid
          item
          gap={3}
          xs={12}
          display="flex"
          flexDirection={isMdUp ? "row-reverse" : "column"}
          // minHeight="815px"
        >
          <Grid
            item
            xs={12}
            md={6}
            gap={3}
            display="flex"
            flexDirection="column"
          >
            <BoxTradingView />
          </Grid>
          <Grid item md={6} xs={12} display="flex" gap={3}>
            <Grid
              item
              md={6}
              xs={12}
              // maxHeight="815px"
              // sx={{ backgroundColor: "red" }}
            >
              <BoxBuyOrSell />
            </Grid>
            <Grid
              item
              md={8}
              xs={12}
              sx={{ overflow: "auto" }}
              maxHeight="815px"
            >
              <BoxOrderBook />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} height="450px">
          <HistoricTable />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
