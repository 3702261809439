export class OfferResume {
  constructor(
    private id: string,
    private operationCode: string,
    private volume: number,
    private remainingVolume: number,
    private value: number | null,
    private bestBidValue: number | null,
    private bestBidVolume: number | null,
    private net: number,
    private spread: number | null,
    private isPldFixed: boolean,
    private currentParticipantBidVolume: number | null, // toDo verificar se é o melhor lance do participante, o último, ou se o participante só pode ter um bid por oferta
    private status: string,
    private type: "COMPRA" | "VENDA"
  ) {}

  public getId() {
    return this.id;
  }

  public getOperationCode() {
    return this.operationCode;
  }

  public getVolume() {
    return this.volume;
  }

  public getRemainingVolume() {
    return this.remainingVolume;
  }

  public getValue() {
    return this.value;
  }

  public getBestBidValue() {
    return this.bestBidValue;
  }

  public getBestBidVolume() {
    return this.bestBidVolume;
  }

  public getNet() {
    return this.net;
  }

  public getSpread() {
    return this.spread;
  }

  public getIsPldFixed() {
    return this.isPldFixed;
  }

  public getCurrentParticipantBidVolume() {
    return this.currentParticipantBidVolume === null
      ? 0
      : this.getCurrentParticipantBidVolume;
  }

  public getStatus() {
    return this.status;
  }

  public getType() {
    return this.type;
  }

  public isFreeBid() {
    return (
      (this.value === null || this.value === undefined) &&
      (this.spread === null || this.spread === undefined)
    );
  }

  public getSpreadOrValue() {
    return this.value || this.spread;
  }
}

export class OrderResume {
  constructor(
    private operationId: string,
    private participantId: number,
    private isOperationOwner: boolean | null,
    private product: string,
    private productMeasurementUnit: string,
    private submarketCode: string,
    private buyOffer: OfferResume | null,
    private sellOffer: OfferResume | null,
    private startAt: Date,
    private closeAt: Date,
    private status: string,
    private deliveryPeriodStart: Date | null,
    private deliveryPeriodEnd: Date | null,
    private reTusd: boolean | null,
    private participantClassName: string | null
  ) {}

  public getOperationId() {
    return this.operationId;
  }

  public getParticipantId() {
    return this.participantId;
  }

  public getIsOperationOwner() {
    return this.isOperationOwner;
  }

  public getProduct() {
    return this.product;
  }

  public getProductMeasurementUnit() {
    return this.productMeasurementUnit;
  }

  public getSubmarketCode() {
    return this.submarketCode;
  }

  public getBuyOffer() {
    return this.buyOffer;
  }

  public getSellOffer() {
    return this.sellOffer;
  }

  public getStartAt() {
    return this.startAt;
  }

  public getCloseAt() {
    return this.closeAt;
  }

  public getStatus() {
    return this.status;
  }

  public getDeliveryPeriodStart() {
    return this.deliveryPeriodStart;
  }

  public getDeliveryPeriodEnd() {
    return this.deliveryPeriodEnd;
  }

  public getReTusd() {
    return this.reTusd;
  }

  public getParticipantClassName() {
    return this.participantClassName;
  }
}

export class MyOrderResume {
  constructor(
    private operationId: string,
    private operatorId: number,
    private participantId: number,
    // private isOperationOwner: boolean | null,
    private product: string,
    private productMeasurementUnit: string,
    private submarketCode: string,
    private buyOffer: OfferResume | null,
    private sellOffer: OfferResume | null,
    private startAt: Date,
    private closeAt: Date // private status: string, // private deliveryPeriodStart: Date | null, // private deliveryPeriodEnd: Date | null, // private reTusd: boolean | null, // private participantClassName: string | null
  ) {}
  public getOperationId() {
    return this.operationId;
  }

  public getOperatorId() {
    return this.operatorId;
  }

  public getParticipantId() {
    return this.participantId;
  }

  // public getIsOperationOwner() {
  //   return this.isOperationOwner;
  // }

  public getProduct() {
    return this.product;
  }

  public getProductMeasurementUnit() {
    return this.productMeasurementUnit;
  }

  public getSubmarketCode() {
    return this.submarketCode;
  }

  public getBuyOffer() {
    return this.buyOffer;
  }

  public getSellOffer() {
    return this.sellOffer;
  }

  public getStartAt() {
    return this.startAt;
  }

  public getCloseAt() {
    return this.closeAt;
  }

  // public getStatus() {
  //   return this.status;
  // }

  // public getDeliveryPeriodStart() {
  //   return this.deliveryPeriodStart;
  // }

  // public getDeliveryPeriodEnd() {
  //   return this.deliveryPeriodEnd;
  // }

  // public getReTusd() {
  //   return this.reTusd;
  // }

  // public getParticipantClassName() {
  //   return this.participantClassName;
  // }
}
