import {
  ArrowDropDownRounded,
  ArrowDropUpRounded,
  Remove,
} from "@mui/icons-material";
import { Box } from "@mui/material";

export interface TextWithArrowIndicatorProps {
  mode: "up" | "down" | "equals";
  children?: JSX.Element;
  invertColors?: boolean;
}
export function TextWithArrowIndicator({
  mode,
  children,
  invertColors,
}: TextWithArrowIndicatorProps) {
  const color =
    mode === "up"
      ? invertColors
        ? "success"
        : "error"
      : mode === "down"
      ? invertColors
        ? "error"
        : "success"
      : "primary";

  return (
    <Box display="inline-flex" alignItems="center" flexWrap="nowrap">
      <Box component="span" color={`${color}.main`}>
        {children}
      </Box>

      <Box
        width={16}
        height={16}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {mode === "up" ? (
          <ArrowDropDownRounded color={color} />
        ) : mode === "down" ? (
          <ArrowDropUpRounded color={color} />
        ) : (
          <Remove color={color} sx={{ fontSize: 12 }} />
        )}
      </Box>
    </Box>
  );
}
