import { Observable, Subscription } from "rxjs";
import { StatusObservableImpl } from "../infra/api/ChatObservableImpl";

export type options = {
  chatroomId: string | null;
  updateLastReadingDate: boolean;
};

export abstract class StatusObservable extends Observable<boolean> {
  abstract subscribeWithOptions(
    options: options,
    next?: ((value: boolean) => void) | null | undefined,
    error?: ((error: any) => void) | null | undefined,
    complete?: (() => void) | null | undefined
  ): Subscription;

  abstract setOptions(options: options): void;

  abstract forceUpdateLastReadingDate(): void;
}

export class GetChatCounterpartStatusObservableService {
  private observable: StatusObservable | null = null;

  public getStatusObservable() {
    if (this.observable === null) {
      this.observable = new StatusObservableImpl();
    }

    return this.observable;
  }
}
