import React from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import {
  Alert as MuiAlert,
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
  CircularProgress,
} from "@mui/material";
import { toLocaleCurrencyString } from "../../utils/string";
import { useTranslation } from "react-i18next";
import { FileDownload, FileUpload } from "@mui/icons-material";
import { useGetBalanceData } from "./hooks/useGetBalanceData";
import { Link, useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";

const Alert = styled(MuiAlert)(spacing);

const InfoBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24;
`;

function getBalanceColor(balance: number) {
  return balance > 0 ? "success.main" : balance < 0 ? "error.main" : undefined;
}

export function BalancePageContent() {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const navigate = useNavigate();

  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  const {
    balanceData,
    isLoading: isLoadingBalanceData,
    isError: isErrorBalanceData,
  } = useGetBalanceData();

  const totalAvaliableBalance = React.useMemo(() => {
    return (
      balanceData?.assetBalances.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.asset.type]:
            (acc[curr.asset.type] ?? 0) +
            curr.balance / 10 ** curr.asset.precision,
        }),
        {} as Record<string, number>
      ) ?? { currency: 0, energy: 0 }
    );
  }, [balanceData?.assetBalances]);

  function handleWithdrawal(balance: number, url: string) {
    if (balance === 0) {
      enqueueSnackbar(t("Insufficient funds"), {
        variant: "error",
      });
      return;
    }
    navigate(url);
  }

  if (isErrorBalanceData) {
    return (
      <Alert mt={2} mb={3} severity="error">
        {t("Something went wrong.")}
      </Alert>
    );
  }

  if (isLoadingBalanceData || balanceData === undefined) {
    return (
      <InfoBox>
        <CircularProgress />
      </InfoBox>
    );
  }

  return (
    <Paper sx={{ maxWidth: 1200, mx: "auto", p: 8 }}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={5.5}>
          <Box>
            <Box
              sx={{
                width: "100%",
                display: "inline-flex",
                gap: 2,
              }}
            >
              <Typography fontSize={20}>
                {t("Available Financial Balance")}
              </Typography>

              <Typography
                fontSize={20}
                fontWeight={600}
                color={getBalanceColor(totalAvaliableBalance?.["currency"])}
                marginLeft="auto"
              >
                {toLocaleCurrencyString(
                  totalAvaliableBalance?.["currency"] ?? 0,
                  "BRL",
                  language
                )}
              </Typography>
            </Box>

            <Divider sx={{ marginTop: 3 }} />

            <Table
              size="small"
              sx={{
                "& .MuiTableCell-root": {
                  border: 0,
                },
              }}
            >
              <TableBody>
                {balanceData?.assetBalances
                  ?.filter((item) => item.asset.type === "currency")
                  ?.map((assetBalance) => (
                    <React.Fragment key={assetBalance.asset.name}>
                      {assetBalance.balance > 0 && (
                        <TableRow>
                          <TableCell>{assetBalance.asset.name}</TableCell>
                          <TableCell
                            sx={{
                              color: getBalanceColor(
                                assetBalance.balance /
                                  10 ** assetBalance.asset.precision
                              ),
                              fontWeight: 500,
                            }}
                            align="right"
                          >
                            {toLocaleCurrencyString(
                              assetBalance.balance /
                                10 ** assetBalance.asset.precision,
                              assetBalance.asset.code,
                              language
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  ))}
              </TableBody>
            </Table>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: 10,
                marginTop: 9,
              }}
            >
              <Button
                component={Link}
                to="/bank/financial-deposit"
                variant="contained"
                sx={{ minWidth: "16ch" }}
              >
                <FileDownload sx={{ marginRight: 2 }} /> {t("Deposit")}
              </Button>
              <Button
                variant="contained"
                sx={{ minWidth: "16ch" }}
                onClick={() =>
                  handleWithdrawal(
                    totalAvaliableBalance?.["currency"] ?? 0,
                    "/bank/financial-withdrawal"
                  )
                }
              >
                <FileUpload sx={{ marginRight: 2 }} /> {t("Withdraw")}
              </Button>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={1}>
          <Divider
            orientation={isMdUp ? "vertical" : "horizontal"}
            sx={isMdUp ? { marginTop: 3, width: 0, mx: "auto" } : { my: 8 }}
          />
        </Grid>

        <Grid item xs={12} md={5.5}>
          <Box>
            <Box
              sx={{
                width: "100%",
                display: "inline-flex",
                gap: 2,
              }}
            >
              <Typography fontSize={20}>
                {t("Available Energy Balance")}
              </Typography>

              <Typography
                fontSize={20}
                fontWeight={600}
                color={getBalanceColor(totalAvaliableBalance?.["energy"])}
                marginLeft="auto"
              >
                {(totalAvaliableBalance?.["energy"] ?? 0).toLocaleString(
                  language,
                  {
                    minimumFractionDigits: 3,
                    maximumFractionDigits: 3,
                  }
                )}{" "}
                MWh
              </Typography>
            </Box>

            <Divider sx={{ marginTop: 3 }} />

            <Table
              size="small"
              sx={{
                "& .MuiTableCell-root": {
                  border: 0,
                },
              }}
            >
              <TableBody>
                {balanceData?.assetBalances
                  ?.filter((item) => item.asset.type === "energy")
                  ?.map((assetBalance) => (
                    <React.Fragment key={assetBalance.asset.name}>
                      {assetBalance.balance > 0 && (
                        <TableRow>
                          <TableCell>{assetBalance.asset.name}</TableCell>
                          <TableCell
                            sx={{
                              color: getBalanceColor(
                                assetBalance.balance /
                                  10 ** assetBalance.asset.precision
                              ),
                              fontWeight: 500,
                            }}
                            align="right"
                          >
                            {(
                              assetBalance.balance /
                              10 ** assetBalance.asset.precision
                            ).toLocaleString(language, {
                              minimumFractionDigits:
                                assetBalance.asset.precision,
                              maximumFractionDigits:
                                assetBalance.asset.precision,
                            })}
                          </TableCell>
                          <TableCell
                            sx={{ fontWeight: 500, color: "success.main" }}
                            align="right"
                          >
                            {assetBalance.asset.unit}
                          </TableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  ))}
              </TableBody>
            </Table>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: 10,
                marginTop: 9,
              }}
            >
              <Button
                component={Link}
                to="/bank/energy-deposit"
                variant="contained"
                sx={{ minWidth: "16ch" }}
              >
                <FileDownload sx={{ marginRight: 2 }} /> {t("Deposit")}
              </Button>
              <Button
                variant="contained"
                sx={{ minWidth: "16ch" }}
                onClick={() =>
                  handleWithdrawal(
                    totalAvaliableBalance?.["energy"] ?? 0,
                    "/bank/energy-withdrawal"
                  )
                }
              >
                <FileUpload sx={{ marginRight: 2 }} /> {t("Withdraw")}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
}
