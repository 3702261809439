import React from "react";
import { Box, Button, Grid, InputAdornment } from "@mui/material";

import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { useSnackbar } from "notistack";

import {
  getCurrencySymbol,
  toLocaleCurrencyString,
} from "../../../../utils/string";
import { FormTextField } from "../../../../components/formFields/FormTextField";
import { SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import {
  submitChangeOfferPrice,
  SubmitChangeOfferPriceProps,
} from "../../../../services/offers";
import useConfirmationModal from "../../../../hooks/useConfirmationModal";
import { ApiError } from "../../../../types/error";
import { StyledModal } from "../../../../components/StyledModal";
import {
  OfferResume,
  OperationResume,
} from "../../entities/data-objects/OperationResume";

interface ChangeOfferPriceModalFormInputs {
  value: string;
}

const changeOfferPriceModalFormDefaultValues: ChangeOfferPriceModalFormInputs =
  {
    value: "",
  };
interface ChangeOfferPriceProps {
  open: boolean;
  onClose: () => void;
  offerData: OfferResume;
  operationData: OperationResume;
  participantId: string | number | undefined;
}
export function ChangeOfferPriceModal({
  open,
  onClose: onCloseProp,
  offerData,
  participantId,
  operationData,
}: ChangeOfferPriceProps) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { control, handleSubmit, reset } =
    useForm<ChangeOfferPriceModalFormInputs>({
      defaultValues: changeOfferPriceModalFormDefaultValues,
    });

  const onClose = React.useCallback(() => {
    reset();
    onCloseProp();
  }, [onCloseProp, reset]);

  const submitChangeOfferPriceMutation = useMutation<
    any,
    unknown,
    SubmitChangeOfferPriceProps
  >(submitChangeOfferPrice, {
    onSuccess: () => {
      enqueueSnackbar(t("The operation completed successfully"), {
        variant: "success",
      });

      onClose();
    },
    onError: (e) => {
      enqueueSnackbar(
        t((e as ApiError | null)?.error ?? "Something went wrong."),
        {
          variant: "error",
        }
      );
    },
  });

  const { showConfirmationModal } = useConfirmationModal();

  const isSpread = React.useMemo(() => {
    return offerData && offerData.getIsPldFixed();
  }, [offerData]);

  const onSubmit = React.useCallback<
    SubmitHandler<ChangeOfferPriceModalFormInputs>
  >(
    (data) => {
      if (!offerData) {
        return;
      }

      showConfirmationModal({
        onSubmit: () => {
          submitChangeOfferPriceMutation.mutate({
            operationId: Number(offerData.getOperationCode()),
            type: offerData.getType() === "COMPRA" ? "BUY" : "SELL",
            priceValue: Number(data.value),
          });
        },
        message: t(
          "Do you really want to change offer {{code}} price to {{price}}?",
          {
            code: offerData.getOperationCode(),
            price: isSpread
              ? `PLD ${
                  Number(data.value) < 0 ? "-" : "+"
                } ${toLocaleCurrencyString(
                  Math.abs(Number(data.value)),
                  "BRL",
                  language
                )}/${operationData.getProductMeasurementUnit()}`
              : `${toLocaleCurrencyString(
                  Number(data.value),
                  "BRL",
                  language
                )}/${operationData.getProductMeasurementUnit()}`,
          }
        ),
      });
    },
    [
      isSpread,
      language,
      offerData,
      operationData,
      showConfirmationModal,
      submitChangeOfferPriceMutation,
      t,
    ]
  );

  const onInvalidSubmit = React.useCallback<
    SubmitErrorHandler<ChangeOfferPriceModalFormInputs>
  >(() => {
    enqueueSnackbar(t("You must fill in all required fields"), {
      variant: "error",
    });
  }, [enqueueSnackbar, t]);

  if (offerData === null) return <></>;

  return (
    <StyledModal
      open={open}
      onClose={onClose}
      maxWidth={600}
      title={t("Change Offer Price")}
      leftBox={offerData.getOperationCode()}
    >
      <>
        {offerData !== null && (
          <Grid container justifyContent="center">
            <form
              onSubmit={handleSubmit(onSubmit, onInvalidSubmit)}
              style={{ width: "100%" }}
            >
              <Grid container justifyContent="center">
                <Grid item xs={12} sm={8}>
                  <FormTextField
                    control={control}
                    name="value"
                    rules={{
                      required: {
                        value: true,
                        message: t("This field is required"),
                      },
                      validate: (value) =>
                        isSpread
                          ? true
                          : Number(value) > 0 ||
                            t("The data entered is invalid", {
                              data: t("Value"),
                            }),
                    }}
                    label={offerData.getIsPldFixed() ? t("Spread") : t("Value")}
                    fullWidth
                    my={2}
                    type="number"
                    inputProps={{
                      step: "0.01",
                      min: isSpread ? undefined : 0,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {getCurrencySymbol("BRL", language)}
                        </InputAdornment>
                      ),
                    }}
                    helperText={`${t("Current")}: ${toLocaleCurrencyString(
                      offerData.getIsPldFixed()
                        ? offerData.getSpread()!
                        : offerData.getValue()!,
                      "BRL",
                      language
                    )}`}
                  />
                </Grid>
              </Grid>

              <Box
                display="inline-flex"
                justifyContent="center"
                width="100%"
                textAlign="center"
                gap={4}
                mt={8}
              >
                {/* <Button
              type="button"
              variant="outlined"
              color="primary"
              onClick={onClose}
            >
              {t("Go Back")}
            </Button> */}
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitChangeOfferPriceMutation.isLoading}
                >
                  {t("Change Offer Price")}
                </Button>
              </Box>
            </form>
          </Grid>
        )}
      </>
    </StyledModal>
  );
}
