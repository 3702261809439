import { Either } from "monet";
import { MessagesRepositoryImpl } from "../infra/api/MessagesRepositoryImpl";

export abstract class MessagesRepository {
  abstract saveChatRoomMessage(
    message: string,
    chatRoomId: string
  ): Promise<
    Either<"CHAT_FINISHED" | "CONTENT_BLOCKED" | "GENERIC_ERROR", undefined>
  >;
}

export class SendChatMessageService {
  private messagesRepository: MessagesRepositoryImpl;
  constructor() {
    this.messagesRepository = new MessagesRepositoryImpl();
  }

  public async sendChatRoomMessage(message: string, chatRoomId: string) {
    return this.messagesRepository.saveChatRoomMessage(message, chatRoomId);
  }
}
