import { useContext } from "react";
import { ConfirmationModalContext } from "../contexts/ConfirmationModalContext";

const useConfirmationModal = () => {
  const context = useContext(ConfirmationModalContext);

  if (!context)
    throw new Error(
      "ConfirmationModalContext must be placed within ConfirmationModalProvider"
    );

  return context;
};

export default useConfirmationModal;
